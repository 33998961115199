import { IonCol, IonContent, IonFooter, IonGrid, IonPage, IonRadioGroup, IonRouterLink, IonRow } from '@ionic/react';
import Button from 'components/Button';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WelcomeImage } from 'components/Icons/welcome.svg';
import { ReactComponent as Welcome2Image } from 'components/Icons/welcome2.svg';
import { ReactComponent as Welcome3Image } from 'components/Icons/welcome3.svg';
import { ReactComponent as LuImage } from 'components/Icons/lu.svg';
import { ReactComponent as EnImage } from 'components/Icons/en.svg';

import CardWithRadioButton from 'components/Cards/CardWithRadioButton';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Link } from 'react-router-dom';

type OnboardingForm = {
    language: 'lu' | 'en';
};

enum OnboardingStep {
    lang = 1,
    whysubscribe = 2,
    end = 3,
}

function OnboardingStepLang({ onContinue }: { onContinue: () => void }) {
    const { t } = useTranslation();

    const schema = yup.object({
        language: yup.string().required(t('onboarding.language-required')),
    });

    const { register, handleSubmit, watch } = useForm<OnboardingForm>({
        resolver: yupResolver(schema),
        defaultValues: {},
    });

    function onSubmit(data: OnboardingForm) {
        console.error(data);
        onContinue();
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <IonPage>
                <IonContent>
                    <IonGrid fixed>
                        <WelcomeImage className="mt-[50px] m-auto mb-[50px]" />
                        <div className="typo-h1 font-bold">{t('onboarding.welcome')}</div>
                        <div className="typo-h2 regular mt-[10px] mb-[30px]">{t('onboarding.select-lang')}</div>
                        <IonRadioGroup>
                            <IonRow className="flex flex-row flex-wrap gap-[16px] mb-blockInnerMobile md:mb-blockInner">
                                <IonCol>
                                    <CardWithRadioButton
                                        title={t('onboarding.lang.en')}
                                        value="en"
                                        register={register('language')}
                                        topContent={
                                            <div className="w-[45px] h-[45px]">
                                                <EnImage />
                                            </div>
                                        }
                                    />
                                </IonCol>
                                <IonCol>
                                    <CardWithRadioButton
                                        title={t('onboarding.lang.lu')}
                                        value="lu"
                                        register={register('language')}
                                        topContent={
                                            <div className="w-[45px] h-[45px]">
                                                <LuImage />
                                            </div>
                                        }
                                    />
                                </IonCol>
                            </IonRow>
                        </IonRadioGroup>
                    </IonGrid>
                </IonContent>
                <IonFooter>
                    <div className="grid place-items-end m-[20px]">
                        <Button
                            type="submit"
                            forceHoverEffectActive={!!watch?.('language')?.length}
                            value={t('main.continue')}
                            className="w-fit"
                        />
                    </div>
                </IonFooter>
            </IonPage>
        </form>
    );
}

function OnboardingStepWhySubscribe({ onContinue }: { onContinue: () => void }) {
    const { t } = useTranslation();

    return (
        <IonPage>
            <IonContent>
                <IonGrid fixed>
                    <Welcome2Image className="mt-[50px] m-auto mb-[50px]" />
                    <div className="typo-h1 font-bold">{t('onboarding.welcome')}</div>
                    <div className="typo-h2 regular mt-[10px] mb-[45px]">{t('onboarding.why-subscribe')}</div>
                    <div className="typo-b1 regular">{t('onboarding.why-sub-description')}</div>
                </IonGrid>
            </IonContent>
            <IonFooter className="p-[20px] grid place-items-center">
                <Button
                    className="w-fit"
                    value={t('next')}
                    onClick={onContinue}
                    forceHoverEffectActive
                />
            </IonFooter>
        </IonPage>
    );
}

function OnboardingStepEnd() {
    const { t } = useTranslation();

    return (
        <IonPage>
            <IonContent>
                <IonGrid fixed>
                    <Welcome3Image className="mt-[50px] m-auto mb-[50px]" />
                    <div className="typo-h1 font-bold">{t('onboarding.manage-bookmark')}</div>
                    <div className="typo-b1 regular">{t('onboarding.manage-bookmark-description')}</div>
                </IonGrid>
            </IonContent>
            <IonFooter className="p-[20px] grid place-items-center">
                <Link to="/login">
                    <Button
                        value={t('onboarding.login')}
                        forceHoverEffectActive
                    />
                </Link>
                <IonRouterLink
                    routerDirection="forward"
                    routerLink="/"
                    className="text-white bg-transparent typo-b2 regular underline mb-4 block cursor-pointer mt-[30px]"
                >
                    {t('onboarding.continue-without-account')}
                </IonRouterLink>
            </IonFooter>
        </IonPage>
    );
}

export default function OnboardingPage() {
    const [step, setStep] = useState<OnboardingStep>(OnboardingStep.lang);

    if (step === OnboardingStep.lang) {
        return <OnboardingStepLang onContinue={() => setStep(OnboardingStep.whysubscribe)} />;
    }
    if (step === OnboardingStep.whysubscribe) {
        return <OnboardingStepWhySubscribe onContinue={() => setStep(OnboardingStep.end)} />;
    }
    if (step === OnboardingStep.end) {
        return <OnboardingStepEnd />;
    }
    return <OnboardingStepEnd />;
}
