import { getDeviceType } from './generateAdPayload';

export async function getPermutiveContent() {
    const sections = window.location.pathname.split('/');
    const deviceType = await getDeviceType();
    return {
        site: window.location.hostname,
        deviceType,
        sectionLevel1: sections[1] ?? '',
        sectionLevel2: sections[2] ?? '',
        sectionLevel3: sections[3] ?? '',
        sectionLevel4: sections[4] ?? '',
        // need a list of what is search, property, info, simulator
        pageType: 'home',
        lang: 'lu',
    };
}

// do not send if it is an article ?
export default async function sendPermutivePayload() {
    // @ts-ignore
    permutive.track('Pageview', {
        content: await getPermutiveContent(),
    });
}
