import { useLocation } from 'react-router';

function containsAccount(text: string) {
    const regex = /account/i; // 'i' flag makes it case-insensitive
    return regex.test(text);
}

export default function ShouldDisplayHoverboardAd() {
    const location = useLocation();
    const pathAsArray = location.pathname.split('/');
    return {
        shouldDisplayHoverboardAd:
            pathAsArray[pathAsArray.length - 1] === 'terms' ||
            pathAsArray[pathAsArray.length - 1] === 'menu' ||
            pathAsArray[pathAsArray.length - 2] === 'eldo4real' ||
            pathAsArray[pathAsArray.length - 1] === 'reset-password' ||
            containsAccount(location.pathname),
    };
}
