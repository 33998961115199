import { IonLabel, useIonRouter } from '@ionic/react';
import { useEffect, useState } from 'react';
import Pill from 'components/PillComponent';
import { ReactComponent as Camera } from 'components/Icons/camera.svg';
import { useTranslation } from 'react-i18next';
import useResponsive from 'features/responsive/responsive';
import PlayerButton from '../../features/radio/component/player/PlayerButton';
import Card from '../Card';

type CardProps = {
    src: string;
    sponsor?: string | null;
    bigSponsor?: boolean;
    title: string;
    isLive?: boolean;
    liveStart?: string | null;
    liveEnd?: string | null;
    showRunner?: string;
    isPlaying?: boolean;
    isSimpleView?: boolean;
    onPlayerClick?: VoidFunction;
    hasActions?: boolean; // for v2 actions like favorite or reminder,
    className?: string;
    isVertical?: boolean;
    displayWatchBtn?: boolean;
    displayPlayBtn?: boolean;
};

function LivePill({ className, isLive }: { className?: string; isLive?: boolean }) {
    const { t } = useTranslation();
    return isLive ? (
        <span className={className || ''}>
            <Pill
                isLive={isLive}
                color="light"
                text={t('live')}
            />
        </span>
    ) : (
        <div />
    );
}

function RadioCard({
    src,
    sponsor,
    bigSponsor = true,
    title,
    isLive,
    liveStart,
    liveEnd,
    showRunner,
    onPlayerClick = () => {},
    isSimpleView,
    hasActions,
    isPlaying,
    className,
    isVertical,
    displayWatchBtn,
    displayPlayBtn = true,
}: CardProps) {
    const { t } = useTranslation();
    const { IsMobile } = useResponsive();
    const router = useIonRouter();

    const [needFallbackImage, setNeedFallbackImage] = useState<string>();

    const playVideo = () => {
        router.push('/radio/live-stream');
    };

    return (
        <Card className={`py-0 px-0  ${!isVertical ? 'flex-row' : 'flex-col'} ${className || ''}`}>
            <div className={`rounded  ${!isVertical ? 'w-[40%] md:w-[50%]' : ''} relative`}>
                <LivePill
                    className="absolute left-[7px] top-[7px] md:left-[17px] md:top-[20px]"
                    isLive={isLive}
                />
                <img
                    src={needFallbackImage === src ? '/assets/img/placeholderimg.svg' : src}
                    alt={showRunner}
                    onError={() => {
                        setNeedFallbackImage(src);
                    }}
                    className={`object-cover w-[100%] min-h-[100%] ${
                        needFallbackImage === src ? 'max-h-[585px]' : ''
                    } ${!isVertical ? 'rounded-none rounded-l' : 'rounded'}`}
                />
                {sponsor && (
                    <div className="absolute bottom-[16px] w-[100%]">
                        <img
                            src={sponsor}
                            alt={showRunner}
                            className={`mx-auto ${
                                bigSponsor
                                    ? 'md:w-[120px] w-[80px] border-[5px] md:border-[10px]'
                                    : 'md:w-[80px] w-[60px] border-[5px]'
                            }  border-white rounded-lg`}
                        />
                    </div>
                )}
            </div>

            <div className={`flex-1 flex flex-col justify-between p-[25px] ${!isVertical ? 'md:py-[60px]' : ''}`}>
                {!isSimpleView && (
                    <div>
                        <h2 className="mb-2 typo-b1 md:typo-h2 font-semibold">{title}</h2>
                        <div className="typo-b2 md:typo-b1 regular">
                            <IonLabel>{showRunner} </IonLabel>
                            {liveStart && liveEnd && (
                                <>
                                    <IonLabel>{liveStart}</IonLabel> - <IonLabel>{liveEnd}</IonLabel>
                                </>
                            )}
                        </div>
                    </div>
                )}

                {displayWatchBtn || displayPlayBtn ? (
                    <section className="mt-[30px] flex items-center justify-between gap-1">
                        {displayPlayBtn ? (
                            <div
                                className="flex items-center gap-[10px]"
                                onClick={onPlayerClick}
                            >
                                <span className="hidden md:block typo-b2 regular">{t('listen')}</span>
                                <PlayerButton
                                    onClick={() => {}}
                                    type={`${isPlaying ? 'pause' : 'play'}`}
                                />
                            </div>
                        ) : (
                            ''
                        )}
                        {displayWatchBtn && isLive ? (
                            <button
                                onClick={() => playVideo()}
                                className="flex items-center gap-[10px]"
                            >
                                <span className="hidden md:block typo-b2 regular">{t('watch')}</span>
                                <div className="bg-white rounded-full grid place-items-center h-[45px] w-[45px] hover:bg-[rgba(255,255,255,0.9)] md:hover:scale-[110%] transform transition-all">
                                    <Camera />
                                </div>
                            </button>
                        ) : (
                            ''
                        )}
                    </section>
                ) : (
                    ''
                )}
            </div>
        </Card>
    );
}

RadioCard.defaultProps = {
    isLive: false,
    liveStart: null,
    liveEnd: null,
    showRunner: null,
    isSimpleView: false,
    hasActions: false,
    isPlaying: false,
    onPlayerClick: () => {},
};

export default RadioCard;
