import { FormEvent } from 'react';
import uuid from 'react-uuid';

interface SwitchProps {
    checked: boolean;
    checkedLabel?: string;
    uncheckedLabel?: string;
    errors?: string;
    onChange?: (e: FormEvent<HTMLInputElement>) => void;
}

export default function Switch({ checked, checkedLabel, uncheckedLabel, errors, onChange }: SwitchProps) {
    const inputId = uuid();

    return (
        <div className="mt-2">
            <div className="flex gap-2.5 items-center">
                <label
                    className={`cursor-pointer w-[48px] h-[28px] ${
                        checked ? 'bg-success' : 'bg-[#BEBEBE]'
                    } rounded-full relative block`}
                    htmlFor={inputId}
                >
                    <input
                        type="checkbox"
                        className="hidden"
                        id={inputId}
                        checked={checked}
                        onChange={onChange}
                    />
                    <div
                        className={`rounded-full h-[20px] w-[20px] bg-white transition duration-300 absolute left-[5px] top-[4px] ${
                            checked ? 'translate-x-[20px]' : ''
                        }`}
                    />
                </label>
                {checkedLabel && checked && checkedLabel}
                {uncheckedLabel && !checked && uncheckedLabel}
            </div>
            {errors && <small className="block text-danger">{errors}</small>}
        </div>
    );
}
