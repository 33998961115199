import {
    IonCol,
    IonCard,
    IonCardContent,
    IonList,
    IonListHeader,
    IonItem,
    IonGrid,
    IonRow,
    IonThumbnail,
    IonImg,
} from '@ionic/react';
import { format } from 'date-fns';
import { t } from 'i18next';
import { dateIsValid, formatMode } from '../../lib/utils.dates';
import { FiveDaysForecast } from './types';

export default function WeeklyForecast({ forecast }: { forecast: FiveDaysForecast[] }) {
    return (
        <IonCol
            size-xs={12}
            size-lg={4}
            className="meteo-cards-row__col"
        >
            <IonCard>
                <IonCardContent>
                    <IonList className="weather-block weekly-weather">
                        <IonListHeader className="typo-b1 font-semibold">{t('meteo.fiveDaysForecast')}</IonListHeader>
                        {forecast?.map(({ id, symbolUrl, temperature, date }, index) => (
                            <IonItem key={id}>
                                <IonGrid>
                                    <IonRow className="flex-nowrap">
                                        <IonCol className="weekly-weather-day typo-b1 medium">
                                            {date && dateIsValid(date) && (
                                                <span>
                                                    {index === 0
                                                        ? 'Today'
                                                        : `${format(new Date(date), formatMode.ccc)}.`}
                                                </span>
                                            )}
                                        </IonCol>

                                        <IonCol>
                                            <IonThumbnail>
                                                <IonImg src={symbolUrl} />
                                            </IonThumbnail>
                                        </IonCol>

                                        <IonCol>
                                            <span className="weekly-weather__temp typo-b1 bold">
                                                <div className="flex justify-between w-[75px]">
                                                    <div className="text-[#f08b1f] text-right">{temperature?.max}°</div>
                                                    <div>{temperature?.min}°</div>
                                                </div>
                                            </span>
                                        </IonCol>
                                    </IonRow>
                                </IonGrid>
                            </IonItem>
                        ))}
                    </IonList>
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
}
