import Card from 'components/Card';
import { RadarIcon } from 'components/Icons';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import useTrafficInfos from './useTrafficInfo';

export default function TrafficRadars() {
    const { t } = useTranslation();
    const { trafficInfos } = useTrafficInfos();

    return (
        <div>
            <section>
                <Title title={t('radars')} />
                <p className="m-4">
                    {t('police_info_text')}
                    <br />
                    {t('police_info_text2')}
                </p>
                <div className="mt-block-innerMobile md:mt-block-inner flex flex-col gap-4 md:grid md:grid-cols-3">
                    {trafficInfos?.speedCameras.map((radar, index) => (
                        <Card
                            className="flex items-center"
                            key={index}
                        >
                            <div>
                                <RadarIcon />
                            </div>
                            <div className="ml-4 flex flex-col">
                                <p className="typo-b1 font-semibold">{radar.city}</p>
                                <p className="typo-b2 capitalize">{radar.street}</p>
                            </div>
                        </Card>
                    ))}
                </div>
            </section>
        </div>
    );
}
