import { useQuery } from '@tanstack/react-query';
import { Picture, PicturesReport } from 'types';
import { getLetzAiGallery } from '../../../lib/ApiService';

export default function usePictureReportsGallery(slug: string) {
    const { data, status } = useQuery<Picture[]>([`pictureReportsGallery-${slug}`], () => getLetzAiGallery(slug), {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        data: { title: '', gallery: data },
        status,
    };
}
