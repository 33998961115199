import { IonCol, IonGrid, IonRow } from '@ionic/react';
import useGamePageInfo from 'features/game/useGamePageInfo';
import { useTranslation } from 'react-i18next';
import { Game } from 'types';
import GameCard from 'features/game/GameCard';
import { Link } from 'react-router-dom';
import LoadingIndicator from 'components/LoadingIndicator';
import Title from 'components/Title';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import PageLayout from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import AdRightBlock from 'components/Ads/AdRightBlock';
import CardListing from 'components/core/CardListing';

export default function GamePage() {
    const { ads } = useAds({ page: 'game' });
    const { t } = useTranslation();
    const { games, isLoading } = useGamePageInfo();

    const { IsMobile } = useResponsive();

    return (
        <PageLayout
            title={t('game_info.Game')}
            hasBackButton
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}
                <IonRow>
                    <IonCol className="p-0 mb-4 md:mb-10">
                        <div className="relative">
                            <div className="absolute right-[-30px] md:hidden">
                                <img
                                    src="./assets/img/team/Ornament-81.png"
                                    alt="ornament"
                                />
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                {!IsMobile && (
                    <Title
                        className="mb-blockInnerMobile md:mb-blockInner"
                        main
                        title={t('game_info.Game')}
                    />
                )}
                {!isLoading ? (
                    <AdRightBlock ad={ads?.data?.[2]}>
                        <CardListing>
                            {games?.map((game: Game, index: number) => (
                                <div key={index}>
                                    <Link
                                        to={`/game/${game.id}`}
                                        className="block"
                                    >
                                        <GameCard
                                            key={index}
                                            id={game.id}
                                            name={game.name}
                                            location={game.location}
                                            dateTime={game.dateTime}
                                            badge={index + 1}
                                        />
                                    </Link>
                                </div>
                            ))}
                        </CardListing>
                    </AdRightBlock>
                ) : (
                    <LoadingIndicator />
                )}
            </IonGrid>
            {ads && (
                <AdComponent
                    ad={ads.data[1]}
                    className="mt-[50px]"
                />
            )}
        </PageLayout>
    );
}
