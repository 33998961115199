import { IonRow, IonRouterLink, IonItem, IonGrid } from '@ionic/react';
import { forwardRef, RefObject, ForwardedRef } from 'react';
import { useTranslation } from 'react-i18next';
import Title from 'components/Title';
import MenuItem from 'features/menu/components/MenuItem';
import useAds from 'hooks/useAds';
import SocialNetworks from './SocialNetworks';
import AdComponent from './Ads/AdComponent';

const menuItems = [
    {
        iconName: 'traffic',
        label: 'main.traffic_info',
        linkTo: '/traffic-info',
    },
    {
        iconName: 'best-moments',
        label: 'eldo4real',
        linkTo: '/podcast-replay/category/eldo4real',
    },
    {
        iconName: 'actions',
        label: 'menu.actions',
        linkTo: '/aktiounen',
    },
    {
        iconName: 'shows',
        label: 'menu.shows',
        linkTo: '/radio/shows',
    },
    {
        iconName: 'trophy',
        label: 'menu.game',
        linkTo: '/game',
    },
    {
        iconName: 'podcast-replay',
        label: 'menu.podcast_replay',
        linkTo: '/podcast-replay',
    },
    {
        iconName: 'weather',
        label: 'menu.weather',
        linkTo: '/aktuell/meteo',
    },
    {
        iconName: 'cinema',
        label: 'menu.cinema',
        linkTo: '/kino',
    },
    {
        iconName: 'gaming',
        label: 'menu.gaming',
        linkTo: '/radio/gaming',
    },
    {
        iconName: 'travel',
        label: 'menu.travel',
        linkTo: '/radio/travel',
    },
    {
        iconName: 'fashionAndBeauty',
        label: 'menu.fashionAndBeauty',
        linkTo: '/radio/fashionAndBeauty',
    },
    {
        iconName: 'lifestyle',
        label: 'menu.lifestyle',
        linkTo: '/radio/lifestyle/lifestyle',
    },
    {
        iconName: 'foodAndDrinks',
        label: 'menu.foodAndDrinks',
        linkTo: '/radio/foodAndDrinks',
    },
    {
        iconName: 'topics',
        label: 'menu.topics',
        linkTo: '/radio/rubriken',
    },
    {
        iconName: 'picture-report',
        label: 'menu.pictures_report',
        linkTo: '/events/fotoen',
    },
    {
        iconName: 'charts',
        label: 'menu.charts',
        linkTo: '/musek/charts',
    },
    {
        iconName: 'ticketing',
        label: 'menu.ticketing',
        linkTo: '/events/ticket-bourse',
    },
    {
        iconName: 'shop',
        label: 'menu.shop',
        linkTo: '/aktuell/shop',
    },
    {
        iconName: 'team',
        label: 'menu.team',
        linkTo: '/radio/team',
    },
];

type BurgerProps = {
    cssClass?: string;
    hasBackgroundColor?: boolean;
};

function BurgerMenu({ cssClass = '', hasBackgroundColor }: BurgerProps, ref: ForwardedRef<Element>) {
    const { t } = useTranslation();
    const { ads } = useAds({ page: 'menu' });

    return (
        <IonGrid
            ref={ref as unknown as RefObject<HTMLIonGridElement>}
            className={`main-burger-menu ${hasBackgroundColor ? 'bg-background' : ''} md:py-9 md:px-[10px] ${
                cssClass ?? ''
            }`}
            fixed
        >
            {ads && <AdComponent ad={ads.data[0]} />}
            <p className="mb-4">{t('menu.follow_us')}</p>

            <SocialNetworks />

            <IonRow>
                <Title
                    className="my-8"
                    title={t('main.my_eldo')}
                />
            </IonRow>

            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6 gap-4 lg:gap-8 mb-4 md:mb-8">
                {menuItems.map(({ iconName, label, linkTo }, index) => (
                    <MenuItem
                        key={index}
                        iconName={iconName}
                        label={t(label)}
                        linkTo={linkTo}
                    />
                ))}
            </div>

            <div className="mb-4 md:mb-8 md:max-w-[300px]">
                <IonItem
                    className="menu-item menu-item--simple"
                    routerLink="/terms"
                    detail
                >
                    {t('menu.legal_mentions')}
                </IonItem>
            </div>
        </IonGrid>
    );
}

export default forwardRef(BurgerMenu);
