import { IonButton, IonItem } from '@ionic/react';
import { HTMLAttributes, useState } from 'react';
import { ReactComponent as NoImgIcon } from 'components/Icons/noimg.svg';
import Card from '../Card';

interface FlatThumbProps extends HTMLAttributes<HTMLDivElement> {
    artistName: string;
    songTitle: string;
    songDuration?: string;
    isChart?: boolean;
    imgSrc?: string;
    linkTo?: string;
}

function FlatThumbCard({
    artistName,
    songTitle,
    songDuration,
    imgSrc,
    isChart,
    linkTo,
    className = '',
}: FlatThumbProps) {
    const [fallbackImg, setFallbackImg] = useState<boolean>(false);

    return (
        <Card className={`flat-thumbed-card my-0 px-[20px] ${className} `}>
            <div className="">
                <div className="flex items-center">
                    <div className="rounded min-w-[60px]">
                        {fallbackImg ? (
                            <NoImgIcon className="w-full" />
                        ) : (
                            <img
                                alt={`${songTitle} - ${artistName}`}
                                className="rounded object-cover w-[60px] h-[60px]"
                                src={imgSrc}
                                onError={() => {
                                    setFallbackImg(true);
                                }}
                            />
                        )}
                    </div>

                    <div className="px-4 py-0">
                        <div className="typo-b1 font-semibold">{artistName}</div>
                        <div className="typo-b2 regular ">
                            <p>
                                {songTitle} {songDuration && `- ${songDuration}`}
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {isChart && (
                <IonItem>
                    <IonButton>+1</IonButton>
                </IonItem>
            )}
        </Card>
    );
}

FlatThumbCard.defaultProps = {
    songDuration: 0,
    isChart: false,
    linkTo: null,
    imgSrc: 'https://ionicframework.com/docs/demos/api/thumbnail/thumbnail.svg',
};

export default FlatThumbCard;
