import moment from 'moment';
import { Link } from 'react-router-dom';
import { prepareUrlTitle } from 'lib/utils';
import { Event } from './types';

type EventAutocompleteOptProps = {
    event: Event;
    selected: boolean;
};

export default function EventAutocompleteOpt({ event, selected }: EventAutocompleteOptProps) {
    return (
        <Link to={`/events/agenda/${event.id as unknown as string}/${prepareUrlTitle(event?.name)}`}>
            <div>
                <div className="flex gap-[16px] items-center">
                    <img
                        className="w-[45px] h-[57px] object-cover rounded-[10px]"
                        src={event.imageUrl}
                        alt=""
                    />
                    <div>
                        <div className="line-clamp-1 typo-b1 bold">{event.name}</div>
                        <div className="typo-b2 regular">{moment(event.startDate).format('DD.MM.YY')}</div>
                    </div>
                </div>
            </div>
        </Link>
    );
}
