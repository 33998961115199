import { IonGrid } from '@ionic/react';
import AdComponent from 'components/Ads/AdComponent';
import LoadingIndicator from 'components/LoadingIndicator';
import PageLayout from 'components/PageLayout';
import Section from 'components/core/Section';
import MovieCard from 'features/cinema/hooks/components/MovieCard';
import MovieSessionsPresenter from 'features/cinema/hooks/components/MovieSessionsPresenter';
import useMovie from 'features/cinema/hooks/useMovie';
import useResponsive from 'features/responsive/responsive';
import useAds from 'hooks/useAds';
import usePermutiveArticle from 'hooks/usePermutive';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

export default function CinemaMoviePage() {
    const { id } = useParams<{ id: string }>();
    const { data, isLoading } = useMovie({ id });
    const { t } = useTranslation();
    const { ads } = useAds({ page: 'movie-detail', articleId: id });

    const LANG = 'lu';

    const wordsCounter = data?.movie?.description[LANG].synopsis.trim().replace(/\s+/g, ' ').split(' ') ?? [];

    usePermutiveArticle({
        audioContent: false,
        videoContent: false,
        photoGallery: false,
        authors: [],
        modifiedAt: new Date('1.1.1970'),
        publishedAt: new Date('1.1.1970'),
        tags: [],
        wordCount: wordsCounter.length,
        send: !isLoading,
    });

    useEffect(() => {}, [data]);

    if (!data && !isLoading) {
        return <div className="text-white">Loading...</div>;
    }

    return (
        <PageLayout
            title={data?.movie?.title || ''}
            hasBackButton
        >
            {data && !isLoading ? (
                <IonGrid fixed>
                    {ads && <AdComponent ad={ads.data[0]} />}
                    <Section
                        component="div"
                        className="lg:flex lg:gap-block"
                    >
                        <Section>
                            <MovieCard
                                isDetails
                                lang={LANG}
                                movie={data?.movie}
                            />
                        </Section>
                        <Section title={t('cinema.synopsis')}>
                            <div className="lg:flex lg:gap-block items-start">
                                <div className="max-w-[730px]">{data.movie.description[LANG].synopsis}</div>
                                {ads && (
                                    <AdComponent
                                        hasSpacings={false}
                                        ad={ads.data[1]}
                                    />
                                )}
                            </div>
                            <div className="mt-block-innerMobile md:mt-block-inner md:gap-standard gap-standardMobile flex items-center">
                                <img
                                    src={data.movie.images.image1}
                                    alt=""
                                    className="flex-1 w-1/2"
                                />
                                <img
                                    src={data.movie.images.image2}
                                    alt=""
                                    className="flex-1 w-1/2"
                                />
                            </div>
                        </Section>
                    </Section>
                    {ads && <AdComponent ad={ads.data[2]} />}
                    <Section>
                        <MovieSessionsPresenter
                            firstDate={new Date()}
                            movieDetails={data}
                        />
                    </Section>
                    {ads && <AdComponent ad={ads.data[3]} />}
                </IonGrid>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
}
