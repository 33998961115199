import useResponsive from 'features/responsive/responsive';
import { CSSProperties, useEffect, useRef, useState } from 'react';

type FullContentHeightProps = {
    children: (height: number) => React.ReactNode;
    className?: string | undefined;
    onlyMobile?: boolean;
    style?: (height: number) => CSSProperties;
};

export default function FullContentHeight({ children, className, onlyMobile, style }: FullContentHeightProps) {
    const [height, setHeight] = useState(0);
    const heightDiv = useRef<HTMLDivElement>(null);

    const { IsMobile } = useResponsive();

    // TODO : compute on pagelayout and use context to get content height
    function computeHeight() {
        if (!heightDiv.current) return;
        const h =
            document.querySelector('.ion-page:not(.ion-page-hidden) > ion-content')?.clientHeight ||
            heightDiv.current?.getBoundingClientRect?.()?.height;
        setHeight(() => h);
    }

    useEffect(() => {
        const observer = new ResizeObserver(computeHeight);
        if (!onlyMobile || IsMobile) {
            // setTimeout with 0 for lifecycle
            setTimeout(() => {
                computeHeight();
            }, 0);

            observer.observe(document.body);
        }
        return () => {
            observer.unobserve(document.body);
        };
    }, []);

    return (
        <>
            <div
                ref={heightDiv}
                className="absolute h-full top-0 bottom-0"
            />
            <div
                style={style ? style(height) : { height: `${height}px` }}
                className={className}
            >
                {children(height)}
            </div>
        </>
    );
}
