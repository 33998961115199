import jwt from 'jwt-decode';

export interface DecodedToken {
    iat: number;
    exp: number;
    roles: string[];
    email: string;
}

export const isTokenExpired = (token: DecodedToken | undefined): boolean => {
    if (!token) {
        return false;
    }

    return Date.now() >= token.exp * 1000;
};

export const decodeJWT = (jwtString: string): DecodedToken | undefined => {
    try {
        return jwt(jwtString);
    } catch {
        return undefined;
    }
};

export function isValidJwt(jwtToken: string) {
    try {
        const decoded = jwt(jwtToken);
        return true;
    } catch (error) {
        return false;
    }
}
