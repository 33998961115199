// Nav
export const HOME = 'home';
export const DASHBOARD = 'dashboard';
export const RADIO = 'radio';
export const NEWS = 'news';
export const MENU = 'menu';

export const luxembourgPosition = {
    latitude: 49.611622,
    longitude: 6.131935,
    accuracy: 13.729,
};

export const TOAST_DURATION = 3000;
