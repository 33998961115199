type IconProps = {
    color?: string;
    width?: string;
    height?: string;
};

function CrossIcon({ color = 'black', width = '21', height = '20' }: IconProps) {
    return (
        <div>
            <svg
                width={width}
                height={height}
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M1.10173 20C0.908561 20 0.71972 19.9428 0.559094 19.8355C0.398468 19.7282 0.273273 19.5756 0.199346 19.3972C0.125418 19.2187 0.106079 19.0223 0.143774 18.8329C0.18147 18.6434 0.274506 18.4694 0.411116 18.3328L18.4577 0.286064C18.6409 0.1029 18.8893 0 19.1483 0C19.4074 0 19.6558 0.1029 19.8389 0.286064C20.0221 0.469228 20.125 0.717652 20.125 0.976685C20.125 1.23572 20.0221 1.48414 19.8389 1.66731L1.79234 19.7141C1.70173 19.8049 1.59408 19.8769 1.47556 19.9259C1.35705 19.975 1.23 20.0002 1.10173 20Z"
                    fill={color}
                />
                <path
                    d="M19.1483 20C19.02 20.0002 18.893 19.975 18.7744 19.9259C18.6559 19.8769 18.5483 19.8049 18.4577 19.7141L0.411062 1.66731C0.2279 1.48414 0.125 1.23572 0.125 0.976685C0.125 0.717652 0.2279 0.469228 0.411062 0.286064C0.594224 0.1029 0.842645 0 1.10168 0C1.36071 0 1.60913 0.1029 1.79229 0.286064L19.8389 18.3328C19.9755 18.4694 20.0685 18.6434 20.1062 18.8329C20.1439 19.0223 20.1246 19.2187 20.0507 19.3972C19.9767 19.5756 19.8515 19.7282 19.6909 19.8355C19.5303 19.9428 19.3414 20 19.1483 20V20Z"
                    fill={color}
                />
            </svg>
        </div>
    );
}

export default CrossIcon;
