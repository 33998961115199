import PageLayout from 'components/PageLayout';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { passwordRegex } from 'lib/utils';
import Input from 'components/Input';
import EditAccount from 'features/auth/components/EditAccount';
import { IonRouterLink, useIonPopover, useIonToast } from '@ionic/react';
import { ErrorMessage } from '@hookform/error-message';
import InputError from 'components/InputError';
import useMyAccount from 'features/auth/useMyAccount';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Popover } from 'features/auth/components/SignupStep1';
import PasswordInput from 'components/PasswordInput';
import { useHistory } from 'react-router';
import { TOAST_DURATION } from '../constants';

type EditPasswordForm = {
    password: string;
    newPassword: string;
    validateNewPassword: string;
};

export default function EditPasswordPage() {
    const { t } = useTranslation();
    const [presentPopover] = useIonPopover(Popover, {});
    const history = useHistory();
    const [present] = useIonToast();

    const schema = yup
        .object({
            password: yup.string().required(t('password_required')),
            newPassword: yup.string().required(t('password_required')), // .matches(passwordRegex, { message: t('password_invalid') }),
            validateNewPassword: yup.string().required(t('password_required')),
        })
        .required();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm<EditPasswordForm>({
        resolver: yupResolver(schema),
    });

    const { user, updatePassword } = useMyAccount();

    function onSubmit(data: EditPasswordForm) {
        if (data.newPassword !== data.validateNewPassword) {
            present(t('reset_password.validation_not_equal_to_new_pwd'), TOAST_DURATION);
        } else {
            updatePassword.mutate(data, {
                onSuccess: () =>
                    present({
                        message: t('reset_password.reset_successful'),
                        duration: TOAST_DURATION,
                        color: 'success',
                    }),
            });
            history.push('/account');
        }
    }

    return (
        <EditAccount
            saveBtnText={t('save')}
            buttons={
                <IonRouterLink
                    routerDirection="forward"
                    routerLink="/reset-password"
                    className="text-white bg-transparent text-base underline mb-4 block w-full text-center mt-2"
                    onClick={() => {}}
                >
                    {t('forgot_password')}
                </IonRouterLink>
            }
            title={t('my-password')}
            onSubmit={handleSubmit(onSubmit)}
        >
            <PasswordInput
                id="context-menu-pwd"
                hasError={!!errors.password}
                placeholder={t('password')}
                {...register('password')}
            />
            <ErrorMessage
                errors={errors}
                name="password"
                as={<InputError />}
            />
            <PasswordInput
                id="context-menu-pwd"
                onClick={(e) =>
                    presentPopover({
                        // @ts-ignore
                        event: e,
                        alignment: 'start',
                        side: 'bottom',
                        // should be 'cover' but this mode has a bug
                        size: 'auto',
                        keyboardClose: false,
                        showBackdrop: false,
                        mode: 'ios',
                        arrow: true,
                        animated: false,
                    })
                }
                hasError={!!errors.password}
                placeholder={t('new-password')}
                {...register('newPassword')}
            />
            <ErrorMessage
                errors={errors}
                name="newPassword"
                as={<InputError />}
            />
            <PasswordInput
                id="context-menu-pwd"
                hasError={!!errors.password}
                placeholder={t('validate-new-password')}
                {...register('validateNewPassword')}
            />
            <ErrorMessage
                errors={errors}
                name="validateNewPassword"
                as={<InputError />}
            />
        </EditAccount>
    );
}
