import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Title from 'components/Title';
import PageLayout from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import useAllShows from 'features/shows/hooks/useAllShows';
import { Show } from 'types';
import CardVertical from 'components/core/CardVertical';
import LoadingIndicator from 'components/LoadingIndicator';
import PageNoData from './PageNoData';

export default function ShowsList() {
    const { t } = useTranslation();
    const { data, isLoading } = useAllShows();

    const { IsMobile } = useResponsive();

    if (!data && !isLoading) {
        return <PageNoData />;
    }

    return (
        <PageLayout
            title={t('menu.shows')}
            hasBackButton
        >
            <IonGrid fixed>
                <IonRow>
                    <IonCol className="p-0 mb-4 md:mb-10">
                        <div className="relative">
                            <div className="absolute right-[-30px] md:hidden">
                                <img
                                    src="./assets/img/team/Ornament-81.png"
                                    alt="ornament"
                                />
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                {!IsMobile && (
                    <Title
                        className="mb-blockInnerMobile md:mb-blockInner"
                        main
                        title={t('menu.shows')}
                    />
                )}
                {!isLoading ? (
                    <div className="grid md:grid-cols-2 gap-standardMobile md:gap-standard">
                        {data?.map((show: Show, index: number) => (
                            <CardVertical
                                thumbnailUrl={show.imageUrl}
                                title={show?.name ?? ''}
                                subtitle={show.description ?? ''}
                                imgSize="small"
                                hasCardHoverEffect
                                displayAllDescription
                                imgAspectRatio="aspect-[710/265]"
                                linkTo={show.url}
                            />
                        ))}
                    </div>
                ) : (
                    <LoadingIndicator />
                )}
            </IonGrid>
        </PageLayout>
    );
}
