import { useQuery } from '@tanstack/react-query';
import { getTopicCategories } from 'lib/ApiService';

export default function useTopicCategories() {
    const { data: categories, status } = useQuery(['topic_categories'], getTopicCategories, {
        onError: (err) => {},
    });

    return {
        categories,
        isLoading: status === 'loading',
    };
}
