import { IonContent, IonGrid, IonRow, useIonRouter } from '@ionic/react';
import Button from 'components/Button';
import PageLayout from 'components/PageLayout';
import { ReactComponent as PageNoDataIcon } from 'components/Icons/PageNoData.svg';
import { useTranslation } from 'react-i18next';
import useResponsive from 'features/responsive/responsive';

export default function PageNoData() {
    const { t } = useTranslation();
    const router = useIonRouter();

    const { IsMobile } = useResponsive();

    return (
        <PageLayout hasBackButton>
            <IonGrid fixed>
                <IonRow>
                    <PageNoDataIcon className="mx-auto" />
                </IonRow>
                <IonRow>
                    <div className={`mt-12.5 h-fit mx-auto ${IsMobile ? 'full-width' : ''}`}>
                        <div className="typo-h2 bold m-8 mt-auto">{t('error_no_data')}</div>
                        <div className="w-fit mt-7.5 mx-auto">
                            <Button
                                onClick={() => router.push('/')}
                                forceHoverEffectActive
                                value={t('page404.back')}
                            />
                        </div>
                    </div>
                </IonRow>
            </IonGrid>
        </PageLayout>
    );
}
