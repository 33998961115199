import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getUser, updateUser, updateUserPicture, updateUserEmail, updateUserPassword } from 'lib/ApiService';
import { useIonRouter, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TOAST_DURATION } from '../../constants';
import { User } from './types';
import useAuth from './useAuth';

export default function useMyAccount() {
    const { t } = useTranslation();
    const { user, refetchUser, setUser } = useAuth();

    const router = useIonRouter();

    const [present] = useIonToast();

    const update = useMutation({
        mutationFn: updateUser,
        onSuccess: (data, variables) => {
            present({ color: 'light', duration: TOAST_DURATION, message: t('account_updated') });
            // @ts-ignore
            setUser(() => ({ ...user, ...variables }));
            router.push('/account');
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });

    const updatePicture = useMutation({
        mutationFn: updateUserPicture,
        onSuccess: (data) => {
            present({ color: 'light', duration: TOAST_DURATION, message: t('account_picture_updated') });
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });

    const updatePassword = useMutation({
        mutationFn: updateUserPassword,
        onSuccess: () => {
            present({ color: 'success', duration: TOAST_DURATION, message: t('reset_password.reset_successful') });
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });

    return {
        user,
        refetch: refetchUser,
        update,
        updatePicture,
        updatePassword,
    };
}
