import { AdData } from 'features/advertising/generateAdPayload';
import useResponsive from 'features/responsive/responsive';
import AdComponent from './AdComponent';

export type AdRightBlockProps = {
    children: any;
    ad?: AdData;
};

export default function AdRightBlock({ children, ad }: AdRightBlockProps) {
    const { IsExclusiveTablet } = useResponsive();
    const { IsDesktop } = useResponsive();

    return (
        <div className={`relative ${IsDesktop ? 'min-h-[600px]' : ''}`}>
            <div>{children}</div>
            {ad && !IsExclusiveTablet && (
                <AdComponent
                    className="absolute top-0 -right-standard transform translate-x-[100%]"
                    hasSpacings={false}
                    ad={ad}
                />
            )}
        </div>
    );
}
