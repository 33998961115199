import { useQuery } from '@tanstack/react-query';
import { getPodcastAndReplayPage } from '../../../lib/ApiService';
import { PodcastAndReplayPageData } from '../../../types';

export default function usePodcastsOrReplays() {
    const { data, status, error } = useQuery<PodcastAndReplayPageData>({
        queryKey: ['podcastsReplaysPage'],
        queryFn: getPodcastAndReplayPage,
    });

    return { podcastAndReplaysData: data, isLoading: status === 'loading', error };
}
