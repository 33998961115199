import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useIonToast } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { ShowsPageInfo } from '../../../types';
import { getShowsPage } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useShows() {
    const [present] = useIonToast();
    const { t } = useTranslation();
    const date = moment().format('YYYY-MM-DD');

    const { data, isLoading, error } = useQuery<ShowsPageInfo>(['shows'], () => getShowsPage(date), {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        error,
        data,
        isLoading,
    };
}
