/* eslint-disable prettier/prettier */
import { HTMLAttributes, PropsWithChildren } from 'react';

type CardProps = HTMLAttributes<HTMLDivElement> & {
    // if set to true, imgs with "cardHoverEffect" set to true have zoom effect on card hover
    hasCardHoverEffect?: boolean;
    bgGradientPurple?: boolean;
};

export default function Card({
    children,
    hasCardHoverEffect,
    className = '',
    bgGradientPurple,
    ...rest
}: PropsWithChildren<CardProps>) {
    return (
        <div
            className={`rounded-[10px] ${
                bgGradientPurple ? 'bg-gradient-purple' : 'bg-light-background'
            }  p-[20px] shadow-neumorphism-outer flex ${className} ${hasCardHoverEffect ? 'group/hovercard' : ''}`}
            {...rest}
        >
            {children}
        </div>
    );
}
