import { IonCol, IonGrid, IonInput, IonItem, IonLabel, IonRadioGroup, IonRow, useIonToast } from '@ionic/react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import CardWithRadioButton from 'components/Cards/CardWithRadioButton';
import Button from 'components/Button';
import { useParams } from 'react-router';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';
import { useForm } from 'react-hook-form';
import { participate } from 'lib/ApiService';
import { useState } from 'react';
import useAds from 'hooks/useAds';
import Title from 'components/Title';
import InputError from 'components/InputError';
import PageLayout from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import useGamePageInfo from 'features/game/useGamePageInfo';
import { ReactComponent as GameWin } from 'components/Icons/gameWin.svg';
import { ReactComponent as Pin } from 'components/Icons/pin.svg';
import { ReactComponent as Calendar2 } from 'components/Icons/calendar2.svg';
import Alert from 'components/Alert';
import AdComponent from 'components/Ads/AdComponent';
import AdRightBlock from 'components/Ads/AdRightBlock';
import PhoneInput from 'components/PhoneInput';
import { DatePicker } from '@mui/x-date-pickers';
import clsx from 'clsx';

type FormValues = {
    gender: string;
    firstName: string;
    lastName: string;
    streetName: string;
    postalCode: string;
    city: string;
    birthday: string;
    email: string;
    phoneNumber: string;
};

export default function GameFormPage() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { games } = useGamePageInfo();
    const [present] = useIonToast();
    const [success, setSuccess] = useState(false);
    const { ads } = useAds({ page: 'game-form' });
    const ySpaceForInputs = '!py-4';

    const { IsMobile } = useResponsive();

    const schema = yup
        .object({
            gender: yup.string().required(t('gender_required')),
            firstName: yup.string().required(t('firstname_required')),
            lastName: yup.string().required(t('lastname_required')),
            streetName: yup.string().required('streetname_required'),
            postalCode: yup.string().required('postalcode_required'),
            city: yup.string().required('city_required'),
            birthday: yup.string().required(t('birthday_required')),
            email: yup.string().required(t('email_required')),
            phoneNumber: yup.string().required(t('phoneNumber_required')),
        })
        .required();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { isValid, errors },
        control,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {},
    });

    const onSubmit = async (data: FormValues) => {
        const payload = {
            gender: data.gender,
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            phoneNumber: data.phoneNumber,
            birthday: moment(new Date(data.birthday), 'DD/MM/YYYY').format('YYYY-MM-DD'),
            address: { street: data.streetName, zip: data.postalCode, city: data.city },
        };
        try {
            const response = await participate(Number(id), payload);
            if (response.ok) {
                setSuccess(true);
                setValue('firstName', '');
                setValue('lastName', '');
                setValue('email', '');
                setValue('phoneNumber', '');
                setValue('birthday', '');
                setValue('streetName', '');
                setValue('postalCode', '');
                setValue('city', '');
            }
        } catch (e: any) {
            const message: string = e.error || e.response?.data?.error || e.response?.message || e.message || 'Error';
            await present({ message, duration: 3000, position: 'top', color: 'danger' });
        }
    };

    function onDateChange(ev: any) {
        if (!ev) {
            return;
        }

        setValue('birthday', ev);
    }

    const gameInfo = games?.find((game) => game.id === Number(id));

    return (
        <PageLayout
            hasBackButton
            title={t('game_info.Game')}
        >
            {ads && <AdComponent ad={ads.data[0]} />}

            <IonGrid
                fixed
                className="md:flex md:justify-center md:bg-[#141414]"
            >
                <div className="md:w-[350px]">
                    <IonRow>
                        <IonCol className="p-0 mb-4 md:mb-10">
                            <div className="relative">
                                <div className="absolute right-[-30px] md:hidden">
                                    <img
                                        src="./assets/img/team/Ornament-81.png"
                                        alt="ornament"
                                    />
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>

                    <IonRow className="flex flex-row flex-wrap">
                        <IonCol className="p-0">
                            <div className="mb-blockInnerMobile md:mb-blockInner">
                                {!IsMobile ? (
                                    <Title
                                        main
                                        title={t('game_info.Game')}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                            <div className="typo-h2 font-bold leading-[34px] grid md:grid-cols-2 w-fit items-center gap-[40px] mb-blockInnerMobile md:mb-blockInner">
                                <GameWin className="w-[138px] max-md:hidden" />
                                {gameInfo?.name}
                            </div>
                            <div className="mt-[32px] leading-[28px] tracking-[0.15px] mb-blockInnerMobile md:mb-blockInner">
                                <div className="grid grid-cols-[min-content_1fr] gap-[27px]">
                                    <Pin />
                                    <span className="regular typo-b1">{gameInfo?.location}</span>
                                </div>
                                <div className="grid grid-cols-[min-content_1fr] gap-[27px] mt-[25px]">
                                    <Calendar2 />
                                    <span className="regular typo-b1">
                                        {moment(gameInfo?.dateTime).format('DD.MM.YY')}
                                    </span>
                                </div>
                            </div>
                        </IonCol>
                    </IonRow>
                    <AdRightBlock ad={ads?.data?.[1]}>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <IonRadioGroup>
                                <IonRow className="flex flex-row flex-wrap gap-[30px] mb-blockInnerMobile md:mb-blockInner">
                                    <IonCol className="p-0 flex flex-row gap-[30px]">
                                        <CardWithRadioButton
                                            title={t('game_info.Man')}
                                            value="m"
                                            register={register('gender')}
                                            className="h-[88px]"
                                        />
                                    </IonCol>
                                    <IonCol className="p-0 flex flex-row gap-[30px]">
                                        <CardWithRadioButton
                                            title={t('game_info.Woman')}
                                            value="f"
                                            register={register('gender')}
                                            className="h-[88px]"
                                        />
                                    </IonCol>
                                </IonRow>
                            </IonRadioGroup>
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <IonItem className="game-input !h-[60px]">
                                    <IonLabel
                                        className="game-input pt-1"
                                        position="stacked"
                                    >
                                        {t('game_info.first_name')}
                                    </IonLabel>
                                    <IonInput
                                        className={clsx('game-input', ySpaceForInputs)}
                                        {...register('firstName')}
                                        name="firstName"
                                    />
                                </IonItem>
                            </IonRow>
                            <div className="relative">
                                <div className="absolute top-[10px]">
                                    <ErrorMessage
                                        errors={errors}
                                        name="firstName"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <IonItem className="game-input !h-[60px]">
                                    <IonLabel
                                        className="game-input pt-1"
                                        position="stacked"
                                    >
                                        {t('game_info.last_name')}
                                    </IonLabel>
                                    <IonInput
                                        className={clsx('game-input', ySpaceForInputs)}
                                        {...register('lastName')}
                                        name="lastName"
                                    />
                                </IonItem>
                            </IonRow>
                            <div className="relative">
                                <div className="absolute top-[10px]">
                                    <ErrorMessage
                                        errors={errors}
                                        name="lastName"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <IonItem className="game-input !h-[60px]">
                                    <IonLabel
                                        className="game-input pt-1"
                                        position="stacked"
                                    >
                                        {t('game_info.Street')}
                                    </IonLabel>
                                    <IonInput
                                        className={clsx('game-input', ySpaceForInputs)}
                                        {...register('streetName')}
                                        name="streetName"
                                    />
                                </IonItem>
                            </IonRow>
                            <div className="relative">
                                <div className="absolute top-[10px]">
                                    <ErrorMessage
                                        errors={errors}
                                        name="streetName"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <IonItem className="game-input !h-[60px]">
                                    <IonLabel
                                        className="game-input pt-1"
                                        position="stacked"
                                    >
                                        {t('game_info.Postal_Code')}
                                    </IonLabel>
                                    <IonInput
                                        className={clsx('game-input', ySpaceForInputs)}
                                        {...register('postalCode')}
                                        name="postalCode"
                                    />
                                </IonItem>
                            </IonRow>
                            <div className="relative">
                                <div className="absolute top-[10px]">
                                    <ErrorMessage
                                        errors={errors}
                                        name="postalCode"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <IonItem className="game-input !h-[60px]">
                                    <IonLabel
                                        className="game-input pt-1"
                                        position="stacked"
                                    >
                                        {t('game_info.Locality')}
                                    </IonLabel>
                                    <IonInput
                                        className={clsx('game-input', ySpaceForInputs)}
                                        {...register('city')}
                                        name="city"
                                    />
                                </IonItem>
                            </IonRow>
                            <div className="relative">
                                <div className="absolute top-[10px]">
                                    <ErrorMessage
                                        errors={errors}
                                        name="city"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <IonItem className="game-input !h-[60px]">
                                    <IonLabel
                                        className="game-input pt-1"
                                        position="stacked"
                                    >
                                        {t('game_info.Email')}
                                    </IonLabel>
                                    <IonInput
                                        className={clsx('game-input', ySpaceForInputs)}
                                        {...register('email')}
                                        name="email"
                                    />
                                </IonItem>
                            </IonRow>
                            <IonRow>
                                <DatePicker
                                    onChange={(date) => onDateChange(date)}
                                    format="DD/MM/YYYY"
                                    label={t('birthday')}
                                    value={watch('birthday')}
                                    className="typo-b2 regular rounded-t-lg px-[15px] border-0 h-[54px] outline-0 bg-background-alt [&_.MuiFormLabel-root]:!text-white w-[100%] [&_.MuiSvgIcon-root]:!text-white [&_.MuiInputBase-input]:!text-white"
                                />
                            </IonRow>

                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <PhoneInput
                                    placeholder={t('phone')}
                                    {...register('phoneNumber')}
                                    control={control}
                                    name="phoneNumber"
                                />
                            </IonRow>
                            <div>
                                <div>
                                    <ErrorMessage
                                        errors={errors}
                                        name="phoneNumber"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            <div>
                                <div>
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        as={<InputError />}
                                    />
                                </div>
                            </div>
                            {/*
                        success && (
                            <IonRow className='mt-[30px]'>
                                <div className='relative flex py-[10px] pr-[20px] pl-[15px] text-[#141414] bg-success h-[60px] w-full rounded-[10px] shadow-[5px_10px_45px_rgba(103,206,103,0.35)] items-center'>
                                    <div className='grid place-items-center bg-white rounded-full w-[35px] h-[35px]'>
                                        <CheckMarkIcon />
                                    </div>
                                    <div className='text-md ml-[10px] w-[200px]'>
                                        {t('game_info.Your-registration-has-been-validated')}
                                    </div>
                                    <div className='absolute right-[12.5px] top-[12.5px]'>
                                        <CrossIcon color='#141414' width='12.5' height='12.5' />
                                    </div>
                                </div>
                            </IonRow>
                        )
                        */}
                            {success ? (
                                <IonRow className="mt-blockMobile md:mt-block">
                                    <Alert
                                        type="success"
                                        onClose={() => setSuccess(false)}
                                    >
                                        {t('game_info.Your-registration-has-been-validated')}
                                    </Alert>
                                </IonRow>
                            ) : (
                                ''
                            )}
                            <IonRow className="my-blockMobile md:my-block">
                                <Button
                                    value={t('game_info.Play')}
                                    className={`bg-[#8A8A8A] ${
                                        isValid ? '!text-[#141414] bg-white' : 'text-[#555555]'
                                    } border-0`}
                                    type="submit"
                                    disabled={false}
                                />
                            </IonRow>
                        </form>
                    </AdRightBlock>
                    {ads && <AdComponent ad={ads.data[2]} />}
                </div>
            </IonGrid>
        </PageLayout>
    );
}
