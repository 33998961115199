import {
    IonCol,
    IonCard,
    IonCardContent,
    IonList,
    IonListHeader,
    IonicSlides,
    IonLabel,
    IonThumbnail,
    IonImg,
} from '@ionic/react';
import { format } from 'date-fns';
import { t } from 'i18next';
import { SwiperSlide, Swiper } from 'swiper/react';
import { dateIsValid, formatMode } from '../../lib/utils.dates';
import { Forecast } from './types';

export default function ForecastPerHour({ forecast }: { forecast: Forecast[] }) {
    return (
        <IonCol
            size-xs={12}
            size-lg={4}
            className="meteo-cards-row__col"
        >
            <IonCard>
                <IonCardContent>
                    <IonList className="weather-block hourly-weather">
                        <IonListHeader className="typo-b1 font-semibold">{t('meteo.forecast_per_hour')}</IonListHeader>
                        <Swiper
                            modules={[IonicSlides]}
                            slidesPerView={4}
                            className="weather-block--swiper"
                        >
                            {forecast?.map(({ id, symbolUrl, dateTime, temperature }, index) => (
                                <SwiperSlide key={id}>
                                    {dateTime && dateIsValid(dateTime) && (
                                        <IonLabel>
                                            {index === 0 ? 'Now' : format(new Date(dateTime), formatMode.HH)}
                                        </IonLabel>
                                    )}
                                    <IonThumbnail class="hourly-weather__thumb typo-b1 medium">
                                        <IonImg src={symbolUrl} />
                                    </IonThumbnail>
                                    <div
                                        className={`per-hour-label per-hour-label-temp typo-h2 bold ${
                                            index === 0 ? 'per-hour-label--current' : ''
                                        }`}
                                    >
                                        {temperature}°
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </IonList>
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
}
