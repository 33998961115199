import { useState, useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import { luxembourgPosition } from '../constants';

type PermissionStatusType = {
    location: string;
    coarseLocation: string;
};

type PositionType = {
    latitude: number | string;
    longitude: number | string;
    accuracy: number | string;
};

const useGeolocation = () => {
    const [position, setPosition] = useState<PositionType>();
    const [locationPermissions] = useState<PermissionStatusType>();

    const requestPerm = async (permissions?: any) => {
        await Geolocation.requestPermissions(permissions);
    };

    const getCurrentCoordinate = async () => {
        if (!Capacitor.isPluginAvailable('Geolocation')) {
            return;
        }

        await Geolocation.getCurrentPosition()
            .then((data) => {
                setPosition({
                    latitude: data.coords.latitude,
                    longitude: data.coords.longitude,
                    accuracy: data.coords.accuracy,
                });
            })
            .catch(() => {
                setPosition(luxembourgPosition);
            });
    };

    useEffect(() => {
        getCurrentCoordinate();
    }, []);

    return {
        permission: locationPermissions,
        getCurrentCoordinate,
        position,
        requestPermission: requestPerm,
    };
};

export default useGeolocation;
