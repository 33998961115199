import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import * as yup from 'yup';
import { Trans, useTranslation } from 'react-i18next';
import { ErrorMessage } from '@hookform/error-message';
import { IonContent, IonLoading, IonToast, useIonPopover, useIonToast } from '@ionic/react';
import { emailRegex, passwordRegex } from 'lib/utils';
import { DatePicker } from '@mui/x-date-pickers';
import ToggleButton from '../../../components/ToggleButton';
import Button from '../../../components/Button';
import { ApiResponse, signup, SignupPayload } from '../../../lib/ApiService';
import Input from '../../../components/Input';
import PasswordInput from '../../../components/PasswordInput';
import InputError from '../../../components/InputError';

type SignupFormValues = SignupPayload & {
    policyAccepted: boolean;
};

const liClassName =
    'before:w-[5px] before:h-[5px] before:bg-white before:rounded-full before:inline-block before:transform before:relative before:top-1/2 before:-translate-y-1/2 before:mr-2';

export function Popover() {
    const { t } = useTranslation();
    const width = document.getElementById('context-menu-pwd')?.getBoundingClientRect()?.width || undefined;
    return (
        <IonContent
            scrollY={false}
            class="ion-padding"
            // should not be done, ionpopover should be 'cover' but this mode has a bug
            style={{ width }}
        >
            <div className="typo-h2 mb-4">{t('signup-form.password-must-be.title')}</div>
            <ul>
                <li className={liClassName}>{t('signup-form.password-must-be.nb-characters')}</li>
                <li className={liClassName}>{t('signup-form.password-must-be.upper')}</li>
                <li className={liClassName}>{t('signup-form.password-must-be.special')}</li>
                <li className={liClassName}>{t('signup-form.password-must-be.number')}</li>
            </ul>
        </IonContent>
    );
}
export default function SignupStep1({
    onComplete,
    onLoginClick,
}: {
    onLoginClick?: () => void;
    onComplete: (email: string) => void;
}) {
    const { t } = useTranslation();
    const [serverError, setServerError] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [present] = useIonToast();
    const { firstName, lastName } = useParams<{ firstName: string; lastName: string }>();
    const [firstNameValue, setFirstNameValue] = useState('');
    const [lastNameValue, setLastNameValue] = useState('');

    useEffect(() => {
        setFirstNameValue(firstName);
        setLastNameValue(lastName);
    }, []);

    const schema = yup
        .object({
            firstName: yup.string().required(t('firstname_required')),
            lastName: yup.string().required(t('lastname_required')),
            email: yup
                .string()
                .required(t('email_required'))
                .matches(emailRegex, { message: t('email_invalid') }),
            birthday: yup.string().required(t('birthday_required')),
            policyAccepted: yup.boolean().required(t('policy_required')),
            password: yup
                .string()
                .required(t('password_required'))
                .matches(passwordRegex, { message: t('password_invalid') }),
        })
        .required();

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm<SignupFormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            // might need to remove this to allow the birthday text so show
        },
    });

    const [presentPopover] = useIonPopover(Popover, {});

    function onDateChange(ev: any) {
        if (!ev) {
            return;
        }

        setValue('birthday', ev);
    }

    const onSubmit = async (data: SignupFormValues) => {
        setIsLoading(true);
        const { policyAccepted, ...payload } = data;

        await signup({
            ...payload,
            birthday: new Date(data.birthday).toISOString(),
        })
            .then((response: any) => {
                onComplete(response.data.email);
            })
            .catch((e) => {
                console.log(e.error);
                const message: string =
                    e.error?.message || e.response?.data?.error || e.response?.message || e.message || 'Error';
                present({ message, duration: 3000, position: 'top', color: 'danger' });
            })
            .finally(() => setIsLoading(false));
    };

    const formOk = watch('policyAccepted');

    return (
        <>
            <h1 className="my-4 typo-h1 font-bold">{t('sign_up_title')}</h1>
            <h2 className="mt-4 typo-h2 regular">{t('create_account_title')}</h2>
            <p className="my-4 typo-b3 regular">{t('all_fields_mandatory')}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <IonToast
                    color="danger"
                    isOpen={serverError !== ''}
                    onDidDismiss={() => setServerError('')}
                    message={serverError}
                    duration={5000}
                />

                {isLoading && <IonLoading isOpen />}

                <Input
                    hasError={!!errors.firstName}
                    placeholder={t('firstname')}
                    {...register('firstName')}
                    onChange={(e) => setFirstNameValue(e.target.value)}
                    value={firstNameValue}
                />
                <ErrorMessage
                    errors={errors}
                    name="firstName"
                    as={<InputError />}
                />

                <Input
                    hasError={!!errors.lastName}
                    placeholder={t('lastname')}
                    {...register('lastName')}
                    name="lastName"
                    onChange={(e) => setLastNameValue(e.target.value)}
                    value={lastNameValue}
                />
                <ErrorMessage
                    errors={errors}
                    name="lastName"
                    as={<InputError />}
                />
                <DatePicker
                    label={t('birthday')}
                    format="DD/MM/YYYY"
                    value={watch('birthday')}
                    onChange={(date) => onDateChange(date)}
                    className="typo-b2 !my-4 rounded-t-[15px] regular px-[15px] border-0 h-[54px] outline-0 bg-background-alt [&_.MuiFormLabel-root]:!text-[#959da8] w-[100%] [&_.MuiSvgIcon-root]:!text-[#959da8] [&_.MuiInputBase-input]:!text-[#959da8] [&_.MuiFormLabel-root]:font-[Poppins]"
                />
                <ErrorMessage
                    errors={errors}
                    name="birthday"
                    as={<InputError />}
                />

                <Input
                    hasError={!!errors.email}
                    placeholder={t('email')}
                    {...register('email')}
                    name="email"
                />
                <ErrorMessage
                    errors={errors}
                    name="email"
                    as={<InputError />}
                />

                <PasswordInput
                    id="context-menu-pwd"
                    onClick={(e) => {
                        presentPopover({
                            // @ts-ignore
                            event: e,
                            alignment: 'start',
                            side: 'bottom',
                            // should be 'cover' but this mode has a bug
                            size: 'auto',
                            keyboardClose: false,
                            showBackdrop: false,
                            mode: 'ios',
                            arrow: true,
                            animated: false,
                        });
                    }}
                    hasError={!!errors.password}
                    placeholder={t('password')}
                    {...register('password')}
                />

                <ErrorMessage
                    errors={errors}
                    name="password"
                    as={<InputError />}
                />
                {/*   <IonPopover
                    trigger='context-menu-pwd'
                    triggerAction='click'
                    alignment='start'
                    side='bottom'
                    size='cover'
                    keyboardClose={false}
                    showBackdrop={false}
                    mode='ios'
                    arrow
                    reference='trigger'
                    keepContentsMounted
                >
                    <IonContent class='ion-padding'>
                        <div className='typo-h2 mb-4'>{t('signup-form.password-must-be.title')}</div>
                        <ul>
                            <li className={liClassName}>{t('signup-form.password-must-be.nb-characters')}</li>
                            <li className={liClassName}>{t('signup-form.password-must-be.upper')}</li>
                            <li className={liClassName}>{t('signup-form.password-must-be.special')}</li>
                            <li className={liClassName}>{t('signup-form.password-must-be.number')}</li>
                        </ul>
                    </IonContent>
                </IonPopover> */}

                <ToggleButton
                    {...register('policyAccepted')}
                    className="my-[15px]"
                    label={
                        <Trans
                            i18nKey="accept_policies_toggle"
                            className="typo-b3"
                        >
                            Agree to
                            <Link
                                target="_blank"
                                to="/terms"
                                className="text-white underline"
                            >
                                Terms of Services
                            </Link>
                            and
                            <Link
                                target="_blank"
                                to="/terms"
                                className="text-white underline"
                            >
                                Privacy Policy
                            </Link>
                        </Trans>
                    }
                />
                <ErrorMessage
                    errors={errors}
                    name="policyAccepted"
                    as={<InputError />}
                />

                <Button
                    type="submit"
                    className="my-[45px] md:my-[15px]"
                    value={t('next')}
                    disabled={!formOk}
                    forceHoverEffectActive={formOk}
                />
            </form>
            <hr
                className="mb-2 mt-2"
                style={{ backgroundColor: '#555555', height: '1px', border: 'none' }}
            />
            <div>
                <div className="my-[15px] typo-h2 bold">{t('already_have_account')}</div>
                <div>
                    <Button
                        onClick={onLoginClick}
                        forceHoverEffectActive
                        value={t('login')}
                    />
                </div>
            </div>
        </>
    );
}
