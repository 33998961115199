import {
    IonCol,
    IonCard,
    IonCardContent,
    IonItem,
    IonThumbnail,
    IonSpinner,
    IonImg,
    IonCardTitle,
    IonItemDivider,
    IonLabel,
    IonList,
    IonNote,
} from '@ionic/react';
import { format } from 'date-fns';
import { t } from 'i18next';
import { dateIsValid, formatMode } from '../../lib/utils.dates';
import { Forecast } from './types';

export default function Observation({ observation }: { observation: Forecast }) {
    return (
        <IonCol
            size-xs={12}
            size-lg={4}
            className="meteo-cards-row__col"
        >
            <IonCard>
                <IonCardContent>
                    <div className="weather-main-info mb-8">
                        <IonItem>
                            <IonThumbnail>
                                <img
                                    src={observation.symbolUrl}
                                    alt=""
                                />
                            </IonThumbnail>
                        </IonItem>
                        <div className="weather-main-info__values">
                            <div className="weather-main-info__value">
                                <IonCardTitle className="mb-2 typo-b1 font-semibold">
                                    {observation.station?.name}
                                </IonCardTitle>
                                {observation.dateTime && dateIsValid(observation.dateTime) && (
                                    <p>
                                        <span className="typo-b2 regular">
                                            {format(new Date(observation.dateTime), formatMode.dd_MM_yyyy_HH_mm)}
                                        </span>
                                    </p>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="weather-detail-info">
                        <IonItemDivider color="divider">
                            <div className="typo-b1 font-semibold">{t('meteo.details')}</div>
                        </IonItemDivider>
                        <IonList lines="none">
                            <IonItem>
                                <div className="typo-b1 font-semibold">{t('meteo.rain')}</div>
                                <IonNote
                                    className="typo-b1 regular"
                                    slot="end"
                                >
                                    {observation.precipitations}mm
                                </IonNote>
                            </IonItem>

                            <IonItem>
                                <div className="typo-b1 font-semibold">{t('meteo.wind')}</div>
                                <IonNote
                                    className="typo-b1 regular"
                                    slot="end"
                                >
                                    {observation.wind?.speed}km/h
                                </IonNote>
                            </IonItem>

                            <IonItem>
                                <div className="typo-b1 font-semibold">{t('meteo.wind_gusts')}</div>
                                <IonNote
                                    className="typo-b1 regular"
                                    slot="end"
                                >
                                    {observation.wind?.gusts}km/h
                                </IonNote>
                            </IonItem>
                        </IonList>
                    </div>
                </IonCardContent>
            </IonCard>
        </IonCol>
    );
}
