import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { IonGrid } from '@ionic/react';

import ButtonGroup from 'components/ButtonGroup';
import useActionArticle from 'features/actions/hooks/useActionArticle';
import LoadingIndicator from 'components/LoadingIndicator';
import useResponsive from 'features/responsive/responsive';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import NewsInfiniteScroll from 'components/NewsInfiniteScroll';
import PageLayout from '../components/PageLayout';
import ChartBreakerSongList, { chartBreakType } from './ChartBreakerSongList';
import { ArticlePartComponent } from './NewsArticle';

export default function ChartbreakerTopic() {
    const { chart } = useParams<{ chart: string }>();
    const { data, isLoading } = useActionArticle(chart === 'chartbreaker' ? 'chartbreaker' : 'dance');
    const [chosenTabByHash, setChosenTabByHash] = useState<any>();
    const [currentTab, setCurrentTab] = useState<any>(undefined);
    const { IsMobile, IsDesktop } = useResponsive();
    const { ads } = useAds({ page: 'chartsong' });
    const [currentArticleForList, setCurrentArticleForList] = useState<string | undefined>(undefined);
    const [currentTopicCategory, setCurrentTopicCategory] = useState<string | undefined>(undefined);

    // @ts-ignore
    const currentParts = data?.tabs?.[currentTab]?.content?.parts;
    const currentLists = data?.tabs?.[currentTab]?.content;

    const displayActionArticleOrActionList = () => {
        return !Array.isArray(currentLists) || currentArticleForList
            ? (currentParts || [])?.map((part: any) => {
                  const adUrl =
                      ads && ads?.data.length
                          ? ads?.data?.filter((item) => item.container === 'media_ads')[0]?.url
                          : '';
                  const updatedPart = { ...part, adUrl };
                  return ads && !isLoading && currentParts ? (
                      <ArticlePartComponent
                          part={updatedPart}
                          IsMobile={IsMobile}
                          displayGalleryCopyright
                      />
                  ) : (
                      ''
                  );
              })
            : [
                  <NewsInfiniteScroll
                      categoryId={parseInt(currentTopicCategory ?? '0', 10)}
                      ads={ads}
                      adPosition={4}
                  />,
              ];
    };

    const preSelectArticle = () => {
        data?.tabs?.forEach((tab, index) => {
            if (decodeURIComponent(window.location.hash.substring(1)) === tab.title && tab.title !== '') {
                setCurrentTab(index);
                setChosenTabByHash({ name: tab.title, id: tab.id });
            } else if (
                decodeURIComponent(window.location.hash.substring(1)).includes(tab.title ?? '') &&
                tab.title !== ''
            ) {
                const parts = decodeURIComponent(window.location.hash.substring(1)).split('--');

                const tabName = parts[0];
                const articleId = parts[parts.length - 1];

                setCurrentTab(index);
                setChosenTabByHash({ name: "Chartbreaker", id: 'Chartbreaker' });
                setCurrentArticleForList(articleId);
            }
        });
    };

    const triggerCategoryListToLoad = () => {
        if (data?.tabs?.[currentTab]?.type === 'listing') {
            const listingTab = data?.tabs[currentTab]?.content;
            if (Array.isArray(listingTab)) {
                setCurrentTopicCategory(listingTab[0]?.category_path);
            }
        }
    };

    useEffect(() => {
        if (data) {
            preSelectArticle();
            triggerCategoryListToLoad();
        }
        
    }, [data, currentTab]);

    useEffect(() => {
        setChosenTabByHash({id: "Chartbreaker", name: "Chartbreaker"})
    }, [])

    return (
        <PageLayout
            title={(IsMobile && chartBreakType[chart]) || ''}
            hasBackButton
            backPath="/musek/charts"
        >
            
            {!isLoading ? (
                <IonGrid
                    fixed
                    className="flex flex-col gap-[30px] pb-[50px]"
                >
                    {ads && (
                        <AdComponent
                            ad={ads.data[0]}
                            className={IsMobile ? '[&>iframe]:!-mx-[25px] [&>iframe]:!-mt-[30px] !w-screen' : ''}
                        />
                    )}
                    <ButtonGroup
                        value={chosenTabByHash}
                        onChange={(opt) => {
                            // @ts-ignore
                            setCurrentTab(opt.index);
                        }}
                        options={[
                            { name: 'Chartbreaker', id: 'Chartbreaker' },
                            ...(data?.tabs
                                ?.map((tab, index) => {
                                    return { name: tab.title ?? '', id: tab.id, index };
                                })
                                ?.filter((filteredTab) => filteredTab.id !== '' || filteredTab.name !== '') || []),
                        ]}
                    />
                    
                    {currentTab === undefined ? (
                        <ChartBreakerSongList
                            chart={chart}
                            ads={ads}
                        />
                    ) : (
                        displayActionArticleOrActionList()
                    )}
                </IonGrid>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
}
