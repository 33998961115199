import { useIonToast } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { TeamPageInfo } from 'types';
import { TEAM_QUERY_KEY, getTeamPageInfo } from 'lib/ApiService';
import { TOAST_DURATION } from '../../constants';

export default function useTeamPageInfo() {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const { data, status } = useQuery<TeamPageInfo>({
        queryKey: [TEAM_QUERY_KEY],
        queryFn: getTeamPageInfo,
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        contacts: data?.contacts,
        persons: data?.persons,
        isLoading: status === 'loading',
    };
}
