import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { ActionList, Article, ArticlesList } from 'types';
import { getActions } from '../../../lib/ApiService';

export default function useActionList(current: boolean) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const callback = useCallback(() => getActions(current), [current]);

    const { data, status, refetch } = useQuery<(ActionList | Article)[]>(['actionsList', current], callback, {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    useEffect(() => {
        refetch();
    }, [current]);

    return {
        data,
        isLoading: status === 'loading',
    };
}
