import { IonButton } from '@ionic/react';
import AppIcon from './Icons/AppIcon';

type BurgerMenuBtnProps = {
    onClick: React.MouseEventHandler;
    isOpen: boolean;
};

const LINE_BASE_CLASSNAME = 'h-[2px] rounded w-[23px] bg-black absolute left-1/2 transform transition-all duration-500';
const CROSS_ICON_CLASSNAME =
    'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 transition-all duration-500';

export default function BurgerMenuBtn({ onClick, isOpen }: BurgerMenuBtnProps) {
    return (
        <IonButton
            onClick={onClick}
            className="burger-menu-toggler relative"
        >
            <div
                className={`${LINE_BASE_CLASSNAME} ${
                    isOpen ? '-translate-x-[150%] opacity-0' : '-translate-x-1/2 delay-500'
                } -translate-y-[5px]`}
            />
            <div
                className={`${LINE_BASE_CLASSNAME} ${
                    isOpen ? 'translate-x-[150%] opacity-0' : '-translate-x-1/2 delay-500'
                }`}
            />
            <div
                className={`${LINE_BASE_CLASSNAME} ${
                    isOpen ? '-translate-x-[150%] opacity-0' : '-translate-x-1/2 delay-500'
                } translate-y-[5px]`}
            />

            <AppIcon
                name="cross"
                classes={`${CROSS_ICON_CLASSNAME} ${isOpen ? 'delay-500' : 'scale-[75%] opacity-0'}`}
            />
        </IonButton>
    );
}
