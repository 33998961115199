import EldoWidget from 'components/Widgets/EldoWidget';
import Section from 'components/core/Section';
import { useTranslation } from 'react-i18next';

type WidgetProps = {
    title: string;
};

function MyWidgets({ title }: WidgetProps) {
    const { t } = useTranslation();

    return (
        <Section title={title}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-standardMobile md:gap-standard">
                <EldoWidget
                    icon="weather"
                    title={t('main.weather')}
                    path="/aktuell/meteo"
                />
                <EldoWidget
                    icon="traffic"
                    title={t('main.traffic_info')}
                    path="/traffic-info"
                />
                <EldoWidget
                    icon="gaming"
                    title={t('menu.gaming')}
                    path="/radio/gaming"
                />
                <EldoWidget
                    icon="podcast"
                    title={t('main.podcast')}
                    path="/podcast-replay"
                />
            </div>
        </Section>
    );
}

export default MyWidgets;
