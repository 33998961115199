import { createContext, useMemo } from 'react';
import { Radio } from './types';
import useRadio from './hooks/useRadio';

export type RadioContextType = {
    radio?: Radio | null;
    radioLoading: boolean;
};

export const RadioContext = createContext<RadioContextType | undefined>(undefined);

export default function RadioProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    const { data: radio, isLoading: radioLoading } = useRadio();

    const value = useMemo(() => ({ radio, radioLoading }), [radio, radioLoading]);

    return <RadioContext.Provider value={value}>{children}</RadioContext.Provider>;
}
