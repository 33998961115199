import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generateId, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { getEventsCategories } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useEventCatgories() {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const {
        data: categories,
        status,
        refetch,
    } = useQuery(['event-categories'], getEventsCategories, {
        onError: (e) => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        categories,
        status,
        refetch,
    };
}
