import { useTranslation } from 'react-i18next';
import TopicCardList from 'components/Cards/TopicCardList';
import Section from 'components/core/Section';
import { TopicCategory } from 'types';

export default function TopicCategoriesList({ categories }: { categories: TopicCategory[] }) {
    const { t } = useTranslation();

    return (
        <Section
            title={t('topics')}
            path="/radio/rubriken"
        >
            <TopicCardList data={categories} />
        </Section>
    );
}
