import { useEffect } from 'react';

export type UseScriptProps = {
    script: string;
};

export default function useScript({ script }: UseScriptProps) {
    useEffect(() => {
        const scriptElem = document.createElement('script');

        scriptElem.innerHTML = script;
        scriptElem.async = true;

        document.body.appendChild(scriptElem);

        return () => {
            document.body.removeChild(scriptElem);
        };
    }, [script]);
}
