import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect } from 'react';
import { TopicCategory } from 'types';
import { getTopicCategory } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useTopicCategory(id: string) {
    const callback = useCallback(() => (id ? getTopicCategory(id) : null), [id]);

    const { data, status, refetch } = useQuery<TopicCategory | null>(['topicCategory'], callback, {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    useEffect(() => {
        refetch();
    }, [id]);

    return {
        data,
        isLoading: status === 'loading',
    };
}
