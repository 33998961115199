import { getEventById } from 'lib/ApiService';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { TOAST_DURATION } from '../../../constants';
import { Event } from '../types';

export default function useEventDetail(id: Event['id']) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const {
        data: event,
        status,
        refetch,
        isLoading,
        error,
    } = useQuery<Event>(['event-detail', id], () => getEventById(id), {
        onError: (e) => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        event,
        status,
        refetch,
        isLoading,
        error,
    };
}
