import { useCallback, useEffect, useMemo } from 'react';
import { useIonToast } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { getMoviesByCinema } from 'lib/ApiService';
import { useTranslation } from 'react-i18next';
import { MovieSession, MoviesByCinemaResponse } from 'types';
import useDebounce from 'hooks/useDebounce';
import { TOAST_DURATION } from '../../../constants';
import { getSessionsOptionsFromSessionsList } from './components/MovieSessionsPresenter';

export default function useMovieByCinema({
    id,
    startDate,
    endDate,
    title,
}: {
    id?: string | number | null;
    startDate?: string;
    endDate?: string;
    title?: string;
}) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const callback = useCallback(() => {
        if (!id || !startDate || !endDate) return [];
        return getMoviesByCinema(id as number, startDate, endDate, title);
    }, [id, startDate, endDate, title]);

    const { data, isLoading, error, refetch } = useQuery<MoviesByCinemaResponse>(
        ['cinemas-movies', id, 'movies'],
        callback,
        {
            onError: () => {
                // present(t('fetch_error'), TOAST_DURATION);
            },
            enabled: false,
        },
    );

    useDebounce(refetch, [title], 1000);

    useEffect(() => {
        if (id != null) {
            refetch();
        }
    }, [id, startDate, endDate]);

    const sessionsOptions = useMemo(() => {
        return getSessionsOptionsFromSessionsList(
            (data || []).reduce((prev, curr) => [...prev, ...(curr.sessions ?? [])], [] as Array<MovieSession>),
        );
    }, [data]);

    return {
        error,
        data,
        isLoading,
        sessionsOptions,
    };
}
