import { useEffect, useRef } from 'react';
import OneSignal from 'react-onesignal';
import CordovaOneSignal from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';

const appId = process.env.REACT_APP_ONE_SIGNAL_IDENTIFIER || '';
const env = process.env.REACT_APP_ENV || 'dev';

function OneSignalInit(): void {
    if (isPlatform('cordova')) {
        CordovaOneSignal.initialize(appId);
        CordovaOneSignal.Notifications.requestPermission(true).then((success: boolean) => {
            console.log('Notification permission granted', success);
        });
    }
}

const useOneSignal = () => {
    const oneSignalInitRef = useRef<boolean>(false);
    useEffect(() => {
        if (!appId || oneSignalInitRef.current) {
            return;
        }

        oneSignalInitRef.current = true;

        if (isPlatform('mobile')) {
            OneSignal.init({
                appId,
                allowLocalhostAsSecureOrigin: env === 'dev',
            });

            try {
                OneSignalInit();
            } catch (err) {
                console.log(err);
            }
        }
    }, []);

    const setExternalUserId = (id: string) => {
        if (isPlatform('cordova')) {
            CordovaOneSignal.login(id);
        } else {
            OneSignal.login(id);
        }
    };

    return { setExternalUserId };
};

export default useOneSignal;
