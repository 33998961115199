import React, { MouseEventHandler } from 'react';

export interface InputProps
    extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
    password?: boolean;
    icon?: React.ReactNode;
    hasError?: boolean;
    roundedBottom?: boolean;
    onIconClick?: MouseEventHandler;
    cursorPointer?: boolean;
}

const INPUT_ERROR_CLASS = 'relative border-b-2 border-danger';

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => (
    <div className="relative">
        {props.password && <div>Show Password</div>}
        <input
            className={`${props.className || ''} ${
                props.cursorPointer ? 'cursor-pointer' : ''
            } typo-b2 regular my-[15px] px-[15px] w-full border-0 h-[54px] outline-0 bg-background-alt ${
                props?.roundedBottom ? 'rounded' : 'rounded-t'
            } ${props.hasError ? INPUT_ERROR_CLASS : ''}`}
            ref={ref}
            {...props}
        />
        {props.icon ? (
            <div
                className="absolute right-[15px] top-1/2 -translate-y-1/2"
                onClick={props.onIconClick}
            >
                {props.icon}
            </div>
        ) : (
            ''
        )}
    </div>
));

export default Input;
