import { IonGrid, IonItem, IonLabel, IonRow, IonTextarea, useIonToast } from '@ionic/react';
import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';
import Button from 'components/Button';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import InputError from 'components/InputError';
import clsx from 'clsx';
import { TOAST_DURATION } from '../../constants';

type FormValues = {
    prompt: string;
};

interface LetzAiPromptProps {
    onComplete: (prompt: string) => void;
    description: string;
}

export default function LetzAiPrompt({ onComplete, description }: LetzAiPromptProps) {
    const { t } = useTranslation();
    const [present] = useIonToast();

    const schema = yup
        .object({
            prompt: yup.string().required(t('form.prompt_required')),
        })
        .required();

    const {
        register,
        handleSubmit,
        formState: { isValid, errors },
        control,
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: FormValues) => {
        try {
            present({ color: 'success', duration: TOAST_DURATION, message: t('letzAi.promptSend') });
            onComplete(data.prompt);
        } catch (e: any) {
            const message: string = e.error || e.response?.data?.error || e.response?.message || e.message || 'Error';
            await present({ message, duration: TOAST_DURATION, position: 'top', color: 'danger' });
        }
    };

    return (
        <IonGrid
            fixed
            className="md:flex md:justify-center md:bg-[#141414]"
        >
            <div className="md:w-[550px] w-full">
                <form
                    onSubmit={handleSubmit(onSubmit)}
                    className="flex flex-col gap-[30px]"
                >
                    <div>{description}</div>
                    <IonRow>
                        <IonItem className="bg-[#343434] w-full rounded-t">
                            <IonLabel
                                className="game-input pt-1"
                                position="stacked"
                            >
                                {t('letzAi.prompt')}
                            </IonLabel>
                            <IonTextarea
                                {...register('prompt')}
                                style={{ minHeight: 220 }}
                                className='py-4'
                            />
                        </IonItem>
                    </IonRow>

                    <IonRow>
                        <div>
                            <ErrorMessage
                                errors={errors}
                                name="prompt"
                                as={<InputError />}
                            />
                        </div>
                        <Button
                            value={t('letzAi.send')}
                            className={clsx(
                                'border-0 !text-[#141414] bg-white hover:bg-[#e5e0e0] transition duration-300',
                            )}
                            type="submit"
                            disabled={false}
                        />
                    </IonRow>
                </form>
            </div>
        </IonGrid>
    );
}
