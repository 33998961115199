import Img from './Img';

export default function BannerForApp() {
    return (
        <div className=" bg-cover h-[380px] background-banner-app my-block flex items-center justify-center rounded-[10px]">
            <section className="max-w-[600px] w-full bg-[#F08B1F] rounded-[10px] h-[275px] flex text-[#141414] md:p-[40px] p-[25px] gap-[100px] mx-[20px] relative pb-[25px]">
                <article className="md:w-2/3 w-3/4 flex flex-col gap-[20px] justify-between">
                    <div className="flex flex-col gap-[20px]">
                        <h2 className="font-poppins font-bold text-[16px] md:text-[23px] md:leading-[32px]">
                            Déi ganz Eldo-Welt och an der Eldo-App!
                        </h2>
                        <p className="font-poppins md:text-base text-[14px]">
                            Lauschter, kuck a maach mat bei eise Gewënnspiller!
                        </p>
                    </div>
                    <div className="flex gap-[10px]">
                        <a
                            href="https://apps.apple.com/lu/app/eldo/id305966737"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Img
                                src="/assets/img/AppStore.svg"
                                className="md:hover:scale-[110%] transition-all duration-300 cursor-pointer"
                                imgClassName="w-[150px]"
                            />
                        </a>
                        <a
                            href="https://play.google.com/store/apps/details?id=lu.rtl.newmedia.eldo"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <Img
                                src="/assets/img/PlayStore.svg"
                                className="md:hover:scale-[110%] transition-all duration-300 cursor-pointer"
                                imgClassName="w-[150px]"
                            />
                        </a>
                    </div>
                </article>
                <article className="md:w-1/3 max-md:absolute max-md:right-[75px] max-md:-top-[30px]">
                    <Img
                        src="/assets/img/bannerSmartPhoneImage.png"
                        className="absolute md:-translate-y-[60px] md:-translate-x-[25px] w-[250px]"
                    />
                </article>
            </section>
        </div>
    );
}
