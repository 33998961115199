import { useInfiniteQuery } from '@tanstack/react-query';
import { generateId, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Article, NewsCategory, NewsResponse } from 'types';
import { AdData } from 'features/advertising/generateAdPayload';
import { getNews, getNewsByCategory, getNewsByTag } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

type UseNewsProps = {
    categoryId?: NewsCategory['id'];
    tag?: string;
    limit?: number;
    waitForTag?: boolean;
    adPage?: string;
};

export default function useNews({ categoryId, tag, limit = 8, waitForTag, adPage }: UseNewsProps = {}) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const [queryName] = useState(`new-request-${generateId('new-request')}`);

    const func = useCallback(
        ({ pageParam }) => {
            if (!tag && waitForTag) return { articles: [], next: false, tag: '' } as NewsResponse;
            const start = pageParam?.start || 0;
            if (tag) {
                return getNewsByTag(tag, limit, start, adPage);
            }
            return categoryId ? getNewsByCategory(categoryId, limit, start) : getNews(limit, start);
        },
        [categoryId, tag, limit, waitForTag],
    );

    const { data, status, refetch, hasNextPage, fetchNextPage } = useInfiniteQuery<NewsResponse>(
        [queryName, categoryId, tag, limit],
        func,
        {
            onError: () => {
                // present(t('fetch_error'), TOAST_DURATION);
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.next) return undefined;
                return {
                    start: allPages.length * limit,
                };
            },
        },
    );

    useEffect(() => {
        refetch();
    }, [categoryId, tag, limit, refetch]);

    return {
        list: (data?.pages || []).reduce(
            (prev, current) => [...prev, ...(current.articles || [])],
            [] as Array<Article | AdData>,
        ),
        isLoading: status === 'loading',
        loadMore: fetchNextPage,
        canLoadMore: hasNextPage,
        data,
        refetch,
    };
}
