import { useInfiniteQuery } from '@tanstack/react-query';
import { generateId, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import { Article, CinemaArticles, NewsCategory, NewsResponse } from 'types';
import { AdData, AdResponse } from 'features/advertising/generateAdPayload';
import { getCinemaArticles, getNews, getNewsByCategory, getNewsByTag } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

type FilmNewsProps = {
    filmsOfTheWeek?: boolean;
    limit?: number;
};

export default function useCinemaNewsOfFilmOfTheWeek({ filmsOfTheWeek = false, limit = 8 }: FilmNewsProps = {}) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const [queryName] = useState(`new-request-${generateId('new-request')}`);

    const func = useCallback(
        ({ pageParam }) => {
            const start = pageParam?.start || 0;
            return getCinemaArticles(filmsOfTheWeek, limit, start);
        },
        [filmsOfTheWeek, limit],
    );

    const { data, status, refetch, hasNextPage, fetchNextPage } = useInfiniteQuery<CinemaArticles>([queryName], func, {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
        getNextPageParam: (lastPage, allPages) => {
            if (!lastPage.next) return undefined;
            return {
                start: allPages.length * limit,
            };
        },
    });

    useEffect(() => {
        refetch();
    }, [filmsOfTheWeek, limit]);

    return {
        list: (data?.pages || []).reduce(
            (prev, current) => [...prev, ...(current.articles || [])],
            [] as Array<Article | AdData>,
        ),
        isLoading: status === 'loading',
        loadMore: fetchNextPage,
        canLoadMore: hasNextPage,
        data,
    };
}
