import React, { useState } from 'react';
import SignupStep1 from '../features/auth/components/SignupStep1';
import SignupStep2 from '../features/auth/components/SignupStep2';

const SIGNUP_INFORMATION_STEP = 1;
const SIGNUP_VALIDATION_STEP = 2;

type SignupFormProps = {
    onComplete: () => void;
    className?: string;
    onLoginClick?: () => void;
};

export default function SignupForm({ onComplete, className, onLoginClick }: SignupFormProps) {
    const [step, setStep] = useState(SIGNUP_INFORMATION_STEP);
    const [email, setEmail] = useState<string | null>(null);

    const onStep1Completed = (e: string) => {
        setEmail(e);
        setStep(SIGNUP_VALIDATION_STEP);
    };

    return (
        <div className={`${className || ''} mx-auto`}>
            {step === SIGNUP_INFORMATION_STEP && (
                <SignupStep1
                    onLoginClick={onLoginClick}
                    onComplete={onStep1Completed}
                />
            )}
            {step === SIGNUP_VALIDATION_STEP && email && (
                <SignupStep2
                    email={email}
                    onComplete={onComplete}
                />
            )}
        </div>
    );
}
