import VerticalMediumCard from 'components/VerticalMediumCard';
import useAuth from 'features/auth/useAuth';
import { Camera } from 'types';
import { HTMLAttributes } from 'react';
import useTrafficInfos from './useTrafficInfo';

interface TrafficCameraCardProps extends HTMLAttributes<HTMLDivElement> {
    camera: Camera;
}

export default function TrafficCameraCard({ camera, className = '' }: TrafficCameraCardProps) {
    const { isAuthenticated } = useAuth();
    const { addCameraToFavorites, removeCameraFromFavorites } = useTrafficInfos();

    return (
        <VerticalMediumCard
            key={camera.id}
            favorite={camera.favorite}
            displayFavorite={isAuthenticated}
            onFavoriteClick={() => {
                return camera.favorite ? removeCameraFromFavorites(camera.id) : addCameraToFavorites(camera.id);
            }}
            thumbnailUrl={camera.image}
            title={camera.from}
            subtitle={camera.to}
            video={camera.stream}
            className={className}
            imgSize="small"
            hasWavePattern={false}
        />
    );
}
