import { IonFab, IonIcon } from '@ionic/react';
import { search } from 'ionicons/icons';
import { Link } from 'react-router-dom';

export default function EventSearchFloatingLink() {
    return (
        <IonFab
            slot="fixed"
            horizontal="end"
            vertical="bottom"
        >
            <Link to="/events-search">
                <div className="flex justify-center items-center bg-[white] rounded-full h-[50px] w-[50px] z-30 md:hidden">
                    <IonIcon
                        icon={search}
                        className="fill-black h-[24px] w-[24px]"
                    />
                </div>
            </Link>
        </IonFab>
    );
}
