import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import FilterButton from './FilterButton';
import useResponsive from '../features/responsive/responsive';

export interface Option {
    id: number | string;
    name: string;
}

interface ButtonGroupProps {
    options: Option[];
    onChange?: (opt: Option | Option[]) => void;
    onClick?: () => void;
    value?: Option;
    color?: 'primary' | 'tertiary';
    multiple?: boolean;
    noFirstSelect?: boolean;
    className?: string;
    fullWidthOnMobile?: boolean;
    eventNavigator?: boolean;
}

function ButtonGroup({
    options,
    onChange,
    onClick,
    color = 'primary',
    multiple,
    noFirstSelect,
    className,
    value,
    fullWidthOnMobile = true,
    eventNavigator = false,
}: ButtonGroupProps): JSX.Element {
    const [activeOption, setActiveOption] = useState<string | string[] | null>(
        // eslint-disable-next-line no-nested-ternary
        multiple ? [] : noFirstSelect ? null : options[0]?.name,
    );
    const { IsMobile } = useResponsive();
    const history = useHistory();

    useEffect(() => {
        if (value !== undefined && !Array.isArray(value)) {
            setActiveOption(value?.name);
        }
    }, [value]);

    const handleClickOpt = (optName: Option['name']) => {
        window.location.hash = optName;
        if (multiple) {
            if (Array.isArray(activeOption)) {
                if (activeOption.includes(optName)) {
                    setActiveOption(activeOption.filter((x) => x !== optName));
                } else {
                    setActiveOption([...activeOption, optName]);
                }
            }
        } else {
            setActiveOption(optName);
        }
    };

    useEffect(() => {
        if (activeOption && onChange) {
            if (!multiple) {
                const optFound = options.find((opt) => opt.name === activeOption);
                if (optFound) {
                    onChange(optFound);
                }
            } else if (Array.isArray(activeOption)) {
                onChange(options.filter((opt) => activeOption.includes(opt.name)));
            }
        }
    }, [activeOption]);

    return (
        <div className={` ${IsMobile && fullWidthOnMobile ? 'full-width' : ''}`}>
            <div
                className={`flex gap-[10px] text-sm overflow-x-auto ${
                    IsMobile && fullWidthOnMobile ? 'scrollbar-hide !pl-[var(--padding-start)] !md:pl-0' : ''
                } ${className || ''}`}
            >
                {options
                    .filter((optionFilter) => optionFilter.name !== '')
                    .map((option) => (
                        <FilterButton
                            key={option.id}
                            filter={option.name}
                            // @ts-ignore
                            setSelectedFilter={(opt: string) => {
                                handleClickOpt(opt);
                                if (onClick) onClick();
                            }}
                            selectedFilter={activeOption}
                            className="whitespace-nowrap text-base"
                            color={color}
                        />
                    ))}

                {eventNavigator && (
                    <>
                        <FilterButton
                            key="event-navigator-charts"
                            filter="Charts"
                            setSelectedFilter={() => history.push('/musek/charts')}
                            selectedFilter={activeOption}
                            className="whitespace-nowrap text-base"
                            color={color}
                        />
                        <FilterButton
                            key="event-navigator-picture-reports"
                            filter="Pictures Report"
                            setSelectedFilter={() => history.push('/events/fotoen')}
                            selectedFilter={activeOption}
                            className="whitespace-nowrap text-base"
                            color={color}
                        />
                    </>
                )}
            </div>
        </div>
    );
}

export default ButtonGroup;
