import { AdData } from 'features/advertising/generateAdPayload';
import React, { useCallback, useMemo, useState } from 'react';
import { generateId } from '@ionic/react';
import { splitArrayChunks, isNativeAd, generateUniqueId } from 'lib/utils';
import CardListing, { CardListingItemFullWidth } from './core/CardListing';
import AdComponent from './Ads/AdComponent';
import useResponsive from '../features/responsive/responsive';

export type CardListingChunksWithAdsProps<T> = {
    children: (item: T) => React.ReactNode;
    adsBetween?: AdData[];
    chunksSize?: number;
    className?: string;
    data: Array<T | AdData>;
    getKey?: (item: T) => string | number;
    adNativeClassName?: string;
    isVerticalCards?: boolean;
    removeMinHeight?: boolean;
};

export default function CardListingChunksWithAds<T = unknown>({
    children,
    adsBetween = [],
    chunksSize,
    className,
    data,
    getKey = () => generateId('chunks'),
    adNativeClassName,
    isVerticalCards,
    removeMinHeight = false,
}: CardListingChunksWithAdsProps<T>) {
    const { IsExclusiveTablet, IsMobile } = useResponsive();

    const [adHiddens, setAdHiddens] = useState<string[]>([]);
    const hideAd = (id: string) => setAdHiddens((prev) => [...(prev || []), id]);
    const isAdHidden = useCallback((id: string) => Boolean(adHiddens.find((k) => k === id)), [adHiddens]);

    const [adsBetweenHiddenKeys, setAdsBetweenHiddenKeys] = useState<string[]>([]);
    const hideAdBetweenKey = (key: string) => {
        setAdsBetweenHiddenKeys((prev) => [...(prev || []), key]);
    };
    const isAdBetweenHiddenKey = useCallback(
        (key: string) => Boolean(adsBetweenHiddenKeys.find((k) => k === key)),
        [adsBetweenHiddenKeys],
    );
    const getAdsBetween = (chunksIndex: number) => {
        const ads = adsBetween
            .filter((ad) => ad != null)
            .filter((ad) => !isAdBetweenHiddenKey(`${ad.containerId}${chunksIndex}`));
        if (ads?.length === 0) return null;
        return (
            <CardListingItemFullWidth
                isVerticalCards={isVerticalCards}
                key={generateUniqueId()}
                className="m-auto"
            >
                {ads.map((ad) => (
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    <AdComponent
                        onNoContent={() => hideAdBetweenKey(`${ad.containerId}${chunksIndex}`)}
                        key={`${ad.containerId}${chunksIndex}`}
                        ad={ad}
                        hasSpacings={IsMobile}
                        className="mt-[40px] relative"
                    />
                ))}
            </CardListingItemFullWidth>
        );
    };

    // @ts-ignore
    const getComputedKey = (item: T | AdData) => item?.id || item?.containerId || generateId('chunks');

    const computedData = useMemo<CardListingChunksWithAdsProps<T>['data']>(() => {
        if (!data?.length) return [];
        if (!adHiddens?.length) return data;
        return data.filter((d) => !isNativeAd(d) || !isAdHidden(d?.containerId));
    }, [data, adHiddens, isAdHidden]);

    const chunks = splitArrayChunks(computedData, chunksSize || (!IsMobile && !IsExclusiveTablet ? 9 : 8));

    return (
        <div className={`${className || ''} grid gap-standardMobile md:gap-standard`}>
            {chunks.map((chunk, chunksIndex) => (
                <div
                    className="flex max-md:flex-col gap-standardMobile md:gap-standard relative"
                    key={generateUniqueId()}
                >
                    <CardListing
                        isVerticalCards={isVerticalCards}
                        className={`flex-1 ${removeMinHeight ? '' : 'min-h-[600px]'}`}
                    >
                        {chunk.map((item, index) =>
                            !isNativeAd(item) ? (
                                <div key={getComputedKey(item)}>{children(item)}</div>
                            ) : (
                                <AdComponent
                                    key={getComputedKey(item)}
                                    onNoContent={() => hideAd(item.containerId)}
                                    ad={item}
                                    hasSpacings={false}
                                    className={`${adNativeClassName || ''} h-full w-full`}
                                />
                            ),
                        )}
                    </CardListing>

                    {!IsExclusiveTablet &&
                    chunksIndex % 2 === 0 &&
                    adsBetween?.length &&
                    (chunksIndex !== chunks.length - 1 || chunksIndex === 0) ? (
                        <div className="md:absolute md:top-0 md:-right-standard transform md:translate-x-[100%]">
                            {getAdsBetween(chunksIndex)}
                        </div>
                    ) : null}
                </div>
            ))}
        </div>
    );
}
