type CustomMapProps = {
    lon: number;
    lat: number;
    width?: number | string | undefined;
    height?: number | string | undefined;
    className?: string;
};

export default function CustomMap({ lon, lat, width, height, className }: CustomMapProps) {
    return (
        <iframe
            className={className}
            title="waze"
            src={`https://embed.waze.com/iframe?zoom=12&lat=${lat}&lon=${lon}`}
            width={width || '100%'}
            height={height || 250}
        />
    );
}
