import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { getRadio } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useRadio() {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const { data, status } = useQuery(['radio'], getRadio, {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
        refetchInterval: 300000,
    });

    return {
        data,
        isLoading: status === 'loading',
    };
}
