import { IonIcon } from '@ionic/react';
import { chevronForwardOutline } from 'ionicons/icons';

type CarouselNavigationProps = {
    next: () => void;
    prev: () => void;
    reachStart: boolean;
    reachEnd: boolean;
    overlap?: boolean;
    forceNavEndArrowsVisible?: boolean;
};

const CAROUSEL_NAV_CLASSNAMES =
    'z-10 absolute top-1/2 transform -translate-y-1/2 hidden md:grid rounded-full bg-primary h-[50px] w-[50px] z-10 place-items-center hover:h-[55px] hover:w-[55px]';

function CarouselNavButton({
    className,
    onClick,
    disabled,
}: {
    disabled?: boolean;
    className?: string;
    onClick: React.MouseEventHandler;
}) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            className={`${CAROUSEL_NAV_CLASSNAMES} ${className || ''}`}
        >
            <IonIcon
                size="large"
                icon={chevronForwardOutline}
                color="white"
            />
        </button>
    );
}

export default function CarouselNavigation({
    prev,
    next,
    reachEnd,
    reachStart,
    overlap,
    forceNavEndArrowsVisible,
}: CarouselNavigationProps) {
    return (
        <div className=" w-full items-center justify-between">
            {!reachStart ? (
                <CarouselNavButton
                    onClick={prev}
                    className={`left-0 -scale-100 ${overlap ? '-translate-x-1/2' : ''}`}
                />
            ) : (
                <div />
            )}
            {!reachEnd || forceNavEndArrowsVisible ? (
                <CarouselNavButton
                    onClick={next}
                    className={`right-0 ${overlap ? 'translate-x-1/2' : ''}`}
                />
            ) : (
                ''
            )}
        </div>
    );
}
