import { useIonToast, IonLoading } from '@ionic/react';
import { validateSignup, resendValidation } from 'lib/ApiService';
import { useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import Button from './Button';
import OtpInput from './OtpInput';
import { TOAST_DURATION } from '../constants';

type OtpFormProps = {
    onComplete: () => void;
    email: string;
};

export default function OtpForm({ onComplete, email }: OtpFormProps) {
    const { t } = useTranslation();
    const [isLoading, setIsLoading] = useState(false);
    const [OTP, setOTP] = useState('');
    const [present] = useIonToast();

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoading(true);
        try {
            await validateSignup({ email, token: OTP });
            onComplete();
        } catch (error: any) {
            await present(error.message, TOAST_DURATION);
        } finally {
            setIsLoading(false);
        }
    };

    const onClick = async () => {
        setIsLoading(true);
        try {
            await resendValidation(email);
            await present({
                message: t('signup_validation.email_sent'),
                duration: 3000,
                position: 'top',
                color: 'success',
            });
        } catch (error: any) {
            await present({
                message: error?.message || t('signup_validation.already_validated'),
                duration: 3000,
                position: 'bottom',
                color: 'warning',
            });
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h1 className="my-4 typo-h1 font-bold">{t('signup_validation.title')}</h1>
            <p className="my-4 typo-b1 regular">{t('signup_validation.subtitle')}</p>
            {isLoading && <IonLoading isOpen />}

            <form onSubmit={onSubmit}>
                <OtpInput
                    shouldAutoFocus
                    isInputNum
                    value={OTP}
                    onChange={setOTP}
                    numInputs={4}
                    separator=""
                />

                <Button
                    type="submit"
                    className="my-[50px]"
                    value={t('next')}
                    disabled={OTP.length < 4}
                />
            </form>

            <p className="mt-0 text-center">
                {t('no_email_received')} <br />
                <button
                    className="text-white bg-transparent typo-b2 regular underline"
                    onClick={onClick}
                >
                    {t('resend_email')}
                </button>
            </p>
        </>
    );
}
