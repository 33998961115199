import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { getActionArticle, getArticle } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useActionArticle(slug: string) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const { data, status } = useQuery(['actionArticle', slug], () => getActionArticle(slug), {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        data,
        isLoading: status === 'loading',
    };
}
