import { ErrorMessage } from '@hookform/error-message';
import { IonLoading, useIonToast } from '@ionic/react';
import { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { passwordRegex } from 'lib/utils';
import Button from '../../../components/Button';
import InputError from '../../../components/InputError';
import Input from '../../../components/Input';
import { resetPasswordComplete } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

type FormValues = {
    password: string;
    passwordConfirmation: string;
};

export default function ResetStep3({
    onComplete,
    token,
    email,
}: {
    token: string;
    email: string;
    onComplete: () => void;
}) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [present] = useIonToast();

    const schema = yup
        .object({
            password: yup
                .string()
                .required(t('password_required'))
                .matches(passwordRegex, { message: t('password_invalid') }),
            passwordConfirmation: yup
                .string()
                .required(t('password_confirmation_required'))
                .oneOf([yup.ref('password')], t('Your passwords do not match.')),
        })
        .required();

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: FormValues) => {
        setIsLoading(true);
        await resetPasswordComplete(email, data.password, token)
            .then(() => {
                onComplete();
            })
            .catch((e) => {
                present({
                    color: 'danger',
                    duration: TOAST_DURATION,
                    message: e?.message ?? t('error_reset_password_final'),
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <h1 className="mt-4 typo-h1 font-bold">{t('reset_password.step3_title')}</h1>
            <p className="mb-4 typo-b1 regular">{t('reset_password.step3_subtitle')}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading && <IonLoading isOpen />}

                <Input
                    hasError={!!errors.password}
                    type="password"
                    placeholder={t('password')}
                    {...register('password')}
                />
                <ErrorMessage
                    errors={errors}
                    name="password"
                    as={<InputError />}
                />

                <Input
                    hasError={!!errors.passwordConfirmation}
                    type="password"
                    placeholder={t('password_confirmation')}
                    {...register('passwordConfirmation')}
                />
                <ErrorMessage
                    errors={errors}
                    name="passwordConfirmation"
                    as={<InputError />}
                />

                <Button
                    type="submit"
                    className="my-[45px] md:my-[15px]"
                    value={t('next')}
                    disabled={!watch('password') || !watch('passwordConfirmation')}
                />
            </form>
        </>
    );
}
