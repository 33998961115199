/* eslint-disable prefer-destructuring */
import React, { useMemo, useEffect, ClipboardEventHandler } from 'react';

type OtpInputProps = {
    shouldAutoFocus?: boolean;
    isInputNum?: boolean;
    numInputs?: number;
    value?: string;
    onChange: (val: string) => void;
    separator?: string;
    className?: string;
};

export default function OtpInput({
    shouldAutoFocus = true,
    isInputNum = true,
    numInputs = 4,
    value = '',
    onChange,
    separator = '',
    className,
}: OtpInputProps) {
    const inputsRef = React.useRef<HTMLInputElement[]>([]);

    useEffect(() => {
        if (shouldAutoFocus) {
            setTimeout(() => {
                inputsRef.current[0].focus();
            }, 0);
        }
    }, []);

    const onInput = (e: any, key: number) => {
        if (isInputNum) {
            e.target.value = e.target.value.replace(/[^0-9]/g, '');
        }
        if (e.target.nextSibling) {
            e.target.nextSibling.focus();
        }
        onChange(inputsRef.current.map((el) => el.value).join(separator));
    };

    const handlePaste: ClipboardEventHandler = (e) => {
        e.preventDefault();
        // @ts-ignore
        const clipboardData = e.clipboardData || e?.originalEvent?.clipboardData || window?.clipboardData;
        const paste = clipboardData?.getData('text');
        if (typeof paste !== 'string') return;
        const text = paste.trim();
        inputsRef.current[0].value = text?.[0] || '';
        inputsRef.current[1].value = text?.[1] || '';
        inputsRef.current[2].value = text?.[2] || '';
        inputsRef.current[3].value = text?.[3] || '';
        onChange(inputsRef.current.map((el) => el.value).join(separator));
    };
    const inputs = useMemo(() => {
        const res = [];
        for (let i = 0; i < numInputs; i += 1) {
            res.push(
                <input
                    key={i}
                    type="number"
                    className="text-center h-[82px] rounded-[15px] focus:outline-none bg-[var(--eldo-background-alt-color)] text-white text-[24px] remove-arrow"
                    onInput={(e) => onInput(e, i)}
                    ref={(el) => {
                        inputsRef.current[i] = el as HTMLInputElement;
                    }}
                    onPaste={handlePaste}
                />,
            );
        }

        return res;
    }, []);

    return <div className={`grid grid-cols-4 gap-[9px] w-full ${className || ''}`}>{inputs}</div>;
}
