import { OrnamentBlueIcon } from './Icons';

type OrnamentBlueProps = {
    className?: string;
};

export default function OrnamentBlue({ className }: OrnamentBlueProps) {
    return (
        <div className="relative">
            <div className={`absolute ${className || ''}`}>
                <OrnamentBlueIcon />
            </div>
        </div>
    );
}
