import { OrnamentIcon } from './Icons';

export default function Ornament() {
    return (
        <div className="relative">
            <div className="absolute right-[0px] top-[42px]">
                <OrnamentIcon />
            </div>
        </div>
    );
}
