import { IonIcon, IonRouterLink } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { chevronForwardOutline } from 'ionicons/icons';
import { Link } from 'react-router-dom';
import UserThumbnail from './UserThumbnail';
import useAuth from '../features/auth/useAuth';
import Card from './Card';
import { ReactComponent as LoginIllustation } from './Icons/login-illustration.svg';

type UserCardProps = {
    linkTo?: string;
    expanded?: boolean;
};

export default function UserAccountCard({ linkTo = '', expanded = false }: UserCardProps) {
    const { user } = useAuth();
    const { t } = useTranslation();

    if (!user) {
        return (
            <IonRouterLink
                routerDirection="root"
                routerLink="/login"
                className=""
            >
                <Card>
                    <div className="flex items-center justify-between text-white w-full">
                        <div className="flex items-center justify-between">
                            <div>
                                <LoginIllustation className="" />
                            </div>
                            <div className="text-md pl-4 ">
                                <p>Create an account for a personalized experience</p>
                            </div>
                        </div>
                        <div>
                            <div className="rounded-full bg-lighter-background w-[30px] h-[30px] flex justify-center items-center">
                                <IonIcon
                                    icon={chevronForwardOutline}
                                    color=""
                                />
                            </div>
                        </div>
                    </div>
                </Card>
            </IonRouterLink>
        );
    }

    return (
        <div>
            <Link
                to={linkTo}
                className="flex items-center"
            >
                {/* <UserThumbnail src={user?.picture?.path || '/assets/img/temp/unsplash.jpg'} /> */}
                <UserThumbnail src="" />
                <div className="ml-3">
                    <div className="typo-h2 font-bold">Hey {user.firstname}!</div>
                    {expanded && <div className="typo-b2">{t('account.manage_my_account')}</div>}
                </div>
            </Link>
        </div>
    );
}
