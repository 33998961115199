import React from 'react';
import sprite from './appIcons.svg';

type IconProps = {
    name: string;
    classes?: string;
    hasCircle?: boolean;
    isAltBg?: boolean;
    iconType?: string;
};

function AppIcon({ name, classes = '', hasCircle, isAltBg, iconType = 'svg' }: IconProps) {
    return (
        <div
            className={`custom-icon-wrapper ${classes} ${hasCircle ? 'has-circle' : ''}
      ${isAltBg ? 'alt-bg' : ''}`}
        >
            {iconType === 'css' ? (
                <i className={`eldo-icon eldo-icon--css-type eldo-icon-${name}`} />
            ) : (
                <svg
                    id={`custom-svg-${name}`}
                    className={`eldo-icon eldo-icon--svg-type custom-svg custom-svg--${name}`}
                >
                    <use href={`${sprite}#icon-${name}`} />
                </svg>
            )}
        </div>
    );
}

export default AppIcon;
