import { Share } from '@capacitor/share';
import { useEffect, useState } from 'react';

export default function useCanShare() {
    const [canShare, setCanShare] = useState(false);

    useEffect(() => {
        Share.canShare().then((c) => {
            setCanShare(c.value);
        });
    }, []);

    return {
        canShare,
    };
}
