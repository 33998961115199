import { IonCard, IonRadio } from '@ionic/react';
import React, { Ref, useRef } from 'react';

type CardProps = {
    title: string;
    value: string;
    register?: any;
    topContent?: React.ReactNode;
    className?: string;
};

function CardWithRadioButton({ title, value, register, topContent, className }: CardProps) {
    const ionRadioRef = useRef<any>(null);

    const cardClicked = () => {
        if (ionRadioRef && ionRadioRef.current) {
            ionRadioRef.current.click();
        }
    };

    return (
        <IonCard
            className={`m-0 ${
                className || ''
            } typo-b1 regular w-100 flex flex-column gap-[10px] justify-center items-center`}
            onClick={cardClicked}
        >
            {topContent || ''}
            <div className="">{title}</div>
            <IonRadio
                value={value}
                {...register}
                ref={ionRadioRef}
                onClick={(e) => {
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                }}
            />
        </IonCard>
    );
}

export default CardWithRadioButton;
