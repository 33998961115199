import { ReactChild, ReactChildren } from 'react';
import { IonCard } from '@ionic/react';
import { useSwiper } from 'swiper/react';

type ChannelCardProps = {
    children: ReactChildren | ReactChild;
    setActiveSlide: (index: number) => void;
    active: boolean;
    index: number;
};

export default function ChannelSlide({ children, index, setActiveSlide, active }: ChannelCardProps) {
    const swiper = useSwiper();

    const cardStyle = {
        height: '105px',
        width: '100%',
        padding: 0,
    };

    const onClick = () => {
        swiper.slideToLoop(index);
        setActiveSlide(index);
    };

    return (
        <IonCard
            onClick={onClick}
            className={`flat-thumbed-card m-0 ${active ? 'active border-2 border-primary' : ''}`}
            style={cardStyle}
        >
            {children}
        </IonCard>
    );
}
