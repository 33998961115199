import React, { InputHTMLAttributes, ReactElement } from 'react';
import uuid from 'react-uuid';

interface ToggleProps extends InputHTMLAttributes<HTMLInputElement> {
    label: string | ReactElement | ReactElement[];
}

export default React.forwardRef<HTMLInputElement, ToggleProps>((props, ref) => {
    const id = uuid();

    return (
        <div className={`flex items-center my-[15px] ${props.className || ''}`}>
            <label
                className="switch"
                htmlFor={`toggle-${id}`}
            >
                <input
                    ref={ref}
                    type="checkbox"
                    id={`toggle-${id}`}
                    {...props}
                />
                <div className="slider round" />
            </label>
            <p className="ml-8 text-[14px] flex-1">{props.label}</p>
        </div>
    );
});
