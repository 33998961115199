import React, { useState } from 'react';
import { AsyncImage } from 'loadable-image';
import clsx from 'clsx';
import LoadingIndicator from './LoadingIndicator';

export type ImgProps = React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> & {
    imgClassName?: string;
    hasCardHoverEffect?: boolean;
    sharp?: boolean;
    lazyLoading?: boolean;
};

export default function Img({ imgClassName, hasCardHoverEffect, sharp, lazyLoading, ...imgProps }: ImgProps) {
    const [needFallbackImage, setNeedFallbackImage] = useState(false);

    const imgClassNames = React.useMemo(() => {
        let res = imgClassName || '';
        if (hasCardHoverEffect) {
            res += ' transition-all duration-300 transform md:group-hover/hovercard:scale-105 !h-full !w-full';
        }
        if (!sharp) {
            res += ' rounded';
        }
        return res;
    }, [imgClassName]);

    return (
        <div className={`md:max-h-[600px] object-fit ${!sharp ? 'rounded' : ''} ${imgProps.className || ''}`}>
            {lazyLoading ? (
                <AsyncImage
                    onError={() => {
                        setNeedFallbackImage(true);
                    }}
                    alt=""
                    {...imgProps}
                    src={needFallbackImage || !imgProps.src ? '/assets/img/placeholderimg2.svg' : imgProps.src}
                    className={clsx(imgClassNames, '[&_img]:object-cover [&_img]:object-top')}
                    loader={<LoadingIndicator imageLoader />}
                />
            ) : (
                <img
                    onError={() => {
                        setNeedFallbackImage(true);
                    }}
                    loading="lazy"
                    alt=""
                    {...imgProps}
                    src={needFallbackImage ? '/assets/img/placeholderimg2.svg' : imgProps.src}
                    className={clsx(imgClassNames, 'object-cover')}
                />
            )}
        </div>
    );
}
