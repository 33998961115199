import { useTranslation } from 'react-i18next';
import SearchPageComponent from 'components/core/SearchPageComponents';

export default function TicketingPage() {
    const { t } = useTranslation();

    const link = (value: string) => ({ to: { pathname: '/events/ticket-bourse', state: value } });

    return (
        <SearchPageComponent
            title={t('your-search')}
            linkBack={link}
            linkSearchEnd={link}
            displayResetSearch={false}
        />
    );
}
