import React from 'react';
import { createPortal } from 'react-dom';
import useClickOutside from 'hooks/useClickOutside';

type SidebarProps = {
    isOpen: boolean;
    children?: React.ReactNode;
    onClose: () => void;
};

export default function Sidebar({ isOpen, children, onClose }: SidebarProps) {
    const sidebarRef = useClickOutside(() => {
        if (onClose) {
            onClose();
        }
    }, []);

    return createPortal(
        <div
            ref={sidebarRef}
            className={`fixed right-0 top-0 bottom-0 h-full transition-all bg-[#141414] text-white ${
                isOpen ? 'w-[560px]' : 'w-0'
            }`}
        >
            {children}
        </div>,
        document.querySelector('ion-app') as HTMLElement,
    );
}
