import { useHistory } from 'react-router';
import PageLayout from '../components/PageLayout';
import ResetPasswordForm from '../components/ResetPasswordForm';

export default function ResetPasswordPage() {
    const history = useHistory();

    return (
        <PageLayout hasBackButton>
            <ResetPasswordForm
                onDone={() => {
                    history.replace('/login');
                }}
            />
        </PageLayout>
    );
}
