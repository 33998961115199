import { useQuery } from '@tanstack/react-query';
import { LetzAiApprovedPicture } from 'types';
import { getApprovedLetzAiPicture } from '../../../lib/ApiService';

export default function useLetzAiApprovedPicture(id: string) {
    const { data, status } = useQuery<LetzAiApprovedPicture>(
        [`LetzAiApprovedPicture-${id}`],
        () => getApprovedLetzAiPicture(id),
        {
            onError: () => {},
        },
    );

    return {
        data,
        isLoading: status === 'loading',
    };
}
