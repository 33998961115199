import ButtonGroup from 'components/ButtonGroup';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    onProductTypeChange: (newType: number) => void;
    option: number;
};

function TicketOption({ onProductTypeChange, option }: Props) {
    const { t } = useTranslation();

    const options = [
        { id: 0, name: t('ticketing.filters.all') },
        { id: 1, name: t('ticketing.filters.sell') },
        { id: 2, name: t('ticketing.filters.buy') },
    ];

    return (
        <ButtonGroup
            options={options}
            // @ts-ignore
            onChange={(opt) => onProductTypeChange(opt.id)}
        />
    );
}

export default TicketOption;
