import Title from 'components/Title';
import { useTranslation } from 'react-i18next';

import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import useResponsive from 'features/responsive/responsive';
import useTrafficInfos from './useTrafficInfo';
import TrafficCameraCard from './TrafficCameraCard';
import FilterButton from '../../components/FilterButton';
import ButtonGroup from '../../components/ButtonGroup';

function CameraFilter({
    highway,
    selectedHighwayFilter,
    setSelectedHighwayFilter,
}: {
    highway: string;
    selectedHighwayFilter: string | null;
    setSelectedHighwayFilter: Dispatch<SetStateAction<string | null>>;
}) {
    return (
        <button
            onClick={() => setSelectedHighwayFilter(highway)}
            className={`px-3 py-2 border-2 rounded-[10px] border-solid typo-b1 regular ${
                highway === selectedHighwayFilter ? 'bg-primary text-black border-transparent' : 'border-white'
            }`}
        >
            {highway}
        </button>
    );
}

export default function TrafficCameras() {
    const { t } = useTranslation();
    const { cameraHighways, trafficInfos } = useTrafficInfos();
    const [selectedHighwayFilter, setSelectedHighwayFilter] = useState<string | null>(cameraHighways[0] || null);
    const [chosenTabByHash, setChosenTabByHash] = useState<any>(undefined);

    const filteredCameras = useMemo(() => {
        if (!trafficInfos?.cameras) return [];
        if (!selectedHighwayFilter) return trafficInfos.cameras;

        return trafficInfos.cameras.filter((c) => c.highway === selectedHighwayFilter);
    }, [trafficInfos?.cameras, selectedHighwayFilter]);

    useEffect(() => {
        if (!selectedHighwayFilter && cameraHighways.length) {
            setSelectedHighwayFilter(cameraHighways[0]);
        }
    }, [cameraHighways, selectedHighwayFilter]);

    useEffect(() => {
        if (cameraHighways) {
            cameraHighways?.forEach((tab, index) => {
                if (decodeURIComponent(window.location.hash.substring(1)) === tab) {
                    setSelectedHighwayFilter(tab);
                    setChosenTabByHash({ name: tab, id: tab });
                }
            });
        }
    }, [cameraHighways]);

    return (
        <div>
            <section>
                <Title title={t('live_cameras')} />
                <ButtonGroup
                    className="mt-block-innerMobile md:mt-block-inner"
                    fullWidthOnMobile
                    options={cameraHighways.map((id) => ({ id, name: id }))}
                    // @ts-ignore
                    onChange={(opt) => setSelectedHighwayFilter(opt.id)}
                    value={chosenTabByHash}
                />

                <div className="flex flex-col gap-4 mt-block-innerMobile md:mt-block-inner md:gap-8 md:grid md:grid-cols-3">
                    {filteredCameras.map((camera) => (
                        <TrafficCameraCard
                            key={camera.id}
                            camera={camera}
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}
