import { useContext, useEffect } from 'react';
import { AuthContext, AuthContextProps, REFETCH_USER_STATE } from './AuthContext';

export default function useAuth(props?: { onNeedOtp?: () => void }) {
    const { user, login, logout, setUser, refetchUser, refetchUserState } = useContext(AuthContext) as AuthContextProps;

    useEffect(() => {
        if (refetchUserState === REFETCH_USER_STATE.need_otp) {
            if (props?.onNeedOtp) {
                props.onNeedOtp();
            }
        }
    }, [refetchUserState, props]);

    return {
        isAuthenticated: !!user,
        login,
        logout,
        user,
        setUser,
        refetchUser,
    };
}
