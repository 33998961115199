import { format } from 'date-fns';

export const formatMode = {
    dd_MM_yyyy_HH_mm: 'dd/MM/yyyy - HH:mm', // 12/10/2022 - 12:00
    d_MM_yyyy: 'd.MM.yyyy', // 20.05.2022
    h_bbb: 'h bbb', // 2pm
    ccc: 'ccc', // Mon., Tue.
    HH: 'HH', // 13:05
};

export function formatDate(date: Date, dateFormat = formatMode.d_MM_yyyy) {
    // const lang = {fr, enGB};
    if (date) {
        return format(new Date(date), dateFormat);
        // todo: solve the TS error here
        /*         return format(new Date(date), formatMode[dateFormat], {
            locale: lang[locale]
        }); */
    }
    return format(new Date(), dateFormat);
}

export function dateIsValid(date: string) {
    return !Number.isNaN(new Date(date).getTime());
}
