import moment from 'moment';
import Button from 'components/Button';
import PageLayout from 'components/PageLayout';
import useAuth from 'features/auth/useAuth';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useCamera from 'hooks/useCamera';
import { createRef, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { IonContent, IonModal, useIonRouter, useIonToast } from '@ionic/react';
import { BASE_URL } from 'lib/ApiService';
import useMyAccount from 'features/auth/useMyAccount';
import { EldoLogo } from '../components/Icons';
import { ReactComponent as EditPencil } from '../components/Icons/edit.svg';

function ProfilePicture({ src, onClick }: { src: string | null; onClick: React.MouseEventHandler }) {
    const { t } = useTranslation();

    return (
        <div
            onClick={onClick}
            className="relative w-fit cursor-pointer"
        >
            {!src ? (
                // todo: check if better default image
                <EldoLogo className="h-[60px] w-[60px] object-cover rounded-full" />
            ) : (
                <img
                    src={src}
                    alt={t('profil-picture')}
                    className="h-[60px] w-[60px] object-cover rounded-full"
                />
            )}

            <div className="absolute z-10 bg-[var(--eldo-background-alt-color)] rounded-full w-[36px] h-[36px] grid place-items-center top-[7px] right-0 transform translate-x-1/2">
                <EditPencil />
            </div>
        </div>
    );
}

function Divider() {
    return <div className="w-full h-[1px] bg-[#555555]" />;
}

function ProfilePictureModal({
    isOpen,
    setIsOpen,
    onPictureChange,
}: {
    isOpen: boolean;
    setIsOpen: (b: boolean) => void;
    onPictureChange: (b64: string | null, f?: File) => void;
}) {
    const { t } = useTranslation();
    const { takePhoto, getFileB64, getFile } = useCamera();

    const inputFileRef = createRef<HTMLInputElement>();

    function deleteCurrentPhoto() {
        onPictureChange(null);
        setIsOpen(false);
    }

    async function takeNewPhoto() {
        const photo = await takePhoto();
        if (photo !== null) {
            const b64Img = await getFileB64(photo);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            const file = await getFile(b64Img as string);
            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
            onPictureChange(b64Img as string, file);
            setIsOpen(false);
            return b64Img;
        }
        return null;
    }

    const btnClassName = 'text-[#0A84FF] text-l';

    const onFileSelected = (e: any) => {
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = async () => {
                if (typeof reader.result === 'string') {
                    onPictureChange(reader.result, await getFile(reader.result));
                    setIsOpen(false);
                }
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <IonModal
            showBackdrop
            isOpen={isOpen}
            onWillDismiss={() => setIsOpen(false)}
        >
            <input
                type="file"
                onChange={onFileSelected}
                className="hidden"
                ref={inputFileRef}
            />
            <IonContent>
                <div className="text-center text-[#EBEBF599] pb-[18px]">{t('change_profile_picture')}</div>
                <Divider />
                <div className="text-center p-[18px] ">
                    <button
                        className={btnClassName}
                        onClick={() => {
                            if (inputFileRef.current) {
                                inputFileRef.current.click();
                            }
                        }}
                    >
                        {t('choose_from_library')}
                    </button>
                </div>
                <Divider />
                <div className="text-center p-[18px]">
                    <button
                        className={btnClassName}
                        onClick={takeNewPhoto}
                    >
                        {t('take_picture')}
                    </button>
                </div>
                <Divider />
                <div className="text-center p-[18px]">
                    <button
                        className={btnClassName}
                        onClick={deleteCurrentPhoto}
                    >
                        {t('delete_current_photo')}
                    </button>
                </div>
                <button
                    onClick={() => setIsOpen(false)}
                    className="p-[18px] text-center bg-[#1C1C1E] text-[#0A84FF] text-l w-full rounded"
                >
                    {t('cancel')}
                </button>
            </IonContent>
        </IonModal>
    );
}

export default function MyAccountPage() {
    const { t } = useTranslation();

    const { logout } = useAuth();

    const [photoModalOpen, setPhotoModalOpen] = useState(false);
    const [imgSrc, setImgSrc] = useState<string | null>(null);
    const router = useIonRouter();

    const { user, updatePicture } = useMyAccount();

    useEffect(() => {
        if (user?.picture?.path?.length) {
            setImgSrc(user.picture.path);
        }
    }, [user]);

    const onPictureChange = (b64: string | null, file?: File) => {
        setImgSrc(b64);
        if (file) {
            updatePicture.mutate(file);
        }
    };

    useEffect(() => {
        if (user?.image) {
            setImgSrc(user.image);
        }
    }, []);

    const userData = useMemo<{ label: string; value: string }[]>(() => {
        if (!user) {
            return [];
        }
        return [
            { label: t('firstname'), value: user.firstname },
            { label: t('lastname'), value: user.lastname },
            { label: t('birthday'), value: user.birthday ? moment(user.birthday).format('DD.MM.YY') : '' },
            { label: t('phone'), value: user.phone },
            { label: t('address'), value: user.address },
        ];
    }, [user]);

    const handleLogout = () => {
        logout();
        router.push('/');
    };

    return (
        <PageLayout hasBackButton>
            <ProfilePictureModal
                onPictureChange={onPictureChange}
                isOpen={photoModalOpen}
                setIsOpen={setPhotoModalOpen}
            />
            <div className="grid gap-[30px] lg:w-[350px] mx-auto lg:mt-[53px] lg:mb-[53px]">
                <h1 className="my-4 text-3xl font-bold text-center">{t('my-account')}</h1>
                {/* <ProfilePicture src={imgSrc} onClick={() => setPhotoModalOpen(true)} /> */}
                <section className="grid gap-[30px]">
                    {userData.map((data) => (
                        <div key={data.label}>
                            <div className="font-bold">{data.label}</div>
                            <div>{data.value}</div>
                        </div>
                    ))}
                    <Link to="/account-info">
                        <Button
                            type="submit"
                            value={t('edit-my-informations')}
                        />
                    </Link>
                </section>
                <Divider />
                <section className="grid gap-[30px]">
                    <div>
                        <div className="font-bold">{t('email_address')}</div>
                        <div>{user?.email}</div>
                    </div>

                    <Link to="/account-email">
                        <Button
                            type="submit"
                            value={t('edit-my-email')}
                        />
                    </Link>
                </section>
                <Divider />
                <section className="grid gap-[30px]">
                    <div>
                        <div className="font-bold">{t('password')}</div>
                        <div>******************</div>
                    </div>
                    <Link to="/account-password">
                        <Button
                            type="submit"
                            value={t('edit-my-password')}
                        />
                    </Link>
                    <Button
                        type="submit"
                        className="text-[var(--eldo-background-color)] bg-white mb-8"
                        value={t('logout')}
                        onClick={handleLogout}
                    />
                </section>
            </div>
        </PageLayout>
    );
}
