import TrafficSwiper from 'features/trafficInfo/TrafficSwiper';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Article } from 'types';
import useResponsive from 'features/responsive/responsive';
import usePodcastReplay from 'features/podcastsReplays/hooks/usePodcastsReplays';
import { useEffect } from 'react';
import { addRecentlyViewed } from 'lib/ApiService';
import AdComponent from 'components/Ads/AdComponent';
import { generateUniqueId, isNativeAd, prepareUrlTitle } from 'lib/utils';
import usePermutiveArticle from 'hooks/usePermutive';
import useAds from 'hooks/useAds';
import Section from 'components/core/Section';
import Carousel from 'components/Carousel';
import useNews from 'features/news/hooks/useNews';
import { AdData } from 'features/advertising/generateAdPayload';
import CardVertical from 'components/core/CardVertical';
import { CalendarIcon } from '../components/Icons';
import ArticlePageComponent, {
    getThumbnail,
    getThumbnailForFilmSortien,
    getThumbnailForHorizontalArticleList,
} from '../components/core/ArticlePageComponent';
import Divider from '../components/core/Divider';
import useTrafficInfo from '../features/trafficInfo/useTrafficInfo';
import CarouselWithAds from '../components/Carousel/CarouselWithAds';
import { doesTagContain } from './NewsArticle';

export default function PodcastReplayArticle({ isCompactView, articleId, type }: PodcastReplayArticleProps) {
    const { IsMobile, IsDesktop } = useResponsive();
    const { t } = useTranslation();
    const { ads } = useAds({ page: `${type}-detail`, articleId });
    const { trafficInfos } = useTrafficInfo(articleId);

    const { podcast: data, isLoading } = usePodcastReplay(articleId, type);
    const podcastArticles = useNews({ tag: data?.subdomain, waitForTag: true, adPage: 'podcast-articles' });

    const cleanerPodcastUrl = data?.src?.slice(0, data.src.indexOf('.io') + 3) ?? '';

    useEffect(() => {
        if (!isLoading) {
            try {
                addRecentlyViewed({ type, media_id: articleId });
            } catch (error) {
                console.error(error || 'An error occurred');
            }
        }

        const script = document.createElement('script');
        script.src = 'https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [data, podcastArticles]);

    const wordsCounter = data?.description?.trim().replace(/\s+/g, ' ').split(' ') ?? [];

    usePermutiveArticle({
        audioContent: type === 'podcast',
        videoContent: type === 'replay',
        photoGallery: false,
        authors: [],
        modifiedAt: new Date('1.1.1970'),
        publishedAt: new Date('1.1.1970'),
        tags: data?.tags.map((item) => item.name) ?? [],
        wordCount: wordsCounter.length,
        send: !isLoading,
    });

    console.log(data?.poster);

    return (
        <ArticlePageComponent
            doubleBack
            isLoading={isLoading}
            image={type !== 'replay' ? { src: data?.poster || '', alt: data?.title || '' } : undefined}
            video={
                type === 'replay'
                    ? {
                          img: data?.poster,
                          src: data?.src ?? '',
                          adSrc:
                              ads && ads?.data.length
                                  ? ads?.data?.filter((item) => item.container === 'media_ads')[0]?.url
                                  : '',
                      }
                    : undefined
            }
            title={data?.title || ''}
            tags={(data?.tags || []).map((tag) => ({ ...tag, publicTag: tag.tag }))}
            tagsWithLinkProps={(data?.tags || []).map(({ name, tag }) => ({
                name,
                linkProps: { to: `/podcast-replay/category/${tag}` },
            }))}
            inpageAdUrl={
                ads && ads?.data.length ? ads?.data?.filter((item) => item.container === 'inpage')[0]?.url : ''
            }
            description={[
                data?.description ?? '',
                !isLoading && type === 'podcast' && (
                    <div>
                        <script
                            className="podigee-podcast-player"
                            async
                            data-configuration={`${cleanerPodcastUrl}/embed?context=external`}
                        />
                    </div>
                ),
            ]}
            shareBtnProps={{ url: data?.src }}
            slotStart={ads && <AdComponent ad={ads.data[0]} />}
            slotDescriptionRight={ads && IsDesktop && <AdComponent ad={ads.data[1]} />}
            slotEndFirstSection={
                <div className={`flex items-center  ${IsDesktop ? 'mr-6' : ''}`}>
                    <div className="">
                        <CalendarIcon />
                    </div>
                    <div className="typo-b1 regular ml-2">{moment(data?.publishedDate).format('DD.MM.YY')}</div>
                </div>
            }
            slotPodcastArticle={
                type === 'podcast' ? (
                    <Section
                        title={t('episodes')}
                        path={`/aktuell/news/${data?.subdomain as unknown as string}`}
                    >
                        <CarouselWithAds<Article>
                            // @ts-ignore
                            data={podcastArticles.list}
                        >
                            {(podcastArticle) => (
                                <Link
                                    to={`/aktuell/news/a/${podcastArticle.id as unknown as string}/${prepareUrlTitle(podcastArticle?.title)}`}
                                    className="block h-full w-full"
                                >
                                    <CardVertical
                                        title={podcastArticle.title}
                                        thumbnailUrl={
                                            getThumbnail(podcastArticle, [
                                                'podcastHP',
                                                'articleANDListing',
                                                'listing_news',
                                            ]) || 'cataas.com/cat'
                                        }
                                        className="h-full"
                                        imgSize="medium"
                                        imageCover={podcastArticle.header?.listing_news ? 'object-fit' : 'object-cover'}
                                    />
                                </Link>
                            )}
                        </CarouselWithAds>
                    </Section>
                ) : null
            }
            slotEnd={
                <>
                    <Divider />
                    <div> {ads && <AdComponent ad={ads.data[3]} />}</div>
                    {trafficInfos?.recommendedNews?.length ? (
                        <TrafficSwiper
                            key={generateUniqueId()}
                            swiperEnabled
                            title={t('recommended_for_you')}
                            items={trafficInfos?.recommendedNews}
                            renderItem={(item: Article, index) => (
                                <Link
                                    to={`/aktuell/news/a/${item.id}/${prepareUrlTitle(item?.title)}`}
                                    className="w-[100%]"
                                    key={index}
                                >
                                    <HorizontalMediumCard
                                        key={index}
                                        title={item.title}
                                        thumbnailUrl={
                                            doesTagContain(item.tags, 'master_filmsortien')
                                                ? getThumbnailForFilmSortien(item)
                                                : getThumbnailForHorizontalArticleList(item)
                                        }
                                        date={item.date}
                                        tags={item.tags}
                                        className=""
                                        truncated
                                        imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                                    />
                                </Link>
                            )}
                        />
                    ) : (
                        ''
                    )}
                    {ads && (
                        <AdComponent
                            ad={ads.data[4]}
                            preloadMovieJobAd
                        />
                    )}
                </>
            }
        />
    );
}

type PodcastReplayArticleProps = {
    isCompactView?: boolean;
    articleId: string;
    type: string;
};

PodcastReplayArticle.defaultProps = {
    isCompactView: false,
};
