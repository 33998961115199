export function ensureUrlHasProtocol(url: string) {
    let newUrl = url;
    if (!/^https?:\/\//i.test(url)) {
        // If the URL does not already start with http:// or https://,
        // prepend it with http://.
        newUrl = `http://${url}`;
    }
    return newUrl;
}

export function ensureSecureUrl(url: string): string {
    if (url.startsWith('http://')) {
        return url.replace('http://', 'https://');
    }
    return url;
}

export default ensureUrlHasProtocol;
