import { TopicCategory } from 'types';
import CardListing from 'components/core/CardListing';
import { generateUniqueId } from 'lib/utils';
import TopicCard from './TopicCard';

type CardProps = {
    data: TopicCategory[] | undefined;
};

export const getRouterLinkForTopics = (item: TopicCategory) => {
    return item.prefixSlug
        ? `${item.prefixSlug as unknown as string}${item.slug as unknown as string}`
        : `/${item.slug as unknown as string}`;
};

function TopicCardList({ data }: CardProps) {
    if (!data) return null;

    return (
        <CardListing>
            {data?.map((item: TopicCategory) => (
                <TopicCard
                    routerLink={getRouterLinkForTopics(item)}
                    item={item}
                    key={generateUniqueId()}
                />
            ))}
        </CardListing>
    );
}

export default TopicCardList;
