import { PropsWithChildren, useEffect, useMemo, useRef, useState } from 'react';
import { LinkProps, Link } from 'react-router-dom';
import { IonPage, IonHeader, IonToolbar, IonButtons, IonBackButton, IonTitle, IonSearchbar } from '@ionic/react';
import { chevronBackOutline } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';
import useDebounce from 'hooks/useDebounce';

export type SearchPageComponentProps = PropsWithChildren<{
    linkSearchEnd: (s: string) => LinkProps;
    linkBack: (s: string) => LinkProps;
    displayResetSearch: boolean | ((s: string) => boolean);
    onResetSearch?: () => void;
    onSearchEnd?: (s: string) => void;
    onSearchChange?: (s: string) => void;
    title: string;
    className?: string;
}>;

export default function SearchPageComponent({
    children,
    linkSearchEnd,
    linkBack,
    displayResetSearch,
    onResetSearch,
    onSearchEnd,
    onSearchChange,
    title,
    className,
}: SearchPageComponentProps) {
    const [search, setSearch] = useState('');
    const { t } = useTranslation();
    const searchLinkRef = useRef<HTMLDivElement>(null);

    function resetSearch() {
        setSearch('');
        if (onResetSearch) onResetSearch();
    }

    function handleSearchEnd() {
        if (onSearchEnd) onSearchEnd(search);
        searchLinkRef.current?.click?.();
    }

    useDebounce(
        () => {
            onSearchChange?.(search || '');
        },
        [search],
        1000,
    );

    const canReset = useMemo<boolean>(() => {
        if (displayResetSearch == null) return false;
        if (typeof displayResetSearch === 'boolean') return displayResetSearch;
        return displayResetSearch?.(search);
    }, [search, displayResetSearch]);

    return (
        <IonPage className={className}>
            <Link
                className="hidden"
                {...linkSearchEnd(search)}
            >
                <div ref={searchLinkRef} />
            </Link>

            <IonHeader className="h-[184px] bg-[rgb(97,88,219)]">
                <IonToolbar className="search-page-toolbar top-[57px] bg-[#6158DB]">
                    <IonButtons slot="start">
                        <Link {...linkBack(search)}>
                            <IonBackButton
                                color="light"
                                text=""
                                icon={chevronBackOutline}
                                disabled
                            />
                        </Link>
                    </IonButtons>
                    <IonTitle className="bg-[#6158DB] text-white font-bold text-h1">{title}</IonTitle>
                    {canReset ? (
                        <div
                            slot="end"
                            className="pr-[20px]"
                        >
                            <button
                                onClick={(e) => {
                                    e.stopPropagation();
                                    resetSearch();
                                }}
                            >
                                {t('reset')}
                            </button>
                        </div>
                    ) : (
                        ''
                    )}
                </IonToolbar>

                <div className="absolute bottom-0 transform translate-y-1/2 z-30 w-full">
                    <IonSearchbar
                        value={search}
                        onIonInput={(e) => setSearch(e.target.value!)}
                        onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                                handleSearchEnd();
                            }
                        }}
                        className="w-full typo-b1 regular"
                        mode="md"
                    />
                </div>
            </IonHeader>
            {children}
        </IonPage>
    );
}
