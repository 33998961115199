import { useIonRouter } from '@ionic/react';

export default function BubbleNavigationItem({ title, to, src }: { title: string; to: string; src: string }) {
    const router = useIonRouter();
    const active = router.routeInfo.pathname === to;
    return (
        <div
            className="flex flex-col items-center cursor-pointer"
            onClick={() => router.push(to, 'none')}
        >
            <div
                className={`rounded-full w-[70px] h-[70px] border-3 overflow-hidden ${
                    active ? 'border-primary' : 'border-transparent'
                }`}
            >
                <img
                    className="object-cover w-full rounded-full"
                    src={src}
                    alt={title}
                />
            </div>
            <div className={`typo-b2 w-[80px] text-center mt-3 ${active ? 'font-bold ' : 'regular'}`}>{title}</div>
        </div>
    );
}
