/* eslint-disable jsx-a11y/anchor-is-valid */

import Title from 'components/Title';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Article, Game, RoadNews } from 'types';
import EldoAdComponent, { ADType } from 'components/AdComponent';
import useResponsive from 'features/responsive/responsive';
import Section from 'components/core/Section';
import Carousel from 'components/Carousel';
import { AdData } from 'features/advertising/generateAdPayload';
import CarouselWithAds from 'components/Carousel/CarouselWithAds';

interface TrafficSwiperProps {
    title?: string;
    titleHtml?: string;
    swiperEnabled?: boolean;
    path?: string;
    items: Game[] | RoadNews[] | (Article | AdData)[];
    renderItem: (item: any, index?: number) => JSX.Element;
    ad?: ADType;
    className?: string;
}

const bkPoints = {
    576: {
        slidesPerView: 1.2,
    },
    768: {
        slidesPerView: 2,
    },
    1200: {
        slidesPerView: 3,
    },
};

export default function TrafficSwipper({
    renderItem,
    items,
    title,
    titleHtml,
    path,
    swiperEnabled = false,
    ad,
    className,
}: TrafficSwiperProps) {
    const { IsMobile } = useResponsive();

    const renderSwiper = () => (
        <div>
            <CarouselWithAds<Game | RoadNews | Article | AdData>
                adNativeClassName="h-[200px] overflow-hidden"
                data={items}
                breakpoints={bkPoints}
                swiperHeightAuto={false}
            >
                {renderItem}
            </CarouselWithAds>
        </div>
    );

    return (
        <Section
            className={className}
            titleHtml={titleHtml}
            path={path}
            titleDirection="none"
        >
            {swiperEnabled ? (
                renderSwiper()
            ) : (
                <div className="flex flex-col md:flex-row gap-4">
                    {items.map((item, index) => renderItem(item, index))}
                </div>
            )}
        </Section>
    );
}
