type BadgeProps = {
    children: React.ReactNode;
    text: string | number;
    className?: string;
};

export default function Badge({ children, text, className }: BadgeProps) {
    return (
        <div className={`relative ${className || ''}`}>
            <div className="absolute left-0 top-0 transform -translate-x-[22.5%] -translate-y-1/2 w-[25px] h-[25px] rounded-full text-white grid place-items-center bg-primary">
                {text}
            </div>
            {children}
        </div>
    );
}
