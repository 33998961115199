// eslint-disable-next-line import/prefer-default-export
export const channels = [
    {
        id: 'eldo',
        title: 'Eldoradio',
        background: 'eldoradio.jpg',
    },
    {
        id: 'eldoalt',
        title: 'Alternative',
        background: 'alternative.png',
    },
    {
        id: 'eldochill',
        title: 'Chill',
        background: 'chill.png',
    },
    {
        id: 'eldotop25',
        title: 'Top 25',
        background: 'top25.png',
    },
    {
        id: 'eldo80s',
        title: "80's",
        background: '80.png',
    },
    {
        id: 'eldo90s',
        title: "90's",
        background: '90.png',
    },
    {
        id: 'eldo30years',
        title: '30 Joer Chartbreaker',
        background: 'chartbreaker.png',
    },
];

export const APP_LINKS = {
    google: 'https://play.google.com/store/apps/details?id=lu.rtl.newmedia.eldo&hl=en_US',
    apple: 'https://apps.apple.com/lu/app/eldo/id305966737?l=fr',
};

export const ipAktiounen = { name: 'IPAktiounen', publicTag: 'IPAktiounen', tag: 'IPAktiounen' };
