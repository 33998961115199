import Title, { TitleProps } from 'components/Title';
import WavePatternContainer, { WavePatternContainerProps } from 'components/WavePatternContainer';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type SectionProps = {
    title?: string;
    titleHtml?: string;
    path?: string;
    children: React.ReactNode;
    waveContainerProps?: Omit<WavePatternContainerProps, 'children'>;
    className?: string;
    titleDirection?: TitleProps['direction'];
    component?: 'div' | 'section';
    onClick?: () => void;
};

export default function Section({
    title,
    titleHtml,
    path,
    children,
    waveContainerProps,
    component = 'section',
    className,
    titleDirection,
    onClick,
}: SectionProps) {
    const { t } = useTranslation();

    const props = waveContainerProps
        ? { className: `mb-blockMobile md:mb-block ${className || ''}`, ...waveContainerProps, component }
        : { className: `mb-blockMobile md:mb-block ${className || ''}` };

    return React.createElement(
        waveContainerProps ? WavePatternContainer : component,
        // @ts-ignore
        props,
        <>
            {title ? (
                <Title
                    className="mb-blockInnerMobile md:mb-blockInner"
                    title={t(title)}
                    path={path}
                    direction={titleDirection}
                    onClick={onClick}
                />
            ) : (
                ''
            )}
            {titleHtml && (
                <div
                    dangerouslySetInnerHTML={{ __html: titleHtml }}
                    className="[&_img]:w-[50px] items-center flex gap-[10px] typo-h2 bold mb-blockInnerMobile md:mb-blockInner"
                />
            )}
            {children}
        </>,
    );
}
