import { generateId } from '@ionic/react';
import { useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { AdData } from 'features/advertising/generateAdPayload';
import { getPicturesReports } from '../../../lib/ApiService';
import { PictureReportResponse, PicturesReport } from '../../../types';

export type UsePictureReportsProps = { limit?: number };

export default function usePictureReports(props?: UsePictureReportsProps) {
    const [queryName] = useState(`picture-report-request-${generateId('picture-reports-request')}`);

    const limit = props?.limit || 8;

    const { data, error, status, isFetching, hasNextPage, fetchNextPage } = useInfiniteQuery<PictureReportResponse>(
        [queryName],
        ({ pageParam }) => {
            const start = pageParam?.start || 0;
            return getPicturesReports(limit, start);
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.next) return undefined;
                return {
                    start: allPages.length * limit,
                };
            },
        },
    );

    return {
        isLoading: isFetching,
        error,
        status,
        canLoadMore: hasNextPage,
        loadMore: fetchNextPage,
        list: (data?.pages || []).reduce(
            (prev, current) => [...prev, ...(current.list || [])],
            [] as Array<PicturesReport | AdData>,
        ),
    };
}
