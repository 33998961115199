import { HTMLAttributes } from 'react';
import FavoriteButton from './FavoriteButton';
import CardVertical, { type CardVerticalProps } from './core/CardVertical';

interface MediumCardProps extends HTMLAttributes<HTMLDivElement> {
    thumbnailUrl: string;
    title: string;
    subtitle: string;
    displayFavorite?: boolean;
    onFavoriteClick?: () => void;
    favorite?: boolean;
    video?: string | null;
    imgSize?: CardVerticalProps['imgSize'];
    hasWavePattern?: boolean;
}

export default function VerticalMediumCard({
    className = '',
    thumbnailUrl,
    title,
    subtitle,
    favorite = false,
    displayFavorite = false,
    video = null,
    imgSize,
    onFavoriteClick = () => {},
    hasWavePattern = true,
    ...rest
}: MediumCardProps) {
    return (
        <CardVertical
            title={title}
            subtitle={subtitle}
            thumbnailUrl={thumbnailUrl}
            video={video}
            className={className}
            imgSize={imgSize}
            hasWavePattern={hasWavePattern}
            titleRightSlot={
                displayFavorite ? (
                    <FavoriteButton
                        favorite={favorite}
                        onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            onFavoriteClick();
                        }}
                    />
                ) : (
                    ''
                )
            }
            {...rest}
        />
    );
}
