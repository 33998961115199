import { createContext, Dispatch, ReactElement, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Preferences } from '@capacitor/preferences';
import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { decodeJWT, isTokenExpired, isValidJwt } from 'lib/JWTService';
import { TOAST_DURATION } from '../../constants';
import { login as apiLogin, LoginPayload, USER_QUERY_KEY, getUser } from '../../lib/ApiService';
import { User } from './types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum REFETCH_USER_STATE {
    base = 1,
    need_otp = 2,
}

export const TOKEN_IDENTIFIER = 'eldoradio;';
export interface AuthContextProps {
    login: (payload: LoginPayload) => Promise<unknown>;
    logout: () => void;
    user: User | null;
    setUser: Dispatch<SetStateAction<User | null>>;
    refetchUser: () => void;
    refetchUserState: REFETCH_USER_STATE;
}

export const AuthContext = createContext<AuthContextProps | null>(null);

export default function AuthProvider({ children }: { children: ReactElement | ReactElement[] }) {
    const [user, setUser] = useState<User | null>(null);
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [authenticationLoading, setAuthenticationLoading] = useState(false);
    const [present] = useIonToast();
    const { t } = useTranslation();

    const [refetchUserState, setRefetchUserState] = useState(REFETCH_USER_STATE.base);

    const authenticate = (): void => {
        setAuthenticationLoading(true);
        setIsAuthenticated(true);
        setAuthenticationLoading(false);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const login = async (payload: Pick<User, 'email' | 'password'>) => {
        const response = await apiLogin(payload);
        await Preferences.set({ key: TOKEN_IDENTIFIER, value: response.token });
        authenticate();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const logout = async () => {
        await Preferences.remove({ key: TOKEN_IDENTIFIER });
        setIsAuthenticated(false);
        setUser(null);
    };

    const { refetch } = useQuery([USER_QUERY_KEY], getUser, {
        enabled: isAuthenticated,
        onSuccess: (data: any) => {
            setUser(data);
        },
        onError: (err: any) => {
            logout();
            present(err.message || t('account-error'), TOAST_DURATION);
            setRefetchUserState(REFETCH_USER_STATE.need_otp);
            setTimeout(() => setRefetchUserState(REFETCH_USER_STATE.base), 0);
        },
    });

    useEffect(() => {
        (async () => {
            const { value: token } = await Preferences.get({ key: TOKEN_IDENTIFIER });

            if (token && token.trim() !== '' && isValidJwt(token) && !isTokenExpired(decodeJWT(token))) {
                authenticate();
            } else if (token && decodeJWT(token)) {
                logout();
                setAuthenticationLoading(false);
            }
        })();
    }, []);

    const value = useMemo(
        () => ({
            user,
            setUser,
            login,
            logout,
            refetchUser: refetch,
            refetchUserState,
        }),
        [user, login, refetch, refetchUserState],
    );

    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
