export type DividerProps = {
    className?: string;
    hasBlockSpacingTop?: boolean;
    hasBlockSpacingBottom?: boolean;
};
export default function Divider({ className, hasBlockSpacingTop = true, hasBlockSpacingBottom = true }: DividerProps) {
    return (
        <hr
            className={`${hasBlockSpacingTop ? 'mt-blockMobile md:mt-block' : ''} ${
                hasBlockSpacingBottom ? 'mb-blockMobile md:mb-block' : ''
            } ${className || ''}`}
            style={{ backgroundColor: '#555555', height: '1px', border: 'none' }}
        />
    );
}
