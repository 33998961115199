import { useQuery } from '@tanstack/react-query';
import { getPodcastReplay } from '../../../lib/ApiService';
import { PodcastOrReplay } from '../../../types';

export default function usePodcastReplay(id: string, type: string) {
    const { data, status, error } = useQuery<PodcastOrReplay>({
        queryKey: ['podcast', id],
        queryFn: () => getPodcastReplay(id, type),
    });

    return { podcast: data, isLoading: status === 'loading', error };
}
