import { ErrorMessage } from '@hookform/error-message';
import { IonLoading, useIonToast } from '@ionic/react';
import { useState } from 'react';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { emailRegex } from 'lib/utils';
import Button from '../../../components/Button';
import InputError from '../../../components/InputError';
import Input from '../../../components/Input';
import { resetPassword } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function ResetStep1({ onComplete }: { onComplete: (email: string) => void }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [present] = useIonToast();

    const schema = yup
        .object({
            email: yup
                .string()
                .required(t('email_required'))
                .matches(emailRegex, { message: t('email_invalid') }),
        })
        .required();

    const {
        handleSubmit,
        register,
        watch,
        formState: { errors },
    } = useForm<{ email: string }>({
        resolver: yupResolver(schema),
    });

    const onSubmit = async (data: { email: string }) => {
        setIsLoading(true);
        await resetPassword(data.email)
            .then(() => {
                onComplete(data.email);
            })
            .catch((e) => {
                present({
                    color: 'danger',
                    duration: TOAST_DURATION,
                    message: e?.message ?? t('error_reset_password'),
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <>
            <h1 className="mt-4 typo-h2 font-bold mb-[10px]">{t('reset_password.step1_title')}</h1>
            <p className="mb-4 typo-b1 regular">{t('reset_password.step1_subtitle')}</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                {isLoading && <IonLoading isOpen />}

                <Input
                    hasError={!!errors.email}
                    placeholder={t('email')}
                    {...register('email')}
                />
                <ErrorMessage
                    errors={errors}
                    name="email"
                    as={<InputError />}
                />

                <Button
                    type="submit"
                    className="my-[45px] md:my-[15px]"
                    value={t('next')}
                    disabled={!watch('email')}
                />
            </form>
        </>
    );
}
