import { IonCol, IonContent, IonGrid, IonRoute, IonRow, useIonRouter } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import TrafficCameras from 'features/trafficInfo/TrafficCameras';
import TrafficDailyRoute from 'features/trafficInfo/TrafficDailyRoute';
import TrafficNews from 'features/trafficInfo/TrafficNews';
import TrafficRadars from 'features/trafficInfo/TrafficRadars';
import { match as Match } from 'react-router';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import useResponsive from 'features/responsive/responsive';
import PageLayout from '../components/PageLayout';
import TrafficMap from '../features/trafficInfo/TrafficMap';
import TrafficInfoNavigation from '../features/trafficInfo/TrafficInfoNavigation';
import Ornament from '../components/Ornament';
import AdRightBlock from '../components/Ads/AdRightBlock';

export default function TrafficInfo({ match }: { match: Match }) {
    const { ads } = useAds({ page: 'trafic-info' });
    const { IsDesktop } = useResponsive();

    const { t } = useTranslation();
    const { routeInfo } = useIonRouter();

    return (
        <PageLayout
            cssClass={`eldo-page ${routeInfo.pathname.indexOf('radars') === -1 ? 'tall-page' : ''}`}
            title={t('traffic_info')}
        >
            {ads && <AdComponent ad={ads.data[0]} />}
            {IsDesktop && <Ornament />}
            <IonGrid fixed>
                <IonRow>
                    <IonCol>
                        <TrafficInfoNavigation match={match} />
                    </IonCol>
                </IonRow>
                <IonRow>
                    <IonCol>
                        <div>
                            <AdRightBlock ad={ads?.data?.[1]}>
                                <div className="relative">
                                    <IonRoute
                                        exact
                                        path={`${match.url}`}
                                        render={() => <TrafficDailyRoute />}
                                    />
                                    <IonRoute
                                        path={`${match.url}/news`}
                                        render={() => <TrafficNews />}
                                    />
                                    <IonRoute
                                        path={`${match.url}/maps`}
                                        render={() => <TrafficMap />}
                                    />
                                    <IonRoute
                                        path={`${match.url}/radars`}
                                        render={() => <TrafficRadars />}
                                    />
                                    <IonRoute
                                        path={`${match.url}/cameras`}
                                        render={() => <TrafficCameras />}
                                    />
                                </div>
                            </AdRightBlock>
                        </div>
                    </IonCol>
                </IonRow>
                {ads && (
                    <AdComponent
                        ad={ads.data[2]}
                        className="mt-[50px]"
                    />
                )}
            </IonGrid>
        </PageLayout>
    );
}
