import { useEffect, useRef } from 'react';

const useClickOutside = (callback: () => void, excludeRefs: React.RefObject<HTMLElement>[]) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleClick = (event: MouseEvent) => {
        const isClickOutside =
            ref.current &&
            !ref.current.contains(event.target as Node) &&
            !excludeRefs.some((excludeRef) => {
                return excludeRef.current ? excludeRef.current.contains(event.target as Node) : false;
            });
        if (isClickOutside) {
            callback();
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, []);

    return ref;
};

export default useClickOutside;
