import { PropsWithChildren, useEffect, useRef, useState } from 'react';

export type TextScrollIfNeededProps = PropsWithChildren<{ className?: string }>;

export default function TextScrollIfNeeded({ children, className = '' }: TextScrollIfNeededProps) {
    const [isAnimated, setIsAnimated] = useState(false);
    const containerRef = useRef<HTMLDivElement>(null);

    function isTextOverflow(): boolean {
        if (!containerRef.current) return false;
        return containerRef.current.scrollWidth > containerRef.current.offsetWidth;
    }

    useEffect(() => {
        setIsAnimated(isTextOverflow());
        setTimeout(() => {
            setIsAnimated(isTextOverflow());
        }, 500);
        const observer = new ResizeObserver(() => setIsAnimated(isTextOverflow()));
        observer.observe(document.body);
        return () => observer.disconnect();
    }, []);

    return (
        <div
            ref={containerRef}
            className={`relative whitespace-nowrap overflow-hidden max-w-[100%] ${className}`}
        >
            <div className={`w-fit ${isAnimated ? 'animate-text-scroll' : ''}`}>{children}</div>
        </div>
    );
}
