import { useContext } from 'react';
import { IonText } from '@ionic/react';
import TextScrollIfNeeded from 'components/core/TextScrollIfNeeded';
import { PlayerContext, PlayerContextType } from '../PlayerContext';
import PlayerButton from './player/PlayerButton';
import { Presenter } from '../types';
import { RadioContext, RadioContextType } from '../RadioContext';

export default function HeaderPlayerController({ mini }: { mini?: boolean }) {
    const { playerState, playerDispatch } = useContext(PlayerContext) as PlayerContextType;
    const { radio } = useContext(RadioContext) as RadioContextType;

    const presenters = radio?.presenters?.map((presenter: Presenter) => presenter.name).join(' & ') || '';

    if (!radio) {
        return <div />;
    }

    return (
        <div
            className={`flex items-center justify-center bg-[#282828] rounded ${
                mini ? 'px-[18px] py-[8px]' : 'p-[18px]'
            }`}
        >
            <div>
                <div className="overflow-hidden">
                    <IonText
                        color="secondary"
                        class="typo-b1 regular"
                    >
                        <TextScrollIfNeeded>{radio?.show?.name}</TextScrollIfNeeded>
                    </IonText>
                </div>
                {!mini ? (
                    <div>
                        <IonText
                            color="light"
                            class="typo-b2 regular"
                        >
                            {presenters}
                        </IonText>
                    </div>
                ) : (
                    ''
                )}
            </div>
            <div className="ml-[10px] grid place-items-center">
                <PlayerButton
                    size="xs"
                    onClick={(e) => {
                        e.stopPropagation();
                        playerDispatch({ type: 'TOGGLE_RADIO' });
                    }}
                    type={playerState.isPlaying && playerState.type === 'RADIO' ? 'pause' : 'playPrimary'}
                />
            </div>
        </div>
    );
}
