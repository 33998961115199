import { useHistory } from 'react-router';
import { useIonRouter } from '@ionic/react';

import PageLayout from '../components/PageLayout';
import LoginForm from '../components/LoginForm';
import Section from '../components/core/Section';

export default function LoginPage() {
    const history = useHistory();
    const router = useIonRouter();

    const onDone = () => {
        if (router.routeInfo?.lastPathname?.length && router.routeInfo.lastPathname !== '/reset-password') {
            history.push(router.routeInfo?.lastPathname);
        } else {
            history.push('/', 'root');
        }
    };

    return (
        <PageLayout hasBackButton>
            <Section>
                <LoginForm
                    onDone={onDone}
                    className="lg:mt-[53px] max-w-[350px]"
                />
            </Section>
        </PageLayout>
    );
}
