import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IonButton, IonContent, IonHeader, IonImg, IonModal, IonRange, IonTitle, IonToolbar } from '@ionic/react';
import Title from '../../../../components/Title';
import MoreActions from '../../../../components/Icons/more_actions.svg';
import { CloseIcon } from '../../../../components/Icons';
import PlayerButton from './PlayerButton';
import PlayerDetailsModal from './PlayerDetailsModal';
import { PlayerContext, PlayerContextType } from '../../PlayerContext';

export default function PlayerModal() {
    const [detailsOpened, setDetailsOpened] = useState<boolean>(false);
    const { t } = useTranslation();
    const {
        playerState: { expanded, media, progress, isPlaying },
        playerDispatch,
    } = useContext<PlayerContextType | null>(PlayerContext) as PlayerContextType;

    const roundBtnClasses =
        '!rounded-full bg-[var(--eldo-background-alt-color)] w-[45px] h-[45px] grid place-items-center';

    return (
        <IonModal
            isOpen={expanded}
            id="player-expanded"
            onWillDismiss={() => {
                playerDispatch({ type: 'MINIMIZE' });
            }}
            style={{ '--backdrop-opacity': 0.8, '--ion-backdrop-color': '#141414' }}
        >
            <IonHeader>
                <IonToolbar className="p-[10px]">
                    <Title title={t('live')} />
                    <button
                        className={`${roundBtnClasses} mr-[20px]`}
                        slot="end"
                        onClick={() => setDetailsOpened(true)}
                    >
                        <img
                            src={MoreActions}
                            alt="more-actions"
                        />
                    </button>
                    <button
                        slot="end"
                        className={roundBtnClasses}
                        onClick={() => {
                            playerDispatch({ type: 'MINIMIZE' });
                        }}
                    >
                        <CloseIcon />
                    </button>
                </IonToolbar>
            </IonHeader>
            <IonImg
                className="rounded-md overflow-hidden m-auto"
                src={media?.thumbnailUrl}
            />

            <div className="grid place-items-center">
                <div className="my-8 whitespace-nowrap flex-1 w-min">
                    <h4 className="text-l font-bold">{media?.title}</h4>
                    <p className="font-medium mt-1">{media?.subtitle}</p>
                    <div className="w-full">
                        <IonRange
                            min={0}
                            max={100}
                            pin
                            value={progress}
                        />
                    </div>
                </div>
            </div>

            <div className="flex justify-center flex-1">
                <PlayerButton
                    main
                    onClick={() => {
                        playerDispatch({ type: 'TOGGLE_PLAY' });
                    }}
                    type={isPlaying ? 'pause' : 'play'}
                    className="mb-4"
                />
            </div>

            <PlayerDetailsModal
                isOpen={detailsOpened}
                onWillDismiss={() => {
                    setDetailsOpened(false);
                }}
            />
        </IonModal>
    );
}
