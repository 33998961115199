import { APP_LINKS } from 'features/radio/constants';

export default function AppStoreBtn({ className }: { className?: string }) {
    return (
        <a
            className={`${className || ''}`}
            href={APP_LINKS.apple}
        >
            <svg
                width="120"
                height="46"
                viewBox="0 0 120 46"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect
                    x="0.5"
                    y="1.25"
                    width="119"
                    height="44"
                    rx="6.5"
                    fill="#0C0D10"
                />
                <path
                    d="M81.5257 21.5344V23.8254H80.0896V25.3278H81.5257V30.4328C81.5257 32.176 82.3143 32.8732 84.2981 32.8732C84.6468 32.8732 84.9788 32.8317 85.2693 32.7819V31.2961C85.0203 31.321 84.8626 31.3376 84.5887 31.3376C83.7005 31.3376 83.3104 30.9225 83.3104 29.9763V25.3278H85.2693V23.8254H83.3104V21.5344H81.5257Z"
                    fill="white"
                />
                <path
                    d="M90.3232 32.9977C92.9628 32.9977 94.5815 31.2297 94.5815 28.2995C94.5815 25.3859 92.9545 23.6096 90.3232 23.6096C87.6835 23.6096 86.0566 25.3859 86.0566 28.2995C86.0566 31.2297 87.6752 32.9977 90.3232 32.9977ZM90.3232 31.4123C88.7709 31.4123 87.8994 30.2751 87.8994 28.2995C87.8994 26.3405 88.7709 25.195 90.3232 25.195C91.8671 25.195 92.747 26.3405 92.747 28.2995C92.747 30.2668 91.8671 31.4123 90.3232 31.4123Z"
                    fill="white"
                />
                <path
                    d="M95.9664 32.8234H97.7511V27.486C97.7511 26.216 98.7056 25.361 100.059 25.361C100.374 25.361 100.905 25.4191 101.055 25.4689V23.7092C100.864 23.6594 100.524 23.6345 100.258 23.6345C99.0792 23.6345 98.0748 24.2819 97.8175 25.1701H97.6846V23.7839H95.9664V32.8234Z"
                    fill="white"
                />
                <path
                    d="M105.486 25.1286C106.806 25.1286 107.669 26.05 107.711 27.4694H103.145C103.245 26.0583 104.166 25.1286 105.486 25.1286ZM107.702 30.383C107.37 31.0886 106.632 31.4787 105.552 31.4787C104.125 31.4787 103.203 30.4743 103.145 28.8888V28.7892H109.529V28.1667C109.529 25.3278 108.009 23.6096 105.494 23.6096C102.946 23.6096 101.327 25.444 101.327 28.3327C101.327 31.2214 102.913 32.9977 105.503 32.9977C107.57 32.9977 109.014 32.0016 109.421 30.383H107.702Z"
                    fill="white"
                />
                <path
                    d="M69.8221 29.4853C69.9598 31.705 71.8095 33.1245 74.5626 33.1245C77.505 33.1245 79.3462 31.6361 79.3462 29.2616C79.3462 27.3946 78.2966 26.3622 75.7499 25.7685L74.382 25.433C72.7645 25.0544 72.1106 24.5468 72.1106 23.6607C72.1106 22.5422 73.1259 21.8109 74.6487 21.8109C76.0941 21.8109 77.0921 22.525 77.2727 23.6693H79.1483C79.0365 21.5786 77.1953 20.1074 74.6745 20.1074C71.9644 20.1074 70.1576 21.5786 70.1576 23.7897C70.1576 25.6137 71.1815 26.6977 73.427 27.2225L75.0272 27.6097C76.6705 27.9968 77.3932 28.5647 77.3932 29.5111C77.3932 30.6123 76.2575 31.4124 74.7089 31.4124C73.0484 31.4124 71.8955 30.6639 71.7321 29.4853H69.8221Z"
                    fill="white"
                />
                <path
                    d="M51.3348 23.6345C50.1063 23.6345 49.0437 24.2487 48.4959 25.278H48.3631V23.7839H46.6448V35.8283H48.4295V31.4538H48.5706C49.0437 32.4084 50.0647 32.9728 51.3514 32.9728C53.6341 32.9728 55.0867 31.1716 55.0867 28.2995C55.0867 25.4274 53.6341 23.6345 51.3348 23.6345ZM50.8284 31.3708C49.3343 31.3708 48.3963 30.1921 48.3963 28.3078C48.3963 26.4152 49.3343 25.2365 50.8367 25.2365C52.3475 25.2365 53.2522 26.3903 53.2522 28.2995C53.2522 30.217 52.3475 31.3708 50.8284 31.3708Z"
                    fill="white"
                />
                <path
                    d="M61.3316 23.6345C60.103 23.6345 59.0405 24.2487 58.4927 25.278H58.3599V23.7839H56.6416V35.8283H58.4263V31.4538H58.5674C59.0405 32.4084 60.0615 32.9728 61.3482 32.9728C63.6309 32.9728 65.0835 31.1716 65.0835 28.2995C65.0835 25.4274 63.6309 23.6345 61.3316 23.6345ZM60.8252 31.3708C59.3311 31.3708 58.3931 30.1921 58.3931 28.3078C58.3931 26.4152 59.3311 25.2365 60.8335 25.2365C62.3443 25.2365 63.249 26.3903 63.249 28.2995C63.249 30.217 62.3443 31.3708 60.8252 31.3708Z"
                    fill="white"
                />
                <path
                    d="M43.4428 32.8234H45.4905L41.008 20.4085H38.9346L34.4521 32.8234H36.431L37.5752 29.5283H42.3072L43.4428 32.8234ZM39.8724 22.6627H40.0186L41.8168 27.9108H38.0656L39.8724 22.6627Z"
                    fill="white"
                />
                <path
                    d="M35.6514 11.0447V17.0337H37.8137C39.5984 17.0337 40.6318 15.9338 40.6318 14.0205C40.6318 12.1362 39.5901 11.0447 37.8137 11.0447H35.6514ZM36.5811 11.8914H37.71C38.9509 11.8914 39.6855 12.6799 39.6855 14.033C39.6855 15.4067 38.9634 16.187 37.71 16.187H36.5811V11.8914Z"
                    fill="white"
                />
                <path
                    d="M43.7969 17.1208C45.1167 17.1208 45.9261 16.2368 45.9261 14.7717C45.9261 13.3149 45.1126 12.4268 43.7969 12.4268C42.4771 12.4268 41.6636 13.3149 41.6636 14.7717C41.6636 16.2368 42.4729 17.1208 43.7969 17.1208ZM43.7969 16.3281C43.0208 16.3281 42.585 15.7595 42.585 14.7717C42.585 13.7922 43.0208 13.2195 43.7969 13.2195C44.5689 13.2195 45.0088 13.7922 45.0088 14.7717C45.0088 15.7554 44.5689 16.3281 43.7969 16.3281Z"
                    fill="white"
                />
                <path
                    d="M52.8182 12.5139H51.9259L51.1207 15.9629H51.0501L50.1205 12.5139H49.2655L48.3358 15.9629H48.2694L47.4601 12.5139H46.5553L47.8004 17.0337H48.7176L49.6473 13.7051H49.7179L50.6517 17.0337H51.5772L52.8182 12.5139Z"
                    fill="white"
                />
                <path
                    d="M53.8458 17.0337H54.7382V14.3899C54.7382 13.6843 55.1574 13.2444 55.8173 13.2444C56.4772 13.2444 56.7926 13.6055 56.7926 14.3318V17.0337H57.685V14.1077C57.685 13.0327 57.1288 12.4268 56.1203 12.4268C55.4396 12.4268 54.9914 12.7297 54.7714 13.2319H54.705V12.5139H53.8458V17.0337Z"
                    fill="white"
                />
                <path
                    d="M59.0903 17.0337H59.9826V10.75H59.0903V17.0337Z"
                    fill="white"
                />
                <path
                    d="M63.3386 17.1208C64.6584 17.1208 65.4678 16.2368 65.4678 14.7717C65.4678 13.3149 64.6543 12.4268 63.3386 12.4268C62.0188 12.4268 61.2053 13.3149 61.2053 14.7717C61.2053 16.2368 62.0146 17.1208 63.3386 17.1208ZM63.3386 16.3281C62.5625 16.3281 62.1267 15.7595 62.1267 14.7717C62.1267 13.7922 62.5625 13.2195 63.3386 13.2195C64.1106 13.2195 64.5505 13.7922 64.5505 14.7717C64.5505 15.7554 64.1106 16.3281 63.3386 16.3281Z"
                    fill="white"
                />
                <path
                    d="M68.1265 16.3572C67.6409 16.3572 67.2881 16.1206 67.2881 15.7139C67.2881 15.3154 67.5704 15.1038 68.1929 15.0623L69.2969 14.9917V15.3694C69.2969 15.9297 68.7989 16.3572 68.1265 16.3572ZM67.8982 17.1084C68.4917 17.1084 68.9856 16.8511 69.2554 16.3987H69.326V17.0337H70.1851V13.9458C70.1851 12.9912 69.5459 12.4268 68.4129 12.4268C67.3877 12.4268 66.6573 12.9248 66.566 13.7009H67.4292C67.5289 13.3813 67.8733 13.1987 68.3714 13.1987C68.9815 13.1987 69.2969 13.4685 69.2969 13.9458V14.3359L68.0726 14.4065C66.9976 14.4729 66.3916 14.9419 66.3916 15.7554C66.3916 16.5813 67.0267 17.1084 67.8982 17.1084Z"
                    fill="white"
                />
                <path
                    d="M73.2132 17.1084C73.8358 17.1084 74.3629 16.8137 74.6327 16.3198H74.7032V17.0337H75.5582V10.75H74.6659V13.2319H74.5995C74.3546 12.7339 73.8316 12.4392 73.2132 12.4392C72.0719 12.4392 71.3373 13.344 71.3373 14.7717C71.3373 16.2036 72.0636 17.1084 73.2132 17.1084ZM73.4664 13.2402C74.2135 13.2402 74.6825 13.8337 74.6825 14.7759C74.6825 15.7222 74.2176 16.3074 73.4664 16.3074C72.711 16.3074 72.2586 15.7305 72.2586 14.7717C72.2586 13.8213 72.7152 13.2402 73.4664 13.2402Z"
                    fill="white"
                />
                <path
                    d="M81.3447 17.1208C82.6645 17.1208 83.4738 16.2368 83.4738 14.7717C83.4738 13.3149 82.6604 12.4268 81.3447 12.4268C80.0249 12.4268 79.2114 13.3149 79.2114 14.7717C79.2114 16.2368 80.0207 17.1208 81.3447 17.1208ZM81.3447 16.3281C80.5686 16.3281 80.1328 15.7595 80.1328 14.7717C80.1328 13.7922 80.5686 13.2195 81.3447 13.2195C82.1166 13.2195 82.5566 13.7922 82.5566 14.7717C82.5566 15.7554 82.1166 16.3281 81.3447 16.3281Z"
                    fill="white"
                />
                <path
                    d="M84.655 17.0337H85.5474V14.3899C85.5474 13.6843 85.9666 13.2444 86.6265 13.2444C87.2864 13.2444 87.6018 13.6055 87.6018 14.3318V17.0337H88.4941V14.1077C88.4941 13.0327 87.938 12.4268 86.9294 12.4268C86.2488 12.4268 85.8005 12.7297 85.5806 13.2319H85.5142V12.5139H84.655V17.0337Z"
                    fill="white"
                />
                <path
                    d="M92.6039 11.3892V12.5347H91.8858V13.2859H92.6039V15.8384C92.6039 16.71 92.9981 17.0586 93.9901 17.0586C94.1644 17.0586 94.3304 17.0378 94.4757 17.0129V16.27C94.3512 16.2825 94.2723 16.2908 94.1353 16.2908C93.6913 16.2908 93.4962 16.0833 93.4962 15.6101V13.2859H94.4757V12.5347H93.4962V11.3892H92.6039Z"
                    fill="white"
                />
                <path
                    d="M95.6735 17.0337H96.5658V14.394C96.5658 13.7092 96.9726 13.2485 97.703 13.2485C98.3339 13.2485 98.6701 13.6138 98.6701 14.3359V17.0337H99.5624V14.116C99.5624 13.041 98.9689 12.4309 98.006 12.4309C97.3253 12.4309 96.848 12.7339 96.6281 13.2402H96.5575V10.75H95.6735V17.0337Z"
                    fill="white"
                />
                <path
                    d="M102.781 13.1863C103.441 13.1863 103.873 13.647 103.894 14.3567H101.611C101.661 13.6511 102.122 13.1863 102.781 13.1863ZM103.89 15.8135C103.724 16.1663 103.354 16.3613 102.815 16.3613C102.101 16.3613 101.64 15.8591 101.611 15.0664V15.0166H104.803V14.7053C104.803 13.2859 104.043 12.4268 102.786 12.4268C101.511 12.4268 100.702 13.344 100.702 14.7883C100.702 16.2327 101.495 17.1208 102.79 17.1208C103.823 17.1208 104.545 16.6228 104.749 15.8135H103.89Z"
                    fill="white"
                />
                <path
                    d="M24.769 22.635C24.7907 20.954 25.6934 19.3635 27.1256 18.483C26.2221 17.1927 24.7088 16.3746 23.1344 16.3253C21.4552 16.149 19.8272 17.3301 18.9715 17.3301C18.0992 17.3301 16.7817 16.3428 15.363 16.372C13.5137 16.4317 11.7898 17.4831 10.8901 19.0999C8.95607 22.4483 10.3987 27.3693 12.2513 30.0759C13.1782 31.4013 14.2615 32.8817 15.6789 32.8292C17.066 32.7717 17.584 31.9448 19.2583 31.9448C20.9171 31.9448 21.4031 32.8292 22.8493 32.7959C24.3377 32.7717 25.2754 31.4646 26.1698 30.1267C26.8358 29.1824 27.3483 28.1386 27.6882 27.0342C25.9391 26.2944 24.771 24.5342 24.769 22.635Z"
                    fill="white"
                />
                <path
                    d="M22.0373 14.5453C22.8489 13.5711 23.2487 12.3189 23.1518 11.0547C21.912 11.1849 20.7668 11.7775 19.9443 12.7143C19.14 13.6296 18.7214 14.8598 18.8006 16.0757C20.0408 16.0885 21.2601 15.512 22.0373 14.5453Z"
                    fill="white"
                />
                <rect
                    x="0.5"
                    y="1.25"
                    width="119"
                    height="44"
                    rx="6.5"
                    stroke="#A6A6A6"
                />
            </svg>
        </a>
    );
}
