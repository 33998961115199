import { Camera, CameraResultType, CameraSource, CameraPhoto } from '@capacitor/camera';
import { Filesystem, Directory } from '@capacitor/filesystem';
import { Capacitor } from '@capacitor/core';
import { isPlatform } from '@ionic/react';

export default function useCamera() {
    async function base64FromPath(path: string): Promise<string> {
        const response = await fetch(path);
        const blob = await response.blob();

        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onerror = reject;
            reader.onload = () => {
                if (typeof reader.result === 'string') {
                    resolve(reader.result);
                } else {
                    reject(new Error('method did not return a string'));
                }
            };

            reader.readAsDataURL(blob);
        });
    }

    async function getFile(path: string): Promise<File> {
        return new File([await (await fetch(path)).blob()], 'file', { type: 'image/png' });
    }

    const takePhoto = async () => {
        try {
            const photo: CameraPhoto = await Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                quality: 100,
            });

            return photo;
        } catch (e) {
            console.error(e);
            return null;
        }
    };

    async function getFileB64(photo: CameraPhoto) {
        if (isPlatform('hybrid')) {
            const file = await Filesystem.readFile({
                path: photo.path!,
            });
            return file.data;
        }
        return base64FromPath(photo.webPath!);
    }

    return {
        takePhoto,
        getFileB64,
        getFile,
    };
}
