import { type DependencyList, useCallback, useEffect, useRef } from 'react';

/**
 *
 * @param callback
 * @param dep
 *
 * "callback" is called when "dep" change
 */
export default function useDebounce(callback: () => any, dep: DependencyList, milliseconds: number) {
    const func = useCallback(callback, dep);

    const timerRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        timerRef.current = setTimeout(() => {
            func();
        }, milliseconds);

        return () => {
            clearTimeout(timerRef?.current);
        };
    }, dep);
}
