import { IonCol, IonGrid, IonRow } from '@ionic/react';
import PageLayout from 'components/PageLayout';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';

/**
    GET ALL DATA FROM eldo old site (https://www.eldo.lu/frequenzen):
 
    [...document.querySelector('tbody').children].map(e => ({
	    village: e.children[0].innerHTML,
        frequency: e.children[1].innerHTML,
        operator: e.children[2].innerHTML,
    }))


 */

export default function FrequenciesPage() {
    const { t } = useTranslation();

    const frequencies: { frequency: string; village: string; operator: string }[] = [
        {
            village: 'Abweiler',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Altlinster',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Aspelt',
            frequency: '107.90',
            operator: 'Coditel',
        },
        {
            village: 'Asselscheuer',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Bastenduerf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Bech-Kleinmacher',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Bereldange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Beringen/Mersch',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Berschbach',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Bettel',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Bettembourg',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Bettendorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Bissen',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Bivels',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Blaschette',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Bourglinster',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Brandenburg',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Brouch/Mersch',
            frequency: '103.80',
            operator: 'Antenne Collective Brouch/Buschdorf',
        },
        {
            village: 'Buschdorf',
            frequency: '103.80',
            operator: 'Antenne Collective Brouch/Buschdorf',
        },
        {
            village: 'Calmus',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Clemency',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Dalheim',
            frequency: '107.90',
            operator: 'Coditel',
        },
        {
            village: 'Diekirch',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Differdange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Dudelange',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Ehlange/Mess',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Ehner',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Eisenborn',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Eppeldorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Ernster',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Erpeldange',
            frequency: '92.80',
            operator: 'Antenne Collective Ettelbruck a.s.b.l',
        },
        {
            village: 'Ettelbruck',
            frequency: '92.80',
            operator: 'Antenne Collective Ettelbruck a.s.b.l',
        },
        {
            village: 'Fennange',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Filsdorf',
            frequency: '107.90',
            operator: 'Coditel',
        },
        {
            village: 'Fingig',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Folkendange',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Fouhren',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Frisange',
            frequency: '107.90',
            operator: 'Coditel',
        },
        {
            village: 'Gilsdorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Godberange',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Gonderange',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Hagen',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Heisdorf',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Hellange',
            frequency: '107.90',
            operator: 'Coditel',
        },
        {
            village: 'Helmsange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Hoesdorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Hostert',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Huncherange',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Imbringen',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Ingeldorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Junglinster',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Kapweiler',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Kleinbettingen',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Lamadelaine',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Landscheid',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Leudelange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Limpach',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Lintgen',
            frequency: '104,40',
            operator: 'Antenne Collective Lintgen a.s.b.l.',
        },
        {
            village: 'Longsdorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Luxembourg-Belair',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Cents',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Cessange',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Cité Henri Frommes',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Clausen',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Domaine du Carmel',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Dommeldange',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Fetschenhof',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Gare',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Gasperich',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Grund',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Hollerich',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Kaltgesbruck',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Kirchberg',
            frequency: '106.00',
            operator: 'Coditel \n',
        },
        {
            village: 'Luxembourg-Limpertsberg',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Luxembourg-Merl',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Luxembourg-Neudorf',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Pulvermühl',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Verlorenkost',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Luxembourg-Weimershof',
            frequency: '100,80',
            operator: 'Siemens',
        },
        {
            village: 'Mersch',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Moesdorf/Mersch',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Moestroff',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Mullendorf',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Munsbach',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Neuhäusgen',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Niederanven',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Niedercorn',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Noertzange',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Oberanven',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Obercorn',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Obersyren',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Pétange',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Pettingen',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Pissange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Rameldange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Reckange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Reisdorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Rodange',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Roedgen',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Rollingen',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Roost',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Saeul',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Schieren',
            frequency: ' 92.80',
            operator: 'Antenne Collective Schieren a.s.b.l',
        },
        {
            village: 'Schoenfels',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Schrassig',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Schuttrange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Schwebach',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Senningen',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Senningerberg',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Stegen',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Steinfort',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Steinsel',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Strassen',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Tandel',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Troisvierges',
            frequency: '100,80',
            operator: 'Imagin',
        },
        {
            village: 'Walferdange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Wallendorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Walsdorf',
            frequency: '106,00',
            operator: 'Commune de Diekirch',
        },
        {
            village: 'Warken',
            frequency: '92.80',
            operator: 'Antenne Collective Ettelbruck A.s.b.l',
        },
        {
            village: 'Wasserbillig',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Weidingen',
            frequency: '106,00',
            operator: 'Coditel',
        },
        {
            village: 'Welfrange',
            frequency: '107.90',
            operator: 'Coditel',
        },
        {
            village: 'Wellenstein',
            frequency: '100.80',
            operator: 'Siemens',
        },
        {
            village: 'Wickrange',
            frequency: '106.00',
            operator: 'Coditel',
        },
        {
            village: 'Wiltz',
            frequency: '106.00',
            operator: 'Coditel',
        },
    ];

    return (
        <PageLayout hasLogo>
            <IonGrid fixed>
                <Title
                    title={t('frequencies.title')}
                    main
                />
                <div className="typo-b1 typo-regular mt-block-innerMobile md:mt-block-inner">
                    {t('frequencies.description')}
                </div>
                <div className="mt-blockMobile md:mt-block w-fit">
                    <IonRow className="typo-b2 font-semibold px-[30px] py-[10px] mb-blockInnerMobile md:mb-blockInner">
                        <IonCol>{t('frequencies.table.village')}</IonCol>
                        <IonCol>{t('frequencies.table.frequency')}</IonCol>
                        <IonCol>{t('frequencies.table.operator')}</IonCol>
                    </IonRow>
                    {frequencies.map((f, index) => (
                        <IonRow
                            className={`bg-light-background px-[30px] py-[10px] rounded ${
                                index !== frequencies.length - 1 ? 'mb-[16px]' : ''
                            }`}
                        >
                            <IonCol className="typo-b2 regular mr-[16px]">{f.village}</IonCol>
                            <IonCol className="typo-b2 font-semibold mr-[16px]">{f.frequency}</IonCol>
                            <IonCol className="typo-b2 regular mr-[16px]">{f.operator}</IonCol>
                        </IonRow>
                    ))}
                </div>
            </IonGrid>
        </PageLayout>
    );
}
