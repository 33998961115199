import DOMPurify from 'dompurify';

export type SanitizeHtmlComponentProps = {
    // html
    children: string;
};

interface AllowedAttributes {
    [tagName: string]: string[];
}

export function sanitizeHtml(rawHtml: string): string {
    const rawHtmlElement = document.createElement('div');
    rawHtmlElement.innerHTML = rawHtml;

    const allowedTags = [];
    allowedTags.push('strong', 'b', 'table', 'thead', 'tbody', 'tr', 'th', 'td', 'a', 'ul', 'ol', 'li', 'p', 'br', 'em');

    const allowedAttributes: AllowedAttributes = {
        table: ['border', 'cellpadding', 'cellspacing'],
        td: ['colspan', 'rowspan'],
        th: ['colspan', 'rowspan', 'scope'],
        img: ['src', 'alt', 'width', 'height'],
        a: ['href', 'target', 'rel'],
        ul: ['style'],
        ol: [],
        li: [],
        div: ['style'],
        span: ['style'],
    };

    const allowedAttr: string[] = Object.values(allowedAttributes).reduce((acc, attrs) => acc.concat(attrs), []);

    const sanitizedHtml = DOMPurify.sanitize(rawHtml, {
        ALLOWED_TAGS: allowedTags,
        ALLOWED_ATTR: allowedAttr,
        FORBID_ATTR: ['style', 'onerror', 'onload', 'width'],
    });

    return sanitizedHtml;
}

export default function SanitizeHtmlComponent({ children }: SanitizeHtmlComponentProps) {
    // eslint-disable-next-line react/no-danger
    return <div dangerouslySetInnerHTML={{ __html: sanitizeHtml(children) }} />;
}
