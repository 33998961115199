import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Title from 'components/Title';
import useResponsive from 'features/responsive/responsive';
import useTopics from 'features/topics/hooks/useTopics';
import useAds from 'hooks/useAds';
import LoadingIndicator from 'components/LoadingIndicator';
import AdComponent from 'components/Ads/AdComponent';
import { useTranslation } from 'react-i18next';
import WavePatternContainer from 'components/WavePatternContainer';
import TopicCardList from 'components/Cards/TopicCardList';
import PageLayout from '../components/PageLayout';
import AdRightBlock from '../components/Ads/AdRightBlock';

export default function TopicsCategoriesPage() {
    const { IsDesktop } = useResponsive();
    const { t } = useTranslation();
    const { ads } = useAds({ page: 'topics' });

    const { data, isLoading } = useTopics();

    return (
        <PageLayout
            title="Topics"
            cssClass="topics-page"
        >
            <IonGrid fixed>
                <WavePatternContainer
                    autoMargin
                    fixed="page"
                    reverse
                    position="top-right"
                    className="mb-0"
                    component="section"
                >
                    <IonCol size-xs={12}>{ads && <AdComponent ad={ads.data[0]} />}</IonCol>
                    {IsDesktop ? (
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonCol size-xs={12}>
                                <Title
                                    main
                                    title={t('topics')}
                                />
                            </IonCol>
                        </IonRow>
                    ) : (
                        ''
                    )}
                </WavePatternContainer>
                {!isLoading ? (
                    <AdRightBlock ad={ads?.data?.[1]}>
                        <TopicCardList data={data} />
                    </AdRightBlock>
                ) : (
                    <LoadingIndicator />
                )}
                {ads && (
                    <AdComponent
                        ad={ads.data[2]}
                        className="mt-[50px]"
                    />
                )}
            </IonGrid>
        </PageLayout>
    );
}
