import React, { useEffect, useState } from 'react';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import ResetStep1 from '../features/auth/components/ResetStep1';
import ResetStep2 from '../features/auth/components/ResetStep2';
import ResetStep3 from '../features/auth/components/ResetStep3';
import { TOAST_DURATION } from '../constants';

const RESET_REQUEST_STEP = 1;
const RESET_CONFIRM_STEP = 2;
const RESET_COMPLETE_STEP = 3;

type ResetPasswordFormProps = {
    onDone?: () => void;
};

export default function ResetPasswordForm({ onDone }: ResetPasswordFormProps) {
    const [step, setStep] = useState(RESET_REQUEST_STEP);
    const [email, setEmail] = useState<string | null>(null);
    const [token, setToken] = useState<string | null>(null);
    const [present] = useIonToast();
    const { t } = useTranslation();

    useEffect(() => {
        return () => {
            setStep(RESET_REQUEST_STEP);
        };
    }, []);

    const onStep1Completed = (e: string) => {
        setEmail(e);
        setStep(RESET_CONFIRM_STEP);
    };

    const onStep2Completed = (tok: string) => {
        setToken(tok);
        setStep(RESET_COMPLETE_STEP);
    };

    const onStep3Completed = () => {
        setStep(RESET_REQUEST_STEP);
        present(t('reset_password.reset_successful'), TOAST_DURATION);
        if (onDone) {
            onDone();
        }
    };

    return (
        <div className="lg:w-[350px] mx-auto">
            {step === RESET_REQUEST_STEP && <ResetStep1 onComplete={onStep1Completed} />}
            {step === RESET_CONFIRM_STEP && email && (
                <ResetStep2
                    onComplete={onStep2Completed}
                    email={email}
                />
            )}
            {step === RESET_COMPLETE_STEP && token && email && (
                <ResetStep3
                    onComplete={onStep3Completed}
                    email={email}
                    token={token}
                />
            )}
        </div>
    );
}
