import { useIonToast } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { TicketingPageInfo } from 'types';
import { getTicketingPageInfo } from 'lib/ApiService';
import { TOAST_DURATION } from '../../constants';

export default function useTicketingPageInfo() {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const { data, status, refetch } = useQuery<TicketingPageInfo>({
        queryKey: ['TICKETING_QUERY_KEY'],
        queryFn: getTicketingPageInfo,
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        tickets: data,
        isLoading: status === 'loading',
        refetch,
    };
}
