import { Share, ShareOptions } from '@capacitor/share';
import { useIonToast } from '@ionic/react';
import { useState } from 'react';
import { ShareOrangeIcon, ShareTrigger } from 'components/Icons';
import useCanShare from 'hooks/useCanShare';
import { useTranslation } from 'react-i18next';
import useResponsive from 'features/responsive/responsive';
import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton,
} from 'react-share';
import { TOAST_DURATION } from '../../constants';

export type ShareButtonProps = {
    className?: string;
} & Partial<ShareOptions>;

export default function ShareButton({ className, ...shareOptions }: ShareButtonProps) {
    const { t } = useTranslation();
    const { canShare } = useCanShare();
    const [present] = useIonToast();
    const [isOpenShare, setIsOpenShare] = useState(false);

    function toggleShare() {
        setIsOpenShare(!isOpenShare);
    }

    function copyToClipboard() {
        if (!shareOptions?.url) return;
        navigator.clipboard.writeText(shareOptions?.url);
        present(t('main.copied_to_clipboard'), TOAST_DURATION);
    }

    const share = () => {
        const opts: ShareOptions = {
            title: t('share'),
            dialogTitle: t('share'),
            ...shareOptions,
        };

        try {
            if (canShare) {
                Share.share(opts);
            } else {
                copyToClipboard();
            }
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className={`flex ${className || ''} h-[55px]`}>
            {isOpenShare && (
                <>
                    <FacebookShareButton url={shareOptions.url ?? ''}>
                        <FacebookIcon
                            className="m-1"
                            size={48}
                            round
                        />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareOptions.url ?? ''}>
                        <TwitterIcon
                            className="m-1"
                            size={48}
                            round
                        />
                    </TwitterShareButton>
                    <EmailShareButton url={shareOptions.url ?? ''}>
                        <EmailIcon
                            className="m-1"
                            size={48}
                            round
                        />
                    </EmailShareButton>
                    <button
                        onClick={share}
                        className="rounded-full bg-[#f08b1f] p-[10px] m-1"
                    >
                        <ShareTrigger />
                    </button>
                </>
            )}
            <button
                onClick={canShare ? share : toggleShare}
                className="m-1 ml-4"
            >
                <ShareOrangeIcon />
            </button>
        </div>
    );
}
