import { IonGrid, IonRow } from '@ionic/react';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import PageLayout from 'components/PageLayout';
import { useHistory } from 'react-router-dom';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import WavePatternContainer from 'components/WavePatternContainer';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import useEvents from 'features/event/hooks/useEvent';
import useResponsive from 'features/responsive/responsive';
import useEventCatgories from 'features/event/hooks/useEventCategories';
import LoadingIndicator from 'components/LoadingIndicator';
import { prepareUrlTitle } from 'lib/utils';
import Pagination from '../components/Pagination';
import CardListingChunksWithAds from '../components/CardListingChunksWithAds';
import PageNoData from './PageNoData';

function EventsArchivePage({ isCompactView }: EventsArchivePageProps) {
    const { ads } = useAds({ page: 'event-archive' });

    const { eventResponse, isLoading, nbPages, setFilters, filters } = useEvents(
        {},
        'archived_events',
        'events-native-horizontal',
    );
    const { IsDesktop, IsMobile } = useResponsive();
    const history = useHistory();

    const { categories } = useEventCatgories();

    const getCategNameFromCategId = (id: string): string => {
        if (!categories?.length) return id;
        const res = categories.find((c: any) => c.id === id);
        if (!res) return id;
        return res.name;
    };

    const { t } = useTranslation();

    if (!eventResponse && !isLoading) {
        return <PageNoData />;
    }

    const handleChangePage = (p: number) => {
        setFilters((f) => ({ ...f, page: p }));
    };

    return (
        <PageLayout
            title={t('events_archive')}
            cssClass="news-page"
            hasBackButton
        >
            {!isLoading ? (
                <IonGrid fixed>
                    {ads && <AdComponent ad={ads.data[0]} />}
                    <WavePatternContainer
                        reverse
                        fixed="page"
                        position="center-right"
                        onlyDesktop
                    >
                        {IsDesktop && (
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <Title
                                    title={t('events_archive')}
                                    main
                                />
                            </IonRow>
                        )}
                    </WavePatternContainer>
                    (
                    <IonRow>
                        <CardListingChunksWithAds
                            data={eventResponse?.events ?? []}
                            className="mb-blockInnerMobile md:mb-blockInner"
                            adNativeClassName="!h-[175px] overflow-hidden"
                            adsBetween={ads ? [ads.data[1]] : []}
                        >
                            {(event) => (
                                <div>
                                    <HorizontalMediumCard
                                        title={event.name}
                                        date={event.startDate}
                                        thumbnailUrl={event?.imageUrl}
                                        truncated={3}
                                        linkTo={`/events/agenda/${event.id as unknown as string}/${prepareUrlTitle(event?.name)}`}
                                        className="max-h-[175px]"
                                        tags={event.genre.map((tag) => {
                                            return { tag, publicTag: tag, name: getCategNameFromCategId(tag) };
                                        })}
                                        onClickForPill={(category) => {
                                            history.push({
                                                pathname: '/events/agenda',
                                                state: { category },
                                            });
                                        }}
                                    />
                                </div>
                            )}
                        </CardListingChunksWithAds>
                    </IonRow>
                    )
                    <IonRow className="mb-blockMobile md:mb-block">
                        <div className="grid place-items-center w-full">
                            <Pagination
                                currentPage={filters.page || 1}
                                numberOfPages={nbPages}
                                onPageChange={handleChangePage}
                            />
                        </div>
                    </IonRow>
                    {ads && <AdComponent ad={ads.data[3]} />}
                </IonGrid>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
}

type EventsArchivePageProps = {
    isCompactView?: boolean;
};

EventsArchivePage.defaultProps = {
    isCompactView: false,
};

export default EventsArchivePage;
