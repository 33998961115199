import Img from '../../../components/Img';

export default function WebChannelSlide({ background, title }: { background: string; title: string }) {
    const contentStyle = {
        // backgroundImage: `linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 98.38%), url('assets/img/webchannels/${background}')`,
        // backgroundSize: 'cover',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '16px',
        letterSpacing: '0.5px',
        display: 'flex',
        height: '100%',
        fontWeight: 600,
    };

    return (
        <div
            style={contentStyle}
            className="relative group/hovercard"
        >
            <Img
                src={`assets/img/webchannels/${background}`}
                hasCardHoverEffect
                className="w-full flex-1 h-full"
                imgClassName="w-full h-full"
            />
            <div
                style={{ background: 'linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 98.38%)' }}
                className="absolute bottom-0 top-0 left-0 right-0 w-full h-full flex justify-center items-end pb-[18px] typo-b1 semibold"
            >
                {title}
            </div>
        </div>
    );
}
