import { getPermutiveContent } from 'features/advertising/generatePermutivePayload';
import { useEffect } from 'react';
import { useLocation } from 'react-router';

type UsePermutiveProps = {
    audioContent: boolean;
    videoContent: boolean;
    photoGallery: boolean;
    authors: string[];
    modifiedAt: Date;
    publishedAt: Date;
    tags: string[];
    wordCount: number;
    send?: boolean;
};

export default function usePermutiveArticle({
    audioContent,
    videoContent,
    photoGallery,
    authors,
    modifiedAt,
    publishedAt,
    tags,
    wordCount,
    send = true,
}: UsePermutiveProps) {
    const location = useLocation();
    useEffect(() => {
        if (send) {
            getPermutiveContent().then((content) => {
                // @ts-ignore
                permutive.track('Pageview', {
                    article: {
                        audioContent,
                        authors,
                        // waiting on eldo
                        listSubTopic: [],
                        // waiting on eldo
                        listTopic: [],
                        modifiedAt,
                        photoGallery,
                        publishedAt,
                        tags,
                        videoContent,
                        wordCount,
                    },
                    content,
                });
            });
        }
    }, [send]);
}
