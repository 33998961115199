import Button from 'components/Button';
import PageLayout from 'components/PageLayout';
import { useTranslation } from 'react-i18next';
import { CloseIcon } from 'components/Icons';
import Title from 'components/Title';
import { useHistory } from 'react-router-dom';
import React, { FormEventHandler } from 'react';

type EditAccountProps = {
    title: string;
    onSubmit: FormEventHandler;
    children: React.ReactNode;
    buttons?: React.ReactNode;
    saveBtnText?: string;
};

export default function EditAccount({ onSubmit, title, children, buttons, saveBtnText }: EditAccountProps) {
    const { t } = useTranslation();
    const history = useHistory();

    const submitBtnRef = React.createRef<HTMLButtonElement>();

    return (
        <PageLayout hideLightHalo>
            <form
                onSubmit={onSubmit}
                className="lg:w-[350px] mx-auto lg:mt-[53px] lg:mb-[53px] relative"
            >
                <Title
                    hidePicto
                    title={title}
                    className="!justify-center relative"
                >
                    <button
                        className="absolute right-0 top-0"
                        onClick={() => history.goBack()}
                    >
                        <CloseIcon />
                    </button>
                </Title>

                {children}

                <div className="mb-8 mt-[60px]">
                    <Button
                        value={saveBtnText || t('requestEmailChange')}
                        className="text-[var(--eldo-background-color)] bg-white"
                        type="submit"
                    />
                    {buttons || ''}
                </div>
            </form>
        </PageLayout>
    );
}
