import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './translations/en.json';
import translationLU from './translations/lu.json';

const defaultLanguage = 'en';

const resources = {
    en: {
        translation: translationEN,
    },
    lu: {
        translation: translationLU,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: true,
        resources,
        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        keySeparator: '.',
        interpolation: {
            escapeValue: false,
        },
        defaultNS: 'translation',
        detection: {
            order: ['querystring', 'cookie', 'localStorage'],
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupLocalStorage: 'i18nextLng',
            caches: ['localStorage', 'cookie'],
        },
    });

export default i18n;
