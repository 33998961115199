import { IonGrid, IonRefresher, IonRefresherContent, IonRow, RefresherEventDetail } from '@ionic/react';
import { useEffect, useMemo, useState } from 'react';
import ButtonGroup from 'components/ButtonGroup';
import PageLayout from 'components/PageLayout';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import useNews from 'features/news/hooks/useNews';
import useNewsCategories from 'features/news/hooks/useNewsCategories';
import { useParams } from 'react-router-dom';
import { Article, NewsCategory } from 'types';
import { useTranslation } from 'react-i18next';
import useResponsive from 'features/responsive/responsive';
import useTopicCategory from 'features/topics/hooks/useTopicCategory';
import LoadingIndicator from 'components/LoadingIndicator';
import NewsInfiniteScroll from 'components/NewsInfiniteScroll';
import WavePatternContainer from '../components/WavePatternContainer';
import Title from '../components/Title';
import PageNoData from './PageNoData';

function NewsPage({ isCompactView }: NewsPageProps) {
    const [isScrolled, setIsScrolled] = useState(false);
    const [chosenTabByHash, setChosenTabByHash] = useState<any>(undefined);

    const categories = useNewsCategories();
    const { t } = useTranslation();
    const { IsMobile, IsDesktop } = useResponsive();
    const { tag, category } = useParams<{ tag: string; category: string }>();

    const [currentCategory, setCurrentCategory] = useState<NewsCategory['id']>(category as unknown as number);
    const [trigger, setTrigger] = useState<boolean>(false);

    const news = useNews({ tag, categoryId: currentCategory });
    const { ads } = useAds({ page: 'news', reloadOnChangeString: currentCategory?.toString() });
    const topicCategory = useTopicCategory(category);

    const handleRefresh = (event: CustomEvent<RefresherEventDetail>) => {
        setTrigger(!trigger);
        event.detail.complete();
    };

    const regex = /^\/aktuell\/news$/;
    const isMatch = regex.test(window.location.pathname);

    useEffect(() => {
        if (!chosenTabByHash && !categories.isLoading) {
            categories?.data?.forEach((tab, index) => {
                if (decodeURIComponent(window.location.hash.substring(1)) === tab.name) {
                    setCurrentCategory(index);
                    setChosenTabByHash({ name: tab.name, id: tab.id });
                }
            });
        }
    }, [categories.isLoading]);

    useEffect(() => {
        if (category) {
            // @ts-ignore
            setCurrentCategory(category);
        } else if (categories.data?.length && !currentCategory && !tag) {
            setCurrentCategory(categories.data[0].id);
        }

        const onIntersection: IntersectionObserverCallback = (entries, observer) => {
            setIsScrolled(!entries[0].isIntersecting);
        };
        const observer = new IntersectionObserver(onIntersection, { threshold: 1, rootMargin: '-50px' });

        const element = document.querySelector('#newsPageTop');
        if (element) {
            observer.observe(element);
        }

        return () => {
            observer.disconnect();
        };
    }, [categories, category, tag, news]);

    const titleOfNewsPage = useMemo<string>(() => {
        if (news?.data?.pages?.length) {
            if (news?.data?.pages?.[news.data.pages.length - 1].tag != null) {
                return news?.data?.pages?.[news.data.pages.length - 1].tag as string;
            }
        }
        return topicCategory.data?.name || t('main.news');
    }, [tag, category, topicCategory]);

    if (
        !news.isLoading &&
        !categories.isLoading &&
        !topicCategory.isLoading &&
        !news.list &&
        !categories.data &&
        !topicCategory.data
    ) {
        return <PageNoData />;
    }

    const hasSpecialAd = !news.isLoading && news.list.length > 0 && (news.list[0] as Article).hasSpecialAd;

    return (
        <PageLayout
            title={titleOfNewsPage}
            cssClass="news-page"
            reduceBottomSpace={(!!category || hasSpecialAd) && !isMatch && !!ads}
        >
            <IonRefresher
                slot="fixed"
                className=""
                onIonRefresh={(e: CustomEvent) => handleRefresh(e)}
            >
                <IonRefresherContent refreshingSpinner="crescent" />
            </IonRefresher>
            <IonGrid fixed>
                {ads && (
                    <AdComponent
                        ad={ads.data[0]}
                        className={`${
                            IsMobile && (category || hasSpecialAd) && !isMatch
                                ? '[&>iframe]:!-mx-[25px] !top-0 [&>iframe]:!-mt-[30px] !w-screen'
                                : '!top-0'
                        }`}
                    />
                )}

                <div id="newsPageTop" />

                {!topicCategory.isLoading && !categories.isLoading ? (
                    <>
                        {IsDesktop && (
                            <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                                <Title
                                    title={titleOfNewsPage}
                                    main
                                />
                            </IonRow>
                        )}
                        <WavePatternContainer
                            patternClassName="-translate-y-[100%]"
                            reverse
                            fixed="page"
                            position="center-right"
                            onlyDesktop
                        >
                            {!category && !tag ? (
                                <ButtonGroup
                                    value={chosenTabByHash}
                                    onChange={(opt) => {
                                        // @ts-ignore
                                        setCurrentCategory(opt.id);
                                    }}
                                    options={categories.data || []}
                                />
                            ) : (
                                ''
                            )}
                        </WavePatternContainer>

                        <NewsInfiniteScroll
                            categoryId={currentCategory}
                            tag={tag}
                            trigger={trigger}
                            setTrigger={setTrigger}
                            ads={ads}
                            adPosition={1}
                            className="mt-blockMobile md:mt-block"
                        />

                        {ads && !news.canLoadMore && !news.isLoading && <AdComponent ad={ads.data[2]} />}
                    </>
                ) : (
                    <LoadingIndicator />
                )}
            </IonGrid>
        </PageLayout>
    );
}

type NewsPageProps = {
    isCompactView?: boolean;
};

NewsPage.defaultProps = {
    isCompactView: false,
};

export default NewsPage;
