import Card from 'components/Card';
import Img from 'components/Img';
import Pill from 'components/Pill';
import { useTranslation } from 'react-i18next';
import { Movie, MovieSession } from 'types';

export type MovieCardProps = {
    movie: Movie;
    lang: 'lu' | 'en' | 'fr' | 'be';
    isDetails?: boolean;
    className?: string;
};

export default function MovieCard({ movie, lang, isDetails, className }: MovieCardProps) {
    const { t } = useTranslation();

    const dataDetails = !isDetails
        ? null
        : {
              version: movie.version,
              director: movie.director,
              producer: movie.producer,
              casting: movie.casting,
          };

    return (
        <Card
            hasCardHoverEffect={!isDetails}
            className={`${className || ''} flex flex-col`}
        >
            <div className="overflow-hidden p-[10px]">
                <Img
                    alt={movie.title}
                    src={movie.images.poster}
                    hasCardHoverEffect={!isDetails}
                    imgClassName="object-cover h-full w-full"
                />
            </div>
            <div className="flex-1 mt-[10px] flex flex-col gap-[8px]">
                <div className="typo-b2 regular text-[var(--eldo-primary)]">
                    {isDetails && movie.year ? `${movie.year} - ` : ''}
                    {movie.description[lang].category}
                </div>
                <div className="typo-b1 font-semibold flex gap-2 items-center">
                    {isDetails && movie.duration > 0 ? (
                        <Pill className="w-fit">
                            {Math.floor(movie.duration / 60)}h{movie.duration % 60}
                        </Pill>
                    ) : (
                        ''
                    )}
                    {movie.title}
                </div>
                {isDetails && dataDetails != null ? (
                    <>
                        {Object.keys(dataDetails).map((key) => (
                            <div
                                key={key}
                                className="typo-b3"
                            >
                                <span className="font-semibold">{t(`cinema.${key}`)} : </span>
                                {/* @ts-ignore */}
                                <span>{dataDetails[key]}</span>
                            </div>
                        ))}
                    </>
                ) : (
                    ''
                )}
            </div>
            {!isDetails ? <div className="mt-[20px] typo-b2">{movie.version}</div> : ''}
        </Card>
    );
}
