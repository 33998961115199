import { useQuery } from '@tanstack/react-query';
import { getReplaysByCategory } from '../../../lib/ApiService';
import { CategorizedReplays, PodcastOrReplay } from '../../../types';

export default function useReplayByCategory(category: string, pageName?: string) {
    const { data, status, error } = useQuery<PodcastOrReplay[] | CategorizedReplays>({
        queryKey: [`podcastByCategory-${category}`],
        queryFn: () => getReplaysByCategory(category, pageName),
    });

    return { podcastByCategory: data, isLoading: status === 'loading', error };
}
