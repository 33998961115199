/* eslint no-useless-return: 0 */
/* eslint consistent-return: 0 */
import { BaseSyntheticEvent, createRef, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { IonCard, IonThumbnail, IonImg, createGesture, Gesture, isPlatform, IonToolbar } from '@ionic/react';
import { stopAllAudio } from 'lib/utils';
import useResponsive from 'features/responsive/responsive';
import AudioPlayer from 'components/AudioPlayer';
import PlayerModal from './PlayerModal';
import PlayerButton from './PlayerButton';
import { PlayerContext, PlayerContextType } from '../../PlayerContext';

const MAX_GESTURE_BEFORE_CLOSE = 145;

export default function PlayerWidget() {
    const { playerState, playerDispatch, setPlayerHeight } = useContext<PlayerContextType | null>(
        PlayerContext,
    ) as PlayerContextType;
    const playerRef = useRef<HTMLAudioElement>(null);
    const playerWidgetRef = useRef<HTMLDivElement>(null);

    const { IsMobile } = useResponsive();

    const handleEnd = () => {
        playerDispatch({ type: 'TOGGLE_PLAY' });
        playerDispatch({ type: 'SET_PROGRESSION', payload: 0 });
    };

    useEffect(() => {
        const elem = document.getElementById('player-widget') as HTMLElement;
        const gesture: Gesture = createGesture({
            el: elem,
            threshold: 15,
            gestureName: 'my-gesture',
            onMove: (ev) => {
                const gap = ev.startX - ev.currentX;
                elem.style.transform = `translateX(${-gap}px)`;
            },
            onEnd: (ev) => {
                if (!elem) return;
                const gap = ev.startX - ev.currentX;
                if (Math.abs(gap) > MAX_GESTURE_BEFORE_CLOSE) {
                    elem.classList.add('transition-all', 'duration-300');
                    elem.style.transform = `translateX(${gap > 0 ? -100 : 100}%)`;
                    playerDispatch({ type: 'PAUSE' });
                    setTimeout(() => {
                        playerDispatch({ type: 'SET_PROGRESSION', payload: 0 });
                        playerDispatch({ type: 'HIDE' });
                        elem.style.transform = `none`;
                        elem.classList.remove('transition-all', 'duration-300');
                    }, 300);
                } else {
                    elem.classList.add('transition-all', 'duration-300');
                    elem.style.transform = `none`;
                    setTimeout(() => {
                        elem.classList.remove('transition-all', 'duration-300');
                    }, 300);
                }
            },
        });
        gesture.enable();

        return () => {
            return gesture.destroy();
        };
    }, []);

    useEffect(() => {
        if (playerState.media?.mediaUrl !== playerRef.current?.currentSrc) {
            playerRef.current?.load();
        }

        if (playerState.isPlaying) {
            stopAllAudio(playerRef.current as HTMLAudioElement);
            playerRef.current?.play();
        } else {
            playerRef.current?.pause();
        }
    }, [playerRef.current, playerState.isPlaying, playerState.media]);

    useEffect(() => {
        if (playerState.type === 'WEBCHANNEL' || playerState.type === 'RADIO') {
            playerDispatch({ type: 'SET_PROGRESSION', payload: 100 });
            return;
        }

        if (
            !(
                playerRef.current?.currentTime !== undefined &&
                playerRef.current?.duration !== undefined &&
                playerState.isPlaying
            )
        ) {
            return;
        }

        const { currentTime, duration } = playerRef.current;
        const interval = setInterval(() => {
            const progress = (currentTime / duration) * 100;
            playerDispatch({ type: 'SET_PROGRESSION', payload: progress });
        }, 100);

        // eslint-disable-next-line consistent-return
        return () => {
            clearInterval(interval);
        };
    }, []);

    const onClick = useCallback(
        (fn: VoidFunction) => (e: BaseSyntheticEvent) => {
            e.stopPropagation();
            fn();
        },
        [],
    );

    useEffect(() => {
        if (!playerWidgetRef.current && playerState.isVisible) return;
        const playerH = playerWidgetRef.current?.getBoundingClientRect().height;
        setPlayerHeight(playerH || 0);
    }, [playerState.isVisible]);

    const calculateBottomStyle = () => {
        if (IsMobile) {
            return { bottom: isPlatform('ios') ? '100px' : '70px' };
        }
        return { bottom: '0px' };
    };

    return (
        <>
            <div
                ref={playerWidgetRef}
                className={`fixed w-full bottom-[60px] lg:bottom-0 z-10 ${
                    !playerState.isVisible ? 'hidden' : 'grid'
                } left-0  place-items-center`}
                style={calculateBottomStyle()}
            >
                <IonCard
                    id="player-widget"
                    className={`${IsMobile ? 'cursor-pointer' : ''} bg-[#282828] m-0  w-full max-w-full !p-0`}
                    onClick={() => {
                        if (IsMobile) {
                            playerDispatch({ type: 'EXPAND' });
                        }
                    }}
                >
                    <div className="flex items-center content-center max-w-[100%] w-full gap-[10px] h-full">
                        <img
                            className="h-full min-h-full w-[90px] md:w-[125px] object-cover"
                            src={playerState.media?.thumbnailUrl || '../../assets/img/placeholderimg.svg'}
                            alt=""
                        />

                        <div className="whitespace-nowrap overflow-hidden">
                            <h4 className="font-bold w-full overflow-hidden">
                                <span className="typo-b1 font-bold">{playerState.media?.title}</span>
                            </h4>
                            <p className="typo-b2 regular mt-1">{playerState.media?.subtitle}</p>
                        </div>
                        <div className="max-md:flex-1 max-md:flex max-md:justify-end max-md:pr-[10px]">
                            <div
                                className="md:absolute left-1/2 top-1/2 md:transform md:-translate-y-1/2 md:-translate-x-1/2"
                                onClick={(e) => e.stopPropagation()}
                            >
                                <AudioPlayer
                                    src={playerState.media?.mediaUrl ?? ''}
                                    type="application/x-mpegurl"
                                    mainAudio
                                />
                            </div>
                        </div>
                    </div>
                </IonCard>
            </div>

            <PlayerModal />
        </>
    );
}
