import { useEffect, useRef, useState } from 'react';
import { IonCol, IonRow, IonGrid, isPlatform } from '@ionic/react';
import useTrafficInfo from 'features/trafficInfo/useTrafficInfo';
import TrafficSwiper from 'features/trafficInfo/TrafficSwiper';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import TwitterEmbed from 'components/TwitterEmbed';
import InstagramEmbedComponent from 'components/InstagramEmbed';
import { Link } from 'react-router-dom';
import useNewsArticle from 'features/news/hooks/useNewsArticle';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { Article, ArticlePart, Picture, PictureDetailResponse, Tags } from 'types';
import useResponsive from 'features/responsive/responsive';
import useAds from 'hooks/useAds';
import usePermutiveArticle from 'hooks/usePermutive';
import AdComponent from 'components/Ads/AdComponent';
import { ensureSecureUrl } from 'features/utils/utils';
import Gallery from 'components/Gallery';
import { useQuery } from '@tanstack/react-query';
import { getPicturesReport } from 'lib/ApiService';
import useAuth from 'features/auth/useAuth';
import Title from 'components/Title';
import Img from 'components/Img';
import ReactTwitchEmbedVideo from 'react-twitch-embed-video';
import TiktokEmbedComponent from 'components/TiktokEmbed';
import { generateUniqueId, prepareUrlTitle } from 'lib/utils';
import { CalendarIcon, PersonIcon } from '../components/Icons';
import AudioPlayer from '../components/AudioPlayer';
import ArticlePageComponent, {
    getCopyrightForArticle,
    getThumbnailForArticle,
    getThumbnailForFilmSortien,
    getThumbnailForHorizontalArticleList,
} from '../components/core/ArticlePageComponent';
import { sanitizeHtml } from '../components/core/SanitizeHtmlComponent';

export interface ArticleAttachmentProps {
    adUrl: string | undefined;
    IsMobile: boolean;
    displayGalleryCopyright?: boolean;
}

export interface ArticlePartProps {
    part: ArticlePart;
    IsMobile: boolean;
    displayGalleryCopyright?: boolean;
}

function joinAuthors(arr: string[]) {
    if (arr.length === 0) {
        return '';
    }
    if (arr.length === 1) {
        return arr[0];
    }
    return `${arr.slice(0, -1).join(', ')} and ${arr[arr.length - 1]}`;
}

export function containsYoutubeVideo(word: string) {
    return word.toLowerCase().includes('youtube');
}

export function containsVimeoVideo(word: string) {
    return word.toLowerCase().includes('vimeo');
}

function containsTwitter(html: string) {
    return html.toLowerCase().includes('twitter-tweet');
}

export function containsInstagram(html: string) {
    return html.toLowerCase().includes('instagram-media');
}

export function containsTiktok(html: string) {
    return html.toLowerCase().includes('tiktok-embed');
}

export function extractYouTubeID(iframeHTML: string) {
    const regex = /src="https:\/\/www\.youtube\.com\/embed\/([^"?]+)/;
    const match = iframeHTML.match(regex);
    return match ? match[1] : null;
}

export function getStylingForVideo(url: string, IsMobile: boolean) {
    if (containsYoutubeVideo(url)) {
        return 'relative [&>iframe]:w-full [&>iframe]:h-full [&>iframe]:absolute';
    }
    if (containsVimeoVideo(url)) {
        return '[&>iframe]:w-full [&>iframe]:md:h-[500px] [&>iframe]:h-[300px]';
    }
    return `[&>blockquote]:!w-[200px] [&>blockquote]:!mx-auto ${
        IsMobile ? '[&>iframe]:max-w-[325px] [&>iframe]:mx-auto' : ''
    }`;
}

export function doesTagContain(tags?: Tags[], tagToCheck?: string) {
    return tags?.some((data) => data.tag === tagToCheck);
}

function getLastPartOfUrl(url: string): string | null {
    const lastSlashIndex = url.lastIndexOf('/');
    if (lastSlashIndex !== -1) {
        return url.substring(lastSlashIndex + 1);
    }
    return null;
}

export function ArticleAttachmentGallery({ id }: { id: string }) {
    const { data, status, isLoading } = useQuery<PictureDetailResponse>(['picture-report', id], () =>
        getPicturesReport(id),
    );
    const [selectedPicture, setSelectedPicture] = useState<Picture | undefined>(undefined);
    const { IsMobile } = useResponsive();

    useEffect(() => {
        if (!selectedPicture && !isLoading) {
            setSelectedPicture(data?.gallery?.[0]);
        }
    }, [data]);

    const getSlidesPerView = () => {
        if (IsMobile) return 1.45;
        return 1;
    };

    return !isLoading ? (
        <IonGrid fixed>
            <IonRow>
                <IonCol size-xs={12}>
                    <div className="relative">
                        <Title title={data?.title ?? ''} />
                        {selectedPicture && (
                            <Gallery
                                key={data?.title.length}
                                pictures={data?.gallery || []}
                                currentPicture={selectedPicture}
                                displayControls
                                setCurrentPicture={setSelectedPicture}
                                slidesPerView={getSlidesPerView()}
                                isFullWithOnMobile
                                pictureClass="max-md:!h-auto"
                            />
                        )}
                    </div>
                </IonCol>
            </IonRow>
        </IonGrid>
    ) : (
        <div />
    );
}

function createCopyrightText(title: string, copyright: string) {
    return title || copyright ? (
        <div className="mt-1 text-gray-400 italic text-[16px]">
            {title && `${title} `} {title && copyright && '/'} {copyright && `© ${copyright}`}
        </div>
    ) : (
        ''
    );
}

export function ArticleAttachment({
    type,
    copyright,
    title,
    url,
    adUrl,
    link,
    IsMobile,
    displayGalleryCopyright = false,
}: ArticlePart['data'] & ArticleAttachmentProps) {
    if (type === 'image') {
        return (
            <div>
                {link ? (
                    <a
                        href={link}
                        target="_blank"
                        rel="noreferrer"
                    >
                        <img
                            alt={copyright}
                            src={url}
                        />
                    </a>
                ) : (
                    <img
                        alt={copyright}
                        src={url}
                    />
                )}
                {createCopyrightText(title, copyright)}
            </div>
        );
    }
    if (type === 'link') {
        if (url?.length && url.indexOf('eldo') !== -1 && url.indexOf('fotoen') !== -1) {
            let id = url.split('/')[url.split('/').length - 1];
            if (id.endsWith('.html')) {
                id = id.substring(0, id.length - 5);
            }

            return (
                <div>
                    <ArticleAttachmentGallery
                        key={id}
                        id={id}
                    />
                    {displayGalleryCopyright && createCopyrightText(title, copyright)}
                </div>
            );
        }
        return (
            <a
                target="_blank"
                href={url}
                rel="noreferrer"
                style={{ overflowWrap: 'anywhere' }}
            >
                {title || url}
            </a>
        );
    }
    if (type === 'embed') {
        let contentToRender;

        if (containsTwitter(url)) {
            contentToRender = <TwitterEmbed tweetHtml={url} />;
        } else if (containsInstagram(url)) {
            contentToRender = <InstagramEmbedComponent html={url} />;
        } else if (containsTiktok(url)) {
            contentToRender = <TiktokEmbedComponent html={url} />;
        } else {
            contentToRender = (
                <div
                    className={getStylingForVideo(url, IsMobile)}
                    style={{ paddingBottom: containsYoutubeVideo(url) ? '56.25%' : '0' }}
                    dangerouslySetInnerHTML={{ __html: url }}
                />
            );
        }

        return (
            <div>
                {/* eslint-disable-next-line react/no-danger */}
                {title?.length > 0 ? <div className="mt-1 text-gray-400">{title}</div> : ''}
                {url?.includes('twitch.tv') ? (
                    <div className="mt-4">
                        <a
                            target="_blank"
                            className="text-orange-500 m-4 flex"
                            href={url}
                            rel="noreferrer"
                            style={{ overflowWrap: 'anywhere' }}
                        >
                            <div className="mt-1 mr-4">Open in twitch</div>
                            <div>
                                <Img
                                    src="/assets/img/Twitch_logo_2019.png"
                                    width={100}
                                    className=" mx-auto"
                                />
                            </div>
                        </a>
                        {!(isPlatform('ios') && isPlatform('cordova')) && (
                            <ReactTwitchEmbedVideo
                                channel={getLastPartOfUrl(url) ?? ''}
                                width="100%"
                                parent={['eldo.lu']}
                                chat="mobile"
                            />
                        )}
                    </div>
                ) : (
                    contentToRender
                )}
            </div>
        );
    }
    if (type === 'audio') {
        return (
            <div>
                <AudioPlayer
                    src={ensureSecureUrl(url)}
                    text={title || 'audio'}
                    ad={adUrl}
                />
            </div>
        );
    }
    return <div className="hidden" />;
}

function updateScriptLink(input: string): string {
    const scriptRegex = /(\/\/ia.eldo.lu\S+)/;
    return input.replace(scriptRegex, `${window.location.origin}/scripts/qualifio.js')`);
}

export function ArticlePartComponent({ part, IsMobile, displayGalleryCopyright }: ArticlePartProps) {
    const contentRef = useRef<HTMLDivElement>(null);

    const isHtmlContent = !!(part.content || '').trim().startsWith('<div');

    useEffect(() => {
        if (!isHtmlContent) return;
        if (!contentRef.current) return;
        if (!part.content?.length) return;

        let script =
            part.content?.split('<script type="text/javascript">')[1] ||
            part.content?.split("<script type='text/javascript'>")[1];
        if (!script?.length) return;
        script = script.substring(0, script.length - '</script>'.length);
        const scriptElem = document.createElement('script');
        scriptElem.innerHTML = updateScriptLink(script);

        const div = part.content?.split('<script')[0];
        if (!div.length || !scriptElem) return;
        contentRef.current.innerHTML = div;
        contentRef.current.appendChild(scriptElem);
    }, []);

    if (part.header) {
        return (
            <div
                className="typo-b1 bold"
                dangerouslySetInnerHTML={{ __html: sanitizeHtml(part.header ?? '') }}
            />
        );
    }

    if (part?.type === 'subtitle' || part?.type === 'title') {
        return (
            <div className="my-[10px]">
                <span
                    className="bg-orange-500 font-normal font-semibold text-[23px] leading-[32px] px-[10px]"
                    dangerouslySetInnerHTML={{ __html: part.content ?? '' }}
                />
            </div>
        );
    }
    if (part?.type === 'attachment') {
        return (
            <ArticleAttachment
                {...part.data}
                adUrl={part.adUrl}
                IsMobile={IsMobile}
                displayGalleryCopyright={displayGalleryCopyright}
            />
        );
    }
    if (isHtmlContent) {
        return (
            <div
                className={`${
                    isPlatform('ios') && isPlatform('cordova')
                        ? 'overflow-y-scroll -webkit-overflow-scrolling-touch'
                        : ''
                }`}
            >
                <div
                    key={part.position}
                    className={`${part?.type === 'title' ? 'typo-h3 font-bold' : 'typo-b2'}`}
                    ref={contentRef}
                />
            </div>
        );
    }

    return (
        <div
            key={part.position}
            className={`${part?.type === 'title' ? 'typo-h3 font-bold' : 'typo-b2'} ${
                part.content?.includes('<ul>') ? 'ml-[20px]' : ''
            }`}
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(part.content ?? '') }}
        />
    );
}

export default function NewsArticle({ isCompactView }: NewsArticleProps) {
    const { IsMobile, IsDesktop } = useResponsive();
    const { t } = useTranslation();
    const { id: articleId } = useParams<{ id: string }>();
    const history = useHistory();
    const { isAuthenticated } = useAuth();

    const { data, isLoading } = useNewsArticle(articleId);
    const { ads } = useAds({ page: 'news-detail', audio: true, articleId, tags: data ? data?.tags : [], isLoading });
    const { trafficInfos } = useTrafficInfo(articleId);

    const contents = { audio: false, video: false, photoGallery: false };
    const totalWords =
        data?.parts?.reduce((total, part) => {
            const wordCount =
                part.type === 'paragraph' ? part?.content?.trim().replace(/\s+/g, ' ').split(' ').length || 0 : 0;
            if (part.type === 'attachment') {
                contents.video = contents.video || part.data?.type === 'embed';
                contents.audio = contents.audio || part.data?.type === 'audio';
            }
            return total + wordCount;
        }, 0) || 0;

    usePermutiveArticle({
        audioContent: contents.audio,
        videoContent: contents.video,
        photoGallery: contents.photoGallery,
        authors: [],
        modifiedAt: new Date('1.1.1970'),
        publishedAt: new Date('1.1.1970'),
        tags: data?.tags?.map((item) => item.name) ?? [],
        wordCount: totalWords,
        send: !isLoading,
    });

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://player.podigee-cdn.net/podcast-player/javascripts/podigee-podcast-player.js';
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [data, ads]);

    return (
        <ArticlePageComponent
            doubleBack
            isLoading={isLoading}
            shareBtnProps={{ url: `${process.env.REACT_APP_FRONTEND_URL || ''}${window.location.pathname}` }}
            description={(data?.parts || [])?.map((part) => {
                const adUrl =
                    ads && ads?.data.length ? ads?.data?.filter((item) => item.container === 'media_ads')[0]?.url : '';
                const updatedPart = { ...part, adUrl };
                return (
                    ads &&
                    !isLoading && (
                        <ArticlePartComponent
                            part={updatedPart}
                            IsMobile={IsMobile}
                        />
                    )
                );
            })}
            inpageAdUrl={
                ads &&
                ((Array.isArray(ads.data) ? ads?.data : null) || [])?.filter((item) => item.container === 'inpage')[0]
                    ?.url
            }
            title={data?.title || ''}
            image={{ src: getThumbnailForArticle(data), alt: data?.title as string }}
            slotStart={
                ads && (
                    <AdComponent
                        ad={ads.data[0]}
                        className={`${
                            IsMobile && data?.category_path !== '' || history.location.pathname.includes('/kino/articles')
                                ? '[&>iframe]:!-mx-[25px] [&>iframe]:!-mt-[30px] !w-screen'
                                : ''
                        }`}
                    />
                )
            }
            slotImageCaption={getCopyrightForArticle(data)}
            imgOpacityGradient
            kicker={data?.kicker}
            tags={data?.tags ?? []}
            tagsWithLinkProps={
                isLoading
                    ? []
                    : (data?.tags || [])?.map(({ name, publicTag }) => {
                          const isFirstTagAndTopicArticle =
                              publicTag && data?.category_path?.length && data?.category_path.includes(publicTag);
                          return {
                              name,
                              linkProps: isFirstTagAndTopicArticle
                                  ? {
                                        to: data?.category_path,
                                        replace: true,
                                    }
                                  : { to: `/aktuell/news/${publicTag || name}`, replace: true },
                          };
                      })
            }
            slotEndFirstSection={
                <>
                    <div className={IsDesktop ? 'flex flex-row items-center' : ''}>
                        <div className={`flex items-center  ${IsDesktop ? 'mr-6' : ''}`}>
                            <div className="">
                                <CalendarIcon />
                            </div>
                            <div className=" ml-2 typo-b2 regular">{moment(data?.date).format('DD.MM.YY')}</div>
                        </div>
                        <div className="flex items-center">
                            <PersonIcon />
                            <div className="ml-2 typo-b2 regular">{joinAuthors(data?.authors ?? [])}</div>
                        </div>
                    </div>
                    {ads && IsMobile && <AdComponent ad={ads.data[1]} />}
                </>
            }
            slotDescriptionRight={
                ads && !IsMobile ? (
                    <AdComponent
                        hasSpacings={false}
                        ad={ads.data[1]}
                    />
                ) : null
            }
            slotEnd={
                <div>
                    {trafficInfos?.recommendedNews?.length ? (
                        <TrafficSwiper
                            key={generateUniqueId()}
                            swiperEnabled
                            title={t('recommended_for_you')}
                            items={trafficInfos?.recommendedNews}
                            renderItem={(item: Article, index) => (
                                <Link
                                    to={`/aktuell/news/a/${item.id}/${prepareUrlTitle(item?.title)}`}
                                    className="block"
                                    key={index}
                                >
                                    <HorizontalMediumCard
                                        key={index}
                                        title={item.title}
                                        thumbnailUrl={
                                            doesTagContain(item.tags, 'master_filmsortien')
                                                ? getThumbnailForHorizontalArticleList(item)
                                                : getThumbnailForFilmSortien(item)
                                        }
                                        date={item.date}
                                        tags={item.tags}
                                        imageCover='object-cover'
                                        truncated
                                    />
                                </Link>
                            )}
                        />
                    ) : (
                        ''
                    )}
                    {ads && isAuthenticated && (
                        <AdComponent
                            ad={ads.data[4]}
                            preloadMovieJobAd
                        />
                    )}
                    {!!trafficInfos?.mostReadNews.length && (
                        <TrafficSwiper
                            key={generateUniqueId()}
                            swiperEnabled
                            title={t('most_read')}
                            items={trafficInfos.mostReadNews}
                            renderItem={(item: Article, index) => (
                                <Link
                                    to={`/aktuell/news/a/${item.id}/${prepareUrlTitle(item?.title)}`}
                                    className="block overflow-hidden"
                                    key={index}
                                >
                                    <HorizontalMediumCard
                                        className="min-h-[170px] md:min-h-[200px]"
                                        key={index}
                                        title={item.title}
                                        thumbnailUrl={
                                            doesTagContain(item.tags, 'master_filmsortien')
                                                ? getThumbnailForFilmSortien(item)
                                                : getThumbnailForHorizontalArticleList(item)
                                        }
                                        date={item.date}
                                        onClick={() => history.push(`/aktuell/news/a/${item.id}/${prepareUrlTitle(item?.title)}`)}
                                        truncated
                                        tags={item.tags}
                                    />
                                </Link>
                            )}
                        />
                    )}
                    {ads && !isAuthenticated && (
                        <AdComponent
                            ad={ads.data[4]}
                            preloadMovieJobAd
                        />
                    )}
                    {ads && isAuthenticated && <AdComponent ad={ads.data[5]} />}

                    {trafficInfos?.mightLikeNews?.length ? (
                        <TrafficSwiper
                            swiperEnabled
                            key={generateUniqueId()}
                            title={t('You_might_like')}
                            items={trafficInfos?.mightLikeNews}
                            renderItem={(item: Article, index) => (
                                <HorizontalMediumCard
                                    key={index}
                                    title={item.title}
                                    thumbnailUrl={getThumbnailForHorizontalArticleList(item)}
                                    date={item.date}
                                    tags={item.tags}
                                    className="w-[100%]"
                                    truncated
                                />
                            )}
                        />
                    ) : (
                        ''
                    )}
                </div>
            }
        />
    );
}

type NewsArticleProps = {
    isCompactView?: boolean;
};

NewsArticle.defaultProps = {
    isCompactView: false,
};
