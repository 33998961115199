import React, { useEffect, useMemo, useRef, useState } from 'react';
import useClickOutside from 'hooks/useClickOutside';
import { ReactComponent as CheckMarkIcon } from 'components/Icons/checkmark.svg';
import Input, { InputProps } from './Input';

export type SelectOption<T = any> = { label: string; value: T; categ?: string };
export type SelectProps<T = any> = InputProps & {
    options: SelectOption<T>[];
    roundedBottom?: boolean;
    onChange?: (value: SelectOption<T>) => void;
};

const Select = React.forwardRef<HTMLSelectElement, SelectProps>(
    ({ options, roundedBottom, onChange, ...inputProps }, ref) => {
        const [currentOptSelected, setCurrentOptSelected] = useState<SelectOption>(options[0]);
        const [open, setOpen] = useState(false);

        useEffect(() => {
            onChange?.(currentOptSelected);
        }, [currentOptSelected, onChange]);

        const selectRef = useClickOutside(() => {
            setOpen(false);
        }, []);

        function isSelected(opt: SelectOption) {
            return opt.value === currentOptSelected?.value;
        }

        const contentClassName = useMemo(() => {
            return `absolute top-[100%] left-0 w-max bg-background-alt rounded z-10 py-2 px-6 shadow-lg max-h-[300px] overflow-auto typo-b2 ̂${
                !open ? ' hidden' : ''
            }`;
        }, [open]);

        function getOptClassName(opt: SelectOption) {
            return `cursor-pointer relative w-full py-2 px-6 hover:font-bold ${isSelected(opt) ? ' font-bold' : ''}`;
        }

        const optsByCateg = useMemo(() => {
            const categs = new Set(options.filter((o) => o.categ != null).map((o) => o.categ));
            const res: { [categ: string]: SelectOption[] } = {};
            // eslint-disable-next-line no-restricted-syntax
            for (const categ of categs.keys()) {
                // @ts-ignore
                res[categ] = options.filter((o) => o.categ === categ);
            }
            return res;
        }, [options]);

        const hasCategs = useMemo(() => Object.keys(optsByCateg).length, [optsByCateg]);

        return (
            <div
                className="relative !cursor-pointer md:w-[525px]"
                ref={selectRef}
            >
                {/* @ts-ignore */}
                <Input
                    {...inputProps}
                    cursorPointer
                    onIconClick={() => setOpen((o) => !o)}
                    icon={
                        <div className="transform rotate-90 select-none">
                            {/* TODO: add real icon */}
                            {'>'}
                        </div>
                    }
                    readOnly
                    onClick={() => setOpen((o) => !o)}
                    value={currentOptSelected?.label || ''}
                    roundedBottom={roundedBottom}
                />
                <div className={contentClassName}>
                    {hasCategs
                        ? Object.keys(optsByCateg).map((key) => (
                              <div key={key}>
                                  <div className="text-[#8a8a8a]">{key}</div>
                                  {optsByCateg[key].map((opt) => (
                                      <div
                                          key={opt.value}
                                          className={getOptClassName(opt)}
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              setCurrentOptSelected(opt);
                                              setOpen(false);
                                          }}
                                      >
                                          {isSelected(opt) ? (
                                              <CheckMarkIcon className="[&>path]:fill-white absolute left-0 top-1/2 transform -translate-y-1/2" />
                                          ) : (
                                              ''
                                          )}

                                          {opt.label}
                                      </div>
                                  ))}
                              </div>
                          ))
                        : ''}
                    {!hasCategs
                        ? options.map((opt) => (
                              <div
                                  key={opt.value}
                                  className={getOptClassName(opt)}
                                  onClick={(e) => {
                                      e.stopPropagation();
                                      setCurrentOptSelected(opt);
                                      setOpen(false);
                                  }}
                              >
                                  {opt.label}
                              </div>
                          ))
                        : ''}
                </div>
                <select
                    ref={ref}
                    className="hidden"
                    value={currentOptSelected?.value || ''}
                >
                    {options.map((opt) => (
                        <option
                            key={opt.value}
                            value={opt.value}
                            className="!cursor-pointer"
                        >
                            {opt.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    },
);

export default Select;
