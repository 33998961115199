import { useEffect, useMemo, useState } from 'react';
import { chevronForwardOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';

export type PaginationProps = {
    currentPage: number;
    onPageChange: (n: number) => void;
    numberOfPages: number;
};

export default function Pagination({ currentPage, onPageChange, numberOfPages }: PaginationProps) {
    const [displayedPageButtons, setDisplayedPageButtons] = useState<Array<number>>([]);
    const allPageButtons: Array<number> = useMemo(() => {
        const arr = Array.from({ length: numberOfPages }, (_, i) => i + 1);
        arr.shift();
        return arr;
    }, [numberOfPages]);

    const [currentPageBtnFirstIndex, setCurrentPageBtnFirstIndex] = useState(0);

    useEffect(() => {
        setDisplayedPageButtons(allPageButtons.slice(currentPageBtnFirstIndex, currentPageBtnFirstIndex + 4));
    }, [currentPageBtnFirstIndex]);

    const handleClickPage = (page: number) => {
        if (page === currentPage) return;
        onPageChange(page);
        if (page === 1) {
            setCurrentPageBtnFirstIndex(0);
        } else if (page === numberOfPages) {
            setCurrentPageBtnFirstIndex(allPageButtons.length - 5);
        }
        document.querySelector('ion-content')?.scrollToTop();
    };

    const goForward = () => {
        if (currentPage === numberOfPages) {
            onPageChange(1);
            setCurrentPageBtnFirstIndex(0);
        } else {
            if (+currentPage === +displayedPageButtons[displayedPageButtons.length - 1]) {
                setCurrentPageBtnFirstIndex(currentPageBtnFirstIndex + 1);
            }
            onPageChange(currentPage + 1);
        }
        document.querySelector('ion-content')?.scrollToTop();
    };

    const goBack = () => {
        if (currentPage === 1) {
            onPageChange(numberOfPages);
            setCurrentPageBtnFirstIndex(0);
        } else {
            if (+currentPage === +displayedPageButtons[0]) {
                setCurrentPageBtnFirstIndex(allPageButtons.length - 1);
            }
            onPageChange(currentPage - 1);
        }
        document.querySelector('ion-content')?.scrollToTop();
    };

    return (
        <div className="flex gap-[10px] items-center justify-center">
            <button
                onClick={goBack}
                className="grid place-items-center"
            >
                <IonIcon
                    icon={chevronForwardOutline}
                    color="white"
                    className="transform rotate-180"
                />
            </button>
            {[1, ...displayedPageButtons, allPageButtons[allPageButtons.length - 1]].map((page, index) => (
                <button
                    key={index}
                    onClick={() => handleClickPage(page)}
                    className={`${
                        +currentPage === +page
                            ? 'bg-primary text-white'
                            : 'bg-light-background text-[var(--eldo-disabled)]'
                    } rounded w-[32px] h-[32px] grid place-items-center text-center typo-b3 regular`}
                >
                    {page}
                </button>
            ))}
            <button
                onClick={goForward}
                className="grid place-items-center"
            >
                <IonIcon
                    icon={chevronForwardOutline}
                    color="white"
                    className=""
                />
            </button>
        </div>
    );
}
