/* eslint-disable jsx-a11y/anchor-is-valid */
import Card from 'components/Card';
import { NotificationIcon } from 'components/Icons';
import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import useTrafficInfos from './useTrafficInfo';
import Switch from '../../components/Switch';

export function transformTagsIntoObject(tags: string[]) {
    return tags.map((tag) => {
        return { tag, name: tag };
    });
}

export default function TrafficNews() {
    const { t } = useTranslation();
    const { trafficInfos } = useTrafficInfos();

    const [newsAlerts, setNewsAlerts] = useState<boolean>(true);

    return (
        <div>
            <section>
                <Title title={t('notification_alerts')} />

                <div className="mt-block-innerMobile md:mt-block-inner">
                    <Card className="flex justify-between items-center md:w-1/2">
                        <div className="flex justify-between items-center">
                            <NotificationIcon />
                            <p className="ml-4 typo-b1 regular">{t('news_alerts')}</p>
                        </div>
                        <Switch
                            checked={newsAlerts}
                            onChange={() => setNewsAlerts((n) => !n)}
                        />
                    </Card>
                </div>
            </section>

            <section className="mt-blockMobile md:mt-block">
                <div className="flex gap-[10px] items-center">
                    <Title title={t('last_road_news')} />
                    <div>
                        <img
                            src="/assets/img/ACL.png"
                            alt="sponsor"
                            className="w-[50px]"
                        />
                    </div>
                </div>
                <div className="mt-blockInnerMobile md:mt-blockInner flex flex-col gap-4 md:gap-8 md:grid md:grid-cols-3">
                    {trafficInfos?.roadNews.map((n) => (
                        <HorizontalMediumCard
                            displayTime
                            title={n.title}
                            className="h-full"
                            date={n.date}
                            tags={transformTagsIntoObject(n.tags)}
                            clickable={false}
                            noImage
                        />
                    ))}
                </div>
            </section>
        </div>
    );
}
