import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useIonRouter } from '@ionic/react';
import PageLayout from '../components/PageLayout';
import SignupForm from '../components/SignupForm';
import Section from '../components/core/Section';

export default function SignupPage() {
    const history = useHistory();

    const router = useIonRouter();

    return (
        <PageLayout hasBackButton>
            <Section>
                <SignupForm
                    onLoginClick={() => router.push('/login')}
                    onComplete={() => history.replace('/login')}
                    className="lg:mt-[53px] max-w-[350px]"
                />
            </Section>
        </PageLayout>
    );
}
