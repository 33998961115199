import TheoPlayerCustom from 'components/TheoplayerCustom';
import { useEffect, useRef } from 'react';

type InPageProps = {
    adSrc: string;
    nameOfElementId: string;
};

function removeParameters(url: string) {
    // Split the URL into parts before and after the '?'
    const [baseUrl, queryString] = url.split('?');

    if (!queryString) {
        // If there's no query string, return the original URL as-is
        return url;
    }

    // Split the query string into individual parameters
    const parameters = queryString.split('&');

    // Filter out parameters to remove (in this case, 'fwd_permutive' and 'consent')
    const filteredParameters = parameters.filter((parameter) => {
        const [paramName] = parameter.split('=');
        return paramName !== 'fwd_permutive' && paramName !== 'consent';
    });

    if (filteredParameters.length === 0) {
        // If all parameters were removed, return the base URL without a query string
        return baseUrl;
    }

    // Reconstruct the URL with the remaining parameters
    const updatedUrl = `${baseUrl}?${filteredParameters.join('&')}`;

    return updatedUrl;
}

function InPage({ adSrc, nameOfElementId }: InPageProps) {
    const divInpage = useRef<HTMLDivElement>(null);
    const scriptRef = useRef<HTMLScriptElement | null>(null);

    useEffect(() => {
        const currentDiv = divInpage.current;

        if (adSrc !== '') {
            const modifiedSrc = `${removeParameters(adSrc)}&elementId=${nameOfElementId}&bitrate=1500`;

            // Create a new script element
            const script = document.createElement('script');
            script.async = true;
            script.src = modifiedSrc;

            // Append the script to the div
            if (currentDiv) {
                currentDiv.appendChild(script);
                scriptRef.current = script; // Store a reference to the script element
            }

            // Cleanup: Remove the script when the component unmounts
            return () => {
                if (currentDiv && scriptRef.current) {
                    currentDiv.removeChild(scriptRef.current);
                    scriptRef.current = null; // Clear the reference
                }
            };
        }
        return () => {};
    }, []);

    return adSrc !== '' ? (
        <div ref={divInpage}>
            <div
                id={nameOfElementId}
                className="!max-h-[450px] aspect-[16/9] md:mb-block mb-blockMobile mx-auto"
            />
        </div>
    ) : null;
}

export default InPage;
