import { useQuery } from '@tanstack/react-query';
import { useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { getCharts } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useCharts(chart: string) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const { data, status } = useQuery([chart], () => getCharts(chart), {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        data,
        isLoading: status === 'loading',
    };
}
