import { Device } from '@capacitor/device';
import { Preferences } from '@capacitor/preferences';
import { isPlatform } from '@ionic/react';
import { TOKEN_IDENTIFIER } from 'features/auth/AuthContext';
import { decodeJWT, isValidJwt } from 'lib/JWTService';
import { Md5 } from 'ts-md5';

interface NetworkInformation {
    readonly type: string;
    readonly effectiveType: string;
}

declare global {
    interface Navigator {
        readonly connection?: NetworkInformation;
    }
}

export type AdPayload = {
    device: {
        type: string;
        carrier?: string;
        screen: {
            density: number;
            orientation: string;
            width: number;
            height: number;
        };
    };
    data: {
        articleId?: string;
        breadcrumb: string;
        playgroundId?: string;
        tags?: string[];
    };
    source: {
        page: string;
        type: string;
    };
    userConsent: {
        gdpr: string;
        consentString?: string;
        usi: string;
        sessionId: string;
    };

    video: any;
};

export type AdData = {
    gdpr: number;
    consentString: string;
    usi: number;
    sessionId: number;
    containerId: string;
    auId: string;
    c: string;
    kv: any;
    url: string;
    container: string;
};

export type AdResponse = {
    data: AdData[];
};

export async function getDeviceType() {
    const info = await Device.getInfo();
    const { platform } = info;
    const isTablet =
        /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
            navigator.userAgent.toLowerCase(),
        );
    if (isTablet || isPlatform('tablet')) {
        return 'tablet';
    }
    if (platform === 'web') {
        // Check if the user agent contains 'Mobile' to identify mobile devices
        if (/Mobi|Android/i.test(navigator.userAgent)) {
            return 'mobile';
        }
        return 'desktop';
    }
    // For non-web platforms (native apps), you can differentiate between tablet and mobile based on the platform
    if (platform === 'ios' || platform === 'android') {
        return 'mobile';
    }
    return 'tablet';
}

export async function getPlatformForGemius() {
    return getDeviceType().then((device) => {
        if (isPlatform('cordova')) {
            if (isPlatform('ios')) {
                if (device === 'tablet') {
                    return 'ipad';
                }
                return 'iphone';
            }
            if (isPlatform('android')) {
                if (device === 'tablet') {
                    return 'androidt';
                }
                return 'android';
            }
        }
        return device;
    });
}

function getWindowOrientation(): string {
    const { innerWidth, innerHeight } = window;
    return innerWidth > innerHeight ? 'landscape' : 'portrait';
}

function generateUsi() {
    const timestamp = Date.now();
    const randomNumber = Math.floor(Math.random() * 10001);
    return `${timestamp}${randomNumber}`;
}

async function getUsi() {
    const identifier = (await Preferences.get({ key: TOKEN_IDENTIFIER }))?.value;
    if (identifier) {
        if (isValidJwt(identifier)) {
            return Md5.hashStr(decodeJWT(identifier)?.email || '');
        }
        return identifier;
    }
    const newUsi = generateUsi();
    await Preferences.set({ key: TOKEN_IDENTIFIER, value: newUsi });
    return newUsi;
}

function getIpDigId() {
    const dateNow = Date.now();
    let ipdigId = localStorage.getItem('ipdig-id');

    if (ipdigId === null) {
        ipdigId = `ipdig${dateNow.toString(36)}${Math.random()
            .toString(36)
            .replace(/[^a-z0-9]+/g, '')}`;
        localStorage.setItem('ipdig-id', ipdigId);
    }

    let ipdigSession: any | null = sessionStorage.getItem('ipdig-data');
    if (ipdigSession !== null) {
        ipdigSession = JSON.parse(ipdigSession);
    }

    const last30MinutesAgo = new Date();
    last30MinutesAgo.setMinutes(last30MinutesAgo.getMinutes() - 30);

    let ipdigSessionId: string;

    if (ipdigSession === null || ipdigSession.time === null || ipdigSession.time - last30MinutesAgo.getTime() < 0) {
        ipdigSessionId = `ipdigsess${dateNow.toString(36)}${Math.random()
            .toString(36)
            .replace(/[^a-z0-9]+/g, '')}`;
        sessionStorage.setItem('ipdig-data', JSON.stringify({ sessionId: ipdigSessionId, time: dateNow }));
    } else {
        ipdigSessionId = ipdigSession.sessionId;
    }

    return { id: ipdigId, session: ipdigSessionId };
}

function joinWithCommas(arrayOfStrings: string[] | null) {
    if (!Array.isArray(arrayOfStrings)) {
        return '';
    }

    return arrayOfStrings.join(',');
}

export default async function generateAdPayload(
    page: string,
    breadcrumb: number,
    path?: string,
    tags?: string[],
    articleId?: string,
    videoType: 'audio' | 'video' = 'video',
): Promise<AdPayload> {
    const deviceType = await getDeviceType();
    const consentStatus = await Preferences.get({ key: 'consentStatus' });
    const constentAds =
        JSON.parse(consentStatus?.value ?? '{"consent": false}')?.consent &&
        (localStorage.getItem('appTrackingTransparency') === '1' ||
            localStorage.getItem('appTrackingTransparency') === null);
    const didomiToken = localStorage.getItem('didomi_token') || '1';
    const euConsent = localStorage.getItem('euconsent-v2') || '';
    const playgroundValue = (await Preferences.get({ key: 'playground' })).value ?? '';
    const info = await Device.getInfo();
    const { platform } = info;

    if ('connection' in navigator) {
        const connection = navigator.connection as NetworkInformation;
        // this does not seem to work yet
        const carrierName = connection.effectiveType || connection.type || '';
    }

    return {
        device: {
            type: deviceType,
            ...(deviceType === 'mobile' ? { carrier: '--' } : {}),
            screen: {
                density: 1,
                orientation: getWindowOrientation(),
                width: window.innerWidth,
                height: window.innerHeight,
            },
        },
        data: {
            articleId: articleId ?? '0', // this needs to be used
            breadcrumb: path?.slice(1) && path?.slice(1) !== '' ? path?.slice(1) : 'home',
            playgroundId: playgroundValue,
            tags: tags ?? [], // this needs to be used
        },
        source: {
            page,
            type: platform === 'ios' || platform === 'android' ? 'app' : 'website',
        },
        userConsent: {
            gdpr: constentAds ? '1' : '0',
            consentString: euConsent || 'not_decided',
            usi: await getUsi(),
            sessionId: didomiToken,
        },
        video: {
            type: videoType,
            mediaDuration: 'infinite',
            contentId: articleId ?? '0',
            ipdig: getIpDigId().id,
            ...(JSON.parse(localStorage.getItem('_pdfps') ?? '{}').length !== 0
                ? { permutive: joinWithCommas(JSON.parse(localStorage.getItem('_pdfps') ?? '{}')) }
                : { permutive: '{}' }),
        },
    };
}

export function generateAdnAdPayload(
    ads: AdData[],
    requestParams: any = { proximity: 100, load: 'lazyRequest' },
    targetId?: string,
    additionalParams?: any,
) {
    const adUnits = ads
        .filter((ad) => ad.container !== 'inpage')
        .map((ad) => {
            const { kv, auId, c, consentString, containerId, sessionId, gdpr } = ad;

            const kvComputed: { [key: string]: any }[] = [{ playgroundID: kv?.playgroundId || '' }];
            delete kv.playgroundId;
            delete kv.carriername;

            const userSegments = kv.permutive
                ? {
                      PERMUTIVE: (kv.permutive || '').split(',').splice(0, 750),
                  }
                : undefined;
            delete kv.permutive;

            // eslint-disable-next-line no-restricted-syntax
            for (const key of Object.keys(kv)) {
                kvComputed.push({ [key]: kv[key] });
            }

            const res: any = {
                auId,
                targetId: targetId || `ad-${containerId}`,
                kv: kvComputed,
                clearTarget: 'false',
                gdpr,
                sessionId,
                c,
                consentString,
                userSegments,
            };
            if (requestParams) {
                res.requestParams = requestParams;
            }
            return res;
        });
    return {
        adUnits,
        protocol: 'https',
        ...(additionalParams || {}),
    };
}
