import { IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';
import Title from 'components/Title';
import { ReactComponent as PhoneSvg } from 'components/Icons/phone.svg';
import { ReactComponent as WhatsappSvg } from 'components/Icons/whatsapp.svg';
import { ReactComponent as EmailSvg } from 'components/Icons/email.svg';
import TeamMemberCard from 'features/teamInfo/TeamMemberCard';
import useTeamPageInfo from 'features/teamInfo/useTeamPageInfo';
import useAds from 'hooks/useAds';
import LoadingIndicator from 'components/LoadingIndicator';
import AdComponent from 'components/Ads/AdComponent';
import { useTranslation } from 'react-i18next';
import PageLayout from 'components/PageLayout';
import WavePatternContainer from 'components/WavePatternContainer';
import { htmlObfuscateForBots, urlObfuscateForBots } from 'lib/utils';
import AdRightBlock from 'components/Ads/AdRightBlock';

export default function TeamPage() {
    const { ads } = useAds({ page: 'team' });
    const { t } = useTranslation();
    const { contacts, persons, isLoading } = useTeamPageInfo();

    return (
        <PageLayout title={t('Team')}>
            <IonGrid fixed>
                <WavePatternContainer
                    autoMargin
                    fixed="page"
                    reverse
                    position="top-right"
                    className="md:mb-block mb-blockMobile"
                    component="section"
                >
                    {ads && <AdComponent ad={ads.data[0]} />}
                    <IonRow>
                        <IonCol className="p-0 md:mb-block mb-blockMobile">
                            <div className="md:mb-blockInner mb-blockInnerMobile max-md:hidden">
                                <Title
                                    title={t('Team')}
                                    main
                                />
                            </div>
                            {contacts?.phone ? (
                                <a
                                    href={`tel:${urlObfuscateForBots(contacts?.phone)}`}
                                    className="flex items-center gap-[8px] md:gap-[14px] mb-[14px] typo-b1"
                                >
                                    <div className="h-[25px] w-[25px] grid place-items-center">
                                        <PhoneSvg />
                                    </div>
                                    <span dangerouslySetInnerHTML={{ __html: htmlObfuscateForBots(contacts?.phone) }} />
                                </a>
                            ) : (
                                ''
                            )}

                            <div className="flex items-center justify-start gap-[8px] md:gap-[14px] mb-[14px] typo-b1">
                                <div className="h-[25px] w-[25px] grid place-items-center">
                                    <WhatsappSvg />
                                </div>
                                <span
                                    dangerouslySetInnerHTML={{ __html: htmlObfuscateForBots(contacts?.whatsapp || '') }}
                                />
                            </div>

                            {contacts?.studioPhone ? (
                                <a
                                    href={`tel:${urlObfuscateForBots(contacts?.studioPhone)}`}
                                    className="flex items-center gap-[8px] md:gap-[14px] mb-[14px] typo-b1"
                                >
                                    <div className="h-[25px] w-[25px] grid place-items-center">
                                        <PhoneSvg />
                                    </div>
                                    Studio:
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: htmlObfuscateForBots(contacts?.studioPhone),
                                        }}
                                    />
                                </a>
                            ) : (
                                ''
                            )}
                            {contacts?.emails.map((email, index) => (
                                <a
                                    key={index}
                                    href={`mailto:${urlObfuscateForBots(email?.email || '')}`}
                                    className="flex items-center justify-start gap-[8px] md:gap-[14px] mb-[14px] typo-b1"
                                >
                                    <div className="h-[25px] w-[25px] grid place-items-center">
                                        <EmailSvg />
                                    </div>
                                    <span>{email?.label}:</span>
                                    <span
                                        dangerouslySetInnerHTML={{ __html: htmlObfuscateForBots(email?.email || '') }}
                                    />
                                </a>
                            ))}
                        </IonCol>
                    </IonRow>

                    {!isLoading ? (
                        <AdRightBlock ad={ads?.data?.[1]}>
                            <div className="grid md:grid-cols-2 gap-standardMobile md:gap-standard">
                                {persons?.map((person, index) => (
                                    <a
                                        className="overflow-hidden max-w-full"
                                        href={`mailto:${urlObfuscateForBots(person?.mail || '')}`}
                                    >
                                        <TeamMemberCard
                                            src={person.image}
                                            name={`${person.firstName} ${person.lastName}`}
                                            job={person.job}
                                            description={person.description}
                                            key={person.id}
                                        />
                                    </a>
                                ))}
                            </div>
                        </AdRightBlock>
                    ) : (
                        <LoadingIndicator />
                    )}
                </WavePatternContainer>
                <div className="relative">
                    <div className="absolute top-[-500px] -z-10 max-md:hidden">
                        <img
                            src="./assets/img/team/Vector-1.png"
                            alt="vector"
                        />
                    </div>
                </div>

                {ads && <AdComponent ad={ads.data[2]} />}
            </IonGrid>
        </PageLayout>
    );
}
