import { useTranslation } from 'react-i18next';
import TheoPlayerCustom from './TheoplayerCustom';

export type AudioPlayerProps = {
    src: string;
    text?: string;
    ad?: string;
    type?: string;
    mainAudio?: boolean;
};

export default function AudioPlayer({ src, text, ad, type, mainAudio = false }: AudioPlayerProps) {
    const { t } = useTranslation();

    return (
        <div
            className={`bg-[#282828] md:w-[350px] rounded px-[30px] py-[20px] flex items-center gap-4 relative overflow-hidden before:absolute before:left-0 before:top-0 before:h-full before:bg-[var(--eldo-primary)] ${
                mainAudio ? '' : 'before:w-[3px]'
            }`}
        >
            {/* This needs to be put inside audio ads if the audio ads are ready adSrc={ad} */}
            <TheoPlayerCustom
                src={src}
                audio
                type={type || (src.endsWith('m3u8') ? 'application/x-mpegurl' : 'audio/mpeg')}
                mainAudio={mainAudio}
                className={mainAudio ? 'mx-auto' : ''}
            />
            {!mainAudio && <div>{text || t('main.play')}</div>}
        </div>
    );
}
