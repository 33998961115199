import moment from 'moment';
import { IonChip } from '@ionic/react';
import React, { useMemo, memo } from 'react';
import { useTranslation } from 'react-i18next';
import TextScrollIfNeeded from 'components/core/TextScrollIfNeeded';
import { Radio } from '../types';

const RadioSlideContent = memo(({ data }: { data: Radio | undefined }) => {
    const { t } = useTranslation();

    const displayShow = useMemo(
        () => (
            <>
                <TextScrollIfNeeded className="typo-b1 regular">{data?.show?.name}</TextScrollIfNeeded>
                <div className="small typo-b2 regular">
                    {moment(data?.show?.startDate).format('HH:mm')} - {moment(data?.show?.endDate).format('HH:mm')}
                </div>
            </>
        ),
        [data?.show],
    );

    const displaySong = useMemo(() => {
        data?.song?.map((song) => {
            return (
                <>
                    <div>{song?.title}</div>
                    <b>{song?.artist}</b>
                </>
            );
        });
    }, [data?.song]);

    return (
        <>
            <IonChip className="mb-[10px] w-fit bg-primary">{t('on_air')}</IonChip>
            <div className="grid place-items-start">
                {data?.show && displayShow}
                {!data?.show && data?.song && displaySong}
                {!data?.show && !data?.song && <div>Live Radio</div>}
            </div>
        </>
    );
});

export default function RadioSlide({ className, data }: { className?: string; data: Radio | undefined }) {
    return (
        <div className={`${className || ''} text-left`}>
            <RadioSlideContent data={data} />
        </div>
    );
}
