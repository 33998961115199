export default function NumberBadge({ children }: { children: number }) {
    const isBigNumber = Number(children) > 99;

    return (
        <div
            className={`h-[30px] bg-primary text-black grid place-items-center typo-b1 medium ${
                isBigNumber ? 'rounded px-1' : 'w-[30px] rounded-full '
            }`}
        >
            {children}
        </div>
    );
}
