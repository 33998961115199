import { IonGrid, IonLoading, IonRow } from '@ionic/react';
import { useContext, useEffect, useMemo, useState } from 'react';

import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import useTopicCategories from 'features/topics/hooks/useTopicCategories';
import LoadingIndicator from 'components/LoadingIndicator';
import PageLayout from '../components/PageLayout';
import ShowSwiper from '../features/shows/components/ShowSwiper';
import LastSongs from '../features/radio/component/LastSongs';
import RadioCard from '../components/Cards/RadioCard';
import useShows from '../features/shows/hooks/useShows';
import { Presenter } from '../features/radio/types';

import { RadioContext, RadioContextType } from '../features/radio/RadioContext';
import { PlayerContext, PlayerContextType } from '../features/radio/PlayerContext';
import useChannels from '../features/radio/hooks/useChannels';
import BestMomentsSwiper from '../features/bestMoments/components/Eldo4RealSwiper';
import TopicCategoriesList from '../features/topics/components/TopicCategoriesList';
import { ShowPageInfoItem } from '../types';
import Section from '../components/core/Section';
import PageNoData from './PageNoData';

export default function ShowsPage() {
    const { data: shows, isLoading, error } = useShows();
    const { data: webChannels } = useChannels();
    const { radio } = useContext(RadioContext) as RadioContextType;
    const { isLoading: isLoadingCategories, categories } = useTopicCategories();
    const { playerState, playerDispatch } = useContext<PlayerContextType | null>(PlayerContext) as PlayerContextType;
    const history = useHistory();
    const { t } = useTranslation();

    const [currentShow, setCurrentShow] = useState<ShowPageInfoItem | null>(shows?.length ? shows[0] : null);
    const { ads } = useAds({ page: 'shows', reloadOnChangeString: currentShow?.show.name });

    useEffect(() => {
        if (!currentShow && shows?.length) {
            setCurrentShow(shows[0]);
        }
    }, [shows]);

    const presenters: string = useMemo(
        () => radio?.presenters.map((presenter: Presenter) => presenter?.name).join(' - ') || '',
        [radio],
    );

    const isCurrentShowTheCurrentEldoShowPlaying = currentShow?.show?.name === radio?.show?.name;

    if (!shows && !isLoading) {
        return <PageNoData />;
    }

    return (
        <PageLayout title="Shows">
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}

                {!isLoading ? (
                    <>
                        <section>
                            <button
                                onClick={() => {
                                    history.push('/radio/shows/all');
                                }}
                                className="px-3 py-2 border-2 rounded-[10px] border-solid primary hover:bg-primary hover:text-black mb-blockInnerMobile md:mb-blockInner"
                            >
                                {t('loadAllShows')}
                            </button>
                            {currentShow && (
                                <ShowSwiper
                                    shows={shows || []}
                                    setCurrentShow={setCurrentShow}
                                    currentShow={currentShow}
                                />
                            )}
                        </section>
                        {currentShow && (
                            <Section className="flex max-md:flex-col gap-standardMobile md:gap-standard">
                                <div>
                                    <div>
                                        <RadioCard
                                            src={currentShow?.show.imageUrl || currentShow?.crew.imageUrl || ''}
                                            title={currentShow?.show?.name || ''}
                                            liveStart={currentShow?.startTime}
                                            liveEnd={currentShow.endTime}
                                            showRunner={currentShow?.crew?.name || ''}
                                            isPlaying={playerState.isPlaying && playerState.type === 'RADIO'}
                                            onPlayerClick={() => {
                                                playerDispatch({
                                                    type: 'TOGGLE_RADIO',
                                                });
                                            }}
                                            isVertical
                                            displayWatchBtn={isCurrentShowTheCurrentEldoShowPlaying}
                                            displayPlayBtn={isCurrentShowTheCurrentEldoShowPlaying}
                                            className="max-w-[730px]"
                                            sponsor={currentShow.show.sponsor?.image}
                                        />

                                        {currentShow.show?.description && (
                                            <p className="typo-b1 regular px-4 py-8">{currentShow.show.description}</p>
                                        )}
                                    </div>

                                    <div className="mt-block-innerMobile md:mt-block-inner">
                                        {webChannels && <LastSongs songs={radio?.song ?? []} />}
                                    </div>
                                </div>
                                {ads && (
                                    <div className="min-w-[300px]">
                                        <AdComponent
                                            ad={ads.data[1]}
                                            hasSpacings={false}
                                        />
                                    </div>
                                )}
                            </Section>
                        )}
                    </>
                ) : (
                    <LoadingIndicator />
                )}

                <Section
                    title={t('eldo4real')}
                    path="/podcast-replay/category/eldo4real"
                >
                    <BestMomentsSwiper />
                </Section>
                <IonRow>{!isLoadingCategories && <TopicCategoriesList categories={categories || []} />}</IonRow>
                {ads && <AdComponent ad={ads.data[2]} />}
            </IonGrid>
        </PageLayout>
    );
}
