import { InstagramEmbed } from 'react-social-media-embed';

function InstagramEmbedComponent({ html }: { html: string }) {
    // Define a function to extract the Instagram URL from the provided HTML string
    const extractInstagramURL = (htmlString: string) => {
        const regex = /https:\/\/www.instagram.com\/(?:p|reel)\/[^/]+/g;
        const match = htmlString.match(regex);
        return match ? match[0] : null;
    };

    // Extract the Instagram URL from the provided HTML string
    const instagramURL = extractInstagramURL(html);

    if (!instagramURL) {
        return null;
    }

    return (
        <div className="mx-auto max-w-md">
            <div className="[&_.instagram-media]:!min-w-[300px] [&_.instagram-media]:!mx-auto">
                <InstagramEmbed url={instagramURL} />
            </div>
        </div>
    );
}

export default InstagramEmbedComponent;
