import PageLayout from 'components/PageLayout';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Input from 'components/Input';
import PhoneInput from 'components/PhoneInput';
import { ErrorMessage } from '@hookform/error-message';
import InputError from 'components/InputError';
import { useEffect } from 'react';
import EditAccount from 'features/auth/components/EditAccount';
import useMyAccount from 'features/auth/useMyAccount';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { format } from 'date-fns';
import { User } from '../features/auth/types';

export default function EditAccountInfoPage() {
    const { t } = useTranslation();

    const schema = yup
        .object({
            firstname: yup.string().required(t('firstname_required')),
            lastname: yup.string().required(t('lastname_required')),
            birthday: yup.string().required(t('birthday_required')),
        })
        .required();

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
        control,
    } = useForm<User>({
        resolver: yupResolver(schema),
    });

    const { user, update, refetch } = useMyAccount();

    useEffect(() => {
        if (user) {
            reset({
                ...user,
                birthday: user.birthday ? format(new Date(user.birthday), 'yyyy-MM-dd') : '',
            });
        }
    }, [user]);

    async function onSubmit(data: User) {
        const newData = { ...user, ...data };
        // @ts-ignore
        delete newData.email;
        if (newData.birthday?.length) {
            newData.birthday = format(new Date(newData.birthday), 'yyyy-MM-dd');
        }
        await update.mutateAsync(newData);
        refetch();
    }

    return (
        <EditAccount
            saveBtnText={t('save')}
            title={t('my-informations')}
            onSubmit={handleSubmit(onSubmit)}
        >
            <Input
                hasError={!!errors.firstname}
                placeholder={t('firstname')}
                {...register('firstname')}
            />
            <ErrorMessage
                errors={errors}
                name="firstname"
                as={<InputError />}
            />
            <Input
                hasError={!!errors.lastname}
                placeholder={t('lastname')}
                {...register('lastname')}
            />
            <ErrorMessage
                errors={errors}
                name="lastname"
                as={<InputError />}
            />

            <Input
                type="date"
                hasError={!!errors.birthday}
                placeholder={t('birthday')}
                {...register('birthday')}
            />
            <ErrorMessage
                errors={errors}
                name="birthday"
                as={<InputError />}
            />

            <PhoneInput
                placeholder={t('phone')}
                {...register('phone')}
                control={control}
            />
            <Input
                placeholder={t('address_and_number')}
                {...register('address')}
            />
            <Input
                placeholder={t('zip')}
                {...register('zip')}
            />
            <Input
                placeholder={t('city')}
                {...register('city')}
            />
            <Input
                placeholder={t('country')}
                {...register('country')}
            />
        </EditAccount>
    );
}
