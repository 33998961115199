import { useEffect, useState } from 'react';
import { TwitterTweetEmbed } from 'react-twitter-embed';

function TwitterEmbed({ tweetHtml }: { tweetHtml: string }) {
    const [tweetId, setTweetId] = useState('');

    useEffect(() => {
        const tweetIdRegex = /twitter\.com\/\w+\/status\/(\d+)/;

        const match = tweetHtml.match(tweetIdRegex);

        if (match && match[1]) {
            // Set the tweet ID in the component state
            setTweetId(match[1]);
        }
    }, [tweetHtml]);

    return (
        <div>
            {tweetId && (
                <div className="mx-auto max-w-md">
                    <TwitterTweetEmbed tweetId={tweetId} />
                </div>
            )}
        </div>
    );
}

export default TwitterEmbed;
