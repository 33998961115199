import Pill from 'components/Pill';
import { ReactComponent as ShopCartIcon } from 'components/Icons/shop-cart.svg';
import { Link } from 'react-router-dom';
import { generateUniqueId } from 'lib/utils';

export type ShopCardProps = {
    title: string;
    tags: string[];
    price: number;
    discount?: {
        priceWithoutDiscount: number;
        discount: number;
        discountType: 'percent' | 'amount';
    };
    currency: string;
    image: {
        src: string;
        alt: string;
    };
    link: string;
};

export default function ShopCard({ currency, discount, price, link, tags, title, image }: ShopCardProps) {
    return (
        <div className="bg-light-background rounded p-[13px] w-[255px] md:mx-0 mx-auto">
            <img
                src={image.src}
                alt={image.alt}
                className="h-[300px] object-cover w-full rounded mb-4"
            />
            <div className="w-full flex justify-between items-center">
                <div className="flex items-center gap-2">
                    {tags.map((tag) => (
                        <Pill key={generateUniqueId()}>{tag}</Pill>
                    ))}
                </div>
                <a
                    href={link}
                    target="_blank"
                    rel="noreferrer"
                    className="bg-[var(--eldo-primary)] w-[45px] h-[45px] rounded-full grid place-items-center aspect-square transform transition-all hover:opacity-95 md:hover:scale-[110%]"
                >
                    <ShopCartIcon />
                </a>
            </div>
            <div className="typo-b1 font-semibold line-clamp-2 mt-1">{title}</div>
            {/* <div className='mt-3'>
                {discount != null ? (
                    <div className='flex items-center gap-2'>
                        <div className='typo-b2 line-through'>
                            {discount.priceWithoutDiscount} {currency}
                        </div>
                        <div className='text-[var(--eldo-primary)]'>
                            -{discount.discount}
                            {discount.discountType === 'percent' ? '%' : currency}
                        </div>
                    </div>
                ) : (
                    ''
                )}

                <div className='typo-b2 font-semibold'>
                    {price} {currency}
                </div>
            </div> */}
        </div>
    );
}
