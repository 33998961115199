import { IonCard } from '@ionic/react';

type TeamMemberCardProps = {
    src?: string;
    name?: string;
    job?: string;
    description?: string;
};

function TeamMemberCard({ src, name, job, description }: TeamMemberCardProps) {
    return (
        <IonCard className="m-0 group/hovercard cursor-pointer h-full">
            <div className="overflow-hidden rounded">
                <img
                    alt={name}
                    src={src}
                    className="object-cover object-top w-full rounded h-[250px] transition-all duration-300 transform md:group-hover/hovercard:scale-110"
                />
            </div>
            <div className="p-[10px] flex-1 flex justify-between">
                <div className="text-left w-full">
                    <div className="font-semibold typo-b1 tracking-[0.5px] leading-6 w-full">{name}</div>
                    <div className="typo-b2 truncate w-full">{job}</div>
                    <div className="typo-b1 mt-[15px] w-full">{description}</div>
                </div>
            </div>
        </IonCard>
    );
}

export default TeamMemberCard;
