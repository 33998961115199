import { IonGrid, IonRow } from '@ionic/react';
import PageLayout from 'components/PageLayout';
import Title from 'components/Title';
import useResponsive from 'features/responsive/responsive';
import AdComponent from 'components/Ads/AdComponent';
import useAds from 'hooks/useAds';
import ShopCard, { ShopCardProps } from 'features/shop/ShopCard';
import { useTranslation } from 'react-i18next';
import AdRightBlock from 'components/Ads/AdRightBlock';
import { generateUniqueId } from 'lib/utils';

export default function ShopPage() {
    const { ads } = useAds({ page: 'shop' });
    const { t } = useTranslation();
    const { IsDesktop } = useResponsive();

    const shopCards: ShopCardProps[] = [
        // {
        //     currency: '€',
        //     discount: {
        //         discount: 10,
        //         priceWithoutDiscount: 12.5,
        //         discountType: 'percent'
        //     },
        //     price: 11.25,
        //     tags: ['shop'],
        //     title: 'Eldo Popsocket',
        //     image: {
        //         src: '/assets/img/shop/popsocket.jpg',
        //         alt: ''
        //     },
        //     link: 'https://letzshop.lu/de/products/eldo-popsocket-3c783d'
        // },
        {
            currency: '€',
            discount: {
                discount: 10,
                priceWithoutDiscount: 12.5,
                discountType: 'percent',
            },
            price: 14.99,
            tags: ['shop'],
            title: 'Charel Figur mat Klemmbausteng',
            image: {
                src: '/assets/img/shop/charel_shop.png',
                alt: '',
            },
            link: 'https://letzshop.lu/fr/products/charel-figur-mat-klemmbausteng-cc0285',
        },
        {
            currency: '€',
            discount: {
                discount: 10,
                priceWithoutDiscount: 12.5,
                discountType: 'percent',
            },
            price: 11.25,
            tags: ['shop'],
            title: 'Xclusive Eldo Strëmp ',
            image: {
                src: '/assets/img/shop/stremp.jpg',
                alt: '',
            },
            link: 'https://letzshop.lu/de/products/xcluiv-eldo-stremp-34e138',
        },
        {
            currency: '€',
            discount: {
                discount: 10,
                priceWithoutDiscount: 9.99,
                discountType: 'percent',
            },
            price: 14.99,
            tags: ['shop'],
            title: 'Xclusiv Chiko Strëmp',
            image: {
                src: '/assets/img/shop/chicko_socks.png',
                alt: '',
            },
            link: 'https://letzshop.lu/de/products/xcluiv-eldo-stremp-0cd54c',
        },
        {
            currency: '€',
            discount: {
                discount: 10,
                priceWithoutDiscount: 12.5,
                discountType: 'percent',
            },
            price: 11.25,
            tags: ['shop'],
            title: 'Eldo Pong Dësch',
            image: {
                src: '/assets/img/shop/pong_desch.jpg',
                alt: '',
            },
            link: 'https://letzshop.lu/de/products/eldo-pong-desch-782b27',
        },
    ];

    return (
        <PageLayout title={t('shop.title')}>
            {ads && <AdComponent ad={ads.data[0]} />}
            <IonGrid fixed>
                {IsDesktop ? (
                    <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                        <Title title={t('shop.title')} />
                    </IonRow>
                ) : (
                    ''
                )}
                <p
                    className="mb-blockInnerMobile md:mb-blockInner whitespace-normal text-left"
                    dangerouslySetInnerHTML={{ __html: t('shop.description') }}
                />
                <AdRightBlock ad={ads?.data?.[1]}>
                    <IonRow className="mb-blockMobile flex gap-standardMobile md:gap-standard">
                        {shopCards.map((card) => (
                            <ShopCard
                                {...card}
                                key={generateUniqueId()}
                            />
                        ))}
                    </IonRow>
                </AdRightBlock>
                {ads && <AdComponent ad={ads.data[2]} />}
            </IonGrid>
        </PageLayout>
    );
}
