import ReactGA from 'react-ga4';

export const initGA = () => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS ?? '', {
        gaOptions: {
            send_page_view: false,
        },
    });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
};

export default initGA;
