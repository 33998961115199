import { HTMLAttributes } from 'react';
import { StarIcon, FilledStarIcon } from './Icons';

interface FavoriteButtonProps extends HTMLAttributes<HTMLDivElement> {
    favorite: boolean;
}

export default function FavoriteButton({ favorite, className = '', ...rest }: FavoriteButtonProps) {
    return (
        <div
            className={`rounded-full w-[45px] h-[45px] bg-lighter-background flex justify-center items-center cursor-pointer ${className}`}
            {...rest}
        >
            {favorite ? <FilledStarIcon /> : <StarIcon className="fill-white" />}
        </div>
    );
}
