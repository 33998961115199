import React, { useState } from 'react';

import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Controller, type Control } from 'react-hook-form';

import { InputProps } from './Input';

import '../theme/react-phone-input-2.scss';

export type PhoneInputProps = InputProps & {
    control?: Control<any>;
};

const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(({ control, ...inputProps }, ref) => {
    return (
        <Controller
            name={inputProps.name || ''}
            control={control}
            render={({ field }) => (
                <ReactPhoneInput
                    country="lu"
                    value={field.value || ''}
                    onChange={(val) => {
                        field.onChange(val);
                    }}
                    inputClass="!px-[15px] !border-0 !h-[54px] !outline-0 !bg-background-alt !rounded flex-1"
                    buttonClass="!bg-background-alt !relative !rounded !border-none !w-[45px]"
                    dropdownClass="!bg-background-alt"
                    containerClass="flex gap-2 flex-row-reverse my-[15px]"
                />
            )}
        />
    );
});

export default PhoneInput;
