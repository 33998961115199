import { IonFooter, IonRouterLink, IonRow } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { HashLink } from 'react-router-hash-link';
import { useState } from 'react';
import { DidomiSDK } from '@didomi/react';
import moonIcon from './Icons/moon_Eldo.svg';
import AppStoreBtn from './mobilestores/AppStoreBtn';
import GooglePlayBtn from './mobilestores/GooglePlayBtn';
import { ReactComponent as LogoIpDigital } from './Icons/logo_ip-digital.svg';
import SocialNetworks from './SocialNetworks';

function DesktopFooterTitle({ children, className }: { children: string; className?: string }) {
    return (
        <h2 className={`mb-2 typo-h2 semibold flex items-center gap-4 ${className || ''}`}>
            <img
                src={moonIcon}
                alt=""
                className="lg:w-[18px]"
            />
            {children}
        </h2>
    );
}

function DesktopFooter() {
    const { t } = useTranslation();
    const [didomiInstance, setDidomiInstance] = useState();

    return (
        <div className="hidden lg:grid bg-[var(--eldo-background-alt-color)] text-white px-[165px] py-[101px] place-items-center mt-blockMobile md:mt-block">
            <DidomiSDK onReady={(didomi: any) => setDidomiInstance(didomi)} />
            <div className="grid grid-cols-3 gap-4 w-fit items-start typo-b2 regular">
                <div className="grid gap-[20px] ">
                    <DesktopFooterTitle className="mb-[30px]">{t('about')}</DesktopFooterTitle>
                    <HashLink
                        className="hover:text-[var(--eldo-primary)]"
                        smooth
                        to="/terms#term"
                    >
                        {t('terms_of_use')}
                    </HashLink>
                    <HashLink
                        className="hover:text-[var(--eldo-primary)]"
                        smooth
                        to="/terms#privacy"
                    >
                        {t('privacy')}
                    </HashLink>
                    <HashLink
                        className="hover:text-[var(--eldo-primary)]"
                        smooth
                        to="/terms#cookies"
                    >
                        {t('cookie_policy')}
                    </HashLink>

                    <div
                        className="hover:text-[var(--eldo-primary)] cursor-pointer"
                        onClick={() => {
                            /* @ts-ignore */
                            didomiInstance?.preferences.show();
                        }}
                    >
                        {t('cookie_managment')}
                    </div>
                    <HashLink
                        className="hover:text-[var(--eldo-primary)]"
                        to="/frequencies"
                    >
                        {t('frequenzen')}
                    </HashLink>
                    <HashLink
                        className="hover:text-[var(--eldo-primary)]"
                        to="/radio/team"
                    >
                        {t('kontakt')}
                    </HashLink>
                </div>
                <div className="grid gap-[30px]">
                    <DesktopFooterTitle>{t('ad_space')}</DesktopFooterTitle>
                    <p dangerouslySetInnerHTML={{ __html: t('ad_space_description') }} />
                    <a
                        target="_blank"
                        href="https://ipl.lu/"
                        rel="noreferrer"
                    >
                        <LogoIpDigital />
                    </a>
                </div>
                <div className="grid gap-[30px]">
                    <DesktopFooterTitle>{t('follow_us')}</DesktopFooterTitle>
                    <SocialNetworks />

                    <DesktopFooterTitle>{t('mobile_app')}</DesktopFooterTitle>
                    <div className="flex items-center gap-[15px]">
                        <AppStoreBtn />
                        <GooglePlayBtn />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DesktopFooter;
