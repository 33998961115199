import React, { Dispatch, SetStateAction } from 'react';

export default function FilterButton({
    filter,
    selectedFilter,
    setSelectedFilter,
    className = '',
    color = 'primary',
}: {
    filter: string;
    selectedFilter: string | string[] | null;
    setSelectedFilter: Dispatch<SetStateAction<string | null>>;
    className?: string;
    color?: 'primary' | 'tertiary';
}) {
    const selectedColor = color === 'primary' ? 'bg-primary text-black' : 'bg-[#6158DB] text-white';
    const hoverColor =
        color === 'primary' ? 'hover:bg-primary hover:text-black' : 'hover:bg-[#6158DB] hover:text-white';

    const isSelected =
        typeof selectedFilter === 'string' ? selectedFilter === filter : selectedFilter?.find((f) => f === filter);

    return (
        <button
            onClick={() => setSelectedFilter(filter)}
            className={`px-3 py-2 border-2 typo-b1 regular rounded-[10px] border-solid ${hoverColor} hover:border-transparent ${className} ${
                isSelected ? `${selectedColor}  border-transparent` : 'border-white'
            }`}
        >
            {filter}
        </button>
    );
}
