import { useCallback, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { generateId, useIonToast } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { GetEventsParams, getEvents } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';
import { EventResponse } from '../types';

export default function useEvents(f?: GetEventsParams, cacheKey?: string, pageName?: string | (() => string)) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const [queryName] = useState(cacheKey || `events-request-${generateId('events-request')}`);

    const [filters, setFilters] = useState<GetEventsParams>(f || {});

    const fetcher = useCallback(
        () => getEvents(filters, typeof pageName === 'string' ? pageName : pageName?.()),
        [filters],
    );

    const {
        data: eventResponse,
        status,
        refetch,
    } = useQuery<EventResponse>([queryName], fetcher, {
        onError: (e) => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    useEffect(() => {
        refetch();
    }, [filters]);

    const nbPages = useMemo(() => {
        return Math.ceil((eventResponse?.count || 1) / (eventResponse?.perPage || 1));
    }, [eventResponse]);

    return {
        eventResponse,
        isLoading: status === 'loading',
        filters,
        setFilters,
        nbPages,
    };
}
