import BurgerMenu from 'components/BurgerMenu';
import PageLayout from 'components/PageLayout';

export default function MenuPage() {
    return (
        <PageLayout
            cssClass="menu-page"
            hasLogo
        >
            <div>
                <BurgerMenu cssClass="main-burger-menu--page" />
            </div>
        </PageLayout>
    );
}
