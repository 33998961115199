import { IonCard } from '@ionic/react';
import useAuth from 'features/auth/useAuth';
import moment from 'moment';
import { Ticket } from 'types';

function TicketCard({ type, id, title, description, date, name, phone }: Ticket) {
    const formattedDate = moment(date).format('DD.MM.YY');
    const formattedType = type.charAt(0).toUpperCase().concat(type.slice(1));

    const { isAuthenticated } = useAuth();

    return (
        <IonCard className="m-0 p-0">
            <div className="p-[20px] flex-1 flex justify-between">
                <div className="text-left flex flex-col h-full">
                    <div className="flex">
                        <div className="bg-[#6158DB] py-[8px] px-[12px] rounded-[10px] typo-b2 regular tracking-[0.25px]">
                            {formattedType}
                        </div>
                    </div>

                    <div className="flex-1 flex flex-col">
                        <div className="flex items-center">
                            <img
                                src="assets/img/ticketing/ticket.png"
                                alt="ticketIcon"
                            />
                            <div className="ml-[10px] font-semibold typo-b1 tracking-[0.5px] leading-6">{title}</div>
                        </div>
                        <div className="mt-[10px] tracking-[0.25px] typo-b2 regular flex-1">{description}</div>
                        <div className="typo-b2 bold mt-[25px]">{formattedDate}</div>
                    </div>

                    {isAuthenticated ? (
                        <div className="flex flex-col items-end text-end w-full mt-[10px]">
                            <div>{name}</div>
                            <div>{phone}</div>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </div>
        </IonCard>
    );
}

export default TicketCard;
