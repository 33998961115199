import { createContext, useMemo } from 'react';
import { WebChannel } from './types';
import useChannels from './hooks/useChannels';

export type ChannelContextType = {
    channels?: WebChannel[] | null;
    channelsLoading: boolean;
};

export const ChannelContext = createContext<ChannelContextType | undefined>(undefined);

export default function ChannelProvider({ children }: { children: JSX.Element | JSX.Element[] }) {
    const { data: channels, isLoading: channelsLoading } = useChannels();

    const value = useMemo(() => ({ channels, channelsLoading }), [channels, channelsLoading]);

    return <ChannelContext.Provider value={value}>{children}</ChannelContext.Provider>;
}
