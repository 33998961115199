import React from 'react';
import { IonCol, IonImg, IonRow, IonThumbnail } from '@ionic/react';

export default function PlayerDetailsMediaPresenterRow({ url, name }: { url: string; name: string }) {
    return (
        <IonRow className="player__presenter">
            <IonCol size="auto">
                <IonThumbnail className="">
                    <IonImg src={url} />
                </IonThumbnail>
            </IonCol>
            <IonCol>
                <strong>{name}</strong>
            </IonCol>
        </IonRow>
    );
}
