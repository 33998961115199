import { IonLoading, useIonToast } from '@ionic/react';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { TOAST_DURATION } from '../../../constants';
import OtpInput from '../../../components/OtpInput';
import Button from '../../../components/Button';
import { resetPassword, resetPasswordConfirmation } from '../../../lib/ApiService';

export default function ResetStep2({ onComplete, email }: { email: string; onComplete: (token: string) => void }) {
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [present] = useIonToast();

    const [OTP, setOTP] = useState('');

    const onSubmit = async (event: FormEvent) => {
        event.preventDefault();
        setIsLoading(true);

        await resetPasswordConfirmation(email, OTP)
            .then(({ token }) => {
                onComplete(token);
            })
            .catch((e) => {
                present({
                    color: 'danger',
                    duration: TOAST_DURATION,
                    message: e?.message ?? t('error_reset_otp'),
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const resendEmail = async () => {
        setIsLoading(true);
        try {
            await resetPassword(email);
        } catch (e) {
            console.error(e);
            // present(t('fetch_error'), TOAST_DURATION);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <>
            <h1 className="mt-4 typo-h1 font-bold">{t('reset_password.step2_title')}</h1>
            <p className="mb-4 typo-b1 regular">{t('reset_password.step2_subtitle')}</p>
            {isLoading && <IonLoading isOpen />}

            <form onSubmit={onSubmit}>
                <OtpInput
                    shouldAutoFocus
                    isInputNum
                    value={OTP}
                    onChange={setOTP}
                    numInputs={4}
                    separator=""
                    className="mt-[30px]"
                />

                <Button
                    type="submit"
                    className="my-[50px]"
                    value={t('reset_password.validate')}
                    disabled={OTP.length < 4}
                />
            </form>
            <div className="grid place-items-center text-center typo-b2 regular font-poppins underline leading-[20px]">
                <button
                    onClick={resendEmail}
                    type="button"
                >
                    {t('reset_password.didnt_receive')} <br /> {t('reset_password.resend')}
                </button>
            </div>
        </>
    );
}
