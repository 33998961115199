import React from 'react';
import { Song } from '../types';
import FlatThumbCard from '../../../components/Cards/FlatThumbCard';

function LastSong({ song }: { song: Song }) {
    return (
        <FlatThumbCard
            artistName={song.artist}
            songDuration={song.time}
            songTitle={song.title}
            imgSrc={song.thumbnailUrl ?? '/assets/img/last_song_image.png'}
        />
    );
}

export default React.memo(LastSong);
