import { IonLoading } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import Carousel from 'components/Carousel';
import useResponsive from 'features/responsive/responsive';
import { Link } from 'react-router-dom';
import { CategorizedReplays, PodcastOrReplay } from 'types';
import { isNativeAd, prepareUrlTitle } from 'lib/utils';
import useReplayByCategory from 'features/podcastsReplays/hooks/useReplayByCategory';
import CardVertical from 'components/core/CardVertical';
import { useMemo } from 'react';
import moment from 'moment';
import AdComponent from 'components/Ads/AdComponent';
import CarouselWithAds from 'components/Carousel/CarouselWithAds';

export default function Eldo4RealSwiper() {
    const { podcastByCategory: data, isLoading } = useReplayByCategory('eldo4real');
    const eldo4Real = useMemo(() => (data as CategorizedReplays)?.replays, [data]);
    const { t } = useTranslation();
    const { IsMobile } = useResponsive();

    if (!eldo4Real && !isLoading) {
        return <p className="text-white m-4">Error loading...</p>;
    }

    return !isLoading ? (
        <CarouselWithAds
            // @ts-ignore
            data={eldo4Real}
            className="w-full"
        >
            {(eldo: PodcastOrReplay) => (
                <Link
                    to={`/podcast-replay/type/eldo4real/${eldo.id as unknown as string}/${prepareUrlTitle(eldo?.title)}`}
                    className="w-full h-full"
                >
                    <CardVertical
                        title={eldo.title}
                        thumbnailUrl={eldo?.poster || 'cataas.com/cat'}
                        tags={[{ name: t('eldo4real'), publicTag: 'Eldo4Real', tag: 'Eldo4Real' }]}
                        footerSlot={
                            <div className="flex justify-between items-center">
                                <div>{eldo.duration ? `${eldo.duration} ${t('sec')}` : ''}</div>
                            </div>
                        }
                        className="w-full h-[430px]"
                        imgClassName="!h-[220px] mb-[20px]"
                        imgSize="medium"
                        titleTruncated={2}
                        
                    />
                </Link>
            )}
        </CarouselWithAds>
    ) : (
        <> </>
    );
}
