import { Media, MediaType, PlayerState } from './types';

export type PlayerAction<T = unknown> =
    | { type: 'SET_PROGRESSION'; payload: number }
    | { type: 'SET_TYPE'; payload: MediaType }
    | { type: 'SET_MEDIA'; payload: Media }
    | { type: 'TOGGLE_WEBCHANNEL'; payload: string }
    | {
          type: 'TOGGLE_PLAY' | 'TOGGLE_RADIO' | 'PLAY' | 'PAUSE' | 'EXPAND' | 'MINIMIZE' | 'DISPLAY' | 'HIDE';
          payload?: T;
      };

const playerReducer = (state: PlayerState, { type, payload }: PlayerAction): PlayerState => {
    function shouldPlaying(): boolean {
        let isPlaying = true;
        if (!state.isPlaying) {
            isPlaying = true;
        } else if (state.currentChannel === payload) {
            isPlaying = false;
        }

        return isPlaying;
    }

    switch (type) {
        case 'PLAY':
            return {
                ...state,
                isPlaying: true,
                isVisible: true,
            };

        case 'TOGGLE_PLAY':
            return {
                ...state,
                isPlaying: !state.isPlaying,
                isVisible: true,
            };

        case 'TOGGLE_RADIO':
            return {
                ...state,
                isPlaying: !state.isPlaying,
                isVisible: true,
                type: 'RADIO',
            };

        case 'TOGGLE_WEBCHANNEL':
            return {
                ...state,
                isPlaying: shouldPlaying(),
                isVisible: true,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                currentChannel: payload,
                type: 'WEBCHANNEL',
            };

        case 'PAUSE':
            return { ...state, isPlaying: false };

        case 'EXPAND':
            return { ...state, expanded: true };

        case 'MINIMIZE':
            return { ...state, expanded: false };

        case 'DISPLAY':
            return { ...state, isVisible: true };

        case 'HIDE':
            return { ...state, isVisible: false };

        case 'SET_TYPE':
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return { ...state, type: payload, progress: 0 };

        case 'SET_PROGRESSION':
            return { ...state, progress: payload };

        case 'SET_MEDIA':
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            return { ...state, media: payload };

        default:
            throw new Error(`No case for this type found in the reducer`);
    }
};

export default playerReducer;
