import Card from 'components/Card';
import Img from 'components/Img';
import Pill from 'components/Pill';
import { useTranslation } from 'react-i18next';
import { Movie, MovieSession, WeekDates } from 'types';
import useResponsive from 'features/responsive/responsive';
import { MovieSessionsPresenter2 } from './MovieSessionsPresenter';

export type MovieHorizontalCardProps = {
    movie: Movie;
    lang: 'lu' | 'en' | 'fr' | 'be';
    className?: string;
    sessions: MovieSession[];
    sessionsOptions: WeekDates[];
    // first date, format --> 'YYYY-MM-DD'
    firstDate: string;
};

export default function MovieHorizontalCard({
    movie,
    lang,
    className,
    sessionsOptions,
    firstDate,
    sessions,
}: MovieHorizontalCardProps) {
    const { t } = useTranslation();
    const { IsDesktop } = useResponsive();

    return (
        <Card
            hasCardHoverEffect
            className={`${className || ''} flex flex-col`}
        >
            <div className="flex gap-standardMobile md:gap-standard">
                <div className="overflow-hidden">
                    <Img
                        alt={movie.title}
                        src={movie.images.poster}
                        hasCardHoverEffect
                        imgClassName="object-cover h-full w-full h-[200px] max-w-[125px]"
                    />
                </div>
                <div className="flex-1 mt-[10px] flex flex-col gap-[8px]">
                    <div className="typo-b2 regular text-[var(--eldo-primary)]">
                        {movie.year ? `${movie.year} - ` : ''}
                        {movie.description[lang].category}
                    </div>
                    {movie.duration > 0 ? (
                        <Pill className="w-fit">
                            {Math.floor(movie.duration / 60)}h{movie.duration % 60}
                        </Pill>
                    ) : (
                        ''
                    )}
                    <div className="typo-b1 font-semibold">{movie.title}</div>
                    <div className="typo-b2">{movie.version}</div>
                </div>
            </div>
            <div className="mt-standardMobile md:mt-standard">
                {IsDesktop && (
                    <MovieSessionsPresenter2
                        sessions={sessions}
                        firstDate={firstDate}
                    />
                )}
            </div>
        </Card>
    );
}
