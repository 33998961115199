import { generateId, useIonToast } from '@ionic/react';
import { useCallback, useState } from 'react';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getCinemaBoxOfficeArticles } from 'lib/ApiService';
import { useTranslation } from 'react-i18next';
import { Article, CinemaArticles } from 'types';
import { AdData } from 'features/advertising/generateAdPayload';
import { TOAST_DURATION } from '../../../constants';

type UseCinemaBoxOfficeArticlesProps = {
    limit?: number;
};

export default function useCinemaBoxOfficeArticles({ limit = 8 }: UseCinemaBoxOfficeArticlesProps = {}) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const [queryName] = useState(`new-request-${generateId('new-request')}`);

    const func = useCallback(
        ({ pageParam }) => {
            const start = pageParam?.start || 0;
            return getCinemaBoxOfficeArticles(limit, start);
        },
        [limit],
    );

    const { data, status, refetch, hasNextPage, fetchNextPage } = useInfiniteQuery<CinemaArticles>(
        ['cinemaBoxOffice'],
        func,
        {
            onError: () => {
                // present(t('fetch_error'), TOAST_DURATION);
            },
            getNextPageParam: (lastPage, allPages) => {
                if (!lastPage.next) return undefined;
                return {
                    start: allPages.length * limit,
                };
            },
        },
    );

    return {
        list: (data?.pages || []).reduce(
            (prev, current) => [...prev, ...(current.articles || [])],
            [] as Array<Article | AdData>,
        ),
        isLoading: status === 'loading',
        loadMore: fetchNextPage,
        canLoadMore: hasNextPage,
        data,
    };
}
