import { useParams } from 'react-router';
import PodcastReplayArticle from './PodcastReplayArticle';
import Eldo4Real from './Eldo4Real';

export default function PodcastReplayOrEldo4Real() {
    const { id: articleId, type } = useParams<{ id: string; type: string }>();
    const isEldo4Real = type === 'eldo4real';

    return isEldo4Real ? (
        <Eldo4Real />
    ) : (
        <PodcastReplayArticle
            articleId={articleId}
            type={type}
        />
    );
}
