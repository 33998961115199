import moment from 'moment';
import { HTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import ellipsisText from 'lib/utils';
import { Tags } from 'types';
import Card from './Card';
import Pill from './Pill';
import Img from './Img';

interface MediumCardProps extends HTMLAttributes<HTMLDivElement> {
    thumbnailUrl?: string;
    title: string;
    date?: string;
    tags?: Tags[];
    truncated?: boolean | 1 | 2 | 3;
    linkForPill?: string;
    onClickForPill?: (tag: string) => void;
    linkTo?: string;
    slotEnd?: React.ReactNode;
    dateFormat?: string;
    displayTime?: boolean;
    timeFormat?: string;
    clickable?: boolean;
    imageCover?: 'object-cover' | 'object-fit' | 'object-fill' | 'object-contain';
    noImage?: boolean;
}

export default function HorizontalMediumCard({
    thumbnailUrl,
    title,
    tags = [],
    date,
    className = '',
    truncated = undefined,
    linkForPill = undefined,
    onClickForPill,
    linkTo,
    slotEnd,
    dateFormat = 'DD.MM.YY',
    displayTime,
    timeFormat = 'HH:mm',
    clickable = true,
    imageCover = 'object-cover',
    noImage = false,
    ...rest
}: MediumCardProps) {
    const history = useHistory();
    const tagCharacterSum = tags
        .slice(0, 2)
        .reduce((accumulator, currentValue) => accumulator + currentValue.name.length, 0);
    const horizontalDivRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            if (horizontalDivRef.current && horizontalDivRef.current.contains(event.target as Node | null)) {
                event.preventDefault();
            }
        };

        if (horizontalDivRef.current) {
            horizontalDivRef.current.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (horizontalDivRef.current) {
                horizontalDivRef.current.removeEventListener('contextmenu', handleContextMenu);
            }
        };
    }, []);

    const handleLinkClick = (event?: React.MouseEvent<HTMLElement>, tag?: string) => {
        if (onClickForPill && tag) {
            onClickForPill(tag);
            event?.stopPropagation();
        } else {
            const linkToGo = (tag && linkForPill ? `${linkForPill}${tag}` : linkTo) ?? '';
            if (linkToGo !== '') {
                if (event?.ctrlKey || event?.button === 1 || event?.button === 2) {
                    window.open(linkToGo, '_blank');
                } else {
                    history.push(linkToGo);
                }
            }
        }
    };

    function getPillComponent(name: string, index: number, tag?: string) {
        return (
            <div
                key={index}
                onMouseDown={(e) => {
                    e?.stopPropagation();
                    handleLinkClick(e, tag);
                }}
            >
                <Pill
                    title={name}
                    key={index}
                    className="capitalize w-max !text-[0.85rem]"
                >
                    {/* {tagCharacterSum > 16 && index !== 0 && name.length < 11 ? ellipsisText(name, 5) : name} */}
                    {(tagCharacterSum > 16 && index !== 0) ||
                    (name.length > 11 && tags?.length !== 1) ||
                    (tagCharacterSum > 16 && tags?.length === 1)
                        ? ellipsisText(name, 5).replaceAll('-', '')
                        : name.replaceAll('-', '')}
                </Pill>
            </div>
        );
    }

    const truncateClass = useMemo(() => {
        if (truncated === true) {
            return 'line-clamp-2';
        }
        switch (truncated) {
            case 1:
                return 'line-clamp-1';
            case 2:
                return 'line-clamp-2';
            case 3:
                return 'line-clamp-3';
            default:
                return '';
        }
    }, [truncated]);

    return (
        <div ref={horizontalDivRef}>
            <Card
                className={`flex flex-row px-[0px] py-[0px] min-h-[175px] md:min-w-[310px] ${
                    noImage ? 'h-full' : 'max-h-[175px]'
                } ${className} ${clickable ? 'cursor-pointer' : ''}`}
                {...rest}
                hasCardHoverEffect
                onMouseDown={(event) => handleLinkClick(event)}
            >
                {!noImage && (
                    <Img
                        alt={title}
                        src={thumbnailUrl}
                        className="w-1/3 min-w-[111px]"
                        imgClassName={`h-full ${imageCover}`}
                        hasCardHoverEffect
                    />
                )}
                <div
                    className={`pt-[20px] pb-[10px] px-[15px] ${
                        thumbnailUrl ? 'w-2/3' : 'w-full'
                    } flex flex-col justify-between`}
                >
                    <div className="">
                        {!!tags.length && (
                            <div className="flex gap-[10px] mb-[10px] overflow-x-auto scrollbar-hide">
                                {tags.slice(0, 2).map((tag, index) => getPillComponent(tag.name, index, tag.publicTag))}
                            </div>
                        )}

                        <div className={`text-left typo-b1 font-semibold ${truncateClass}`}>{title}</div>
                    </div>
                    {date || slotEnd != null ? (
                        <div className="flex items-center justify-between mt-1">
                            <div className="text-left typo-b2 regular">
                                {slotEnd != null ? <div>{slotEnd}</div> : ''}
                                {date ? <div>{moment(date).format(dateFormat)}</div> : ''}
                            </div>
                            {displayTime ? <div>{moment(date).format(timeFormat)}</div> : ''}
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Card>
        </div>
    );
}
