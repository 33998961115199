import { IonIcon } from '@ionic/react';
import { chevronBackOutline, chevronForwardOutline } from 'ionicons/icons';
import { transpose2dArray } from 'lib/utils';
import moment, { Moment, now } from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MovieResponse, MovieSession } from 'types';

export type MovieSessionsPresenterProps = {
    movieDetails: MovieResponse;
    // first date, format --> 'YYYY-MM-DD'
    firstDate: string | Date;
};

const DAYS_GIRD_CLASS = 'grid grid-cols-7 place-items-center p-[10px]';
const TITLE_CONTENT_GRID_CLASS = 'grid grid-cols-[300px_1fr]';

type MovieSessionPresenterWeekDay = {
    cinema: string;
    sessionsLine: [string, string, string, string, string, string, string][];
};

export function getSessionsOptionsFromSessionsList(sessions: MovieSession[]) {
    const dates = sessions.map((s) => s.date);
    const currentDayNumber = moment().day();

    return Array.from(new Set(dates)).map((d) => {
        const date = moment(d, 'YYYY-MM-DD');
        const dateDay = date.day();
        if (dateDay === currentDayNumber) {
            return {
                label: `${date.format('DD/MM')} - ${date.clone().add(6, 'day').format('DD/MM')}`,
                value: d,
            };
        }
        let dateStart = date;
        while (dateStart.day() !== currentDayNumber) {
            dateStart = dateStart.subtract(1, 'day');
        }
        return {
            label: `${dateStart.format('DD/MM')} - ${dateStart.clone().add(6, 'day').format('DD/MM')}`,
            value: d,
        };
    });
}

function getSessionForDate(date: Moment, sessions: MovieSession[]): MovieSession[] {
    return sessions.filter((s) => moment(s.date).format('YYYY-MM-DD') === date.format('YYYY-MM-DD'));
}

export function getSessionsLinesFormatted(
    sessions: MovieSession[],
    dtStart: Moment,
    pastDates = false,
): MovieSessionPresenterWeekDay['sessionsLine'] {
    const sessionsByDay = pastDates
        ? [
              [...getSessionForDate(dtStart.clone().subtract(6, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().subtract(5, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().subtract(4, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().subtract(3, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().subtract(2, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().subtract(1, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone(), sessions)],
          ]
        : [
              [...getSessionForDate(dtStart.clone(), sessions)],
              [...getSessionForDate(dtStart.clone().add(1, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().add(2, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().add(3, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().add(4, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().add(5, 'day'), sessions)],
              [...getSessionForDate(dtStart.clone().add(6, 'day'), sessions)],
          ];
    return transpose2dArray(sessionsByDay).map((arr) =>
        // @ts-ignore
        arr.map((val) => val?.time.substr(0, 5) || ''),
    ) as unknown as MovieSessionPresenterWeekDay['sessionsLine'];
}

export default function MovieSessionsPresenter({ movieDetails, firstDate }: MovieSessionsPresenterProps) {
    const { t } = useTranslation();
    const firstDateMoment = moment(firstDate, 'YYYY-MM-DD');
    const [pastDates, setPastDates] = useState<boolean>(false);

    const CINEMAS: MovieSessionPresenterWeekDay[] = movieDetails.cinemas
        .map((c) => {
            const sessionsLine = getSessionsLinesFormatted(c.sessions, firstDateMoment.clone(), pastDates);
            return {
                cinema: c.fullName,
                sessionsLine,
            };
        })
        .filter((c) => c.sessionsLine.length);

    const WEEK_DAYS = pastDates
        ? [
              firstDateMoment.clone().subtract(6, 'day'),
              firstDateMoment.clone().subtract(5, 'day'),
              firstDateMoment.clone().subtract(4, 'day'),
              firstDateMoment.clone().subtract(3, 'day'),
              firstDateMoment.clone().subtract(2, 'day'),
              firstDateMoment.clone().subtract(1, 'day'),
              firstDateMoment.clone(),
          ]
        : [
              firstDateMoment.clone(),
              firstDateMoment.clone().add(1, 'day'),
              firstDateMoment.clone().add(2, 'day'),
              firstDateMoment.clone().add(3, 'day'),
              firstDateMoment.clone().add(4, 'day'),
              firstDateMoment.clone().add(5, 'day'),
              firstDateMoment.clone().add(6, 'day'),
          ];

    return (
        <>
            <div className="max-lg:hidden">
                <div className={`${TITLE_CONTENT_GRID_CLASS} mb-standardMobile md:mb-standard`}>
                    <div className="flex items-center justify-end">
                        <button
                            className="bg-[var(--eldo-primary)] aspect-square w-[40px] h-[40px] rounded-full grid place-items-center"
                            onClick={() => setPastDates(!pastDates)}
                        >
                            <IonIcon
                                className="transform -translate-x-px"
                                icon={pastDates ? chevronForwardOutline : chevronBackOutline}
                                color=""
                            />
                        </button>
                    </div>
                    <div className={DAYS_GIRD_CLASS}>
                        {WEEK_DAYS.map((d, weekDaysIndex) => (
                            <div
                                key={`${weekDaysIndex}${d.day()}weekdays`}
                                className={`typo-b2 font-semibold grid place-items-center ${
                                    new Date().getDate() === d.date() ? 'p-2 bg-primary rounded mt-2' : ''
                                }`}
                            >
                                <div>{t(`cinema.week.${d.day()}`)}</div>
                                <div>{d.format('DD')}</div>
                            </div>
                        ))}
                    </div>
                </div>
                {CINEMAS.map((w) => (
                    <div className={`${TITLE_CONTENT_GRID_CLASS} mb-standardMobile md:mb-standard`}>
                        <div className="typo-b1 font-semibold">{w.cinema}</div>
                        <div className="grid gap-[10px]">
                            {w.sessionsLine.map((line) => (
                                <div className={`bg-light-background rounded ${DAYS_GIRD_CLASS}`}>
                                    {line.map((dayStr) => (
                                        <div>{dayStr}</div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            <div className="grid lg:hidden gap-standardMobile">
                {CINEMAS.map((c, cinemaIndex) => (
                    <div>
                        <div className="typo-b1 font-semibold">{c.cinema}</div>
                        <div className={DAYS_GIRD_CLASS}>
                            {WEEK_DAYS.map((d) => (
                                <div
                                    key={`${cinemaIndex}${d.day()}sessions`}
                                    className={`typo-b2 font-semibold grid place-items-center ${
                                        new Date().getDate() === d.date() ? 'p-2 bg-primary rounded mt-2' : ''
                                    }`}
                                >
                                    <div>{t(`cinema.week.${d.day()}`)}</div>
                                    <div>{d.format('DD')}</div>
                                </div>
                            ))}
                        </div>
                        <div className="bg-light-background rounded">
                            {c.sessionsLine.map((line) => (
                                <div className={DAYS_GIRD_CLASS}>
                                    {line.map((dayStr) => (
                                        <div>{dayStr}</div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
}

export type MovieSessionPresenter2Props = {
    firstDate: MovieSessionsPresenterProps['firstDate'];
    sessions: MovieSession[];
};

export function MovieSessionsPresenter2({ firstDate, sessions }: MovieSessionPresenter2Props) {
    const { t } = useTranslation();
    const firstDateMoment = moment(firstDate, 'YYYY-MM-DD');

    const WEEK_DAYS = [
        firstDateMoment.clone().add(0, 'day'),
        firstDateMoment.clone().add(1, 'day'),
        firstDateMoment.clone().add(2, 'day'),
        firstDateMoment.clone().add(3, 'day'),
        firstDateMoment.clone().add(4, 'day'),
        firstDateMoment.clone().add(5, 'day'),
        firstDateMoment.clone().add(6, 'day'),
    ];

    const currentMonth = firstDateMoment.month();
    const currentLastMonth = firstDateMoment.clone().add(6, 'day').month();

    return (
        <div>
            <div className="typo-b2 text-[var(--eldo-primary)] mb-[10px]">
                {t(`cinema.month.${currentMonth}`)}
                {currentLastMonth !== currentMonth ? ` - ${t(`cinema.month.${currentLastMonth}`)}` : ''}
            </div>
            <div className={DAYS_GIRD_CLASS}>
                {WEEK_DAYS.map((d) => (
                    <div
                        key={d.day()}
                        className={`typo-b2 font-semibold grid place-items-center ${
                            new Date().getDate() === d.date() ? 'p-2 bg-primary rounded mt-2' : ''
                        }`}
                    >
                        <div>{t(`cinema.week.${d.day()}`)}</div>
                        <div>{d.format('DD')}</div>
                    </div>
                ))}
            </div>
            <div className="bg-light-background rounded">
                {getSessionsLinesFormatted(sessions, firstDateMoment.clone()).map((line) => (
                    <div className={DAYS_GIRD_CLASS}>
                        {line.map((dayStr) => (
                            <div className="typo-b2 semibold">{dayStr}</div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}
