import { useContext, useEffect, useRef, useState } from 'react';
import { AdData, generateAdnAdPayload } from 'features/advertising/generateAdPayload';
import { generateId, isPlatform } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { addView } from 'lib/ApiService';
import { ScrolledContext } from 'components/PageLayout';
import useResponsive from 'features/responsive/responsive';
import moment from 'moment';

export type AdComponentProps = {
    ad: AdData;
    hasSpacings?: boolean;
    className?: string;
    onNoContent?: () => void;
    onClosed?: () => void;
    splash?: boolean;
    removeMarginTopOnWFull?: boolean;
    preloadMovieJobAd?: boolean;
};

declare global {
    interface Window {
        adn: any;
    }
}

function AdComponent({
    ad,
    hasSpacings = true,
    className,
    onNoContent,
    onClosed,
    splash = false,
    removeMarginTopOnWFull = false,
    preloadMovieJobAd,
}: AdComponentProps): JSX.Element | null {
    const [targetID, setTargetID] = useState<string>();
    const ref = useRef<HTMLDivElement | null>(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isDisplayed, setIsDisplayed] = useState(splash);
    const { t } = useTranslation();
    const isScrolled = useContext(ScrolledContext);
    const { IsMobile } = useResponsive();

    const [removeMarginTop, setRemoveMarginTopOnWFull] = useState(false);

    const setLeaderboardLittle = (val: boolean) => {
        if (!ref.current) return;
        const iframe = ref.current?.getElementsByTagName('iframe')[0];
        if (!iframe?.contentWindow) return;
        iframe.contentWindow.postMessage({ cmd: 'changeHeader', value: val ? 'true' : 'false' }, '*');
    };

    function requestAd() {
        // addView(`/container/${ad?.kv?.container as unknown as string}/requested/${window.location.pathname}`);
        if (ad?.kv == null) return;

        if (!window.adn.version) {
            if (onNoContent) onNoContent();

            addView(
                `/container/${ad?.kv?.container as unknown as string}/blockedByAdblocker/${window.location.pathname}`,
            );
            setIsDisplayed(false);
            return;
        }
        window.adn.calls.push(function () {
            window.adn.request(
                generateAdnAdPayload([ad], null, targetID, {
                    onNoMatchedAds: () => {
                        setIsDisplayed(false);
                        // addView(
                        //     `/container/${ad?.kv?.container as unknown as string}/noImpression/${
                        //         window.location.pathname
                        //     }`,
                        // );
                        if (onNoContent) {
                            onNoContent();
                        }
                    },
                    onImpressionResponse: (data: any) => {
                        setIsVisible(true);
                        setIsDisplayed(data?.retAdCount !== 0);
                        // addView(
                        //     `/container/${ad?.kv?.container as unknown as string}/onImpression/${
                        //         window.location.pathname
                        //     }`,
                        // );
                    },
                    onError: (data: any) => {
                        if (onNoContent) {
                            setIsDisplayed(false);
                            onNoContent();
                        }
                        addView(
                            `/container/${ad?.kv?.container as unknown as string}/blockedByAdblockerOrError/${
                                window.location.pathname
                            }`,
                        );
                    },
                }),
            );
        });
    }

    useEffect(() => {
        if (!targetID) return;
        requestAd();
    }, [targetID]);

    function initMutationObserver() {
        if (!ref.current) return;
        const observer = new MutationObserver(function (mutations) {
            mutations.forEach(function (mutationRecord) {
                if (!ref.current) return;
                const style = getComputedStyle(ref.current);
                if (removeMarginTopOnWFull) {
                    if (ref.current.style.width === '100%') {
                        setRemoveMarginTopOnWFull(true);
                    }
                }
                if (style.display === 'none') {
                    setIsDisplayed(false);
                    if (onClosed) {
                        onClosed();
                    }
                }
            });
        });
        observer.observe(ref.current, { attributes: true, attributeFilter: ['style'] });
    }

    useEffect(() => {
        setIsDisplayed(false);
        let observer: IntersectionObserver;
        const preloadAds = process.env.REACT_APP_PRELOAD_ADS === '1';
        if (!(preloadMovieJobAd || preloadAds)) {
            observer = new IntersectionObserver(
                ([entry]) => {
                    if (entry.isIntersecting) {
                        observer.unobserve(entry.target);
                        observer.disconnect();
                        setTargetID(`${(ad?.kv?.container as string) || 'ad'}-${generateId('ad')}`);
                    }
                },
                { threshold: 0.0 }, // This represents the % of how much the component has to be visible to be loaded.
            );

            if (ref.current) {
                observer.observe(ref.current);
            }

            initMutationObserver();
        } else {
            setTargetID(`${(ad?.kv?.container as string) || 'ad'}-${generateId('ad')}`);
            initMutationObserver();
        }

        return () => {
            if (ref.current) {
                observer.unobserve(ref.current);
            }
        };
    }, []);

    const isAdTitleBottom = true;
    const hideAdTitle = (ad?.kv?.container || '').includes('native');
    useEffect(() => {
        if ((ad?.kv?.container || '').includes('leaderboard')) {
            setLeaderboardLittle(isScrolled);
        }
    }, [isScrolled]);

    return !ad?.kv ? null : (
        <div
            ref={ref}
            className={`${(className || '').includes('absolute') ? '' : 'relative'} ${
                ad?.kv?.container === 'native' ? 'rounded' : ''
            } ${splash && isPlatform('cordova') && isPlatform('ios') ? '[&>iframe]:!top-[35px]' : ''} 
            ${splash ? '[&>iframe]:!h-[100%]' : ''} 
            duration-1000 transition-opacity ${isVisible ? 'opacity-100' : 'opacity-0'} mx-auto ${
                hasSpacings && isDisplayed && !splash ? 'mb-block md:mb-block' : ''
            } ${removeMarginTop ? '!-mt-[var(--padding-top)]' : ''} ${className || ''} ${
                !isDisplayed && !splash ? '!h-0' : ''
            }`}
            id={targetID}
        >
            {hideAdTitle || splash ? (
                ''
            ) : (
                <div
                    className={`text-left text-gray-400 text-xs w-[90%] mx-auto ${
                        isAdTitleBottom ? 'absolute bottom-0 transform translate-y-full self-start' : ''
                    }`}
                >
                    {t('main.advertisement')}
                </div>
            )}
        </div>
    );
}

export default AdComponent;
