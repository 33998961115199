import { IonRow, IonRouterLink } from '@ionic/react';
import { TikTokIcon, YoutubeIcon, FacebookIcon } from './Icons';
import InstagramIconUrl from './Icons/instagram.svg';

export default function SocialNetworks() {
    return (
        <IonRow className="menu-social-links">
            <IonRouterLink
                href="https://www.instagram.com/eldoradio_lux/?hl=en"
                target="_blank"
                className="menu-social-link bg-[white]"
            >
                {/* we do not use directly svg because of id conflict (multiple id on the same page, see instagram.svg code <linearGradient id="paint1_linear_2713_36447" />) */}
                <img
                    src={InstagramIconUrl}
                    alt="instagram"
                />
            </IonRouterLink>

            <IonRouterLink
                href="https://www.tiktok.com/discover/eldoradio_lux?lang=en"
                target="_blank"
                className="menu-social-link bg-[black]"
            >
                <TikTokIcon />
            </IonRouterLink>

            <IonRouterLink
                href="https://www.youtube.com/channel/UCgtVSYB7EuKjPckT5EKa21g"
                target="_blank"
                className="menu-social-link bg-[red]"
            >
                <YoutubeIcon />
            </IonRouterLink>

            <IonRouterLink
                href="https://www.facebook.com/eldoradiolux"
                target="_blank"
                className="menu-social-link bg-[#1877f2]"
            >
                <FacebookIcon />
            </IonRouterLink>
        </IonRow>
    );
}
