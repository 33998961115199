import { ReactComponent as CheckMarkIcon } from 'components/Icons/checkmark.svg';
import CrossIcon from 'components/Icons/CrossIcon';

type AlertProps = {
    children: React.ReactNode;
    type?: 'success';
    onClose?: () => void;
};

export default function Alert({ children, type = 'success', onClose }: AlertProps) {
    return (
        <div className="relative flex py-[10px] pr-[20px] pl-[15px] text-[#141414] bg-success h-[60px] w-full rounded-[10px] shadow-[5px_10px_45px_rgba(103,206,103,0.35)] items-center">
            <div className="grid place-items-center bg-white rounded-full w-[35px] h-[35px]">
                {type === 'success' ? <CheckMarkIcon /> : ''}
            </div>
            <div className="typo-b2 regular ml-[10px] w-[200px]">{children}</div>
            <div
                className="absolute right-[12.5px] top-[12.5px] cursor-pointer"
                onClick={onClose}
            >
                <CrossIcon
                    color="#141414"
                    width="12.5"
                    height="12.5"
                />
            </div>
        </div>
    );
}
