import { IonSearchbar } from '@ionic/react';
import useClickOutside from 'hooks/useClickOutside';
import useDebounce from 'hooks/useDebounce';
import { useRef, useState } from 'react';

type AutocompleteProps<T> = {
    search: (s: string) => Promise<Array<T>>;
    optionKey: keyof T;
    renderOption: (opt: T, selected: boolean) => React.ReactNode;
    className?: string;
    inputClassName?: string;
    // press "enter" or click "search" on the keyboard;
    onValidateSearch?: (s: string) => void;
    debounceMilliseconds?: number;
};

export default function Autocomplete<T>({
    search,
    inputClassName,
    className,
    renderOption,
    optionKey,
    onValidateSearch,
    debounceMilliseconds = 1000,
}: AutocompleteProps<T>) {
    const [internalValue, setInternalValue] = useState<T>();
    const [internalOptions, setInternalOptions] = useState<T[]>([]);
    const [internalSearchString, setInternalSearchString] = useState<string>('');

    const [isResultsOpen, setIsResultsOpen] = useState(false);

    const isSelected = (opt: T) => opt[optionKey] === internalValue?.[optionKey];
    const select = (opt: T) => setInternalValue(opt);

    async function handleSearch() {
        if (internalSearchString.length > 0) {
            setInternalOptions(await search(internalSearchString));
            setIsResultsOpen(true);
        } else {
            setIsResultsOpen(false);
        }
    }

    const autocompleteRef = useClickOutside(() => setIsResultsOpen(false), []);
    useDebounce(handleSearch, [internalSearchString], debounceMilliseconds);

    const handleClickAutoComplete = () => {
        if (internalSearchString.length > 0) {
            setIsResultsOpen(!isResultsOpen);
        }
    };

    const handleValidateSearch = () => {
        if (onValidateSearch) {
            onValidateSearch(internalSearchString);
            setIsResultsOpen(false);
        }
    };

    return (
        <div
            className={`relative ${className || ''}`}
            ref={autocompleteRef}
            onClick={handleClickAutoComplete}
        >
            <IonSearchbar
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        handleValidateSearch();
                    }
                }}
                className={`${inputClassName || ''} p-0`}
                onIonInput={(e) => setInternalSearchString(e.target.value!)}
                mode="md"
            />
            <div
                className={`${
                    isResultsOpen
                        ? '[&>*]:opacity-100 scale-y-100'
                        : 'scale-y-0 [&>*]:opacity-0 select-none pointer-events-none'
                } transition-all [&>*]:transition-all origin-top-left absolute bottom-0 left-0 w-full max-w-full transform translate-y-[calc(100%+16px)] z-50 bg-[var(--eldo-background-alt-color)] p-[20px] rounded max-h-[550px] overflow-auto`}
            >
                {internalOptions.map((opt, index) => (
                    <div
                        key={opt[optionKey] as string}
                        onClick={() => select(opt)}
                        className="cursor-pointer"
                    >
                        {renderOption(opt, isSelected(opt))}
                        {index < internalOptions.length - 1 ? (
                            <div className="my-[16px] border-t border-solid border-[#555] w-full" />
                        ) : (
                            ''
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
}
