import PageLayout from 'components/PageLayout';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';
import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { Link } from 'react-router-dom';
import useResponsive from 'features/responsive/responsive';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import LoadingIndicator from 'components/LoadingIndicator';
import { prepareUrlTitle } from 'lib/utils';
import Title from '../components/Title';
import usePictureReports from '../features/pictureReport/hooks/usePictureReport';
import CardVertical from '../components/core/CardVertical';
import Section from '../components/core/Section';
import CardListingChunksWithAds from '../components/CardListingChunksWithAds';

export default function PicturesReports() {
    const { ads } = useAds({ page: 'report' });

    const { t } = useTranslation();
    const { IsMobile } = useResponsive();

    const { list, isLoading, canLoadMore, loadMore } = usePictureReports();

    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    loadMore();
                }
            },
            { threshold: 1 },
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, list]);

    return (
        <PageLayout
            title={t('pictures_report')}
            hasBackButton
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}

                <Section
                    waveContainerProps={{
                        autoMargin: true,
                        fixed: 'page',
                        reverse: true,
                        position: 'top-right',
                    }}
                >
                    <IonRow className="">
                        <IonCol size-xs={12}>
                            {!IsMobile && (
                                <Title
                                    className="mb-blockInnerMobile md:mb-blockInner"
                                    main
                                    title={t('pictures_report')}
                                />
                            )}
                            {list?.length ? (
                                <CardListingChunksWithAds
                                    data={list}
                                    adNativeClassName="overflow-hidden"
                                    adsBetween={ads ? [ads.data[1]] : []}
                                    isVerticalCards
                                    chunksSize={8}
                                >
                                    {(report) => (
                                        <Link
                                            to={`/events/fotoen/${report.id as unknown as string}/${prepareUrlTitle(report?.title)}`}
                                            key={report.id}
                                            className="relative"
                                        >
                                            <div className="md:duration-300 md:hover:scale-105 relative">
                                                <CardVertical
                                                    thumbnailUrl={report.thumbnailUrl}
                                                    title={report.title}
                                                    className="h-full"
                                                    hasWavePattern={false}
                                                    hasCardHoverEffect={false}
                                                />
                                                <div
                                                    className={`before:m-[10px] ${
                                                        IsMobile
                                                            ? 'picture-report-cut-corner-mobile'
                                                            : 'picture-report-cut-corner'
                                                    }`}
                                                />
                                            </div>
                                        </Link>
                                    )}
                                </CardListingChunksWithAds>
                            ) : (
                                <LoadingIndicator />
                            )}
                        </IonCol>
                    </IonRow>
                    {canLoadMore ? (
                        <IonRow className="justify-center mt-block-innerMobile md:mt-block-inner">
                            <div
                                className="m-auto h-4"
                                ref={observerTarget}
                            />
                        </IonRow>
                    ) : (
                        ''
                    )}
                    {ads && !canLoadMore && <AdComponent ad={ads.data[2]} />}
                </Section>
            </IonGrid>
        </PageLayout>
    );
}
