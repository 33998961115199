import { ReactChild, ReactChildren } from 'react';
import { useSwiper } from 'swiper/react';
import Card from './Card';

type NavigationSlideProps = {
    children: ReactChildren | ReactChild;
    setActiveSlide: (index: number) => void;
    active: boolean;
    background?: string;
    index: number;
};

export default function NavigationSlide({ children, index, setActiveSlide, active, background }: NavigationSlideProps) {
    const swiper = useSwiper();

    const onClick = () => {
        swiper.slideToLoop(index);
        setActiveSlide(index);
    };

    return (
        <Card
            onClick={onClick}
            style={background ? { backgroundImage: `url('assets/img/webchannels/${background}')` } : {}}
            className={` py-[10px] px-[30px] text-base h-[125px] w-full flex flex-col items-start bg-cover justify-center ${
                active ? 'border-2 border-primary' : ''
            } `}
        >
            {children}
        </Card>
    );
}
