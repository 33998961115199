import { Swiper, SwiperSlide } from 'swiper/react';
import { useEffect, useRef } from 'react';
import { Swiper as SwiperClass } from 'swiper/types';
import useRadio from 'features/radio/hooks/useRadio';
import RadioSlide from '../../radio/component/RadioSlide';
import NavigationSlide from '../../../components/NavigationSlide';
import { ShowPageInfoItem } from '../../../types';

const bkPoints = {
    576: {
        slidesPerView: 2.3,
        spaceBetween: 10,
    },
    768: {
        slidesPerView: 3.3,
        spaceBetween: 10,
    },
    1024: {
        slidesPerView: 4.3,
        spaceBetween: 10,
    },
};

export default function ShowSwiper({
    shows,
    currentShow,
    setCurrentShow,
}: {
    shows: ShowPageInfoItem[];
    currentShow: ShowPageInfoItem;
    setCurrentShow: (show: ShowPageInfoItem) => void;
}) {
    const { data, isLoading } = useRadio();
    const swiperRef = useRef<SwiperClass>();

    const isEldoCurrentShowPlayed = (show: ShowPageInfoItem) => show.show.name === data?.show?.name;

    useEffect(() => {
        const showIndex = shows.findIndex(isEldoCurrentShowPlayed);
        if (showIndex === -1 || swiperRef.current == null) return;
        swiperRef.current?.slideToLoop(showIndex);
        setCurrentShow(shows[showIndex]);
    }, []);

    return (
        <Swiper
            loopPreventsSlide
            freeMode
            spaceBetween={10}
            slidesPerView={1.3}
            breakpoints={bkPoints}
            className="mb-blockMobile md:mb-block"
            onSwiper={(swiper) => {
                swiperRef.current = swiper;
            }}
        >
            {shows.map((show, index) => (
                <SwiperSlide key={index}>
                    <NavigationSlide
                        setActiveSlide={(slideIndex: number) => {
                            setCurrentShow(show);
                        }}
                        active={currentShow === show}
                        index={index}
                    >
                        {isEldoCurrentShowPlayed(show) ? (
                            <RadioSlide data={data} />
                        ) : (
                            <>
                                <div className="typo-b1 regular text-left">{show.show.name}</div>
                                <div className="typo-b2 regular">
                                    {show.startTime} - {show.endTime}
                                </div>
                            </>
                        )}
                    </NavigationSlide>
                </SwiperSlide>
            ))}
        </Swiper>
    );
}
