import { IonCol, IonContent, IonGrid, IonLabel, IonModal, IonRow } from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import AppIcon from '../../../../components/Icons/AppIcon';
import PlayerDetailsMediaPresenterRow from './PlayerDetailsMediaPresenterRow';
import { PlayerContext, PlayerContextType } from '../../PlayerContext';

type PlayerDetailsModalProps = {
    isOpen: boolean;
    onWillDismiss: VoidFunction;
};

export default function PlayerDetailsModal({ isOpen, onWillDismiss }: PlayerDetailsModalProps) {
    const { t } = useTranslation();
    const {
        playerState: { media },
    } = useContext<PlayerContextType | null>(PlayerContext) as PlayerContextType;

    return (
        <IonModal
            showBackdrop
            isOpen={isOpen}
            initialBreakpoint={0.4}
            breakpoints={[0, 0.4, 0.75]}
            onWillDismiss={onWillDismiss}
        >
            <IonContent className="ion-padding">
                <IonGrid>
                    <br />
                    {media?.presenters?.map((presenter) => (
                        <PlayerDetailsMediaPresenterRow
                            name={presenter.name}
                            url={presenter.thumbnailUrl}
                        />
                    ))}
                    <br />
                    <IonRow>
                        <IonCol size="auto">
                            <AppIcon name="facebook" />
                        </IonCol>
                        <IonCol size="auto">
                            <IonLabel>{t('share')}</IonLabel>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    );
}
