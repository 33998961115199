import { IonSearchbar } from '@ionic/react';
import Select from 'components/Select';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Cinema, CinemasResponse, MovieSession, WeekDates } from 'types';

export type CinemaFiltersProps = {
    className?: string;
    cinemas?: CinemasResponse;
    sessionsOptions?: WeekDates[];
    cinema?: Cinema;
    onCinemaChange: (c: Cinema) => void;
    onDateChange: (c: WeekDates) => void;
    onSearch: (search: string) => void;
};

export default function CinemaFilters({
    className,
    cinemas,
    onSearch,
    cinema,
    onCinemaChange,
    onDateChange,
    sessionsOptions,
}: CinemaFiltersProps) {
    const [search, setSearch] = useState('');

    const { t } = useTranslation();

    useEffect(() => {
        onSearch(search);
    }, [search, onSearch]);

    // preselect first cinema:
    useEffect(() => {
        if (cinema == null && cinemas?.lu?.[0] != null && sessionsOptions) {
            onCinemaChange(cinemas?.lu[0]);
            onDateChange(sessionsOptions[0]);
        } else if (cinema) onCinemaChange(cinema);
    }, [cinemas, cinema]);

    const cinemasList = [
        ...(cinemas?.lu || []),
        ...(cinemas?.fr || []),
        ...(cinemas?.be || []),
        ...(cinemas?.de || []),
    ];

    const cinemasOptions = cinemasList.map((c) => ({
        label: c.fullName,
        value: c.id,
        categ: t(`cinema.countries.${c.country}`),
    }));

    return (
        <div className={`${className || ''} md:flex items-center flex-column gap-standardMobile md:gap-standard`}>
            {cinemas ? (
                <Select
                    roundedBottom
                    value={cinema?.id}
                    options={cinemasOptions}
                    onChange={(opt) => {
                        // @ts-ignore
                        const idCinemaSelected = opt.value;
                        const cine = cinemasList.find((c) => c.id === idCinemaSelected);
                        if (cine) onCinemaChange(cine);
                    }}
                />
            ) : (
                ''
            )}
            {sessionsOptions ? (
                <Select
                    roundedBottom
                    options={
                        sessionsOptions?.map((session, index) => {
                            return {
                                label: `${moment(session.start).format('DD.MM.YY')}  \n ${moment(session.end).format(
                                    'DD.MM.YY',
                                )}`,
                                value: index,
                            };
                        }) || []
                    }
                    onChange={(date) => {
                        // @ts-ignore
                        const dateRange = sessionsOptions[date.value];
                        if (dateRange) onDateChange(dateRange);
                    }}
                />
            ) : (
                ''
            )}
            <IonSearchbar
                value={search}
                onIonInput={(e) => setSearch(e.target.value!)}
                className="w-full typo-b1 regular !p-0 !mt-4 md:!mt-0 !mb-8 md:!mb-0"
                mode="md"
            />
        </div>
    );
}
