import { t } from 'i18next';
import { match as Match } from 'react-router';
import { SwiperSlide, Swiper } from 'swiper/react';
import useResponsive from 'features/responsive/responsive';
import BubbleNavigationItem from '../../components/BubbleNavigationItem';
import Title from '../../components/Title';

const trafficInfoRoutes = [
    {
        title: 'my_daily_routes',
        url: '',
        src: '/assets/img/route.png',
    },
    {
        title: 'maps',
        url: '/maps',
        src: '/assets/img/map.png',
    },
    {
        title: 'cameras',
        url: '/cameras',
        src: '/assets/img/camera.png',
    },
    {
        title: 'news',
        url: '/news',
        src: '/assets/img/flag.png',
    },
    {
        title: 'radars',
        url: '/radars',
        src: '/assets/img/radar.png',
    },
];

const trafficInfoNavigationBreakpoints = {
    0: {
        slidesPerView: 3.8,
    },
    400: {
        slidesPerView: 4.5,
    },
};

export default function TrafficInfoNavigation({ match }: { match: Match }) {
    const { IsDesktop } = useResponsive();

    if (IsDesktop) {
        return (
            <>
                <Title
                    title={t('traffic_info')}
                    main
                />
                <div className="flex gap-4 overflow-x-auto mt-block-innerMobile md:mt-block-inner mb-blockMobile md:mb-block">
                    {trafficInfoRoutes.map((route, index) => (
                        <BubbleNavigationItem
                            key={index}
                            title={t(route.title)}
                            src={route.src}
                            to={`${match.url}${route.url}`}
                        />
                    ))}
                </div>
            </>
        );
    }

    return (
        <div className="mb-blockMobile md:mb-block full-width">
            <Swiper
                breakpoints={trafficInfoNavigationBreakpoints}
                className="!pl-[var(--padding-start)] !md:pl-0"
            >
                {trafficInfoRoutes.map((route, index) => (
                    <SwiperSlide>
                        <BubbleNavigationItem
                            key={index}
                            title={t(route.title)}
                            src={route.src}
                            to={`${match.url}${route.url}`}
                        />
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
}
