import { PropsWithChildren } from 'react';

export default function Pill({
    children,
    className,
    title,
    onClick,
}: PropsWithChildren<{ className?: string; title?: string; onClick?: React.MouseEventHandler }>) {
    return (
        <div
            title={title}
            onClick={onClick}
            className={`bg-secondary text-white typo-b2 regular rounded px-[12px] py-[4.5px] ${className || ''}`}
        >
            {children}
        </div>
    );
}
