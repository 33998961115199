import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAds from 'hooks/useAds';
import moment from 'moment';

import AdComponent from 'components/Ads/AdComponent';
import BestMomentsSwiper from 'features/bestMoments/components/Eldo4RealSwiper';
import ChannelsSwiper from '../features/radio/component/ChannelSwiper';
import ChannelContent from '../features/radio/component/ChannelContent';
import { channels } from '../features/radio/constants';
import PageLayout from '../components/PageLayout';
import 'swiper/css';
import Title from '../components/Title';
import Section from '../components/core/Section';
import RadioCard from '../components/Cards/RadioCard';

export default function RadioPage() {
    const [currentChannel, setCurrentChannel] = useState(channels[0]);
    const { ads } = useAds({ page: 'radio', reloadOnChangeString: currentChannel.title });
    const { t } = useTranslation();

    return (
        <PageLayout
            title={t('radio.title')}
            cssClass="radio-page"
        >
            <IonGrid fixed>
                {ads && <AdComponent ad={ads.data[0]} />}
                <IonRow className="md:mb-block mb-blockMobile">
                    <Title
                        main
                        title={t('radio.title')}
                        className="max-md:hidden"
                    />
                    <IonCol
                        size-xs={12}
                        className="md:mt-block-inner"
                    >
                        <ChannelsSwiper
                            currentChannel={currentChannel}
                            setCurrentChannel={setCurrentChannel}
                            channels={channels}
                        />
                    </IonCol>
                </IonRow>
                <Section>
                    <ChannelContent
                        channel={currentChannel}
                        slotAd={
                            ads ? (
                                <AdComponent
                                    hasSpacings={false}
                                    ad={ads.data[1]}
                                />
                            ) : null
                        }
                    />
                </Section>
                <Section
                    title={t('eldo4real')}
                    path="/podcast-replay/category/eldo4real"
                >
                    <BestMomentsSwiper />
                </Section>
                {/* TODO : next show (currently : missing data ??) */}
                {/* <Section title={t('next_show')}>
                    <RadioCard
                        src={`assets/img/webchannels/${'eldoradio.jpg'}`}
                        title={`${'test'}`}
                        liveStart={moment().format('HH:mm')}
                        liveEnd={moment().format('HH:mm')}
                        showRunner='Jean'
                        isVertical
                        displayWatchBtn={false}
                        displayPlayBtn={false}
                    />
                </Section> */}
                {ads && (
                    <AdComponent
                        hasSpacings={false}
                        ad={ads.data[2]}
                    />
                )}
            </IonGrid>
        </PageLayout>
    );
}
