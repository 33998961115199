import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import { luxembourgPosition } from '../../constants';

export default function TrafficMap() {
    const { t } = useTranslation();

    const { latitude, longitude } = luxembourgPosition;

    return (
        <div>
            <section>
                <Title title={t('map')} />

                <div className="mt-block-innerMobile md:mt-block-inner mb-blockMobile md:mb-block">
                    <iframe
                        className="rounded"
                        title="waze"
                        src={`https://embed.waze.com/iframe?zoom=12&lat=${latitude}&lon=${longitude}`}
                        width="100%"
                        height="455"
                    />
                </div>
            </section>
        </div>
    );
}
