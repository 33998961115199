import { useTranslation } from 'react-i18next';
import Title from 'components/Title';
import { Song } from '../types';
import LastSong from './LastSong';

export default function LastSongs({ songs = [], className = '' }: { songs: Song[]; className?: string }) {
    const { t } = useTranslation();

    return (
        <div>
            <Title
                className={className}
                title={t('last_songs')}
            />
            <div className="grid lg:grid-cols-2 md:gap-standard gap-standardMobile pt-blockInnerMobile md:pt-blockInner">
                {songs.map((song: Song, index: number) => {
                    return (
                        <LastSong
                            key={index}
                            song={song}
                        />
                    );
                })}
            </div>
        </div>
    );
}
