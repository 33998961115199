/* eslint-disable no-nested-ternary */
import { Swiper as SwiperClass } from 'swiper/types';
import 'swiper/css/zoom';
import { SwiperSlide, Swiper, SwiperProps } from 'swiper/react';
import React, { useEffect, useRef, useState } from 'react';
import useResponsive from 'features/responsive/responsive';
import { useMediaQuery } from 'react-responsive';
import { Zoom, Lazy } from 'swiper';
import CarouselNavigation from './CarouselNavigation';

export type CarouselProps<T = any> = SwiperProps & {
    data: T[];
    itemKey?: string | ((d: T, index: number) => string | number);
    children: (item: T, index: number) => React.ReactNode;
    navButtonsOverlap?: boolean;
    isFullWithOnMobile?: boolean;
    className?: string;
    displayNavArrows?: boolean;
    forceNavEndArrowsVisible?: boolean;
    slidesPerView?: number;
    isGallery?: boolean;
    swiperHeightAuto?: boolean;
};

type CarouselComputedData = { node: React.ReactNode; key: string };

export default function Carousel<T>({
    data,
    className,
    itemKey,
    children,
    displayNavArrows = true,
    navButtonsOverlap = true,
    isFullWithOnMobile = true,
    forceNavEndArrowsVisible,
    slidesPerView,
    isGallery = false,
    swiperHeightAuto = true,
    spaceBetween,
    ...swiperProps
}: CarouselProps<T>) {
    const { IsMobile } = useResponsive();
    const isTablet1 = useMediaQuery({ minWidth: 768, maxWidth: 992 });
    const isTablet2 = useMediaQuery({ minWidth: 992, maxWidth: 1200 });

    const getKey =
        itemKey == null
            ? // @ts-ignore
              (item, index) => index
            : typeof itemKey === 'string'
            ? // @ts-ignore
              (item: T, index) => item[itemKey]
            : // @ts-ignore
              (item: T, index) => itemKey(item, index);
    const swiperRef = useRef<SwiperClass>();

    const { onSwiper, onSlideChange } = swiperProps;
    const swiperPropsCopy = {
        ...swiperProps,
    };
    delete swiperPropsCopy.onSwiper;
    delete swiperPropsCopy.onSlideChange;

    const [reachEnd, setReachEnd] = useState(false);
    const [reachStart, setReachStart] = useState(!swiperProps.loop);

    const getSlidesPerView = () => {
        if (slidesPerView) return slidesPerView;
        if (IsMobile) return 1.2;
        if (isTablet1) return 2;
        return isTablet2 ? 3 : 4;
    };

    const getSpaceBetween = () => {
        if (spaceBetween) return spaceBetween;
        if (IsMobile) return 16;
        return 30;
    };
    return (
        <div className={`relative ${IsMobile && isFullWithOnMobile ? 'full-width' : ''} ${className || ''}`}>
            <Swiper
                modules={[Zoom, Lazy]}
                freeMode
                cssMode={!isGallery}
                preloadImages
                watchSlidesProgress
                lazy={{
                    enabled: true,
                    loadPrevNext: true,
                    loadPrevNextAmount: 2,
                }}
                // spaceBetween -> have to be iso tailwind-config spacing 'standard'
                spaceBetween={getSpaceBetween()}
                slidesPerView={slidesPerView || getSlidesPerView()}
                onSwiper={(swiper) => {
                    swiperRef.current = swiper;
                    if (onSwiper) {
                        onSwiper(swiper);
                    }
                }}
                onSlidesLengthChange={(swiper) => {
                    setTimeout(() => {
                        if (!swiperProps.loop) {
                            setReachEnd(swiper.isEnd || swiper.isLocked);
                        }
                    }, 0);
                }}
                onSlideChange={(swiper) => {
                    if (!swiperProps.loop) {
                        setReachEnd(swiper.isEnd || swiper.isLocked);
                        setReachStart(swiper.isBeginning);
                    }
                    if (onSlideChange) {
                        onSlideChange(swiper);
                    }
                }}
                
                className={`w-full relative ${isFullWithOnMobile ? '!pl-[var(--padding-start)] !md:pl-0' : ''}`}
                zoom={{ maxRatio: 3, minRatio: 1, toggle: true }}
                {...swiperPropsCopy}
            >
                {data?.map((item, index) => (
                    <SwiperSlide
                        key={getKey(item, index)}
                        style={{ height: swiperHeightAuto ? 'auto' : '' }}
                    >
                        {children(item, index)}
                    </SwiperSlide>
                ))}
            </Swiper>
            {displayNavArrows ? (
                <CarouselNavigation
                    next={() => swiperRef.current?.slideNext()}
                    prev={() => swiperRef.current?.slidePrev()}
                    reachEnd={reachEnd}
                    reachStart={reachStart}
                    overlap={navButtonsOverlap}
                    forceNavEndArrowsVisible={forceNavEndArrowsVisible}
                />
            ) : (
                ''
            )}
        </div>
    );
}
