/* eslint no-useless-return: 0 */
/* eslint consistent-return: 0 */
import { generateId } from '@ionic/react';
import React, { useState } from 'react';

export type WavePatternContainerPosition =
    | 'top-left'
    | 'bottom-left'
    | 'top-right'
    | 'bottom-right'
    | 'center-right'
    | 'center-left';

export type WavePatternContainerProps = {
    children: React.ReactNode;
    position?: WavePatternContainerPosition;
    component?: 'div' | 'section';
    className?: string;
    fixed?: 'page' | 'block' | 'block-inner';
    patternClassName?: string;
    autoMargin?: boolean;
    reverse?: boolean;
    onlyMobile?: boolean;
    onlyDesktop?: boolean;
};

export default function WavePatternContainer({
    onlyDesktop,
    onlyMobile,
    children,
    position = 'top-left',
    component = 'div',
    className,
    fixed = 'block-inner',
    patternClassName,
    autoMargin,
    reverse,
}: WavePatternContainerProps) {
    const [patternID] = useState(generateId());
    const [wrapperID] = useState(generateId());
    const [patternColorId] = useState(generateId());

    function computeStylePageEdge() {
        const wrapperEl = document.getElementById(wrapperID);
        const patternEl = document.getElementById(patternID);
        if (!patternEl || !wrapperEl) return;

        const isPositionLeft = position === 'bottom-left' || position === 'top-left';
        const { left, right, width } = wrapperEl.getBoundingClientRect();
        if (isPositionLeft) {
            patternEl.style.left = `-${left}px`;
        } else {
            patternEl.style.right = `-${right - width}px`;
        }
    }

    React.useEffect(() => {
        if (fixed !== 'page') return;
        const observer = new ResizeObserver(computeStylePageEdge);
        observer.observe(document.body);

        return () => {
            observer.unobserve(document.body);
        };
    }, []);

    function getPatternClassNames() {
        const res: string[] = ['absolute'];
        const isPositionLeft = position === 'bottom-left' || position === 'top-left';
        const isPositionTop = position === 'top-left' || position === 'top-right';
        const isPositionCenter = position === 'center-left' || position === 'center-right';

        if (fixed === 'block' || reverse || isPositionCenter) {
            res.push('transform');
        }

        if (reverse) {
            res.push('-scale-100');
        }

        if (isPositionLeft) {
            res.push('left-0');
            if (fixed === 'block') {
                res.push('-translate-x-[100%]');
            }
        } else {
            res.push('right-0');
            if (fixed === 'block') {
                res.push('translate-x-[100%]');
            }
        }
        if (isPositionTop) {
            if (!patternClassName?.includes('top')) {
                res.push('top-0');
            }
        } else {
            res.push(isPositionCenter ? 'top-1/2 -translate-y-1/2' : 'bottom-0');
        }

        if (patternClassName) {
            res.push(patternClassName);
        }

        if (autoMargin) {
            res.push('mt-12 mb-12');
        }

        if (onlyDesktop) {
            res.push('hidden md:block');
        }

        if (onlyMobile) {
            res.push('md:hidden');
        }

        return res.join(' ');
    }

    return React.createElement(
        component,
        {
            className: `relative overflow-visible ${className || ''}`,
            id: wrapperID,
        },
        <>
            {children}
            <svg
                id={patternID}
                className={getPatternClassNames()}
                width="108"
                height="56"
                viewBox="0 0 108 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M27.4327 0.0701294L28.2981 0.557274L40.7163 7.54773L53.1346 0.557274L54 0.0701294L54.8654 0.557274L67.2837 7.54773L79.7019 0.557274L80.5673 0.0701294L81.4327 0.557274L93.851 7.54773L106.269 0.557274L108 3.66406L94.7163 11.1417L93.851 11.6288L92.9856 11.1417L80.5673 4.15121L68.149 11.1417L67.2837 11.6288L66.4183 11.1417L54 4.15121L41.5817 11.1417L40.7163 11.6288L39.851 11.1417L27.4327 4.15121L15.0144 11.1417L14.149 11.6288L13.2837 11.1417L0 3.66406L1.73078 0.557274L14.149 7.54773L26.5673 0.557274L27.4327 0.0701294ZM27.4327 21.7908L28.2981 22.2779L40.7163 29.2684L53.1346 22.2779L54 21.7908L54.8654 22.2779L67.2837 29.2684L79.7019 22.2779L80.5673 21.7908L81.4327 22.2779L93.851 29.2684L106.269 22.2779L108 25.3847L94.7163 32.8623L93.851 33.3495L92.9856 32.8623L80.5673 25.8719L68.149 32.8623L67.2837 33.3495L66.4183 32.8623L54 25.8719L41.5817 32.8623L40.7163 33.3495L39.851 32.8623L27.4327 25.8719L15.0144 32.8623L14.149 33.3495L13.2837 32.8623L0 25.3847L1.73078 22.2779L14.149 29.2684L26.5673 22.2779L27.4327 21.7908ZM28.2981 43.9986L27.4327 43.5114L26.5673 43.9986L14.149 50.9891L1.73078 43.9986L0 47.1054L13.2837 54.583L14.149 55.0701L15.0144 54.583L27.4327 47.5925L39.851 54.583L40.7163 55.0701L41.5817 54.583L54 47.5925L66.4183 54.583L67.2837 55.0701L68.149 54.583L80.5673 47.5925L92.9856 54.583L93.851 55.0701L94.7163 54.583L108 47.1054L106.269 43.9986L93.851 50.9891L81.4327 43.9986L80.5673 43.5114L79.7019 43.9986L67.2837 50.9891L54.8654 43.9986L54 43.5114L53.1346 43.9986L40.7163 50.9891L28.2981 43.9986Z"
                    fill={`url(#${patternColorId})`}
                />
                <defs>
                    <linearGradient
                        id={patternColorId}
                        x1="-4.03994e-07"
                        y1="27.0089"
                        x2="89"
                        y2="28.0702"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor="#F08B1F" />
                        <stop
                            offset="1"
                            stopColor="#F08B1F"
                            stopOpacity="0"
                        />
                    </linearGradient>
                </defs>
            </svg>
        </>,
    );
}
