import { IonRow, IonGrid, IonCol } from '@ionic/react';
import '@ionic/react/css/ionic-swiper.css';

import PageLayout from 'components/PageLayout';
import EldoWidget from 'components/Widgets/EldoWidget';
import useGeolocation from 'hooks/useGeolocation';
import { useTranslation } from 'react-i18next';
import Title from 'components/Title';
import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import useAds from 'hooks/useAds';
import LoadingIndicator from 'components/LoadingIndicator';
import AdComponent from 'components/Ads/AdComponent';
import useResponsive from 'features/responsive/responsive';
import { getWeather } from '../lib/ApiService';
import ForecastPerHour from '../features/meteo/ForecastPerHour';
import Observation from '../features/meteo/Observation';
import WeeklyForecast from '../features/meteo/WeeklyForecast';

const luxembourgCoord = {
    lat: 49.611622,
    long: 6.131935,
};

export default function MeteoPage() {
    const { ads } = useAds({ page: 'weather' });
    const { t } = useTranslation();
    const { IsMobile } = useResponsive();

    const { position } = useGeolocation();

    const [longitude, latitude] = useMemo(() => {
        if (position?.longitude && position?.latitude) {
            return [parseFloat(position.longitude as string), parseFloat(position.latitude as string)];
        }
        return [luxembourgCoord.long, luxembourgCoord.lat];
    }, [position]);

    const { data: weather, isLoading } = useQuery(['meteo'], () => getWeather(longitude, latitude));

    return (
        <PageLayout
            title={t('weather.title')}
            cssClass="meteo-page /*meteo-page--bg*/"
        >
            {!isLoading && weather ? (
                <IonGrid fixed>
                    {ads && (
                        <AdComponent
                            ad={ads.data[0]}
                            className={IsMobile ? '!top-0' : ''}
                        />
                    )}
                    {!IsMobile && (
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonCol>
                                <Title
                                    title="Weather"
                                    main
                                />
                            </IonCol>
                        </IonRow>
                    )}

                    <IonRow className="meteo-cards-row [&>*]:md:p-[15px] [&>*]:p-[8px]">
                        <Observation observation={weather.observation[0]} />
                        <ForecastPerHour forecast={weather.forecast} />
                        <WeeklyForecast forecast={weather['5_days_forecast']} />
                    </IonRow>

                    <Title
                        title="Other widgets"
                        className="mt-blockMobile md:mt-block"
                    />

                    <div className="mt-block-innerMobile md:mt-block-inner mb-blockMobile md:mb-block grid gap-4 md:grid-cols-2">
                        <EldoWidget
                            icon="traffic"
                            title={t('traffic_info')}
                            path="/traffic-info"
                        />
                    </div>
                    {ads && <AdComponent ad={ads.data[1]} />}
                </IonGrid>
            ) : (
                <LoadingIndicator />
            )}
        </PageLayout>
    );
}
