import React, { useState } from 'react';
import Eye from './Icons/eye';
import EyeSlash from './Icons/eye-slash';
import Input, { InputProps } from './Input';

const PasswordInput = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
    const [show, setShow] = useState(false);

    return (
        <div className="relative">
            <Input
                type={show ? 'text' : 'password'}
                {...props}
                ref={ref}
            />
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <span
                className="absolute bottom-[2rem] right-4 cursor-pointer"
                onClick={() => setShow(!show)}
            >
                {show ? <Eye /> : <EyeSlash />}
            </span>
        </div>
    );
});

export default PasswordInput;
