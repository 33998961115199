import { IonRow } from '@ionic/react';
import { useEffect, useRef } from 'react';
import HorizontalMediumCard from 'components/HorizontalMediumCard';
import { AdResponse } from 'features/advertising/generateAdPayload';
import LoadingIndicator from 'components/LoadingIndicator';
import AdComponent from 'components/Ads/AdComponent';
import useCinemaNewsOfFilmOfTheWeek from 'features/cinema/hooks/useCinemaNewsOrFilmOfTheWeek';
import { getThumbnailForHorizontalArticleList } from 'components/core/ArticlePageComponent';
import { prepareUrlTitle } from 'lib/utils';
import CardListingChunksWithAds from '../components/CardListingChunksWithAds';

function CinemaNews({ isCompactView, filmsOfTheWeek, ads }: CinemaNewsProps) {
    const cinemaNews = useCinemaNewsOfFilmOfTheWeek({ filmsOfTheWeek });
    const observerTarget = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting) {
                    cinemaNews.loadMore();
                }
            },
            { threshold: 1 },
        );

        if (observerTarget.current) {
            observer.observe(observerTarget.current);
        }

        return () => {
            if (observerTarget.current) {
                observer.unobserve(observerTarget.current);
            }
        };
    }, [observerTarget, cinemaNews]);

    return !cinemaNews.isLoading ? (
        <>
            <CardListingChunksWithAds
                className="mb-blockMobile md:mb-block"
                adsBetween={ads ? [ads.data[1]] : []}
                data={cinemaNews?.list || []}
                adNativeClassName="!h-[175px] overflow-hidden"
            >
                {(item) => (
                    <HorizontalMediumCard
                        title={item.title}
                        date={item.date}
                        tags={item.tags ?? []}
                        thumbnailUrl={getThumbnailForHorizontalArticleList(item)}
                        imageCover={item.header?.listing_news ? 'object-fit' : 'object-cover'}
                        truncated={3}
                        linkForPill="/aktuell/news/"
                        linkTo={`/kino/articles/${item.id as unknown as string}/${prepareUrlTitle(item?.title)}`}
                        className="max-h-[175px]"
                    />
                )}
            </CardListingChunksWithAds>
            {cinemaNews.canLoadMore ? (
                <IonRow className="mb-blockMobile md:mb-block">
                    <div
                        className="m-auto h-4"
                        ref={observerTarget}
                    />
                </IonRow>
            ) : (
                ''
            )}
            {ads && !cinemaNews.canLoadMore && !cinemaNews.isLoading && <AdComponent ad={ads.data[2]} />}
        </>
    ) : (
        <LoadingIndicator />
    );
}

type CinemaNewsProps = {
    isCompactView?: boolean;
    filmsOfTheWeek?: boolean;
    ads: AdResponse | undefined;
};

CinemaNews.defaultProps = {
    isCompactView: false,
    filmsOfTheWeek: false,
};

export default CinemaNews;
