import { IonFab, IonGrid, IonIcon, IonRouterLink, IonRow, IonSearchbar } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Ticket } from 'types';
import { Link, useLocation } from 'react-router-dom';
import Title from 'components/Title';
import TicketCard from 'features/ticketing/TicketCard';
import TicketOption from 'features/ticketing/TicketOption';
import { search } from 'ionicons/icons';
import moonIcon from 'components/Icons/moon_Eldo.svg';
import useTicketingPageInfo from 'features/ticketing/useTicketingPageInfo';
import { useEffect, useMemo, useState } from 'react';
import useAds from 'hooks/useAds';
import LoadingIndicator from 'components/LoadingIndicator';
import AdComponent from 'components/Ads/AdComponent';
import useAuth from 'features/auth/useAuth';
import PageLayout from 'components/PageLayout';
import useDebounce from 'hooks/useDebounce';
import WavePatternContainer from 'components/WavePatternContainer';
import useResponsive from 'features/responsive/responsive';
import CrossIcon from 'components/Icons/CrossIcon';
import AdRightBlock from 'components/Ads/AdRightBlock';

export default function TicketingPage() {
    const { t } = useTranslation();
    const { tickets, isLoading, refetch } = useTicketingPageInfo();
    const location = useLocation();
    const [option, setOption] = useState(0);
    const { ads } = useAds({ page: 'ticketing', reloadOnChangeString: option?.toString() });
    const [count, setCount] = useState<number>(0);
    const [resetO, setRestO] = useState(true);
    const [searchValue, setSearchValue] = useState<string>('');
    const { user, isAuthenticated } = useAuth();
    const { IsMobile } = useResponsive();

    const [hideAlertAuth, setHideAlertAuth] = useState(false);

    // @ts-ignore
    const hasNoFilters = () => !location.state?.length && (option === 0 || option == null) && !searchValue.length;

    useEffect(() => {
        if (!isLoading) {
            refetch();
        }
    }, [isAuthenticated]);

    const showTickets = useMemo(() => {
        if (!tickets?.length) return [];
        if (hasNoFilters()) {
            setRestO(true);
            return tickets;
        }
        let list = tickets;
        if (option === 1) {
            list = list?.filter((ticket) => ticket.type === 'sell');
        }
        if (option === 2) {
            list = list?.filter((ticket) => ticket.type === 'buy');
        }
        if ((typeof location.state === 'string' && location.state) || searchValue.length) {
            const val: string = searchValue || (location.state as string);
            const re = new RegExp(val.toLowerCase(), 'g');
            list =
                list?.filter(
                    (ticket) => ticket.description.toLowerCase().match(re) || ticket.title.toLowerCase().match(re),
                ) || [];
        }
        setCount(list?.length);
        setRestO(false);
        return list;
    }, [tickets, location, option, searchValue]);

    const selectOption = (opt: number) => {
        setOption(opt);
    };

    useEffect(() => {
        setOption(0);
    }, [tickets]);

    useEffect(() => {
        setRestO(hasNoFilters());
        location.state = '';
    }, []);

    const reset = () => {
        setCount(0);
        setOption(0);
        setRestO(true);
        setSearchValue('');
    };

    useDebounce(
        () => {
            if (tickets === undefined) return;
            const re = new RegExp(searchValue.toLowerCase(), 'g');
            const result = !searchValue.length
                ? tickets || []
                : tickets?.filter(
                      (ticket) => ticket.description.toLowerCase().match(re) || ticket.title.toLowerCase().match(re),
                  ) || [];
            setCount(result.length);
            setRestO(!searchValue.length);
        },
        [searchValue],
        1000,
    );

    useEffect(() => {
        setRestO(true);
    }, []);

    return (
        <PageLayout
            title={t('ticketing.title')}
            hasBackButton
        >
            <IonGrid fixed>
                <WavePatternContainer
                    autoMargin
                    fixed="page"
                    reverse
                    position="top-right"
                    component="section"
                >
                    {ads && <AdComponent ad={ads.data[0]} />}
                    {resetO ? (
                        <IonRow className="mb-blockMobile md:mb-block max-md:hidden">
                            <Title
                                title={t('ticketing.title')}
                                main
                            />
                        </IonRow>
                    ) : (
                        ''
                    )}
                    {!isAuthenticated && !hideAlertAuth ? (
                        <div className="p-4 typo-b2 regular bg-white b-blockMobile md:mb-block rounded-[10px] !text-[#141414] max-md:mb-blockMobile">
                            <div className="flex justify-between items-center">
                                <div>
                                    <span className="font-medium">{t('ticketBourse.information')} </span>
                                    <span>{t('ticketBourse.loggedInRequired2')}</span>
                                </div>
                                <div
                                    onClick={() => setHideAlertAuth(true)}
                                    className="w-fit cursor-pointer"
                                >
                                    <CrossIcon
                                        color="#141414"
                                        width="12.5"
                                        height="12.5"
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <IonRow className="max-md:hidden md:max-w-[730px] mb-blockMobile md:mb-block">
                        <div className=" w-full">
                            <IonSearchbar
                                value={searchValue}
                                placeholder="What are you looking for ?"
                                onIonChange={(e) => {
                                    setSearchValue(e.target.value!);
                                }}
                                enterkeyhint="enter"
                                mode="md"
                            />
                        </div>
                    </IonRow>
                    {!resetO && (
                        <IonRow>
                            <h3 className="font-bold flex items-center mb-blockInnerMobile md:mb-blockInner">
                                <div>
                                    <img
                                        src={moonIcon}
                                        alt=""
                                        className="lg:w-[18px]"
                                    />
                                </div>
                                <div className="ml-4 typo-h2 md:typo-h1 bold">Your results</div>
                                <div className="ml-3 bg-[#F08B1F] h-[23px] w-[23px] rounded-full font-medium text-[#141414] text-base flex items-center justify-center">
                                    <div>{count}</div>
                                </div>
                            </h3>
                            <div
                                className="max-md:absolute right-[20px] font-normal md:ml-auto typo-b2 regular cursor-pointer"
                                onClick={reset}
                            >
                                Reset results
                            </div>
                        </IonRow>
                    )}
                    <div className="md:flex mb-blockMobile md:mb-block">
                        <TicketOption
                            option={option}
                            onProductTypeChange={selectOption}
                        />
                        <div className={`ml-auto flex-column grid-cols-${user ? 4 : 6}`}>
                            <IonRouterLink
                                routerDirection="forward"
                                routerLink={user ? '/events/ticket-bourse/sendTicket' : '/login'}
                                className={`eldo-button md:w-[500px] md:w-[350px] cursor-pointer typo-b1 grid place-items-center ${
                                    IsMobile ? 'my-blockMobile md:my-block' : ''
                                } `}
                            >
                                {user ? t('ticketBourse.createTicket') : t('ticketBourse.loggedInRequired')}
                            </IonRouterLink>
                        </div>
                    </div>

                    {!isLoading ? (
                        <AdRightBlock ad={ads?.data?.[1]}>
                            <IonRow className="grid md:grid-cols-3 gap-standardMobile md:gap-standard">
                                {showTickets?.map((ticket: Ticket, index: number) => (
                                    <TicketCard
                                        key={index}
                                        {...ticket}
                                    />
                                ))}
                            </IonRow>
                        </AdRightBlock>
                    ) : (
                        <LoadingIndicator />
                    )}
                </WavePatternContainer>
                {ads && <AdComponent ad={ads.data[2]} />}
            </IonGrid>
            <IonFab
                slot="fixed"
                horizontal="end"
                vertical="bottom"
            >
                <Link to="/events/ticket-bourse-search">
                    <div className="flex justify-center items-center bg-[white] rounded-full h-[50px] w-[50px] z-30 md:hidden">
                        <IonIcon
                            icon={search}
                            className="fill-black h-[24px] w-[24px]"
                        />
                    </div>
                </Link>
            </IonFab>
        </PageLayout>
    );
}
