import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App, URLOpenListenerEvent } from '@capacitor/app';

export function AppUrlListener() {
    const history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
            const { url } = event;
            const match = url.match(/eldoradio:\/\/(.*)/);

            if (match && match[1]) {
                const dynamicPart = match[1];
                const transformedUrl = `/${dynamicPart}`;

                history.push(transformedUrl);
            }
        });
    }, []);
    return null;
}

export default AppUrlListener;
