import { useIonToast } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { getMovie } from 'lib/ApiService';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MovieResponse } from 'types';
import { TOAST_DURATION } from '../../../constants';

export type UseMovieProps = {
    id: string | number;
};

export default function useMovie({ id }: UseMovieProps) {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const callback = useCallback(() => {
        return getMovie(id as number);
    }, [id]);

    const { data, isLoading, error, refetch } = useQuery<MovieResponse>(['cinemas-movies', 'movies', id], callback, {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
        enabled: false,
    });

    useEffect(() => {
        if (id != null) {
            refetch();
        }
    }, [id]);

    return {
        error,
        data,
        isLoading,
    };
}
