import { useMemo, createContext, Dispatch, useReducer, Reducer, useEffect, useContext, useState } from 'react';
import moment from 'moment';
import { Channel, Media, PlayerState, Presenter, WebChannel } from './types';
import playerReducer, { PlayerAction } from './playerReducer';
import { RadioContext, RadioContextType } from './RadioContext';
import { ChannelContext, ChannelContextType } from './ChannelContext';
import { channels } from './constants';

export type PlayerContextType = {
    playerState: PlayerState;
    playerDispatch: Dispatch<PlayerAction>;
    playerHeight: number;
    setPlayerHeight: (n: number) => void;
};

export const PlayerContext = createContext<PlayerContextType | null>(null);

const initialPlayerState: PlayerState = {
    expanded: false,
    isPlaying: false,
    isVisible: false,
    progress: 0,
    duration: 0,
    type: undefined,
};

type Props = {
    children: JSX.Element | JSX.Element[];
};

export default function PlayerProvider({ children }: Props) {
    const { radio } = useContext(RadioContext) as RadioContextType;
    const { channels: webchannels } = useContext(ChannelContext) as ChannelContextType;

    const [playerState, playerDispatch] = useReducer<Reducer<PlayerState, PlayerAction>>(
        playerReducer,
        initialPlayerState,
    );

    const [playerHeight, setPlayerHeight] = useState(0);

    const value = useMemo(
        () => ({ playerState, playerDispatch, playerHeight, setPlayerHeight }),
        [playerState, playerHeight],
    );

    useEffect(() => {
        if (playerState.type !== 'RADIO') return;

        const presenters = radio?.presenters.map((presenter: Presenter) => presenter.name).join(' & ') || '';
        const media: Media = {
            audio: true,
            mediaUrl: radio?.audioHlsUrl,
            thumbnailUrl: radio?.show?.thumbnailUrl || radio?.presenters[0]?.thumbnailUrl || '',
            title: radio?.show?.name ?? '',
            subtitle: `
          ${presenters}
          ${moment(radio?.show?.startDate).format('HH:mm')} - 
          ${moment(radio?.show?.endDate).format('HH:mm')}
        `,
        };
        playerDispatch({ type: 'SET_MEDIA', payload: media });
    }, [playerState.type, radio]);

    useEffect(() => {
        if (playerState.type !== 'WEBCHANNEL') return;
        const current = webchannels?.find((item: WebChannel) => item.title === playerState.currentChannel);
        const metadata = channels.find((item: Channel) => item.id === playerState.currentChannel);

        if (!metadata) {
            console.error('Invalid metadata');
        }

        if (!current) {
            console.error('Invalid channel');
        }

        const media: Media = {
            audio: true,
            mediaUrl: current?.audioUrls.hls,
            thumbnailUrl: `assets/img/webchannels/${metadata?.background || ''}` || '',
            title: metadata?.title || '',
            subtitle: `${current?.lastSongs[0]?.title || ''} - ${current?.lastSongs[0]?.artist || ''}` || '',
        };
        playerDispatch({ type: 'SET_MEDIA', payload: media });
    }, [playerState.type, playerState.currentChannel, webchannels]);

    return <PlayerContext.Provider value={value}>{children}</PlayerContext.Provider>;
}
