import { IonCol, IonGrid, IonRow } from '@ionic/react';
import Title from 'components/Title';
import useResponsive from 'features/responsive/responsive';
import { useTranslation } from 'react-i18next';
import useAds from 'hooks/useAds';
import AdComponent from 'components/Ads/AdComponent';
import WavePatternContainer from 'components/WavePatternContainer';
import TopicCard from 'components/Cards/TopicCard';
import PageLayout from '../components/PageLayout';
import AdRightBlock from '../components/Ads/AdRightBlock';

const categoryData = {
    gaming: {
        title: 'menu.gaming',
        list: [
            {
                pictureUrl: '../assets/img/highscore.png',
                name: 'Highscore',
                id: '747',
                routerLink: '/radio/highscore',
            },
            {
                pictureUrl: '../assets/img/gaming_events.png',
                name: 'Events',
                id: '',
                routerLink: '/aktuell/news/gamingevent',
            },
            {
                pictureUrl: '../assets/img/Eldo_Podcast_GamerThings.jpg',
                name: 'Gamer Things',
                id: '',
                routerLink: '/aktuell/news/gamerthings',
            },
        ],
    },
    travel: {
        title: 'menu.travel',
        list: [
            {
                pictureUrl: '../assets/img/travel_citycheck.jpg',
                name: 'menu.categories.travel.cityCheck',
                id: '',
                routerLink: '/radio/travel/citycheck',
            },
            {
                pictureUrl: '../assets/img/travel_vakanz.jpg',
                name: 'menu.categories.travel.vacation',
                id: '',
                routerLink: '/radio/travel/vakanzdoheem',
            },
            {
                pictureUrl: '../assets/img/travel_explore.jpg',
                name: 'menu.categories.travel.explore',
                id: '',
                routerLink: '/radio/travel/explore',
            },
        ],
    },
    fashionAndBeauty: {
        title: 'menu.fashionAndBeauty',
        list: [
            {
                pictureUrl: '../assets/img/fashionAndBeauty_dressed.jpg',
                name: 'menu.categories.fashionAndBeauty.dressed',
                id: '',
                routerLink: '/radio/fashionAndBeauty/getdressed',
            },
            {
                pictureUrl: '../assets/img/fashionAndBeauty_tutorials.jpg',
                name: 'menu.categories.fashionAndBeauty.tutorials',
                id: '',
                routerLink: '/radio/fashionAndBeauty/tutorials',
            },
            {
                pictureUrl: '../assets/img/fashionAndBeauty_glowUp.jpg',
                name: 'menu.categories.fashionAndBeauty.glowUp',
                id: '',
                routerLink: '/radio/fashionAndBeauty/glowup',
            },
        ],
    },
    foodAndDrinks: {
        title: 'menu.foodAndDrinks',
        list: [
            // {
            //     pictureUrl: '../assets/img/foodAndDrinks_italianConcept.jpg',
            //     name: 'menu.categories.foodAndDrinks.italianConcept',
            //     id: '',
            //     routerLink: '/radio/foodAndDrinks/italianConcept',
            //   },
            {
                pictureUrl: '../assets/img/foodAndDrinks_recipes.jpg',
                name: 'menu.categories.foodAndDrinks.recipes',
                id: '',
                routerLink: '/radio/foodAndDrinks/recipes',
            },
            {
                pictureUrl: '../assets/img/foodAndDrinks_restauBars.jpg',
                name: 'menu.categories.foodAndDrinks.restauBars',
                id: '',
                routerLink: '/radio/foodAndDrinks/restaubars',
            },
            {
                pictureUrl: '../assets/img/foodAndDrinks_goodToKnow.jpg',
                name: 'menu.categories.foodAndDrinks.gootToknow',
                id: '',
                routerLink: '/radio/foodAndDrinks/goodtoknow',
            },
        ],
    },
};

function generateTopicCards(category: string | undefined) {
    // Check if the category exists in categoryData
    if (category && category in categoryData) {
        return (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-8 h-fit">
                {categoryData[category as keyof typeof categoryData].list.map(
                    (
                        item,
                        index, // Use type assertion here
                    ) => (
                        <TopicCard
                            key={index}
                            item={item}
                            routerLink={item.routerLink}
                            imgProps={{
                                className: 'h-[125px] grow',
                            }}
                        />
                    ),
                )}
            </div>
        );
    }
    return null;
}

export default function CategoryPage() {
    const { IsDesktop } = useResponsive();
    const { t } = useTranslation();
    const lastPathSegment = window.location.pathname.split('/').filter(Boolean).pop();
    const titleOfPage = categoryData[lastPathSegment as keyof typeof categoryData]?.title;
    const { ads } = useAds({ page: 'category-news-replays' });

    return (
        <PageLayout title={t(titleOfPage) ?? ''}>
            <IonGrid
                fixed
                className="min-h-[50%]"
            >
                {ads && <AdComponent ad={ads.data[0]} />}
                <WavePatternContainer
                    autoMargin
                    fixed="page"
                    reverse
                    position="top-right"
                    className="mb-0"
                    component="section"
                >
                    {IsDesktop ? (
                        <IonRow className="mb-blockInnerMobile md:mb-blockInner">
                            <IonCol size-xs={12}>
                                <Title
                                    main
                                    title={t(titleOfPage) ?? ''}
                                />
                            </IonCol>
                        </IonRow>
                    ) : (
                        ''
                    )}
                </WavePatternContainer>
                <AdRightBlock ad={ads?.data?.[1]}>{generateTopicCards(lastPathSegment)}</AdRightBlock>
                {ads && (
                    <AdComponent
                        ad={ads.data[2]}
                        className="mt-[50px]"
                    />
                )}
            </IonGrid>
        </PageLayout>
    );
}
