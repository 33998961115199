import { IonContent, IonHeader, IonItem, IonModal, IonToolbar } from '@ionic/react';
import { Link } from 'react-router-dom';
import useAuth from 'features/auth/useAuth';
import HeaderPlayerController from 'features/radio/component/HeaderPlayerController';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router';
import useAds from 'hooks/useAds';
import BurgerMenu from './BurgerMenu';
import { EldoLogo, CloseIcon } from './Icons';
import useClickOutside from '../hooks/useClickOutside';
import LoginForm from './LoginForm';
import BurgerMenuBtn from './BurgerMenuBtn';
import { ReactComponent as LoginIcon } from './Icons/loginicon.svg';
import Img from './Img';
import ResetPasswordForm from './ResetPasswordForm';
import SignupForm from './SignupForm';
import AdComponent from './Ads/AdComponent';

const MENU_ITEM_SELECTED_CLASSES =
    'relative font-bold before:transform before:left-1/2 before:-translate-x-1/2 before:absolute before:bottom-0 before:w-[80%] before:bg-[#F78E1E] before:h-[1px]';

export default function DesktopHeader({
    isScrolled,
    reduceBottomSpace,
}: {
    isScrolled?: boolean;
    reduceBottomSpace?: boolean;
}) {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState(false);
    const location = useLocation();
    const { logout, user } = useAuth();
    const openMenuButtonRef = useRef<HTMLDivElement>(null);
    const ref = useClickOutside(() => setIsOpen(false), [openMenuButtonRef]);

    const history = useHistory();

    const [isLoginOpen, setIsLoginOpen] = useState(false);
    const [isResetPwdOpen, setResetPwdOpen] = useState(false);
    const [isSignupOpen, setIsSignupOpen] = useState(false);
    const [counter, setCounter] = useState(0);
    const { ads } = useAds({ page: 'track-pixel', reloadOnChangeString: `${counter}`, isLoading: counter === 0 });

    useEffect(() => {
        if (!isLoginOpen) {
            setIsSignupOpen(false);
        }
    }, [isLoginOpen]);

    useEffect(() => {
        setIsOpen(false);
    }, [location]);

    useEffect(() => {
        if (isOpen) {
            setCounter(counter + 1);
        }
    }, [isOpen]);

    const menuItems = [
        { link: '/aktuell/radio', title: t('main.radio') },
        { link: '/events/agenda', title: t('main.events') },
        { link: '/aktuell/news', title: t('main.news') },
    ];

    const headerClasses = useMemo(() => {
        if (isScrolled) {
            return 'bg-light-background max-h-[100px]';
        }
        return 'bg-background h-[135px]';
    }, [isScrolled]);

    return (
        <IonHeader
            className={`${headerClasses} desktop-nav justify-content-between mx-auto justify-around hidden lg:flex relative ${
                reduceBottomSpace ? '-mb-[25px]' : ''
            }`}
        >
            <div className="flex items-center gap-4">
                <Link
                    to="/home"
                    className="h-[135px] max-w-[125px] pt-[10px]"
                >
                    <Img
                        imgClassName={`${isScrolled ? 'transform scale-[80%]' : ''} w-[115px] h-[127px]`}
                        src="/assets/img/logo.png"
                        alt="logo"
                    />
                </Link>
                <HeaderPlayerController mini={isScrolled} />
            </div>

            <div className=" flex items-center">
                {ads && <AdComponent ad={ads.data[0]} />}
                <div className="flex items-center">
                    {menuItems.map((item) => (
                        <IonItem
                            button
                            routerLink={item.link}
                            lines="none"
                            key={item.link}
                            className="typo-b1 regular hover:font-bold"
                        >
                            <span className={item.link === location.pathname ? MENU_ITEM_SELECTED_CLASSES : ''}>
                                {item.title}
                            </span>
                        </IonItem>
                    ))}

                    {user && (
                        <IonItem
                            button
                            onClick={() => history.push('/account')}
                            className="nav__login font-bold typo-b1"
                            lines="none"
                        >
                            Hey {user.firstname}!
                        </IonItem>
                    )}

                    {!user && (
                        <IonItem
                            button
                            onClick={() => setIsLoginOpen(true)}
                            className="nav__login typo-b1"
                            lines="none"
                        >
                            {t('main.login')}
                            <LoginIcon />
                        </IonItem>
                    )}
                </div>

                <div
                    className=""
                    ref={openMenuButtonRef}
                >
                    <BurgerMenuBtn
                        onClick={() => setIsOpen(!isOpen)}
                        isOpen={isOpen}
                    />
                </div>
            </div>

            <IonModal
                isOpen={isOpen}
                className={`burger-menu-modal ${isScrolled ? 'top-[100px]' : 'top-[135px]'}`}
                style={{ alignItems: 'start' }}
                animated={false}
                onDidDismiss={() => {
                    setIsOpen(false);
                }}
            >
                <IonContent>
                    <BurgerMenu
                        ref={ref}
                        hasBackgroundColor
                    />
                </IonContent>
            </IonModal>

            <IonModal
                isOpen={isLoginOpen}
                onDidDismiss={() => setIsLoginOpen(false)}
                className="auto-height"
                style={{
                    '--backdrop-opacity': 0.8,
                    '--ion-backdrop-color': '#141414',
                    '--ion-background-color': '#282828',
                    '--border-radius': '10px',
                }}
            >
                <div className="ion-page">
                    <div className="inner-content">
                        {!isSignupOpen ? (
                            <LoginForm
                                onClose={() => setIsLoginOpen(false)}
                                onDone={() => setIsLoginOpen(false)}
                                className="py-standard w-[350px]"
                                onForgotPasswordClick={() => setResetPwdOpen(true)}
                                onSignupClick={() => setIsSignupOpen(true)}
                            />
                        ) : (
                            <SignupForm
                                onLoginClick={() => setIsSignupOpen(false)}
                                className="py-standard w-[350px]"
                                onComplete={() => {
                                    setIsSignupOpen(false);
                                    setIsLoginOpen(true);
                                }}
                            />
                        )}
                        <button
                            className="absolute top-[25px] right-[25px]"
                            onClick={() => {
                                setIsLoginOpen(false);
                            }}
                        >
                            <CloseIcon />
                        </button>
                    </div>
                </div>
            </IonModal>
            <IonModal
                isOpen={isResetPwdOpen}
                onDidDismiss={() => setResetPwdOpen(false)}
                style={{
                    '--backdrop-opacity': 0.8,
                    '--ion-backdrop-color': '#141414',
                    '--height': 'auto',
                    '--ion-background-color': '#282828',
                    '--border-radius': '10px',
                }}
            >
                <button
                    className="absolute top-[25px] right-[25px]"
                    slot="end"
                    onClick={() => {
                        setResetPwdOpen(false);
                    }}
                >
                    <CloseIcon />
                </button>
                <div className="m-[64px]">
                    <ResetPasswordForm
                        onDone={() => {
                            setResetPwdOpen(false);
                            setIsLoginOpen(true);
                        }}
                    />
                </div>
            </IonModal>
        </IonHeader>
    );
}
