import { useIonToast } from '@ionic/react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { TrafficInfo } from 'types';
import {
    TRAFFIC_INFO_QUERY_KEY,
    deleteCameraFromFavorites,
    getTrafficInfo,
    postCameraToFavorites,
} from 'lib/ApiService';
import { TOAST_DURATION } from '../../constants';

export default function useTrafficInfos(id?: string) {
    const [present] = useIonToast();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const { data, status } = useQuery<TrafficInfo>({
        queryKey: [TRAFFIC_INFO_QUERY_KEY],
        queryFn: () => getTrafficInfo(id),
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    const addCameraToFavorites = useMutation({
        mutationFn: postCameraToFavorites,
        onSuccess: () => {
            present({ color: 'light', duration: TOAST_DURATION, message: t('camera_added') });
            queryClient.invalidateQueries([TRAFFIC_INFO_QUERY_KEY]);
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });
    const removeCameraFromFavorites = useMutation({
        mutationFn: deleteCameraFromFavorites,
        onSuccess: () => {
            queryClient.invalidateQueries([TRAFFIC_INFO_QUERY_KEY]);
            present({ color: 'light', duration: TOAST_DURATION, message: t('camera_removed') });
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });

    const cameraHighways = useMemo(() => {
        if (!data?.cameras) return [];

        return data.cameras.reduce((acc: string[], camera) => {
            const set = new Set([...acc]);
            return Array.from(set.add(camera.highway));
        }, []);
    }, [data]);

    const favoriteCams = useMemo(() => {
        if (!data?.cameras) return [];
        return data.cameras.filter((c) => c.favorite);
    }, [data]);

    const lastRoadNews = useMemo(() => {
        if (!data?.roadNews) return [];
        return [...data.roadNews].sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
    }, [data]);

    return {
        trafficInfos: data,
        addCameraToFavorites: addCameraToFavorites.mutate,
        removeCameraFromFavorites: removeCameraFromFavorites.mutate,
        favoriteCams,
        lastRoadNews,
        cameraHighways,
        isLoading: status === 'loading',
    };
}
