import { t } from 'i18next';
import { ReactComponent as Moon } from './Icons/moon.svg';

type UserThumbProps = {
    src: string;
};

export default function UserThumbnail({ src }: UserThumbProps) {
    return (
        <div className="relative  grid place-items-center">
            <Moon className="w-[24px] h-[24px]" />
            <div className="w-[50px] h-[50px] absolute top-[7.5px] left-[2.5px] rounded-full">
                {src && (
                    <img
                        className="w-[45px] h-[45px] object-cover rounded-full"
                        alt={t('main.user_profile')}
                        src={src}
                    />
                )}
            </div>
        </div>
    );
}
