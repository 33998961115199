export type CardListingProps = {
    children: React.ReactNode;
    className?: string;
    isVerticalCards?: boolean;
    oneColumn?: boolean;
};

export default function CardListing({ children, className, isVerticalCards, oneColumn }: CardListingProps) {
    return (
        <div
            className={`grid ${
                // eslint-disable-next-line no-nested-ternary
                !oneColumn
                    ? isVerticalCards
                        ? 'md:grid-cols-2 lg:grid-cols-4'
                        : 'grid-cols-1 md:grid-cols-2 xl:grid-cols-3 '
                    : ''
            }  gap-standardMobile md:gap-standard [&>*]:flex-1 ${className || ''}`}
        >
            {children}
        </div>
    );
}

export function CardListingItemFullWidth({ children, className, isVerticalCards, oneColumn }: CardListingProps) {
    return (
        <div
            className={
                !oneColumn
                    ? `${isVerticalCards ? 'md:col-span-2 lg:col-span-4' : 'md:col-span-2 xl:col-span-3'} ${
                          className || ''
                      }`
                    : className || ''
            }
        >
            {children}
        </div>
    );
}
