import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { useIonToast } from '@ionic/react';
import { useQuery } from '@tanstack/react-query';
import { Show } from '../../../types';
import { getAllShows } from '../../../lib/ApiService';
import { TOAST_DURATION } from '../../../constants';

export default function useAllShows() {
    const [present] = useIonToast();
    const { t } = useTranslation();

    const { data, isLoading, error } = useQuery<Show[]>(['allShows'], getAllShows, {
        onError: () => {
            // present(t('fetch_error'), TOAST_DURATION);
        },
    });

    return {
        error,
        data,
        isLoading,
    };
}
