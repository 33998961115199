import { ButtonHTMLAttributes } from 'react';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    value: string;
    forceHoverEffectActive?: boolean;
}

export default function Button({ type, value, className, forceHoverEffectActive, ...props }: ButtonProps) {
    return (
        <button
            className={`eldo-button typo-b1 regular px-[45px] ${
                forceHoverEffectActive ? 'text-[#333333] bg-white' : ''
            } ${className || ''}`}
            type={type}
            {...props}
        >
            {value}
        </button>
    );
}
