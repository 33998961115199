import { IonGrid } from '@ionic/react';
import PageLayout from 'components/PageLayout';
import Title from 'components/Title';
import Section from 'components/core/Section';
import useScript from 'hooks/useScript';
import { useTranslation } from 'react-i18next';

const script = `
(function(b,o,n,u,s){var a,t;a=b.createElement(u);a.async=1;a.src=s;t=b.getElementsByTagName(u)[0];t.parentNode.insertBefore(a,t);o[n]=o[n]||[]}) (document,window,'_qual_async','script','https://files.qualifio.com/kit/qualp.2.min.js');
_qual_async.push(['createIframe','qualifio_insert_place_478734','ia.eldo.lu','20','AC246746-ED11-A4CB-0C01-D3F6D3FDF824','100%','1200','','','','max-width:810px;margin:0 auto;']);
`;

export default function BabyOfTheMonthPage() {
    const { t } = useTranslation();

    useScript({
        script,
    });

    return (
        <PageLayout title={t('baby_of_the_month')}>
            <IonGrid fixed>
                <Section>
                    <Title
                        main
                        title={t('baby_of_the_month')}
                        className="max-md:hidden mb-blockInner"
                    />
                    <div
                        id="qualifio_insert_place_478734"
                        className="qualifio_iframe_wrapper"
                    />
                </Section>
            </IonGrid>
        </PageLayout>
    );
}
