/* eslint no-case-declarations: 0 */
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { emailRegex, passwordRegex } from 'lib/utils';
import Input from 'components/Input';
import { ErrorMessage } from '@hookform/error-message';
import InputError from 'components/InputError';
import { useEffect, useState } from 'react';
import OtpInput from 'components/OtpInput';
import { useIonRouter, useIonToast } from '@ionic/react';
import EditAccount from 'features/auth/components/EditAccount';
import useAuth from 'features/auth/useAuth';

import useMyAccount from 'features/auth/useMyAccount';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { User } from 'features/auth/types';
import { useMutation } from '@tanstack/react-query';
import { updateUserEmail } from 'lib/ApiService';
import { TOAST_DURATION } from '../constants';

enum EditAccountEmailStep {
    start = 1,
    otp = 2,
    end = 3,
}

export default function EditAccountEmailPage() {
    const { t } = useTranslation();
    const router = useIonRouter();

    const [present] = useIonToast();

    const [step, setStep] = useState<EditAccountEmailStep>(EditAccountEmailStep.start);
    const [otp, setOtp] = useState<string>();
    const [email, setEmail] = useState<string>('');
    const [token, setToken] = useState<string>('');

    const { logout } = useAuth();

    useEffect(() => {
        setStep(EditAccountEmailStep.start);
    }, []);

    const schema = yup
        .object({
            email: yup
                .string()
                .required(t('email_required'))
                .matches(emailRegex, { message: t('email_invalid') }),
        })
        .required();

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        reset,
        formState: { errors },
    } = useForm<User>({
        resolver: yupResolver(schema),
    });

    const { user } = useMyAccount();

    const updateUserEmailInit = useMutation({
        mutationFn: updateUserEmail.init,
        onSuccess: () => {
            // present({ color: 'light', duration: TOAST_DURATION, message: t('account_picture_updated') });
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });

    const updateUserEmailConfirm = useMutation({
        mutationFn: updateUserEmail.confirm,
        onSuccess: () => {
            // present({ color: 'light', duration: TOAST_DURATION, message: t('account_picture_updated') });
        },
        onError: () => {
            present(t('request_error'), TOAST_DURATION);
        },
    });

    const updateUserEmailComplete = useMutation({
        mutationFn: updateUserEmail.complete,
        onSuccess: () => {
            present({ color: 'success', duration: TOAST_DURATION, message: t('account_email_updated') });
            logout();
            router.push('/');
        },
        onError: () => {
            present({ color: 'success', duration: TOAST_DURATION, message: t('request_error') });
        },
    });

    useEffect(() => {
        if (user) {
            reset(user);
        }
    }, [user]);

    async function onSubmit(data?: User) {
        switch (step) {
            case EditAccountEmailStep.start:
                if (!data?.email) return;
                await updateUserEmailInit.mutateAsync(data?.email);
                setStep(EditAccountEmailStep.otp);
                setEmail(data?.email);
                break;
            case EditAccountEmailStep.otp:
                if (!otp) return;
                const response = (await updateUserEmailConfirm.mutateAsync({ email, otp })) as { token: string };
                setToken(response.token);
                setStep(EditAccountEmailStep.end);
                break;
            case EditAccountEmailStep.end:
                if (!data?.email) return;
                await updateUserEmailComplete.mutateAsync({ email, newEmail: data?.email, token });
                setStep(EditAccountEmailStep.start);
                router.push('/account');
                break;
            default:
                break;
        }
    }

    if (step === EditAccountEmailStep.end) {
        return (
            <EditAccount
                onSubmit={handleSubmit(onSubmit)}
                title={t('my-new-email-address')}
            >
                <Input
                    hasError={!!errors.email}
                    placeholder={t('email')}
                    {...register('email')}
                />
                <ErrorMessage
                    errors={errors}
                    name="email"
                    as={<InputError />}
                />
            </EditAccount>
        );
    }

    if (step === EditAccountEmailStep.otp) {
        return (
            <EditAccount
                title={t('my-email-address')}
                onSubmit={(e) => {
                    e.preventDefault();
                    onSubmit();
                }}
            >
                <OtpInput
                    shouldAutoFocus
                    isInputNum
                    value={otp}
                    onChange={setOtp}
                    numInputs={4}
                    separator=""
                    className="my-[15px]"
                />
            </EditAccount>
        );
    }
    return (
        <EditAccount
            onSubmit={handleSubmit(onSubmit)}
            title={t('my-email-address')}
        >
            <Input
                disabled
                hasError={!!errors.email}
                placeholder={t('email')}
                {...register('email')}
            />
            <ErrorMessage
                errors={errors}
                name="email"
                as={<InputError />}
            />
        </EditAccount>
    );
}
