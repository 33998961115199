import { IonItem, IonLabel } from '@ionic/react';
import AppIcon from 'components/Icons/AppIcon';

type MenuItemProps = {
    iconName: string;
    label: string;
    linkTo: string;
};

function MenuItem({ iconName, label, linkTo }: MenuItemProps) {
    return (
        <IonItem
            routerLink={linkTo}
            className="menu-item menu-item--with-icon group/menu-item"
            detail={false}
        >
            <div className="flex items-center justify-center w-full h-[100px] flex-col">
                <AppIcon
                    name={iconName}
                    classes="transition-all transform group-hover/menu-item:scale-[90%]"
                />
                <IonLabel>{label}</IonLabel>
            </div>
        </IonItem>
    );
}

export default MenuItem;
