import { Swiper, SwiperSlide } from 'swiper/react';
import WavePatternContainer from 'components/WavePatternContainer';
import { Dispatch, SetStateAction } from 'react';
import useResponsive from 'features/responsive/responsive';
import ChannelSlide from './ChannelSlide';
import WebChannelSlide from './WebChannelSlide';
import RadioSlide from './RadioSlide';
import { Channel } from '../types';
import useRadio from '../hooks/useRadio';

const bkPoints = {
    576: {
        slidesPerView: 2.3,
        spaceBetween: 16,
    },
    768: {
        slidesPerView: 3.3,
        spaceBetween: 16,
    },
    1024: {
        slidesPerView: 4.3,
        spaceBetween: 30,
    },
};

type ChannelSwiperProps = {
    currentChannel: Channel;
    setCurrentChannel: Dispatch<SetStateAction<Channel>>;
    channels: Channel[];
};

export default function ChannelsSwiper({ currentChannel, setCurrentChannel, channels }: ChannelSwiperProps) {
    const { data, isLoading } = useRadio();
    const { IsMobile } = useResponsive();

    return (
        <WavePatternContainer
            className={IsMobile ? 'full-width' : ''}
            patternClassName="-top-5"
            reverse
            fixed="page"
            position="top-right"
            onlyMobile
        >
            <Swiper
                className={IsMobile ? '!pl-[var(--padding-start)] !md:pl-0' : ''}
                loopPreventsSlide
                loop
                freeMode
                spaceBetween={10}
                slidesPerView={1.3}
                breakpoints={bkPoints}
            >
                {channels.map((channel, index) => (
                    <SwiperSlide key={channel.id}>
                        <ChannelSlide
                            setActiveSlide={(slideIndex: number) => {
                                setCurrentChannel(channels[slideIndex]);
                            }}
                            active={currentChannel.id === channel.id}
                            index={index}
                        >
                            {channel.id === 'eldo' ? (
                                <RadioSlide
                                    data={data}
                                    className="my-[10px] mx-[30px]"
                                />
                            ) : (
                                <WebChannelSlide
                                    background={channel.background}
                                    title={channel.title}
                                />
                            )}
                        </ChannelSlide>
                    </SwiperSlide>
                ))}
            </Swiper>
        </WavePatternContainer>
    );
}
