import { useIonRouter } from '@ionic/react';

import Title from 'components/Title';
import { useTranslation } from 'react-i18next';
import useAuth from 'features/auth/useAuth';
import Button from 'components/Button';
import Card from 'components/Card';
import { AddIcon } from 'components/Icons';
import Carousel from 'components/Carousel';
import { Camera } from 'types';
import useResponsive from 'features/responsive/responsive';
import useTrafficInfos from './useTrafficInfo';
import TrafficCameraCard from './TrafficCameraCard';

export default function TrafficFavoriteCams() {
    const { t } = useTranslation();
    const { isAuthenticated } = useAuth();
    const history = useIonRouter();
    const { favoriteCams } = useTrafficInfos();
    const { IsMobile } = useResponsive();

    const renderCams = () => {
        if (!favoriteCams.length) {
            return (
                <Card
                    className=" flex items-center"
                    onClick={() => history.push('/traffic-info/cameras', 'none')}
                >
                    <div className="rounded-full w-[45px] h-[45px] bg-lighter-background mr-4 flex justify-center items-center">
                        <AddIcon />
                    </div>
                    <p className="typo-b1 regular">{t('add_new_camera')}</p>
                </Card>
            );
        }

        return (
            <Carousel<Camera>
                spaceBetween={IsMobile ? 16 : 30}
                slidesPerView={IsMobile ? 1.3 : 3}
                data={favoriteCams}
                itemKey={(n, index) => `${n.id}${index}`}
            >
                {(n) => (
                    <TrafficCameraCard
                        className="w-full"
                        camera={n}
                    />
                )}
            </Carousel>
        );
    };

    const renderLogin = () => (
        <div>
            <p className="typo-b2">{t('favorite_cam_login')}</p>
            <Button
                value={t('login')}
                className="bg-white text-black mt-block-innerMobile md:mt-block-inner max-w-[400px]"
                onClick={() => history.push('/login', 'none')}
            />
        </div>
    );

    return (
        <section className="mb-blockMobile md:mb-block">
            <Title
                title={t('favorites_cam')}
                path="/traffic-info/cameras"
                direction="none"
                className="mb-blockInnerMobile md:mb-blockInner"
            />

            {!isAuthenticated ? renderLogin() : renderCams()}
        </section>
    );
}
