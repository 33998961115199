import Card from 'components/Card';
import Img from 'components/Img';
import Pill from 'components/Pill';
import TheoPlayerCustom from 'components/TheoplayerCustom';
import Waves from 'components/Icons/Waves_pattern.png';
import { HTMLAttributes, useEffect, useMemo, useRef } from 'react';
import { Tags } from 'types';
import useResponsive from 'features/responsive/responsive';
import AppIcon from 'components/Icons/AppIcon';
import ellipsisText, { generateUniqueId } from 'lib/utils';
import PlayerButton from 'features/radio/component/player/PlayerButton';
import { useHistory } from 'react-router';
import clsx from 'clsx';

export type CardVerticalProps = {
    thumbnailUrl: string;
    video?: string | null;
    title?: string;
    subtitle?: string;
    footerSlot?: React.ReactNode;
    titleRightSlot?: React.ReactNode;
    className?: string;
    imgSize?: 'small' | 'medium' | 'large' | 'extrasmall';
    imageCover?: 'object-cover' | 'object-fit' | 'object-fill';
    titleTruncated?: boolean | 1 | 2 | 3 | 4;
    tags?: Array<Tags>;
    tagsIcon?: Array<string>;
    hasWavePattern?: boolean;
    bgGradientPurple?: boolean;
    nbMaxCaractersTags?: number;
    onTagClick?: (tag: Tags) => void;
    linkForPill?: string;
    linkTo?: string;
    externalLink?: string;
    hasPlayBtnOnTopOfImg?: boolean;
    hasCardHoverEffect?: boolean;
    displayAllDescription?: boolean;
    slotTitle?: React.ReactNode;
    imgClassName?: string;
    imgAspectRatio?: string;
} & HTMLAttributes<HTMLDivElement>;

export default function CardVertical({
    footerSlot,
    titleRightSlot,
    subtitle,
    thumbnailUrl,
    title,
    video,
    className,
    imgSize = 'large',
    imageCover = 'object-cover',
    titleTruncated,
    tags,
    hasWavePattern = true,
    tagsIcon,
    bgGradientPurple,
    nbMaxCaractersTags,
    onTagClick,
    linkForPill,
    linkTo,
    externalLink,
    hasPlayBtnOnTopOfImg,
    hasCardHoverEffect = true,
    displayAllDescription,
    slotTitle,
    imgClassName,
    imgAspectRatio,
    ...rest
}: CardVerticalProps) {
    const history = useHistory();
    const tagCharacterSum =
        tags?.slice(0, 2).reduce((accumulator, currentValue) => accumulator + currentValue.name.length, 0) || 0;
    const { IsMobile } = useResponsive();
    const cardVerticalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleContextMenu = (event: MouseEvent) => {
            if (cardVerticalRef.current && cardVerticalRef.current.contains(event.target as Node | null)) {
                event.preventDefault();
            }
        };

        if (cardVerticalRef.current) {
            cardVerticalRef.current.addEventListener('contextmenu', handleContextMenu);
        }

        return () => {
            if (cardVerticalRef.current) {
                cardVerticalRef.current.removeEventListener('contextmenu', handleContextMenu);
            }
        };
    }, []);

    const titleTruncatedClass = useMemo(() => {
        if (titleTruncated === true) {
            return 'line-clamp-2';
        }
        switch (titleTruncated) {
            case 1:
                return 'line-clamp-1';
            case 2:
                return 'line-clamp-2';
            case 3:
                return 'line-clamp-3';
            case 4:
                return 'line-clamp-4';
            default:
                return '';
        }
    }, [titleTruncated]);

    const getImgSizeClass = () => {
        if (imgAspectRatio?.length) return '';
        if (imgSize === 'extrasmall') return 'h-[100px]';
        if (imgSize === 'large') return 'h-[300px]';
        if (imgSize === 'medium') return `${IsMobile ? 'h-[260px]' : 'h-[220px]'}`;
        return 'h-[150px]';
    };

    const getTitleMarginTopClass = () => {
        let res = '';
        if (tagsIcon?.length) res = '-mt-[25px]';
        else if (tags?.length) res = '-mt-[16.5px]';
        return `${res} pt-[10px]`;
    };

    const handleLinkClick = (event?: React.MouseEvent<HTMLElement>, tag?: Tags) => {
        if (externalLink) {
            window.open(externalLink, '_blank');
            return;
        }
        const linkToGo = (tag?.publicTag && linkForPill ? `${linkForPill}${tag?.publicTag}` : linkTo) ?? '';
        if (tag != null && onTagClick) {
            onTagClick(tag);
            event?.stopPropagation();
            return;
        }
        if (linkToGo !== '') {
            if (event?.ctrlKey || event?.button === 1 || event?.button === 2) {
                window.open(linkToGo, '_blank');
            } else {
                history.push(linkToGo);
            }
        }
    };

    function getPillComponent(tag: Tags, index: number) {
        return (
            <div
                key={index}
                onMouseDown={(e) => {
                    handleLinkClick(e, tag);
                }}
            >
                <Pill
                    title={tag.name}
                    key={index}
                    className="capitalize w-max !text-[0.85rem]"
                >
                    {(tagCharacterSum > 16 && index !== 0) ||
                    (tag.name.length > 8 && tags?.length !== 1) ||
                    (tagCharacterSum > 16 && tags?.length === 1)
                        ? ellipsisText(tag.name, 6)
                        : tag.name}
                </Pill>
            </div>
        );
    }

    return (
        <Card
            hasCardHoverEffect={hasCardHoverEffect}
            bgGradientPurple={bgGradientPurple}
            className={`flex flex-col px-[0px] py-[0px] overflow-auto cursor-pointer ${className || ''}`}
            {...rest}
            onMouseDown={(e) => handleLinkClick(e)}
            onClick={(e) => handleLinkClick(e)}
        >
            <div
                className={clsx('p-[10px] relative', (title || subtitle) && 'pb-0')}
                ref={cardVerticalRef}
            >
                {video ? (
                    <TheoPlayerCustom
                        src={video}
                        type="application/x-mpegURL"
                        className={`${getImgSizeClass()} w-full [&>video]:object-cover rounded overflow-hidden`}
                        thumbnailUrl={thumbnailUrl}
                        controls={{
                            fullscreen: true,
                        }}
                    />
                ) : (
                    <Img
                        alt={title}
                        src={thumbnailUrl}
                        className={getImgSizeClass()}
                        hasCardHoverEffect={hasCardHoverEffect}
                        imgClassName={`${imageCover} ${imgAspectRatio || ''} ${imgClassName || ''} h-full w-full`}
                    />
                )}
                {hasPlayBtnOnTopOfImg ? (
                    <PlayerButton
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                        onClick={() => {}}
                        type="play"
                        animate={false}
                    />
                ) : (
                    ''
                )}
                {hasWavePattern ? (
                    <div className="absolute -bottom-[8px] left-0">
                        <img
                            src={Waves}
                            alt=""
                        />
                    </div>
                ) : (
                    ''
                )}
            </div>

            {tags?.length || tagsIcon?.length ? (
                <div className="flex mx-[20px] gap-[10px] overflow-x-hidden overflow-y-hidden transform -translate-y-1/2 translate-x-0 max-w-[100%]">
                    {(tags || []).slice(0, 2).map((tag: Tags, index) => {
                        return tag.name && getPillComponent(tag, index);
                    })}
                    {(tagsIcon || []).slice(0, 2).map((tag: string) => {
                        return (
                            <AppIcon
                                key={generateUniqueId()}
                                name={tag}
                                classes="bg-[#6158db] h-[50px] w-[50px] rounded-[5px]"
                            />
                        );
                    })}
                </div>
            ) : (
                ''
            )}

            {(title || subtitle) && (
                <div className={`p-[20px] ${getTitleMarginTopClass()} flex-1 flex justify-between`}>
                    <div className="text-left mt-3 break-keep max-w-full">
                        {slotTitle || ''}
                        {title ? <div className={`typo-b1 font-semibold ${titleTruncatedClass}`}>{title}</div> : ''}
                        {subtitle ? (
                            <div className={`mt-3 ${!displayAllDescription ? 'line-clamp-3' : ''} typo-b1 regular`}>
                                {subtitle}
                            </div>
                        ) : (
                            ''
                        )}
                    </div>
                    <div className="self-center">{titleRightSlot || ''}</div>
                </div>
            )}
            {footerSlot && <div className="px-[20px] pt-[20px] pb-[10px] text-left typo-b2">{footerSlot}</div>}
        </Card>
    );
}
