import React, { useContext, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { IonCol } from '@ionic/react';
import Title from 'components/Title';
import useResponsive from 'features/responsive/responsive';
import { Channel, Presenter, WebChannel } from '../types';
import RadioCard from '../../../components/Cards/RadioCard';
import { RadioContext, RadioContextType } from '../RadioContext';
import { ChannelContext, ChannelContextType } from '../ChannelContext';
import { PlayerContext, PlayerContextType } from '../PlayerContext';
import LastSongs from './LastSongs';

function ChannelContent({ channel, slotAd }: { channel: Channel; slotAd: React.ReactNode | null }) {
    const [currentWebChannel, setCurrentWebChannel] = useState<WebChannel | null>();
    const { channels } = useContext(ChannelContext) as ChannelContextType;
    const { radio, radioLoading } = useContext(RadioContext) as RadioContextType;
    const { playerState, playerDispatch } = useContext(PlayerContext) as PlayerContextType;
    const { t } = useTranslation();
    const { IsDesktop, IsMobile } = useResponsive();
    const isOnAir = channel.id === 'eldo';

    useEffect(() => {
        const current = channels?.find((item: WebChannel) => item.title === channel.id);
        if (current) {
            setCurrentWebChannel(current);
        }
    }, [channel, channels]);

    const presenters: string = useMemo(
        () => radio?.presenters?.map((presenter: Presenter) => presenter.name).join(' - ') || '',
        [radio],
    );

    const onRadioClick = () => {
        playerDispatch({
            type: 'TOGGLE_RADIO',
        });
    };

    const onChannelClick = () => {
        if (currentWebChannel?.title) {
            playerDispatch({
                type: 'TOGGLE_WEBCHANNEL',
                payload: currentWebChannel.title,
            });
        }
    };

    if (radioLoading) {
        return <div>Loading</div>;
    }

    return (
        <IonCol>
            <div className="flex max-md:flex-col gap-standardMobile md:gap-standard">
                <div>
                    <div>
                        {IsDesktop && (
                            <Title
                                title={isOnAir ? t('on_air') : channel.title}
                                className="mb-blockInnerMobile md:mb-blockInner"
                            />
                        )}

                        {channel.id === 'eldo' && (
                            <RadioCard
                                src={
                                    radio?.presenters?.[0]?.thumbnailUrl ||
                                    `assets/img/webchannels/${channel.background}`
                                }
                                title={radio?.show?.name || ''}
                                liveStart={moment(radio?.show?.startDate).format('HH:mm')}
                                liveEnd={moment(radio?.show?.endDate).format('HH:mm')}
                                showRunner={presenters}
                                isPlaying={playerState.isPlaying && playerState.type === 'RADIO'}
                                onPlayerClick={onRadioClick}
                                isVertical
                                displayWatchBtn
                                isLive={isOnAir}
                                sponsor={radio?.show?.sponsor?.image}
                            />
                        )}

                        {channel.id !== 'eldo' && (
                            <RadioCard
                                src={`assets/img/webchannels/${channel.background}`}
                                title={currentWebChannel?.lastSongs[0].title || ''}
                                showRunner={currentWebChannel?.lastSongs[0].artist || ''}
                                isPlaying={
                                    playerState.isPlaying &&
                                    playerState.type === 'WEBCHANNEL' &&
                                    playerState.currentChannel === channel.id
                                }
                                onPlayerClick={onChannelClick}
                                isVertical
                                displayWatchBtn
                                isLive={isOnAir}
                            />
                        )}
                    </div>

                    <div className="mt-block-innerMobile md:mt-block-inner">
                        {currentWebChannel && <LastSongs songs={radio?.song ?? []} />}
                    </div>
                </div>
                <div className="min-w-[300px]">{slotAd}</div>
            </div>
        </IonCol>
    );
}

export default React.memo(ChannelContent);
